import React from 'react'
import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import analyzeIcon from '../../Assets/Images/Community/assess.png';
import alignmentIcon from '../../Assets/Images/Community/benchmark.png';
import directionIcon from '../../Assets/Images/Community/calibrate.png';
import CommunityTestimonial from '../../Components/Community/CommunityTestimonial';

const CommunityInviteInfo = (props) => {

  const { id } = useParams();

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this assessment</h3>
        <p>Invite your team to take this assessment with you, and view group and individual results to find where you're aligned and where you need to align to maximize community engagement!</p>

        <div className="invite-reasons">
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon" /><br />
            <div className="height30"></div>
            <span>Assess</span><br />
            Measure your performance in key areas concerning engagement
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="alignment icon" /><br />
            <div className="height30"></div>
            <span>Benchmark</span><br />
            Check how you compare with similarly-sized churches
          </div>

          <div className="reason">
            <img src={directionIcon} alt="direction icon" /><br />
            <div className="height30"></div>
            <span>Calibrate</span><br />
            Align around action based on carefully assessed data
          </div>

        </div>

        <div className="cta-btn">
          <Link to={"/community/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>

        {/* <CommunityTestimonial /> */}


      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/community/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}

    </div>
  );
}

export default CommunityInviteInfo;
