import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../../../Assets/Images/Bookkeeping/Testimonial1.png';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../../../Assets/Images/Bookkeeping/leftQuote.png';
import bottomQ from '../../../Assets/Images/Bookkeeping/rightQuote.png';
import quotation from "../../../Assets/Images/Bookkeeping/quote-single.png";

const Testimonial = () => {

  const { id } = useParams();

  return (
    <div className="testimonial-container">
        <div className="testimonial">
        <div className="testi">
            <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>I was hesitant to use this tool, knowing it would highlight many gaps. It did just that, which has helped us resolve many of our issues. We're happily growing in our financial stewardship with increased competence. We trust ACS Technologies and our congregation can rely on us to be faithful too!</p>
            
            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{transform:'rotate(180deg)'}} alt="quote icon" /></div></div>
            
          </div>
        {/* <div>
          <div className="avatar">
            <img src={testimonialImage} alt="pastor" width={125} height={125} />
          </div>
        </div> */}
        <div style={{display:'flex', justifyContent:'center' , flexDirection:'column', alignItems:'center'}}>          
          <div className="designation">
          Peter Wallace, <p className="designation-inner">Executive Pastor, Faithful Fellowship</p>
          {/* <p className="designation-inner">The ACS Technologies Team</p>  */}
          </div>
        </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
  );
}

export default Testimonial;
