import { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate, useSearchParams } from "react-router-dom";
import Select from 'react-select';

import prevArrow from '../../../Assets/Images/Community/prev-arrow.svg';
import nextArrow from '../../../Assets/Images/Community/next-arrow.svg';
import hourGlass from '../../../Assets/Images/Community/hourglass.gif';


// import '../App.scss';
import CommunityAdditionalQuestionContext from '../../../Store/Community/CommunityAdditionalQuestionContext';

const jobTitleValues = [
  {value: "Other", label: "Other"},
  {value: "Administrative Pastor", label: "Administrative Pastor"},
  {value: "Lead Pastor", label: "Lead Pastor"},
  {value: "Campus Pastor", label: "Campus Pastor"},
  {value: "Children's Pastor", label: "Children's Pastor"},
  {value: "College Pastor", label: "College Pastor"},
  {value: "Community Pastor", label: "Community Pastor"},
  {value: "Connections Pastor", label: "Connections Pastor"},
  {value: "Creative Arts Pastor", label: "Creative Arts Pastor"},
  {value: "Discipleship Pastor", label: "Discipleship Pastor"},
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Family Pastor", label: "Family Pastor"},
  {value: "High School Pastor", label: "High School Pastor"},
  {value: "Leadership Development Pastor", label: "Leadership Development Pastor"},
  {value: "Middle School Pastor", label: "Middle School Pastor"},
  {value: "Missions Pastor", label: "Missions Pastor"},
  {value: "Outreach Pastor", label: "Outreach Pastor"},
  {value: "Personal Assistant to Lead Pastor", label: "Personal Assistant to Lead Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Small Group Pastor", label: "Small Group Pastor"},
  {value: "Stewardship Pastor", label: "Stewardship Pastor"},
  {value: "Student Pastor", label: "Student Pastor"},
  {value: "Teacher Pastor", label: "Teacher Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},  
  {value: "AV Director", label: "AV Director"},
  {value: "Bookkeeper", label: "Bookkeeper"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Children's Ministry Coordinator", label: "Children's Ministry Coordinator"},
  {value: "Communications Coordinator", label: "Communications Coordinator"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Communications Project Manager", label: "Communications Project Manager"},
  {value: "Content Writer", label: "Content Writer"},
  {value: "Digital Media Analyst", label: "Digital Media Analyst"},
  {value: "Events Coordinator", label: "Events Coordinator"},
  {value: "Facility Manager", label: "Facility Manager"},
  {value: "Graphic Designer", label: "Graphic Designer"},
  {value: "Groups Director", label: "Groups Director"},
  {value: "Guest Services Director", label: "Guest Services Director"},
  {value: "Human Resources Coordinator", label: "Human Resources Coordinator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Mission and Outreach Coordinator", label: "Mission and Outreach Coordinator"},
  {value: "Office Manager", label: "Office Manager"},
  {value: "Online Community Manager", label: "Online Community Manager"},
  {value: "Pastoral Care Minister", label: "Pastoral Care Minister"},
  {value: "Preschool Director", label: "Preschool Director"},
  {value: "Production Coordinator", label: "Production Coordinator"},
  {value: "Social Media Manager", label: "Social Media Manager"},
  {value: "Video Producer", label: "Video Producer"},
  {value: "Volunteer Coordinator", label: "Volunteer Coordinator"},
  {value: "Web Developer", label: "Web Developer"},
  {value: "Worship Leader", label: "Worship Leader"},
];

const jobTitleValuesNew = [
  {value: "Executive Pastor", label: "Executive Pastor"},
  {value: "Senior Pastor", label: "Senior Pastor"},
  {value: "Worship Pastor", label: "Worship Pastor"},
  {value: "Youth Pastor", label: "Youth Pastor"},
  {value: "Director of Children's Ministry", label: "Director of Children's Ministry"},
  {value: "Chief Financial Officer", label: "Chief Financial Officer"},
  {value: "Church/Business Administrator", label: "Church/Business Administrator"},
  {value: "IT Director", label: "IT Director"},
  {value: "Communications Director", label: "Communications Director"},
  {value: "Administrative Assistant", label: "Administrative Assistant"},
  {value: "Financial Administrative Assistant", label: "Financial Administrative Assistant"},
  {value: "Elder/Deacon", label: "Elder/Deacon"},
  {value: "Congregant", label: "Congregant"},
  
];

const denominationValues = [
{ value: "African Meth. Episc. Zion", label: "African Meth. Episc. Zion" },
{ value: "African Methodist Episcopal", label: "African Methodist Episcopal" },
{ value: "Assembly of God", label: "Assembly of God" },
{ value: "Baptist", label: "Baptist" },
{ value: "Baptist - Missionary" , label: "Baptist - Missionary" },
{ value: "Baptist - National", label: "Baptist - National" },
{ value: "Baptist - SBC", label: "Baptist - SBC" },
{ value: "Catholic", label: "Catholic" },
{ value: "Christian Methodist Episcopal", label: "Christian Methodist Episcopal" },
{ value: "Church of Christ", label: "Church of Christ" },
{ value: "Church of God", label: "Church of God" },
{ value: "Church of God in Christ", label: "Church of God in Christ" },
{ value: "Episcopal", label: "Episcopal" },
{ value: "Evangelical", label: "Evangelical" },
{ value: "Jewish", label: "Jewish" },
{ value: "Lutheran", label: "Lutheran" },
{ value: "Lutheran - ELCA", label: "Lutheran - ELCA" },
{ value: "Lutheran - Missouri Synod", label: "Lutheran - Missouri Synod" },
{ value: "Methodist", label: "Methodist" },
{ value: "Non - Denominational", label: "Non - Denominational" },
{ value: "Other Denomination", label: "Other Denomination" },
{ value: "Pentecostal", label: "Pentecostal" },
{ value: "Presbyterian", label: "Presbyterian" },
]

const Details = (props) => {

  const addCtx                        = useContext(CommunityAdditionalQuestionContext);
  let navigate                        = useNavigate();
  const { id }                        = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving]       = useState(false);
  const [isLoading, setIsLoading]     = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isMember, setIsMember]       = useState(true);

  const [userId, setUserId]     = useState(0);
  const [firstName, setFirstName]     = useState("");
  const [lastName, setLastName]       = useState("");
  // const [fullName, setFullName] = useState("");
  const [email, setEmail]             = useState("");
  const [jobTitle, setJobTitle]       = useState("");
  const [selectedValue, setSelectedValue] = useState();
  const [selectedJobTitleValue, setSelectedJobTitleValue] = useState();
  const [jobTitleMember, setJobTitleMember]       = useState("");
  const [churchName, setChurchName]   = useState("");
  const [schoolName, setSchoolName]   = useState("");
  const [denomination, setDenomination] = useState("");
  const [zipCode, setZipCode]         = useState("");
  const [firstNameError, setFirstNameError]       = useState(false);
  const [lastNameError, setLastNameError]         = useState(false);
  // const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError]               = useState(false);
  const [jobTitleError, setJobTitleError]         = useState(false);
  const [churchNameError, setChurchNameError]     = useState(false);
  const [denominationError, setDenominationError] = useState(false);
  const [schoolNameError, setSchoolNameError]     = useState(false);
  const [zipCodeError, setZipCodeError]           = useState(false);
  const [showMyResultsBtn, setShowMyResultsBtn] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [firstFormAttendance, setFirstFormAttendance] = useState(0);
  const [firstFormZipCode, setFirstFormZipCode] = useState("");
  const [userQnA, setUserQnA]                     = useState([]);

  useEffect(() => {
    getUserDetails();
    getFirstFormDetails();
    getUserAnswers();
    // if(searchParams.get("member") != null) {
    //   getUserDetails();
    // } else {
    //   setIsMember(false);
    // }
    // document.body.style.backgroundColor = "#EEEEEE";
  }, []);

  // const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  // const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  
  useEffect(() => {
    
    const updateFormCtxDetails = (fieldName, value) => {
      if (value !== addCtx.formDetails?.[fieldName]) {
        addCtx.addFormDetails(prev => ({ ...prev, [fieldName]: value }));
      }
    };

    // updateFormCtxDetails("fullName", fullName);
    updateFormCtxDetails("firstName", firstName);
    updateFormCtxDetails("lastName", lastName);
    updateFormCtxDetails("email", email);
    updateFormCtxDetails("jobTitle", jobTitle);
    updateFormCtxDetails("churchName", churchName);
    updateFormCtxDetails("denomination", denomination);
    updateFormCtxDetails("zipCode", zipCode);

  }, [firstName, lastName, email, jobTitle, churchName, zipCode, denomination]);

  useEffect(() => {
    if (isMember) {
      if (firstName?.length && lastName?.length && email?.length && jobTitle?.length) {
        setBtnDisabled(false);
      } else setBtnDisabled(true);
    } else if(!isMember) {
      if (firstName?.length && lastName?.length && churchName?.length && email?.length && jobTitle?.length && denomination?.length) {
        setBtnDisabled(false);
      } else setBtnDisabled(true);
    }
  }, [churchName, denomination, email, emailRegex, firstName, jobTitle, lastName, isMember]);

  useEffect(() => {
    let formDetails = addCtx.formDetails;

    if (formDetails?.firstName?.length)  setFirstName(formDetails.firstName);
    if (formDetails?.lastName?.length)   setLastName(formDetails.lastName);
    // if (formDetails?.fullName?.length)  setFullName(formDetails.fullName);
    if (formDetails?.email?.length)      setEmail(formDetails.email);
    if (formDetails?.churchName?.length) setChurchName(formDetails.churchName);
    // if (formDetails?.denomination?.length) setDenomination(formDetails.denomination);
    if (formDetails?.zipCode?.length)    setZipCode(formDetails.zipCode);
    if (formDetails?.jobTitle?.length) setJobTitle(formDetails.jobTitle);

    // if (formDetails?.jobTitle?.length) {
    //   let selected = jobTitleValuesNew?.find(job => job.value === formDetails?.jobTitle);
    //   // console.log("typeof selected", typeof selected, selected);
    //   setSelectedValue(selected);
    //   selected?.value && setJobTitle(selected?.value);
    // }
    if (formDetails?.denomination?.length) {
      let selected = denominationValues?.find(denom => denom.value === formDetails?.denomination);
      // console.log("typeof selected", typeof selected, selected);
      setSelectedValue(selected);
      selected?.value && setDenomination(selected?.value);
    }
  }, [addCtx.formDetails]);

  console.log("addCtx.formDetails", addCtx.formDetails);
  // console.log("typeof jobTitle", typeof jobTitle, jobTitle);

  const getUserDetails = () => {
    setIsLoading(true);
  
    // fetch(process.env.REACT_APP_API_URI + '/v1/user/' + searchParams.get("member"), {    
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/details', {    
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if(data.status === "success") {
        if(data.isMember == true) {
          setIsMember(true);
          setUserId(data.userId);
          setFirstName(data.firstName);
          setLastName(data.lastName);
          // setFullName(data.fullName);
          setEmail(data.email);
          // setJobTitle("none");
          // setJobTitle("");
        } else {
          setIsMember(false);
        }
        
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  // fetch first form page 27 details 
  const getFirstFormDetails = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/27', {
        method: 'GET', mode: 'cors', headers: { 'Content-Type': 'application/json' }
      });
      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        const ffattendanceNum = data?.data?.questions?.find(question => question.id === 650)?.answer;
        const ffzipCode = data?.data?.questions?.find(question => question.id === 651)?.answer;
        setFirstFormAttendance(ffattendanceNum);
        setFirstFormZipCode(ffzipCode);
      }
    } catch (err) {
      console.error("Failed to get first form details...", err);
    }
  };

  const getUserAnswers = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
      // const response = await fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/QuestionAnswers', {
        method: 'GET', mode: 'cors', headers: { 'Content-Type': 'application/json' }
      });
      const data = await response.json();
      console.log(data);
      if (data.status === "success") {
        console.log("review data", data);
        const qna = data.questions?.map((q) => {
          const optionText = q.answers?.find(ans => ans.value === q.answer)?.desktop_text;
          return { 
            question: q.question,
            answer: optionText, // text like Strongly Agree instead of value like 5 
            // ans: q.answer, // value like 5 instead of text like Strongly Agree
          };
        });
        setUserQnA(qna);        
      }
    } catch (err) {
      console.error("Failed to get user questions answers...", err);
    }
  };

  const updateFirstName = (event) => {
    setFirstName(event.target.value);
    checkFormValid();
  }

  const updateLastName = (event) => {
    setLastName(event.target.value);
    checkFormValid();
  }

  // const updateFullName = (event) => {
  //   setFullName(event.target.value);
  //   checkFormValid();
  // }

  const updateEmail = (event) => {
    setEmail(event.target.value);
    checkFormValid();
  }

  const updateJobTitle = (event) => {
  // const updateJobTitle = (selectedOption) => {
    console.log(event);
    // console.log(selectedOption.value);
    // setSelectedValue(selectedOption);
    // setJobTitle(selectedOption.value);
    setJobTitle(event.value);
    checkFormValid();
  }
  
  // const updateSchoolName = (event) => {
  //   setSchoolName(event.target.value);
  //   checkFormValid();
  // }
  
  const updateChurchName = (event) => {
    setChurchName(event.target.value);
    checkFormValid();
  }
  
  // const updateDenomination = (event) => {
  const updateDenomination = (selectedOption) => {
    // setDenomination(event.value);
    // console.log("DENOMINATION", event.value);
    setSelectedValue(selectedOption);
    setDenomination(selectedOption.value);
    checkFormValid();
  }

  const updateZipCode = (event) => {
    setZipCode(event.target.value);
    checkFormValid();
  }

  const checkFormValid = () => {
    if(isMember) {
      // console.log(firstName);
      // console.log(jobTitle);
      // console.log(jobTitleMember);
      if(firstName && lastName) {
        console.log("We get here");
        setIsFormValid(true);
        setShowMyResultsBtn(true);
      } else {
        setIsFormValid(false);
        setShowMyResultsBtn(false);
      }

      // if(fullName) {
      //   console.log("We get here");
      //   setIsFormValid(true);
      // } else {
      //   setIsFormValid(false);
      // }

    }
    // else {
    //   // if(firstName != "" && lastName != "" && email != "" && emailRegex.test(email) && jobTitle != "" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
    //   if(firstName && lastName && email && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
    //     setIsFormValid(true);
    //   } else {
    //     setIsFormValid(false);
    //   }
    // }
    else {
      // if(firstName && lastName && email && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
      if(firstName && lastName && email && emailRegex.test(email) && jobTitle) {
      // if(fullName && email && emailRegex.test(email) && churchName && zipCode.length > 3 && zipCode.length < 13) {
        setIsFormValid(true);
        setShowMyResultsBtn(true);
      } else {
        setIsFormValid(false);
        setShowMyResultsBtn(false);
      }
    }
  }

  const showErrors = () => {
    if(!firstName) {
      setFirstNameError(true);
      setTimeout(() => { console.log(firstNameError); }, 2000);
    }
    if(!lastName) {
      setLastNameError(true);
    }
    // if(!fullName) {
    //   setFullNameError(true);
    //   setTimeout(() => { console.log(fullNameError); }, 2000);
    // }

    if(!emailRegex.test(email)) {
      setEmailError(true);
    }
    if(!jobTitle) {
      setJobTitleError(true);
    }
    // if(schoolName == "") {
    //   setSchoolNameError(true);
    // }
    if(!churchName) {
      setChurchNameError(true);
    }
    if(!denomination) {
      setDenominationError(true);
    }
    if(zipCode.length < 4) {
      setZipCodeError(true);
    }
  }

  const submitToApi = () => {

    console.log("Submit Salesforce form...");
    submitHiddenForm();
    
    let apiCall         = '/v1/assessment/' + id + '/leader';
    if(isMember) {
      apiCall           = '/v1/assessment/' + id + '/member/' + userId;
    }

    let jsonBody = JSON.stringify({
      details: {
        "firstName": firstName,
        "lastName": lastName,
        "email": email,
        "jobTitle": jobTitle,
        "churchName": churchName,
        "denomination": denomination,
        // "zipCode": zipCode
      },
      formVersion: 2
    });

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        localStorage.removeItem("assessmentAnswers");
        localStorage.removeItem("additionalAnswers");
        navigate("/community/results/" + id + "?" + searchParams, { replace: true });
      } else {
        setIsSaving(false);
      }
    }).catch((err) => {
      setIsSaving(false);
      console.error("Failed to submit user details...", err);
    })

  }


  const handleSubmit = () => {
    setIsSaving(true);
    console.log("Runnign");


    // if(isMember && firstName && lastName && jobTitle) {
    //   submitToApi();
    // // } else if(!isMember && firstName != "" && lastName != "" && emailRegex.test(email) && jobTitle != "none" && churchName != "" && zipCode.length > 3 && zipCode.length < 13) {
    // } else if(!isMember && firstName && lastName && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
    //   submitToApi();
    // } else {
    //   showErrors();
    //   setIsSaving(false);
    // }

    if(isMember && firstName && lastName && jobTitle) {
      submitToApi();
    // } else if(!isMember && firstName && lastName && emailRegex.test(email) && jobTitle && churchName && zipCode.length > 3 && zipCode.length < 13) {
    } else if(!isMember && firstName && lastName && emailRegex.test(email) && jobTitle) {
    // } else if(!isMember && fullName && emailRegex.test(email) && churchName && zipCode.length > 3 && zipCode.length < 13) {
      submitToApi();
    } else {
      showErrors();
      setIsSaving(false);
    }

  }

  const showButton = () => {
    if(isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "SHOW MY RESULTS";
  }

  const showHintText = (field) => {
    if(field == "firstName") {
      if(firstNameError) {
        return <div className="error-text">Please confirm your first name.</div>
      }
    }
    if(field == "lastName") {
      if(lastNameError) {
        return <div className="error-text">Please confirm your last name.</div>
      }
    }

    // if(field == "fullName") {
    //   if(fullNameError) {
    //     return <div className="error-text">Please confirm your full name.</div>
    //   }
    // }

    if(field == "email") {
      if(emailError) {
        return <div className="error-text">Please enter a valid email address.</div>
      }
    }
    if(field == "jobTitle") {
      if(jobTitleError) {
        return <div className="error-text">Please provide a job title.</div>
      }
    }
    if(field == "orgName") {
      if(churchNameError) {
        return <div className="error-text">Please confirm your church name.</div>
      }
    }
    // if(field == "churchName") {
    //   if(churchNameError) {
    //     return <div className="error-text">Please confirm your church name.</div>
    //   }
    // }
    if(field == "denomination") {
      if(denominationError) {
        return <div className="error-text">Please confirm your denomination.</div>
      }
    }
    if(field == "zipCode") {
      if(zipCodeError) {
        return <div className="error-text">Please confirm your ZIP code.</div>
      }
    }
  }

  const removeHintText = (field, e) => {

    // change active input field title color 
    if(e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#4A4A4A")
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#ED2028";
    }

    if(field == "firstName") {
      setFirstNameError(false);
    }
    if(field == "lastName") {
      setLastNameError(false);
    }

    // if(field == "fullName") {
    //   setFullNameError(false);
    // }

    if(field == "email") {
      setEmailError(false);
    }
    if(field == "jobTitle") {
      setJobTitleError(false);
    }
    if(field == "orgName") {
      setChurchNameError(false);
    }
    if(field == "denomination") {
      setDenominationError(false);
    }
    // if(field == "churchName") {
    //   setChurchNameError(false);
    // }
    if(field == "zipCode") {
      setZipCodeError(false);
    }
  }

  const prevQuestion = () => {
    // if(searchParams.get("leader")) {
    //   navigate('/assessment/' + id + '/review?' + searchParams, { replace: true });
    // } else {
    //   navigate('/assessment-additional/' + id + "?" + searchParams, { replace: true });
    // }
    localStorage.setItem("backFromDetailsPage", true);
    navigate("/community/assessment-additional/" + id + "/39?" + searchParams, { replace: true });
  };

  console.log("searchParams", [...searchParams]);
  console.log("\nuserQnA", userQnA, "\n");

  function submitHiddenForm() {

    console.log("\nSubmitting Salesforce form...\n\n");
    var customHiddenIframeName = 'JLA_API';
  
    if (!document.getElementById(customHiddenIframeName)) {
      var theiFrame = document.createElement("iframe");
      theiFrame.id = customHiddenIframeName;
      theiFrame.name = customHiddenIframeName;
      theiFrame.src = 'about:blank';
      theiFrame.style.display = 'none';
      document.body.appendChild(theiFrame);
    }
  
    var formData = [
      { name: "oid", value: "00D3t0000011lsD" },
      { name: "success_location", value: "https://www.acstechnologies.com" },
      { name: "Source__c", value: "Community Engagement Indicator" },
      { name: "UTM_Source__c", value: searchParams.get("utm_source") || "" },
      { name: "UTM_Medium__c", value: searchParams.get("utm_medium") || "" },
      { name: "UTM_Campaign__c", value: searchParams.get("utm_campaign") || "" },
      { name: "BDR__c", value: "1" },
      { name: "first_name", value: firstName?.trim() },
      { name: "last_name", value: lastName?.trim() },
      { name: "email", value: email?.trim() },
      { name: "company", value: churchName?.trim() },
      { name: "TWA_Number__c", value: firstFormAttendance.toString() || "" },
      { name: "Role__c", value: jobTitle?.trim() },
      { name: "Denomination__c", value: denomination?.trim() },
      { name: "Message__c", value: userQnA },
    ];

    // commented out fields that are no longer required and updated other field names as requested
      // Ministry_Resource__c field name has changed to BDR__c. retURL field name has changed to success_location 
      // { name: "oid", value: "00D3t0000011lsD" },
      // { name: "00N3t00000E3Q1y", value: searchParams.get("source") || "" },
      // { name: "retURL", value: "" },
      // { name: "Ministry_Resource__c", value: "1" },

    // const formData = [
    //   { name: "oid",                  value: "00D3t0000011lsD" },
    //   { name: "retURL",               value: "" },
    //   { name: "00N3t00000E3Q1j",      value: "https://indicators.acst.com" },
    //   { name: "00N3t00000E3Q1y",      value: searchParams.get("source") || "" },
    //   { name: "00N3t00000E3Q28",      value: searchParams.get("utm_source") || "" },
    //   { name: "00N3t00000E3Q23",      value: searchParams.get("utm_medium") || "" },
    //   { name: "00N3t00000E3Q0N",      value: searchParams.get("utm_campaign") || "" },
    //   { name: "Ministry_Resource__c", value: "1" },
    //   { name: "first_name",           value: firstName?.trim() },
    //   { name: "last_name",            value: lastName?.trim() },
    //   { name: "email",                value: email?.trim() },
    //   { name: "company",              value: churchName?.trim() },
    //   { name: "00N3t00000GVtPJ",      value: firstFormAttendance || 0 },
    //   { name: "00N3t00000E3hLY",      value: jobTitle?.trim() },
    //   { name: "Denomination__c",      value: denomination?.trim() },
    //   { name: "Message__c",           value: userQnA },
    // ];
  
    const form = document.createElement("form");
    form.method = "POST";
    form.action = "https://success.acstechnologies.com/l/1038923/2023-11-17/3tsykj";
    // form.action = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
    form.setAttribute("target", customHiddenIframeName);
  
    formData.forEach((hiddenField) => {
      var hiddenInputField = document.createElement("input");
      hiddenInputField.name = hiddenField.name;
      hiddenInputField.id = hiddenField.name;
      hiddenInputField.value = Array.isArray(hiddenField.value) ? JSON.stringify(hiddenField.value) : hiddenField.value;
      hiddenInputField.setAttribute("type", "hidden");
      form.appendChild(hiddenInputField);
    });

    console.log("\nformData:", formData, "\n");
  
    document.body.appendChild(form);
    console.log("Form appended to body. Submitting...");
    form.submit();
    console.log("Form submitted!");
  }


  if (isLoading) {
		return <div className="container details loading"><CircularProgress style={{ color: '#ED2028' }} size={60} /></div>
	}
  
  return (
    <div className="container details">
      
      <div className="details-container">
        
        {/* <h1>Thank you, now let's get to your results!</h1> */}
        <h1>
          {/* <img src={hourGlass} width={38} height={38} style={{margin:"-6px 0"}}/> */}
          You're almost done!
        </h1>
        {/* <p>"<span style={{ color: "#c02b0a" }}>*</span>" indicates required fields</p> */}
        <div style={{ height: '10px' }}></div>
        {/* <p>As we tabulate your report, please confirm your details so that we can email you a copy.</p> */}
        <p>Before we get to your report, please confirm your details so that we can email you a copy.</p>

        <div className="form">

          <div className="form-detail">
            <p className="input-title">First name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g.: Simon" value={firstName} onFocus={(e) => removeHintText("firstName", e)} onChange={(e) => {updateFirstName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("firstName")}
          </div>

          <div className="form-detail">
            <p className="input-title">Last name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g.: Peter" value={lastName} onFocus={(e) => removeHintText("lastName", e)}  onChange={(e) => {updateLastName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("lastName")}
          </div>
          {/* 
          <div className="form-detail">
            <p className="input-title">Full name<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="Simon Peter" value={fullName} onFocus={(e) => removeHintText("fullName", e)}  onChange={(e) => {updateFullName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("fullName")}
          </div> */}

          <div className="form-detail">
            <p className="input-title">Email<span className="required-field">*</span></p>
            <TextField hiddenLabel placeholder="E.g.: sp@r.church" disabled={isMember} value={email} onFocus={(e) => removeHintText("email", e)} onChange={(e) => {updateEmail(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
            {showHintText("email")}
          </div>

          <div className="form-detail">
            <p className="input-title">Job title<span className="required-field">*</span></p>
            {/* <TextField hiddenLabel placeholder="E.g.: Senior Pastor" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}
            {/* {showHintText("jobTitle")} */}
            {/* <TextField hiddenLabel placeholder="Board Member" value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}

            {/* <TextField select hiddenLabel value={jobTitle} onFocus={(e) => removeHintText("jobTitle", e)} onChange={(e) => {updateJobTitle(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text">
              <MenuItem key={0} value="none" disabled>Please select an option below</MenuItem>
              {jobTitleValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField> */}

            {/* Added react-select instead of MUI TextField */}
            <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValuesNew} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle}
              value={selectedJobTitleValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#FFFFFF',
                  primary: '#3B3A3A',
                  neutral0: '#F4F4F4',
                  neutral50: '#868686', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} />
            {showHintText("jobTitle")}
          </div>  

          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">School name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="Rock Church" value={schoolName} onFocus={(e) => removeHintText("schoolName", e)} onChange={(e) => {updateSchoolName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("churchName")}
              </div>
            ) : null
          } */}
          
          {
            !isMember ? (
              <div className="form-detail school-name">
                <p className="input-title">Church name<span className="required-field">*</span></p>
                <TextField hiddenLabel placeholder="E.g.: Rock Church" value={churchName} onFocus={(e) => removeHintText("orgName", e)} onChange={(e) => {updateChurchName(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                {showHintText("orgName")}
              </div>
            ) : null
          }

         
            {/* Added react-select instead of MUI TextField */}
             {/* <Select classNamePrefix="react-select" className="form-details-text react-select" 
              options={jobTitleValues} 
              onFocus={(e) => removeHintText("jobTitle", e)}
              onChange={updateJobTitle}
              value={selectedValue}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#F1813233',
                  primary: '#F18132',
                  neutral0: '#F4F4F4',
                  neutral50: '#B5B5B5', // placeholder text color
                },
              })}
              placeholder={"Start typing or select"} /> */}
          
           {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Denomination<span className="required-field">*</span></p>
                {/* <TextField hiddenLabel placeholder="E.g.: Protestant" value={denomination} onFocus={(e) => removeHintText("denomination", e)} onChange={(e) => {updateDenomination(e)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}
                <Select classNamePrefix="react-select" className="form-details-text react-select" 
                  options={denominationValues} 
                  onFocus={(e) => removeHintText("denomination", e)}
                  onChange={(e) => updateDenomination(e)}
                  value={selectedValue}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: '#FFFFFF',
                      primary: '#3B3A3A',
                      neutral0: '#F4F4F4',
                      neutral50: '#868686', // placeholder text color
                    },
                  })}
                  placeholder={"Start typing or select"} />
                {showHintText("Start typing or select")}
              </div>
            ) : null
          }
          
          {/* {
            !isMember ? (
              <div className="form-detail">
                <p className="input-title">Denomination<span className="required-field">*</span></p>
                <TextField hiddenLabel 
                  type="text" 
                  placeholder="E.g.: Protestant" 
                  className="required-field" 
                  value={denomination} 
                  onFocus={(e) => removeHintText("denomination", e)} 
                  onChange={(e) => {updateDenomination(e)}} 
                  fullWidth variant="standard" size="normal" 
                  InputProps={{ disableUnderline: true }} 
                  onInput = {(e) => {
                    return e.target.value = e.target.value?.toString().slice(0,12)
                  }}
                />
                {showHintText("zipCode")}
              </div>
            ) : null
          } */}


        </div>
          <div className="details-buttons">
            <div className="back-btn">
              <Button onClick={prevQuestion}>
                <img src={prevArrow} alt="previous button" />&nbsp;&nbsp;PREV
              </Button>
            </div>

            <div className={showMyResultsBtn ? "cta-btn-active": "cta-btn" }>
              <Button onClick={handleSubmit} disabled={btnDisabled}>
                {showButton()}
              </Button>
            </div>
          </div>

      </div>
      
    </div>
  );

}
export default Details;