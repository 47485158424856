export const sectionsText = {
  "policy compliance": [
    <>A breakdown in policy compliance can result in reputation damage, severe data loss, and legal penalties.<br/><br/>We'd love to get in touch and share free resources specific to your current security environment.</>
  ],
  "asset management": [
    <>Compromised asset management makes critical data and resources vulnerable to exploitation.<br/><br/>We'd love to get in touch and share free resources specific to your current security environment.</>
    ],
  "access control": [
    <>It takes only 1 breach through unauthorized access to jeopardize an entire security infrastructure.<br/><br/>We'd love to get in touch and share free resources specific to your current security environment.</>
  ],
  "network security": [
    <>Weak network security exposes your organization to devastating data breaches and cyber attacks.<br/><br/>We'd love to get in touch and share free resources specific to your current security environment.</>
    ],
  "surveillance measures": [
    <>Lack of robust surveillance measures can result in undetected threats, putting your entire system at risk.<br/><br/>We'd love to get in touch and share free resources specific to your current security environment.</>
  ],
  // Bookkeeping Texts Below
  "process": [
    <>Lack of clear procedures causes inefficiency and raises trust concerns within your church.</>
  ],
  "compliance": [
    <>Non-compliance risks legal penalties, regulatory issues, and damages your reputation.</>
  ],
  "stewardship": [
    <>Compromised stewardship damages your credibility.</>
  ],
  "technology": [
    <>Inefficient financial practices hinder your church's missional impact.</>
  ],
  "systems": [
    <>Inefficient financial practices hinder your church's missional impact.</>
  ],
  sameScores: [<>Weak network security exposes your organization to devastating data breaches and cyber attacks.<br/><br/>We'd love to get in touch and share free resources specific to your current security environment.</>],
};

