import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Switch } from "@mui/material";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import logoFooterLeft from '../../../Assets/Images/Common/footer-logo.png';
import logoRight from '../../../Assets/Images/LandingPage/logo.png';
import logoMob from '../../../Assets/Images/LandingPage/ACSTLogoSmall.png';
import clock from '../../../Assets/Images/LandingPage/clock-white.svg';
import downArrow from '../../../Assets/Images/LandingPage/down-arrow.svg';
import teamDashboardImg from '../../../Assets/Images/LandingPage/congregation-sample-dash-team-desktop.png';
import teamDashboardMobileImg from '../../../Assets/Images/Congregation/sample-team-dashboard-mobile1.png';
import individualDashboardImg from '../../../Assets/Images/LandingPage/congregation-sample-dash-indi-desktop.png';
import individualDashboardMobileImg from '../../../Assets/Images/Congregation/sample-dash-indi-mobi.png';

import testimonialImgCurtis from '../../../Assets/Images/LandingPage/testimonial-curtis-ponzio.jpg';
import testimonialImgBrandon from '../../../Assets/Images/LandingPage/testimonial-brandon-huff.jpeg';
import testimonialImgRonnie from '../../../Assets/Images/LandingPage/testimonial-ronnie-hardy.jpg';
import topQ from '../../../Assets/Images/LandingPage/quotetop.svg';
import bottomQ from '../../../Assets/Images/LandingPage/quote-bottom.svg';
import analyzeIcon from '../../../Assets/Images/LandingPage/assess.png';
import alignmentIcon from '../../../Assets/Images/LandingPage/measure-light.png';
import directionIcon from '../../../Assets/Images/LandingPage/build.png';
import protectIcon from '../../../Assets/Images/LandingPage/protect.png';
import compareIcon from '../../../Assets/Images/LandingPage/compare.png';
import Group from '../../../Assets/Images/LandingPage/landing-bottom.png';
import ArrowLeft from '../../../Assets/Images/LandingPage/left-arrow.svg';
import ArrowRight from '../../../Assets/Images/LandingPage/right-arrow.svg';

import slide1 from '../../../Assets/Images/LandingPage/SliderImageInfosec/slide1.png';
import slide2 from '../../../Assets/Images/LandingPage/SliderImageInfosec/slide2.png';
import slide3 from '../../../Assets/Images/LandingPage/SliderImageInfosec/slide3.png';
import slide4 from '../../../Assets/Images/LandingPage/SliderImageInfosec/slide4.png';
import HigherGroundLogo from '../../../Assets/Images/LandingPage/higher_grounds_white_logo.png'

import "./LandingPageInfosec.scss";



const LandingPageInfosec = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();

  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [largeScreen, setLargeScreen] = useState(true);


  useEffect(() => {
    if (windowWidth > 830) {
      setLargeScreen(true);
    }
    else {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }

  const toggleIndividualChange = (e) => {
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const createAssessmentInfosec = () => {

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_INFOSEC_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        navigate("/infosec/before-you-start/" + data.id + "/?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
        console.log("Failed to create new assessment...", data);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }


  const showDashboard = () => {
    return (
      <div className="sample-dashboard">
        <div className="scroll-to-see">
          <h2>Scroll to see what a sample dashboard looks like</h2>
          <img src={downArrow} alt="scroll down" />
        </div>
        <div className="dashboard-heading">
          <div className="line"></div>
          <h1>SAMPLE DASHBOARD</h1>
        </div>
        {
          largeScreen ?   
          <div className="individual-group">
            <button onClick={() => setCurrentView(0)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
            <button onClick={() => setCurrentView(1)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
          </div> :
          <div className="toggle-individual-group">
            <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
            <Switch
              checked={!toggleIndividual}
              onChange={toggleIndividualChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
          </div>
        }
        <div className="results-dashboard-container">
          <img src={currentView === 0 ? (largeScreen ? teamDashboardImg : teamDashboardMobileImg) : (largeScreen ? '' : '')} alt="" />

          {/* Replace Image with Individual Dashboard */}
          <img src={currentView === 1 ? (largeScreen ? individualDashboardImg : individualDashboardMobileImg) : (largeScreen ? '' : '')} alt="" /> 
        </div>
      </div>
    )
  }

  const showTestimonial = () => {
    return (
      <div className="testimonial-container">
        <div className="testimonial">
          <div className="testi">
            {/* <div className="quotation-mark" style={{ top: "66px", left: '-6%' }}><img src={topQ} alt="quote icon" /></div>
                <p style={{ fontSize: "32px", color: "#FFFFFF", maxWidth: '46ch' }}>
                It's very hard to be up to date and up to speed on every section of the network, whether it's the servers, the switches, or the firewall. You can know a lot about all those areas, but with a managed service provider (like <i><b>Higher Ground</b></i>), you have people who are extremely knowledgeable about every area of the network instead of just one.
                </p>

                <div className="quotation-mark bottom" style={{ top: "-37px", left: '7%' }} ><div className="quotation-block"><img src={bottomQ} style={{ transform: 'rotate(180deg)' }} alt="quote icon" /></div></div> */}
            <p style={{ color: "#343333" }}>
              <span className="quotation-mark-left"><img src={topQ} alt="quote icon" /></span>
              I'm kind of scared for the vulnerability testing to happen because I know it's going to show me many things. But I'm also very excited to try to figure out what I can protect better. I count on <i><b>Higher Ground</b></i> to guide us and support us in every way.
              <span className="no-wrap"><span className="quotation-mark-right"><img src={bottomQ} alt="quote icon" /></span></span>
            </p>
          </div>
          <div>
            <div className="avatar">
              <img src={testimonialImgRonnie} alt="pastor" />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div className="designation">
              Ronnie Hardy
              <p className="designation-inner" style={{ color: "#343333" }}>Network and Systems Administrator, <strong>Shades Mountain Baptist Church</strong></p>
            </div>
          </div>

        </div>
      </div>
    )
  }

  const showStartContainer = () => {
    return (
      <div className="container-start">
        <div className="image">
          <img  className="thumbnail" src={Group} alt="" />
        </div>
        <div className="intro">
          <h1>How prepared are you to combat cybercrime if it happened today?</h1>
          <p>The hacker comes like a "thief in the night".{windowWidth > 830 ? <br/> : " "}Take this 3-minute test to see if you're ready!</p>
          <div className="completion">
            <Button className="cta" onClick={createAssessmentInfosec}>GET STARTED</Button>
            {/* <a className="assess-link" href="/infosec" target="_blank" rel="noreferrer">GET STARTED</a> */}
          </div>

        </div>
      </div>
    )
  }

  return (
    <div className="landing-page-container infosec">

      <div className="content-container">

        <div className="top-section">
          <div className="header-container-wrapper">
            <div className="header-container">
              { <div className="logo-left"><img style={{maxWidth: '171px', marginTop: '10px'}} src={HigherGroundLogo} alt="Logo" /></div>}
              <div className="logo-left right-logo"><img src={logoRight} alt="Logo" /></div>
              {/* { !largeScreen && <div className="logo-left" style={{ textAlign: "right" }}><img style={{maxWidth: '98px', marginTop: '10px', marginLeft: "auto"}} src={HigherGroundLogo} alt="Logo" /></div>} */}
            </div>
            <div className="contents">
              <h1>In 2023, <span className="heading-highlight">billions were lost</span> to cybercrime, <br/> <span className="heading-light">in addition to the loss of reputation, privacy, and data.</span></h1>
              <p>How safe is your organization's information security (infosec)?</p>

              <ol className="intro-points-list">

                <li>Take this FREE 3-minute security assessment</li>
                <li>Identify where your organization may be at risk</li>
                <li>Learn the best practices to better secure your information</li>
                {/* <li>Learn the best practices that can better secure your information</li> */}

              </ol>

              <div className="completion">
                <Button className="cta" onClick={createAssessmentInfosec}>GET STARTED</Button>
                {/* <a className="assess-link" href="/infosec" target="_blank" rel="noreferrer">GET STARTED</a> */}
                <div className='time-estimate'>
                  <div className='img-container'><img className='clock-icon' src={clock} alt="estimated time" /></div><span style={{ margin: "auto" }}>3 min</span>
                </div>
              </div>

            </div>
            <div className='para-container'>
              <p className='para'>Note: It is essential the individual most knowledgeable about your organization's IT practices takes this assessment. If you are not in such a position, please share this assessment with your IT expert.</p>
            </div>

          </div>

        </div>


        <div className="middle-section">

          <h3>Preview Our Process</h3>

          <div className="custom-slider">

            {Slider()}

            <Button className="cta" onClick={createAssessmentInfosec}>GET STARTED</Button>
            {/* <a className="assess-link" href="/infosec" target="_blank" rel="noreferrer">GET STARTED</a> */}

          </div>

          {showTestimonial()}

          <div className="unlock-growth">

            <h3>3 Steps For Successful Security</h3>

            <div className="unlock-reasons">
              <div className="reason">
                <img src={analyzeIcon} alt="insights icon" /><br />
                <div className="height30"></div>
                <span>Assess</span><br />
                Evaluate how secure you are against cybercrime across 5 standard metrics
              </div>

              <div className="reason">
                <img src={compareIcon} alt="alignment icon" /><br />
                <div className="height30"></div>
                <span>Compare</span><br />
                Benchmark your results with industry standards to know where you stand
              </div>

              <div className="reason">
                <img src={protectIcon} alt="direction icon" /><br />
                <div className="height30"></div>
                <span>Protect</span><br />
                Identify which breaches to repair and which walls are to be built anew
              </div>

            </div>

            {/* <div className="cta-btn">
              <Button>GET STARTED</Button>
            </div> */}

          </div>

          {/* {showDashboard()} */}

          <div className="testimonial-container" style={{ background: 'unset' }}>

            <div className="testimonial testimonial2">
              <div className="testi">
                {/* <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>It's very hard to be up to date and up to speed on every section of the network, whether it's the servers, the switches, or the firewall. You can know a lot about all those areas, but with a managed service provider (like <i><b>Higher Ground</b></i>), you have people who are extremely knowledgeable about every area of the network instead of just one.</p>

            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{ transform: 'rotate(180deg)' }} alt="quote icon" /></div></div> */}
                <p>
                  <span className="quotation-mark-left"><img src={topQ} alt="quote icon" /></span>
                  We totally trust them (<i><b>Higher Ground</b></i>). There are two big reasons. First, how well they respond to emergencies, which I think is phenomenal; second, their pricing. It's much more manageable in the church world than any other vendor.<span className="no-wrap"><span className="quotation-mark-right"><img src={bottomQ} alt="quote icon" /></span></span>
                </p>
              </div>
              <div>
                <div className="avatar">
                  <img src={testimonialImgBrandon} alt="pastor" />
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div className="designation">
                  Brandon Huff
                  <p className="designation-inner" style={{ color: "#FFFFFF" }}>Director of Technology Operations,<strong> Perimeter Church</strong></p>
                </div>
              </div>

            </div>
          </div>


          {showStartContainer()}

        </div>

      </div>

      <div className="footer-container">
        <div className="contents">
          <div className="logo-left"><img src={largeScreen ? logoFooterLeft : logoFooterLeft} alt="Logo" /></div>
          <p>© Copyright 2024 ACS Technologies | <a className="privacy-policy" href="https://help.acst.com/en/trust-center/policies/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a></p>
          <div className="logo-left"></div>
        </div>
      </div>

    </div>
  )
}


export default LandingPageInfosec;





const slides = [
  { image: slide1, },
  { image: slide2, },
  { image: slide3, },
  { image: slide4, },
];

const Slider = () => {

  const [active, setActive] =useState(0);
  const [autoplay, setAutoplay] = useState(1);
  const max = slides.length;

  const intervalBetweenSlides = () => autoplay && setActive(active === max - 1 ? 0 : active + 1)

  useEffect(() => {
      const interval = setInterval( () => intervalBetweenSlides(), 4000);
      return () => clearInterval(interval);
  });


  const nextOne = () => active < max - 1 && setActive(active + 1)

  const prevOne = () => active > 0 && setActive(active - 1)

  const isActive = value => active === value && 'active'

  const setSliderStyles = () => {
      const transition = active * - 100;
      
      return {
        width: ( slides.length * 100 ) + 'vw',
        transform: 'translateX(' + transition + 'vw)'
      }
  }

  const renderSlides = () => slides.map((item, index) => (
      <div 
          className='each-slide' 
          key={ index } 
          >
            <img src={item.image} alt="" />
      </div> 
  ));

  const renderDots = () => slides.map((silde, index) => ( // check index
    <li className={isActive(index) + ' dots'} key={index}>
      <button onClick={() => setActive(index)}>
        <div className="slider-dots"></div>
      </button>
    </li>
  ));


  const renderArrows = () => (
    <React.Fragment>
      <button type='button' className='arrows prev' onClick={() => prevOne()}>
        <img src={ArrowLeft} alt="arrow left" />

      </button>
      <button type='button' className='arrows next' onClick={() => nextOne()}>
        <img src={ArrowRight} alt="arrow right" />
      </button>
    </React.Fragment>
  )

  return (
    <section className='slider'>
      <div className='wrapper' style={setSliderStyles()}>
        {renderSlides()}
      </div>
      {renderArrows()}
      <ul className='dots-container'>
        {renderDots()}
      </ul>
    </section>
  );
};
