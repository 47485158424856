export function getChartsValues(qNum, attendance) {

  const avgWeeklyAttd = attendance || localStorage.getItem("avgWeeklyAttd");

  let avgDonorCount = 0;
  let donationAmount = 0;
  let donationTimes = "0";
  let donationTimes2 = 0;
  let donationPerDonor = 0;

  let avgNumOfKids = 0;
  
  let avgVolunteerCount = 0;

  let avgMissionalDonationAmount = 0;
  let avgMissionalDonationPerDonor = 0;
  let avgMissionalDonorCount = 0;

  if (avgWeeklyAttd >= 0 && avgWeeklyAttd <= 50) {
    avgDonorCount = 68;
    donationAmount = "$151,453";
    donationTimes = "782";
    donationPerDonor = "$194";

    avgVolunteerCount = 3;

    avgNumOfKids = 1;

    avgMissionalDonationAmount = "$2,418";
    avgMissionalDonationPerDonor = "$502";
    avgMissionalDonorCount = 5;

  } else if (avgWeeklyAttd >= 51 && avgWeeklyAttd <= 100) {
    avgDonorCount = 103;
    donationAmount = "$306,392";
    donationTimes = "1,384";
    donationPerDonor = "$221";

    avgVolunteerCount = 6;

    avgNumOfKids = 4;

    avgMissionalDonationAmount = "$4,472";
    avgMissionalDonationPerDonor = "$670";
    avgMissionalDonorCount = 9;
    
  } else if (avgWeeklyAttd >= 101 && avgWeeklyAttd <= 150) {
    avgDonorCount = 144;
    donationAmount = "$447,383";
    donationTimes = "1,977";
    donationPerDonor = "$226";

    avgVolunteerCount = 11;

    avgNumOfKids = 7;

    avgMissionalDonationAmount = "$8,544";
    avgMissionalDonationPerDonor = "$530";
    avgMissionalDonorCount = 16;
    
  } else if (avgWeeklyAttd >= 151 && avgWeeklyAttd <= 200) {
    avgDonorCount = 194;
    donationAmount = "$631,898";
    donationTimes = "2,704";
    donationPerDonor = "$234";

    avgVolunteerCount = 18;

    avgNumOfKids = 21;

    avgMissionalDonationAmount = "$12,140";
    avgMissionalDonationPerDonor = "$511";
    avgMissionalDonorCount = 22;
    
  } else if (avgWeeklyAttd >= 201 && avgWeeklyAttd <= 250) {
    avgDonorCount = 228;
    donationAmount = "818,344";
    donationTimes = "3,081";
    donationPerDonor = "$266";

    avgVolunteerCount = 23;

    avgNumOfKids = 28;

    avgMissionalDonationAmount = "$19,246";
    avgMissionalDonationPerDonor = "$499";
    avgMissionalDonorCount = 29;
    
  } else if (avgWeeklyAttd >= 251 && avgWeeklyAttd <= 300) {
    avgDonorCount = 298;
    donationAmount = "$1,024,751";
    donationTimes = "4,332";
    donationPerDonor = "$237";

    avgVolunteerCount = 32;

    avgNumOfKids = 31;

    avgMissionalDonationAmount = "$24,604";
    avgMissionalDonationPerDonor = "$661";
    avgMissionalDonorCount = 36;
    
  } else if (avgWeeklyAttd >= 301 && avgWeeklyAttd <= 500) {
    avgDonorCount = 394;
    donationAmount = "$1,526,549";
    donationTimes = "5,727";
    donationPerDonor = "$267";

    avgVolunteerCount = 51;

    avgNumOfKids = 71;

    avgMissionalDonationAmount = "$42,592";
    avgMissionalDonationPerDonor = "$694";
    avgMissionalDonorCount = 54;
    
  } else if (avgWeeklyAttd >= 501 && avgWeeklyAttd <= 700) {
    avgDonorCount = 528;
    donationAmount = "$2,129,228";
    donationTimes = "8,145";
    donationPerDonor = "$261";

    avgVolunteerCount = 72;

    avgNumOfKids = 91;

    avgMissionalDonationAmount = "$58,662";
    avgMissionalDonationPerDonor = "$800";
    avgMissionalDonorCount = 74;
    
  } else if (avgWeeklyAttd >= 701 && avgWeeklyAttd <= 900) {
    avgDonorCount = 729;
    donationAmount = "$2,899,152";
    donationTimes = "10,495";
    donationPerDonor = "$276";

    avgVolunteerCount = 85;

    avgNumOfKids = 150;

    avgMissionalDonationAmount = "$94,128";
    avgMissionalDonationPerDonor = "$905";
    avgMissionalDonorCount = 102;
    
  } else if (avgWeeklyAttd >= 901 && avgWeeklyAttd <= 1500) {
    avgDonorCount = 980;
    donationAmount = "$3,548,450";
    donationTimes = "16,262";
    donationPerDonor = "$218";

    avgVolunteerCount = 125;

    avgNumOfKids = 196;

    avgMissionalDonationAmount = "$193,467";
    avgMissionalDonationPerDonor = "$834";
    avgMissionalDonorCount = 142;

  } else if (avgWeeklyAttd >= 1501 && avgWeeklyAttd <= 2000) {
    avgDonorCount = 1174;
    donationAmount = "$4,659,499";
    donationTimes = "19,280";
    donationPerDonor = "$242";

    avgVolunteerCount = 239;

    avgNumOfKids = 41;

    avgMissionalDonationAmount = "$195,987";
    avgMissionalDonationPerDonor = "$746";
    avgMissionalDonorCount = 190;
    
  } else if (avgWeeklyAttd > 2000) {
    avgDonorCount = 2542;
    donationAmount = "10,129,576";
    donationTimes = "35,072";
    donationPerDonor = "$289";

    avgVolunteerCount = 250;

    avgNumOfKids = 153;

    avgMissionalDonationAmount = "$447,903";
    avgMissionalDonationPerDonor = "$1,400";
    avgMissionalDonorCount = 210;      
  }


  const strToNum = donationTimes?.replace(/,/g, ""); // remove commas
  const donationTimesNum = parseFloat(strToNum);
  // this is being done according to clients ACST Congregational Engagement spreadsheet calculations example:
  // On average, a church of your size typically sees about 68 donors who donate on average about 12 times a year (782/68) totalling about $194 per donor
  donationTimes2 = Math.round(donationTimesNum / avgDonorCount);

  let chart2Text = <>A similarly-sized American church typically sees <span>{avgDonorCount} donors donate about {donationTimes2} times a year, averaging {donationPerDonor} per donor</span>.</>;
  let chart3Text = <>Typically, a church of your size has <span>{avgNumOfKids} kids</span> attend Children's Bible School in <span>a year</span>.</>;
  let chart4Text = <>In a church of your size, on an average there are <span>{avgVolunteerCount} serving volunteers</span>.</>;
  let chart5Text = <>A similarly-sized American church typically sees <span>{avgMissionalDonorCount} missional donors</span> giving about <span>{avgMissionalDonationPerDonor} a year</span>.</>

  return { avgDonorCount, donationAmount, donationTimes, donationTimes2, donationPerDonor, avgVolunteerCount, avgNumOfKids, avgMissionalDonationAmount, avgMissionalDonationPerDonor, avgMissionalDonorCount, chart2Text, chart3Text, chart4Text, chart5Text };

}

