import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, Button, CircularProgress, LinearProgress, Snackbar, Alert } from '@mui/material';

import BookkeepingQuestion from '../../../Components/Bookkeeping/Questions/BookkeepingQuestions.jsx';
import BookkeepingProgressBar from '../../../Components/Bookkeeping/ProgressBar/BookkeepingProgressBar.jsx';
import ProgressBarSingle from '../../../Components/Bookkeeping/ProgressBarSingle/BookkeepingProgressBarSingle.jsx';

// import prevArrow from '../../../Assets/Images/Bookkeeping/prev-arrow.svg';
import prevArrow from '../../../Assets/Images/Bookkeeping/prevArrow.svg';
import nextArrow from '../../../Assets/Images/Bookkeeping/next-arrow.svg';
import nextArrowWhite from '../../../Assets/Images/Bookkeeping/next-arrow-white.svg';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
// import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import BookkeepingAdditionalQuestionContext from '../../../Store/Bookkeeping/BookkeepingAdditionalQuestionContext.jsx';
import BookkeepingQuestionsProgressContext from '../../../Store/Bookkeeping/BookkeepingQuestionsProgressContext';
import BookkeepingQuestionInfo from '../../../Components/Bookkeeping/QuestionInfo/BookkeepingQuestionInfo.jsx';
import { questions_statistics } from '../temp.js';
import BookkeepingAssessmentQuestionContext from '../../../Store/Bookkeeping/BookkeepingAssessmentQuestionContext.jsx';


const BookkeepingAssessmentV3 = () => {

  // const assessProgressCtx = useContext(AssessmentProgressContext);
  // console.log("assessProgressCtx", assessProgressCtx);
  // const assessCtx = useContext(BookkeepingAdditionalQuestionContext);
  const assessCtx = useContext(BookkeepingAssessmentQuestionContext);
  const questionsCtx = useContext(BookkeepingQuestionsProgressContext);

  const { windowWidth } = useWindowDimensions();
  let navigate = useNavigate();
  const { id, pageId } = useParams();

  console.log("pageId", pageId);

  useEffect(() => {
    // document.body.style.backgroundColor = "#E5E5E5";

    if (!questions?.length || !sections?.length) {
      setIsLoading(true);
      getQuestions();
    }
    localStorage.removeItem("backFromDetailsPage");
    // setQs(questions);
    // if(id != null) {      
    //   getAssessment();
    // } else {
    //   getQuestions();
    // }

    // getOldQuestions();

    // assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
    //   if(sec.title === "Response") return { ...sec, status: "active" };
    //   else return { ...sec, status: "unfinished" };
    // }));

  }, [pageId]);

  // useEffect(() => {
  //   getQuestions();
  // }, [pageId]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [nextValue, setNextValue] = useState(0);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [currentHash, setCurrentHash] = useState(0);
  const [hasAdditional, setHasAdditional] = useState(false);
  const [qs, setQs] = useState([]);
  const [apiData, setApiData] = useState();
  const [oldQuestions, setOldQuestions] = useState();
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [prevBtnClicked, setPrevBtnClicked] = useState(false);
  const [showStatistics, setShowStatistics] = useState(false);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [answerSubmissionError, setAnswerSubmissionError] = useState(false);
  const [onLastStatisticsPage, setOnLastStatisticsPage] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [queSnackbarOpen, setQueSnackbarOpen] = useState(false);

  const [textInput, setTextInput] = useState("");


  const questionType = questions?.[activeStep]?.answerType;
  const questionsWithoutStats = [1, 3, 5, 6, 8, 10, 11, 13, 14, 15, 16, 18, 20, 21, 22, 23, 24, 25, 26, 27, 28];

  // console.log("searchParams.get('member')", searchParams.get("member"));

  useEffect(() => {
    // to hide Next button when user has just answered the question (500ms delay) and 
    // to show it again when user clicks on Prev button

    let timeoutId = null;
    let conditionToShowNextBtn = (
      (questions?.[activeStep]?.answerType !== "slider" &&
        questions?.[activeStep]?.answerType !== "multiple_choice" &&
        questions?.[activeStep]?.answerType !== "select" &&
        questions?.[activeStep]?.answerType !== "month_year") ||
      (questions?.[activeStep]?.answer >= 0 || questions?.[activeStep]?.answerTxt?.length > 0));

    if (conditionToShowNextBtn && !prevBtnClicked) {
      timeoutId = setTimeout(() => {
        setShowNextBtn(true);
      }, 500);
    }

    if (conditionToShowNextBtn && prevBtnClicked) {
      setPrevBtnClicked(false);
      setShowNextBtn(true);
    }

    if (!conditionToShowNextBtn) {
      setShowNextBtn(false);
    }

    if (showStatistics) {
      setShowNextBtn(true);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [prevBtnClicked, activeStep, questions, showStatistics]);

  useEffect(() => {
    if (localStorage.getItem("backFromAddQPage") === "true" && questions?.length) {
      // console.log("activeStep", activeStep); console.log("questions", questions);
      setActiveStep(questions?.length - 1);
      localStorage.removeItem("backFromAddQPage");
    }
  }, [questions, pageId]);

  useEffect(() => {
    if (questions?.[activeStep]?.orderNo === questions?.length) {
      setOnLastStatisticsPage(true);
    } else setOnLastStatisticsPage(false);
  }, [questions, activeStep]);


  useEffect(() => {
    if (questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(questions?.[activeStep]?.answerTxt);
    }
  }, [questions, activeStep]);

  useEffect(() => {
    if (assessCtx.questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(assessCtx.questions?.[activeStep]?.answerTxt);
    }
  }, [activeStep, assessCtx]);

  useEffect(() => {
    if (answerSubmissionError) {
      setSnackbarOpen(true);
    }
  }, [answerSubmissionError]);

  useEffect(() => {
    if (!questions?.[activeStep]?.submissionSuccess) {
      setQueSnackbarOpen(true);
    } else {
      setQueSnackbarOpen(false);
    }
  }, [activeStep, questions]);

  useEffect(() => {
    console.log("Do we run??");
    let isDisplay = true;
    // Get all standalone logic (ie parent_id = 0)
    let displayLogic = questions[activeStep]?.logic.filter(obj => obj.parent_id == 0 && obj.logic_type == "display");
    // console.log("displayLogic", displayLogic);

    if (displayLogic && displayLogic.length > 0) {

      for (let l = 0; l < displayLogic.length; l++) {

        let currentLogic = [];
        currentLogic.push(displayLogic[l]);
        let childLogic = questions[activeStep]?.logic.filter(obj => obj.parent_id == displayLogic[l].id);
        currentLogic.push(...childLogic);
        console.log("currentLogic", currentLogic);

        if (currentLogic.length > 1) {
          console.log("getOperatorLogic", getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer, questions[activeStep]?.logic[l]?.operator, questions[activeStep]?.logic[l]?.value));
          let subDisplay = 0;
          for (let c = 0; c < currentLogic.length; c++) {
            if (getOperatorLogic(getQuestionBasedOnVariable(questions, currentLogic[c].variable).answer, currentLogic[c].operator, currentLogic[c].value)) {
              subDisplay++;
            }
          }
          if (subDisplay == currentLogic.length) {
            isDisplay = false;
          }
        } else {
          if (getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable)?.answer, questions[activeStep]?.logic[l]?.operator, parseInt(questions[activeStep]?.logic[l]?.value))) {
            isDisplay = false;
          }
        }
      }

    }

    if (!isDisplay) {
      console.log("DOES THIS WORK??");
      assessCtx.updateSkipStatus(questions?.[activeStep]?.id, true);
      setQuestions(questions?.map((q) => {
        if (q.id === questions?.[activeStep]?.id) q.skip = true;
        return q;
      }))
      if (assessCtx.questions?.[activeStep]?.skip) {
        setActiveStep(activeStep + 1);
      }
    } else {
      assessCtx.updateSkipStatus(questions?.[activeStep]?.id, false);
      setQuestions(questions?.map((q) => {
        if (q.id === questions?.[activeStep]?.id) q.skip = false;
        return q;
      }));
      const idx = activeStep || 0;
      for (let i = idx; i >= 0; i--) {
        if (!assessCtx.questions?.[i]?.skip) {
          setActiveStep(i);
          break;
        }
      }
    }

  }, [activeStep, assessCtx]);


  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleCloseQueSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setQueSnackbarOpen(false);
  };

  function getQuestions() {

    // if(assessCtx.questions?.length > 0) {
    //   console.log("assessCtx.questions", assessCtx.questions);
    //   setQuestions(assessCtx.questions);
    //   setQuestionCount(assessCtx.questions?.length);
    //   setIsLoading(false);

    //   // go to the last additional question if coming back from Details page 
    //   // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
    //   if(localStorage.getItem("backFromDetailsPage") === "true") {
    //     setActiveStep(assessCtx.questions?.length - 1);
    //   } else {
    //     // go to the first unanswered question 
    //     for (let i = 0; i < assessCtx.questions?.length; i++) {
    //       if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
    //         setActiveStep(i);
    //         break;
    //       } else setActiveStep(assessCtx.questions?.length - 1);
    //     }
    //   }
    // }
    // else {

    let clientId = process.env.REACT_APP_BOOKKEEPING_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        console.log(data);
        let questions = data.data?.questions;
        if (retrieveStoredAnswers()) {
          let answeredQuestions = [];
          let storedQuestions = retrieveStoredAnswers();
          questions?.forEach(q => {
            let question = storedQuestions.find(obj => obj.id == q.id);
            if (question) {
              q.answerTxt = question.answerTxt;
              q.answer = question.answer;
              q.answers = question.answers;
            }
          })
        }
        // setSections(data.sections);
        // setQuestions(data.questions);
        // setQuestionCount(data.questions?.length);
        assessCtx.addQuestions(questions);

        // fix for the issue with question no 22, 25

        const softwareValue = questions?.find(i => i.orderNo == 22)
        const softwareFeatures = questions?.find(i => i.orderNo == 25)

        if (softwareValue.answerTxt !== '') {
          let options = assessCtx.bookkeepingSoftwareValues
          const newOption = { label: softwareValue.answerTxt, value: softwareValue.answerTxt };
          options.push(newOption)
          assessCtx.addBookkeepingSoftwareValues(options);
        }
        if (softwareFeatures.answerTxt !== '') {
          assessCtx.addBookkeepingSoftwareFeatures(softwareFeatures.answerTxt);
        }

        // if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);

        // go to first unanswered question 
        let goingForward = localStorage.getItem("goingForward") === "true" ? true : false;

        const jumpToUnansweredQuestion = () => {
          let sortedQuestions = data?.data.questions;
          sortedQuestions = sortedQuestions?.sort((a, b) => a.orderNo - b.orderNo);
          for (let i = 0; i < sortedQuestions?.length; i++) {
            if (sortedQuestions?.[i]?.answer == undefined && assessCtx.questions?.[i]?.answer !== 0) {
              setActiveStep(i);
              break;
            } else setActiveStep(sortedQuestions?.length - 1);
          }
        }

        if (goingForward) {
          setActiveStep(0);
          localStorage.removeItem("goingForward");
        }
        else jumpToUnansweredQuestion();

        setSections(data?.data.sections);
        setQuestions(data?.data.questions);
        assessCtx.addQuestions(questions);
        setQuestionCount(data?.data.questions?.length);
        console.log("QUESTIONS", data?.data.questions);
        console.log("QUESTIONS", data?.data);
        setApiData(data.data);
        setIsLoading(false);
      } else {
        console.error("Error while fetching questions...", data);
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error("Error while fetching questions...", err);
      setIsLoading(false);
    });
    // }
  }


  console.log("apiData", apiData);

  const currentSectionName = sections?.find(section => section.id === questions?.[activeStep]?.sectionId)?.section;

  console.log("hasAdditional", hasAdditional);

  function completeAssessment() {

    setIsLoading(true);
    let clientId = process.env.REACT_APP_BOOKKEEPING_CLIENT_ID;

    let isReview = false;
    if (id != null) {
      isReview = true;
    }

    const jsonBody = { pageId: 835, questions: questions, isReview: isReview }

    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        const addQuestionsPageId = 836;
        localStorage.removeItem("assessmentAnswers");
        setTextInput('')
        markAssessmentAsCompleted();
        // navigate(`/assessment-additional/${id}/${addQuestionsPageId}?${searchParams}`, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        console.error("Failed to submit answers...", data);
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error("Failed to submit answers...", err);
      setIsLoading(false);
    });


    // console.log("questions", questions);
    // fetch(process.env.REACT_APP_API_URI + '/v3/questions/' + questionsCtx.currentPage, {
    //   method: 'POST', mode: 'cors',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ clientId, questions, isReview, id })
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   if (data.status == "success") {
    //     if (hasAdditional) {
    //       if (data.member) {
    //         if (searchParams.get("member") === data.memberId) {
    //           navigate("/bookkeeping/assessment-additional/" + data.id + "?" + searchParams, { replace: true });
    //         } else navigate("/bookkeeping/assessment-additional/" + data.id + "?member=" + data.memberId, { replace: true });
    //       } else navigate("/bookkeeping/assessment-additional/" + data.id, { replace: true });
    //     } else {
    //       if (searchParams.get("user") === data.memberId) {
    //         navigate("/bookkeeping/details/" + data.id + "?member=" + data.memberId, { replace: true });
    //       } else {
    //         // navigate("/bookkeeping/pre-additional-info/" + data.id, { replace: true });
    //         navigate("/bookkeeping/details/" + data.id + "?" + searchParams, { replace: true });
    //       }
    //     }
    //   } else {
    //     // setOpenSnackbar(true);
    //   }
    // });

  }

  function markAssessmentAsCompleted() {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/' + id + '/completed', {
      method: 'PUT', mode: 'cors', headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ hash: id }),
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setIsLoading(false);
        // const assessmentName = localStorage.getItem("assessmentName");
        // navigate(`/${assessmentName}/outro/${id}/?${searchParams}`, { replace: true });
        const addQuestionsPageId = 836;
        // navigate(`/bookkeeping/assessment-additional/${id}/${addQuestionsPageId}?${searchParams}`, { replace: true });
        if (searchParams.get("leader")) {
          navigate("/bookkeeping/details/" + id + '?member=' + id + '&' + searchParams, { replace: true });
          //   navigate("/bookkeeping/post-additional-info/" + id + '?' + searchParams, { replace: true });
        } else {
          navigate("/bookkeeping/details/" + id + "?" + searchParams, { replace: true });
          // navigate("/bookkeeping/post-additional-info/" + id + '?' + searchParams, { replace: true });
        }
      } else {
        setIsLoading(false);
        console.error("Failed to mark assessment as completed...", data);
      }
    }).catch((err) => {
      console.error("Failed to mark assessment as completed...", err);
      setIsLoading(false);
    })
  }

  console.log("searchParams", [...searchParams]);

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if (savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  // move to next question without waiting for submitAnswer API request to complete
  const goToNextQuestion = () => {
    // this should not depend on submitAnswer API response as that needs to happen in the background
    setTimeout(() => {
      if (activeStep < (questions?.length - 1)) {
        // setActiveStep(activeStep + 1);
        if (questionsWithoutStats.includes(questions?.[activeStep]?.orderNo)) {
          setShowStatistics(false);
          setActiveStep((prev) => prev + 1);
        } else {
          setShowStatistics(true);
        }
      } else {
        completeAssessment();
      }
    }, 500);
  }

  const prevQuestion = () => {
    if (showStatistics) {
      // setPrevBtnClicked(false);
    } else setPrevBtnClicked(true);
    // if(activeStep < 1) {
    //   // if(searchParams.get("member")) {
    //   if([...searchParams]?.length) {
    //     // navigate("/bookkeeping/start?member=" + searchParams.get("member"), { replace: true });
    //     navigate("/bookkeeping/start?" + searchParams, { replace: true });
    //   } else navigate("/bookkeeping/start", { replace: true });
    // } else {
    //   setActiveStep(activeStep - 1);
    // }

    if (activeStep > 0) {
      if (showStatistics) {
        setShowStatistics(false);
        // setActiveStep(activeStep - 1);
      } else if (questionsWithoutStats.includes(questions?.[activeStep - 1]?.orderNo)) {
        setShowStatistics(false);
        // setActiveStep(activeStep - 1);
        let newActiveStep = activeStep - 1;
        // loop back until a question without skip = true is found 
        while (newActiveStep >= 0 && questions?.[newActiveStep]?.skip) {
          newActiveStep -= 1;
        }
        setActiveStep(newActiveStep);
      } else {
        setShowStatistics(true);
        setActiveStep(activeStep - 1);
      }
    } else if (activeStep == 0 && showStatistics) {
      setShowStatistics(false);
    } else {
      navigateBackwardFunc();
    }
  };

  const nextQuestion = () => {

    assessCtx.updateAnswer(questions?.[activeStep].id, questions?.[activeStep].answer);
    assessCtx.updateTextAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt);

    if (questionType === "checkbox") {
      assessCtx.updateCheckboxAnswer(questions?.[activeStep].id, questions?.[activeStep].answers);
      submitAnswer(0); // does not matter what we pass as answer arg as answers array is used for checkbox
    }

    if (questionType === "select") {
      setQuestions((prev) => prev.map((q) => {
        if (q.id === questions?.[activeStep]?.id) {
          let selectQAns = 0;
          if (q.orderNo == 25) {
            selectQAns = 0; // Q25 is non scoring question
          } else if (q.orderNo == 26) {
            selectQAns = 0; // Q26 is non scoring question
          }
          q.answer = selectQAns;
          q.answerTxt = textInput?.trim();
        }
        return q;
      }))

      let selectQAns = 0;
      if (questions?.[activeStep]?.orderNo == 22) {
        if (textInput?.toString()?.toLowerCase() === "none") selectQAns = 0;
        else if (textInput?.toString()?.trim()?.length > 2) selectQAns = 5;
        else selectQAns = 0;
        assessCtx.updateAnswer(questions?.[activeStep]?.id, selectQAns);
      } else if (questions?.[activeStep]?.orderNo == 25) {
        selectQAns = 0; // Q25 is non scoring question
        assessCtx.updateAnswer(questions?.[activeStep]?.id, selectQAns);
      }
      assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);
      if (questions?.[activeStep]?.orderNo == 22) {
        if (questions?.[activeStep]?.answerTxt?.length > 0) {
          submitAnswer({ ansValue: 1, id: questions?.[activeStep]?.optionId || null });
        }
      }
      else {
        submitAnswer(textInput?.trim());
      }
      return; // to prevent the setActivestep code below to jump another question 
      // since that is already happening after submitAnswer runs (in goToNextQuestion)
    }

    // else if (hasAdditional) {
    //   // if(searchParams.get("member") != null) {
    //     if (searchParams?.get("member")) {
    //       navigate("/bookkeeping/assessment-additional/" + currentHash + "?" + searchParams, { replace: true });
    //     } else {
    //       navigate("/bookkeeping/pre-additional-info/" + currentHash + "?" + searchParams, { replace: true });
    //     }
    // } else {
    //   // if(searchParams.get("member") != null) {
    //   if(searchParams.get("member") && currentHash) {
    //     navigate("/bookkeeping/details/" + currentHash + "?" + searchParams, { replace: true });
    //   } else {
    //     console.log("\nSUBMITTING ANSWERS\n");
    //     setTimeout(() => { completeAssessment(); }, 500);
    //   }
    // }


    if (activeStep < (questions?.length - 1) && !(onLastStatisticsPage && showStatistics)) {
      if (questions?.[activeStep]?.answer > 0) {
        // setActiveStep((prev) => prev + 1);
        if (showStatistics && !onLastStatisticsPage) {
          setActiveStep((prev) => prev + 1);
          setShowStatistics(false);
        } else if (questionsWithoutStats.includes(questions?.[activeStep]?.orderNo)) {
          setActiveStep((prev) => prev + 1);
          setShowStatistics(false);
        } else {
          setShowStatistics(true);
        }
      }
    } else {
      // navigateForwardFunc();
      // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
      if (questions[activeStep]?.answer > 0) {
        // navigate(`/assessment-additional/${id}/${addQPageId}?${searchParams}`, { replace: true });
        // navigate(`/details/${id}?${searchParams}`, { replace: true });
        setTimeout(() => { completeAssessment(); }, 500);
      }
    }

  };

  const saveAnswerSubmissionStatus = (qId, submissionSuccess) => {
    setQuestions((prev) => prev.map((q) => {
      if (q.id === qId) {
        q.submissionSuccess = submissionSuccess;
      }
      return q;
    }));
  }

  const submitAnswer = (answer) => {
    // console.log("submitAnswer", answer);
    let jsonBody = {
      // "id": questions?.[activeStep]?.id,
      "pageId": pageId,
      "answer": answer,
      "answerTxt": null,
      "answerChecked": false,
    }

    if (questionType === "checkbox") {
      jsonBody = {
        // "id": questions?.[activeStep]?.id,
        "pageId": pageId,
        "answer": answer,
        "answers": questions?.[activeStep]?.answers,
        "answerTxt": null,
        "answerChecked": true,
      }
    }

    if (questionType === "select") {
      let selectQAns = 0;
      let ansText = questions?.[activeStep]?.answerTxt || "";
      if (questions?.[activeStep]?.orderNo == 22) {
        selectQAns = answer?.ansValue > 0 ? 1 : 0;
        ansText = answer?.label || questions?.[activeStep]?.answerTxt || "";
        // answer = questions?.[activeStep]?.answerTxt;
        // if (textInput?.toString()?.toLowerCase()?.trim() === "none") selectQAns = 0;
        // else if (textInput?.toString()?.trim()?.length > 2) selectQAns = 5;
        // else selectQAns = 0;

      } else if (questions?.[activeStep]?.orderNo == 23) {
        if (textInput?.toString()?.toLowerCase()?.trim() === "none") selectQAns = 0;
        else if (textInput?.toString()?.trim()?.length > 2) selectQAns = 5;
        else selectQAns = 0;
      } else if (questions?.[activeStep]?.orderNo == 24) {
        if (textInput?.toString()?.toLowerCase()?.trim() === "none") selectQAns = 0;
        else if (textInput?.toString()?.trim()?.length > 2) selectQAns = 5;
        else selectQAns = 0;
      } else if (questions?.[activeStep]?.orderNo == 25) {
        selectQAns = 0; // Q25 is non scoring question
      } else if (questions?.[activeStep]?.orderNo == 26) {
        selectQAns = 0; // Q26 is non scoring question
      }
      if (answer?.id) {
        jsonBody = {
          "pageId": pageId,
          "answer": selectQAns,
          "answerTxt": ansText,
          "optionId": answer?.id,
        }
      } else {
        jsonBody = {
          "pageId": pageId,
          "answer": selectQAns,
          "answerTxt": ansText,
        }
      }
    }
    console.log("jsonBody", jsonBody);

    setIsSubmittingAnswer(true);
    setAnswerSubmissionError(false);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/question/' + questions?.[activeStep]?.id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      // // this should not depend on API response as it needs to happen in the background
      // if (activeStep < (questions?.length - 1)) {
      //   setActiveStep(activeStep + 1);
      // } else {
      //   // navigate(navigatePath, { replace: true });
      //   // navigateForwardFunc();
      //   completeAssessment();
      // }

      if (data.status === "success") {
        // navigate("/bookkeeping/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        // save the answer option so you see the selected option when going back & forth between questions 
        setIsSubmittingAnswer(false);
        setAnswerSubmissionError(false);
        // saveAnswerSubmissionStatus(questions?.[activeStep]?.id, true);

        console.log("data", data);
      } else {
        // setIsSaving(false);
        console.error("Failed to send answer details...", data);
        setIsSubmittingAnswer(false);
        setAnswerSubmissionError(true);
        // saveAnswerSubmissionStatus(questions?.[activeStep]?.id, false);
      }
    }).catch((err) => {
      console.error("Failed to send answer details...", err);
      setIsSubmittingAnswer(false);
      setAnswerSubmissionError(true);
      // saveAnswerSubmissionStatus(questions?.[activeStep]?.id, false);
    });

    // goToNextQuestion should not depend on submitAnswer API response as that needs to happen in the background
    goToNextQuestion();

  }


  const navigatePath = (pgId) => "/bookkeeping/assessment/" + id + "/" + pgId + "/?" + searchParams;
  const navigateForwardFunc = () => {
    if (pageId === "28") {
      navigate("/bookkeeping/charts/" + id + "/29?" + searchParams, { replace: true });
    } else if (pageId === "30") {
      navigate("/bookkeeping/charts/" + id + "/31?" + searchParams, { replace: true });
    } else if (pageId === "32") {
      navigate("/bookkeeping/charts/" + id + "/33?" + searchParams, { replace: true });
    } else if (pageId === "34") {
      navigate("/bookkeeping/charts/" + id + "/35?" + searchParams, { replace: true });
    } else if (pageId === "36") {
      navigate("/bookkeeping/charts/" + id + "/37?" + searchParams, { replace: true });
    } else if (pageId === "804") {
      navigate("/bookkeeping/assessment-additional/" + id + "/805?" + searchParams, { replace: true });
    }
  }
  const navigateBackwardFunc = () => {
    let qOrderNum = questions?.[activeStep]?.orderNo;
    console.log("qOrderNum", qOrderNum);
    console.log("pageId", pageId, typeof pageId);

    if (pageId === "28") navigate("/bookkeeping/first-form/" + id + "?" + searchParams, { replace: true });
    else if (pageId === "30") {
      if (qOrderNum == 2) navigate("/bookkeeping/charts/" + id + "/29?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(28), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "32") {
      if (qOrderNum == 5) navigate("/bookkeeping/charts/" + id + "/31?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(30), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "34") {
      if (qOrderNum == 8) navigate("/bookkeeping/charts/" + id + "/33?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(32), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "36") {
      if (qOrderNum == 11) navigate("/bookkeeping/charts/" + id + "/35?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(34), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "38") {
      if (qOrderNum == 14) navigate("/bookkeeping/charts/" + id + "/37?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(36), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    } else {
      navigate("/bookkeeping/?" + searchParams, { replace: true });
    }
  }

  const moveToNextQuestion = (answer, answeredAsNone = false) => {
    // console.log("moveToNextQuestion answer", answer);
    // questions[activeStep].answer = answer;
    setQuestions((prev) => prev.map((q) => {
      if (q.id === questions?.[activeStep]?.id) {
        if (q.orderNo == 22 || q.orderNo == 25) {
          let selectQAns = 0;
          if (q.orderNo == 22) {
            if (textInput?.toString()?.toLowerCase()?.trim() === "none") selectQAns = 0;
            else if (textInput?.toString()?.trim()?.length > 2) selectQAns = 1;
            else selectQAns = 0;
            q.optionId = answer?.id;
            if (answeredAsNone) {
              q.answerTxt = "";
              q.optionId = null;
              q.answer = 0;
            }
          } else if (q.orderNo == 25) {
            selectQAns = 0; // Q25 is non scoring question
          } else if (q.orderNo == 26) {
            selectQAns = 0; // Q26 is non scoring question
          }
          q.answer = selectQAns;
        } else {
          q.answer = answer;
        }
        if (q.na === "yes" && answer > 0) {
          q.skip = false;
        }
      }
      return q;
    }));
    if (answeredAsNone) {
      assessCtx.updateAnswer(questions?.[activeStep]?.id, 0);
    } else {
      assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
    }
    // assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);
    // if(activeStep < questions?.length - 1) {
    //   setTimeout(() => {
    //     setActiveStep(activeStep + 1);
    //   }, 500);
    // } else {
    //   // questions?.[activeStep].answer = answer;
    //   console.log("\n\nSUBMITTING ANSWERS\n\n");
    //   setTimeout(() => { completeAssessment(); }, 500);
    // }

    // setTimeout(() => {
    if (answeredAsNone) {
      submitAnswer({ ansValue: 0, id: null });
    } else {
      submitAnswer(answer);
    }
    // }, 500);
    console.log("answer", answer);

  }

  const nextDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return { display: "flex" };
    }
    if (questions?.[activeStep]?.answer > 0) {
      return { display: "flex" };
    };
    return { display: "flex" };
  }

  const checkNextBtnDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return false;
    }
    if (questions?.[activeStep]?.answerType === "checkbox") {
      const anyOptionChecked = questions?.[activeStep]?.answers?.some(option => option.checked);
      if (anyOptionChecked) return false;
      else return true;
    }

    if (questions?.[activeStep]?.answerType === "select" && (textInput?.length > 0 || questions?.[activeStep]?.optionId)) {
      return false
    }

    if (questions?.[activeStep]?.answer > 0 || questions?.[activeStep]?.skip) {
      return false;
    };
    if (showStatistics) return false;
    return true;
  }

  // const nextDisabled = (answer) => {
  //   if(questions?.[activeStep]?.mandatory === "no") {
  //     return {display: "block"};
  //   } else {
  //     // if(questions?.[activeStep]?.answerType == "slider" && questions?.[activeStep]?.answer != 0) {
  //     if(questions?.[activeStep]?.answerType == "slider" && !questions?.[activeStep]?.answer > 0) {
  //       return { display: "block" };
  //     } else if(questions?.[activeStep]?.answerType == "radio" && questions?.[activeStep]?.answer != 0) {
  //       return {display: "block"};
  //     } else if(questions?.[activeStep]?.answerType == "textarea" && questions?.[activeStep]?.answerTxt != "") {
  //       return {display: "block"};
  //     }
  //   } 
  //   return {display: "none"};
  // }

  const updateTextAnswer = (id, textVal, otherTextInput = false) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        q.answerTxt = textVal;
        q.answer = 1;
        if (otherTextInput) {
          q.optionId = null;
        }
      }
      return q;
    }))
  }

  const updateTextAnswerForMCQ = (id, textVal) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        q.answerTxt = textVal;
      }
      return q;
    }))
  }


  const updateCheckboxQuestion = (id, checkboxAnswers) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        q.answers = checkboxAnswers;
      }
      return q;
    }));
  }

  const answerAsNotApplicable = (id, checked) => {
    // checked is boolean. if checked, skip will be set to true
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        // q.skip = checked;
        if (checked) {
          q.answer = 7;
          q.answerTxt = "not applicable";
        } else {
          q.answer = 0;
          q.answerTxt = "";
        }
      }
      return q;
    }));
    if (checked) {
      // setTimeout(() => {
      submitAnswer(7);
      // }, 500);
    }
  }

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr?.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }

  function getOperatorLogic(operand1, operator, operand2) {
    console.log(operand1, operator, operand2);
    if (operator == '<') {
      return operand1 < operand2;
    } else if (operator == '>') {
      return operand1 > operand2;
    } else if (operator == '||') {
      return operand1 || operand2;
    } else if (operator == '!=') {
      return operand1 != operand2;
    } else if (operator == '==') {
      return operand1 == operand2;
    } else if (operator == '<=') {
      return operand1 <= operand2;
    } else if (operator == '>=') {
      return operand1 >= operand2;
    }
  }

  console.log("questions[activeStep]", questions?.[activeStep]);

  function getQuestionBasedOnVariable(array, variable) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].variable == variable) {
        return array[i]
      }
    }
  }

  const updateTextQuestion = (answer, qId) => {
    console.log("updateTextQuestion answer", answer);
    setTextInput(answer);
    setQuestions((prev) => prev.map((q) => {

      if (q.orderNo == 22 || q.orderNo == 25) {
        let selectQAns = 0;
        if (q.orderNo == 22) {
          if (answer?.toString()?.toLowerCase()?.trim() === "none") selectQAns = 0;
          else if (answer?.toString()?.trim()?.length > 2) selectQAns = 5;
          else selectQAns = 0;
        } else if (q.orderNo == 23) {
          selectQAns = 0; // Q25 is non scoring question
        }
        if (q.id === qId) {
          q.answer = selectQAns;
        }
      }
      if (q.id === qId) {
        q.answerTxt = answer?.trim();
      }
      return q;
    }))

    // setQuestions((prev) => prev.map((q) => {
    //   if (q.id === questions?.[activeStep]?.id) {
    //     let selectQAns = 0;
    //     if (q.orderNo == 22) {
    //       if (textInput?.toString()?.toLowerCase() === "none") selectQAns = 0;
    //       else if (textInput?.toString()?.trim()?.length > 2) selectQAns = 5;
    //       else selectQAns = 0;
    //     } else if (q.orderNo == 25) {
    //       selectQAns = 0; // Q25 is non scoring question
    //     }
    //     q.answer = selectQAns;
    //     q.answerTxt = textInput?.trim();
    //   }
    //   return q;
    // }))
  }

  const questionsPerSection = getQuestionsPerSection();
  // const questionsPerSection = [4, 5, 4, 3];
  console.log("questionsPerSection", questionsPerSection);
  console.log("\n\nquestions?.[activeStep]", activeStep, (questions?.length - 1), "\n\n");
  console.log("assessCtx.questions", assessCtx.questions);
  console.log("questions", questions);
  console.log(checkNextBtnDisabled(), 'checkNextBtnDisabled')

  const getStepContent = (step) => {
    console.log("step: ", step);
    return (
      <BookkeepingQuestion question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} updateTextAnswer={updateTextAnswer} updateCheckboxQuestion={updateCheckboxQuestion} answerAsNotApplicable={answerAsNotApplicable} textAnswer={updateTextQuestion} updateTextAnswerForMCQ={updateTextAnswerForMCQ} />
    );
  };

  const getStatistics = () => {

    const currentStat = questions_statistics?.find(stat => stat.id == questions?.[activeStep]?.orderNo);

    function skipStatistic() {
      setShowStatistics(false);
    }

    if (questionsWithoutStats.includes(currentStat.id)) {
      skipStatistic();
    }

    let imgStyles = currentStat.image?.desktop;

    if (windowWidth <= 650) {
      imgStyles = currentStat.image?.mobile;
    } else {
      imgStyles = currentStat.image?.desktop;
    }

    return (
      <div className="statistics">
        <div className="chart">
          {currentStat.image.src && <img src={currentStat.image.src} alt="" style={imgStyles} />}
        </div>
        {currentStat.title && <p className="chart-title">{currentStat.title}</p>}
        <div className="statistic">
          {/* <div className="circle"></div> */}
          {currentStat.desc && <p className="statistic-desc">{currentStat.desc}</p>}
        </div>
      </div>
    )
  }

  function getQuestionNum() {
    let qNum = questions?.[activeStep]?.orderNo;
    switch (qNum) {
      case 24:
        if (questions?.[activeStep - 2].answer == 0) {
          qNum = 23;
        } else {
          qNum = 24;
        }
        break;
      case 25:
        if (questions?.[activeStep - 2].answer > 2) {
          qNum = 24;
        } else {
          qNum = 24;
        }
        break;
      case 26:
        qNum = questions?.[21]?.answer == 0 ? 24 : 25;
        break;
      case 27: qNum = questions?.[21]?.answer == 0 ? 25 : 26; break;
      default: break;
    }
    return qNum;
  }
  console.log(questions?.[21])

  if (isLoading) {
    return <div className="container assessment loading"><CircularProgress style={{ color: '#ED2028' }} size={60} /></div>
  }

  return (
    <>

      <div className={`container assessment ${showStatistics ? "statistics" : ""}`}>
        {windowWidth < 830 ? <>
          <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={questionCount} />
          <p className="current-section">{currentSectionName}</p>
        </> : <div className="section-progress-top">
          <BookkeepingProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
        </div>}
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}></Stepper>
        </Box>
        <div>
          {(!showStatistics) ? <div className="steps-container">
            <div>
              <div className="steps">

                {(windowWidth > 830 && questionType !== "checkbox") && <div className="spacer"></div>}

                {/* {windowWidth > 830 ?
                <>
                  <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
                  <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={16} />
                </>
                : <>
                  <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} />
                  <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={16} />
                  <p className="current-section">{currentSectionName}</p>
                </>
              } */}
                {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} sections={sections} currentSectionName={currentSectionName} /> */}
                {/* <div className="spacer"></div> */}
                {/* <div className="spacer"></div> */}
                {/* <div className="spacer mobile-spacer"></div> */}
                {/* <div className="spacer mobile-spacer"></div> */}

                {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/16</div> */}
                <div className="progress-indicator">
                  <p className="step-no">{getQuestionNum()}</p>
                  /
                  {/* <p className="total-question-count">{questionCount}</p> */}
                  <p className="total-question-count">{questions?.[21]?.answer == 0 || questions?.[21]?.answer == undefined  ? 25 : 26}</p>
                </div>

                {getStepContent(activeStep)}
                {/* <QuestionInfo /> */}

              </div>
            </div>
          </div> : <>
            {isSubmittingAnswer ? <div className="loading-container"><CircularProgress style={{ color: '#ED2028' }} size={60} /></div> :
              <>
                <div className="progress-indicator">
                  <p className="step-no">{getQuestionNum()}</p>
                  /
                  {/* <p className="total-question-count">{questionCount}</p> */}
                  <p className="total-question-count">{questions?.[21]?.answer == 0 || questions?.[21]?.answer == undefined  ? 25 : 26}</p>
                </div>
                {getStatistics()}
              </>
            }
          </>}

          <div className="step-buttons" style={{ justifyContent: "space-between" }}>
            <Button onClick={prevQuestion} className="prev-btn">
              <img src={prevArrow} alt="previous button" />
              {/* {windowWidth <= 550 ? (
                <><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;</>
              ) : (
                <><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;</>
              )} */}
            </Button>

            {showNextBtn && <Button className="next-btn" onClick={nextQuestion} disabled={checkNextBtnDisabled()}
            // style={nextDisabled()}
            >
              NEXT
              {/* {questions?.[activeStep]?.orderNo === 22 ? "ALL DONE!" : "Next"} */}
              {/* {windowWidth <= 550 ? (
                <span style={{ fontSize: "18px", }}>NEXT</span>
              ) : (
                <><span style={{ fontSize: "18px", }}>NEXT</span><img style={{ paddingLeft: "8px" }} src={nextArrowWhite} alt="next button" /></>
              )} */}
            </Button>}
          </div>

        </div>
      </div>
      {answerSubmissionError &&
        <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            Failed to submit answer. Please try again.
          </Alert>
        </Snackbar>
      }

      {/* TODO: uncomment this after confirming this error indication feature addition with client team */}
      {/* {questions?.[activeStep]?.submissionSuccess === false &&
        <Snackbar open={queSnackbarOpen} autoHideDuration={4000} onClose={handleCloseQueSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseQueSnackbar} severity="error">
            Error submitting answer for question {questions?.[activeStep]?.orderNo}. Please try again.
          </Alert>
        </Snackbar>
      } */}
    </>
  );
}

export default BookkeepingAssessmentV3;

