
import React, { useContext, useEffect, useState } from "react";
import * as d3 from "d3";
import CongregationAssessmentQuestionContext from '../../../Store/Congregation/CongregationAssessmentQuestionContext';
import CongregationAdditionalQuestionContext from "../../../Store/Congregation/CongregationAdditionalQuestionContext";
import useWindowDimensions from '../../../Hooks/useWindowDimensions'

const CongregationDonutChart = (props) => {

  const AssessmentQuestionCtx = useContext(CongregationAssessmentQuestionContext)
  const additionalCtx = useContext(CongregationAdditionalQuestionContext);

  // console.log(AssessmentQuestionCtx.questions[0])
  // console.log(additionalCtx.form1Details.avgWeeklyAttd)

  const calculationData = [
    { weeklyAtt: [0, 50], answers: [13, 2, 1, 1, 1] },
    { weeklyAtt: [51, 100], answers: [18, 5, 3, 3, 2] },
    { weeklyAtt: [101, 150], answers: [34, 11, 7, 7, 4] },
    { weeklyAtt: [151, 200], answers: [81, 22, 14, 14, 8] },
    { weeklyAtt: [201, 250], answers: [90, 31, 20, 19, 11] },
    { weeklyAtt: [251, 300], answers: [136, 40, 24, 24, 13] },
    { weeklyAtt: [301, 500], answers: [240, 77, 47, 47, 25] },
    { weeklyAtt: [501, 700], answers: [371, 120, 76, 75, 40] },
    { weeklyAtt: [701, 900], answers: [550, 175, 106, 98, 41] },
    { weeklyAtt: [901, 1500], answers: [677, 197, 106, 101, 51] },
    { weeklyAtt: [1501, 2000], answers: [930, 289, 159, 155, 88] },
    { weeklyAtt: [2000, 2000], answers: [1251, 287, 133, 95, 33] },
  ]

  const data = [
    { label: '1-12 weeks', count: 57.4 },
    { label: '13-25 weeks', count: 16.9 },
    { label: '26-34 weeks', count: 10.1 },
    { label: '35-43 weeks', count: 10.1 },
    { label: '44-52 weeks', count: 5.5 }
  ]

  const { windowWidth } = useWindowDimensions()

  // let windowWidth = props.windowWidth

  useEffect(() => {

    /**
     * Perform calculations based on the weekly attendance data from the additional context.
     * If the weekly attendance is greater than 2000, calculate the percentage distribution based on the last set of answers.
     * Otherwise, determine the appropriate range of weekly attendance and calculate the percentage distribution accordingly.
     * Update the 'count' property of the 'data' array with the calculated percentages.
     * 
     * @param {Object} additionalCtx - The additional context object containing the weekly attendance data.
     * @param {Array} calculationData - The array of objects containing ranges of weekly attendance and corresponding answers.
     * @param {Array} data - The array of objects representing labels and counts for the donut chart.
     */

    let weeklyAttendance = additionalCtx.form1Details.avgWeeklyAttd == '' ? +localStorage.getItem("avgWeeklyAttd") : +additionalCtx.form1Details.avgWeeklyAttd
    // let weeklyAttendance = 2074

    for (let i = 0; i < calculationData?.length; i++) {

      if (weeklyAttendance > 2000) {

        let answersArray = calculationData[calculationData?.length - 1].answers

        const initialValue = 0;
        const sumWithInitial = answersArray?.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue,)

        for (let j = 0; j < answersArray?.length; j++) {
          let percentage = (answersArray[j] / sumWithInitial) * 100
          data[j].count = percentage.toFixed(1)
        }

      } else if (weeklyAttendance >= calculationData[i].weeklyAtt[0] && weeklyAttendance <= calculationData[i].weeklyAtt[1]) {
        let answersArray = calculationData[i].answers

        const initialValue = 0;
        const sumWithInitial = answersArray?.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue,)

        for (let j = 0; j < answersArray?.length; j++) {
          let percentage = (answersArray[j] / sumWithInitial) * 100
          data[j].count = percentage.toFixed(1)
        }
      }
    }

  }, [additionalCtx])


  let width = windowWidth < 600 ? 300 : 350
  let height = windowWidth < 600 ? 300 : 350
  let innerRadius = windowWidth < 600 ? 40 : 50
  let outerRadius = windowWidth < 600 ? 70 : 100

  const createPie = d3
    .pie()
    .value(d => d.count)
    .sort(null);

  //arc for texts
  const createArc2 = d3
    .arc()
    .innerRadius(windowWidth < 600 ? 100 : 120)
    .outerRadius(windowWidth < 600 ? 110 : 160);

  //color range
  var colorScale = d3.scaleOrdinal()
    .domain(data?.map(i => i.label))
    .range(['#FF3131', '#737373', '#A6A6A6', '#D9D9D9', '#F2F0F0']);


  //states
  const [pieData, setPieData] = useState(null)

  useEffect(() => {
    if (data !== null) {
      setPieData(createPie(data))
    }
  }, []);


  const getArcPath = (d) => {

    const createArc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(.7)
    // .padAngle(hoverData && 0.005)

    return <path style={{ transition: '400ms ease-in-out' }} className="arc" d={createArc(d)} fill={colorScale(d.data.label)} ></path>
  }


  const hoverEffectText = (d) => {
    return { fontSize: windowWidth < 600 ? '10px' : '13px', fontWeight: '900', textAlign: 'center', width: '100%', transition: '400ms ease-in-out' }
  }

  const getPositionBasedOnIndex = (i) => {

    if (i == 3) {
      return 14
    } else if (i == 4) {
      return -10
    } else {
      return 0
    }
  }


  return (
    <svg id="chart-svg" width={width} height={height} style={{ overflow: 'visible', position: 'relative' }}>

      <g transform={`translate(${height / 2} ${width / 2})`}>
        {
          pieData
            ? pieData?.map((d, i) => {

              return <g className="arc" key={i} >
                {getArcPath(d)}
                <text style={hoverEffectText(d)} fill={'#000'} textAnchor="middle" transform={`translate(${createArc2.centroid(d)[0] - getPositionBasedOnIndex(i)}, ${createArc2.centroid(d)[1]} )`} >{d.data.label}</text>
                <text style={hoverEffectText(d)} fill={'#000'} textAnchor="middle" transform={`translate(${createArc2.centroid(d)[0] - getPositionBasedOnIndex(i)}, ${createArc2.centroid(d)[1] + (windowWidth < 600 ? 13 : 20)} )`} >{d.data.count}%</text>
              </g>
            })
            : null
        }

      </g>
    </svg>
  );

};

export default CongregationDonutChart;
