import { useState, useEffect, createContext } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const BookkeepingAssessmentQuestionContext = createContext({
  questions: {},
  addQuestions: (addQuestions) => { },
  updateAnswer: (updateAnswer) => { },
  updateTextAnswer: (updateTextAnswer) => { },
  addBookkeepingSoftwareValues: (addBookkeepingSoftwareValues) => { },
  addBookkeepingSoftwareFeatures: (features) => { },
  softwareFeatures: '',
  bookkeepingSoftwareValues: [
    { label: "Acts", value: "Acts" },
    { label: "Aplos", value: "Aplos" },
    { label: "ChurchTrac", value: "ChurchTrac" },
    { label: "FreshBooks", value: "FreshBooks" },
    { label: "IconCMO", value: "IconCMO" },
    { label: "NetSuite", value: "NetSuite" },
    { label: "PowerChurch", value: "PowerChurch" },
    { label: "QuickBooks", value: "QuickBooks" },
    { label: "Quicken", value: "Quicken" },
    { label: "Realm", value: "Realm" },
    { label: "ShelbyNext Financials", value: "ShelbyNext Financials" },
    { label: "Wave", value: "Wave" },
    { label: "Xero", value: "Xero" },
    { label: "Zoho Books", value: "Zoho Books" },
  ],
});

export function BookkeepingAssessmentQuestionContextProvider(props) {

  const location = useLocation();

  const [questions, setQuestions] = useState([]);
  const [bookkeepingSoftwareFeatures, setBookkeepingSoftwareFeatures] = useState('')
  const [bookkeepingSoftwareValues, setBookkeepingSoftwareValues] = useState([
    { label: "Acts", value: "Acts" },
    { label: "Aplos", value: "Aplos" },
    { label: "ChurchTrac", value: "ChurchTrac" },
    { label: "FreshBooks", value: "FreshBooks" },
    { label: "IconCMO", value: "IconCMO" },
    { label: "NetSuite", value: "NetSuite" },
    { label: "PowerChurch", value: "PowerChurch" },
    { label: "QuickBooks", value: "QuickBooks" },
    { label: "Quicken", value: "Quicken" },
    { label: "Realm", value: "Realm" },
    { label: "ShelbyNext Financials", value: "ShelbyNext Financials" },
    { label: "Wave", value: "Wave" },
    { label: "Xero", value: "Xero" },
    { label: "Zoho Books", value: "Zoho Books" },
  ]);

  // console.log(location.pathname.split("/").includes("review"));
  useEffect(() => {
    if (location.pathname.split("/").includes("review")) {
      let localStoredQuestions = JSON.parse(localStorage.getItem("assessmentAnswers"));
      if (localStoredQuestions?.length) {
        setQuestions(localStoredQuestions);
      }
    }
  }, [location]);

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answer = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answerTxt = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }
  
  function updateCheckboxAnswerHandler(questionId, answersArray) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answers = answersArray;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
  }

  function updateSkipStatusHandler(questionId, skip) {
    let question = questions?.find(function(obj) { return obj.id == questionId; });
    if (question) {
      question.skip = skip;
      let localQuestions = [];
      questions.forEach(q => localQuestions.push(q));
      localStorage.setItem('assessmentAnswers', JSON.stringify(localQuestions));
    }
  }

  function addBookkeepingSoftwareValuesHandler(data) {
    setBookkeepingSoftwareValues(data);
  }

  function addBookkeepingSoftwareFeaturesHandler(data) {
    setBookkeepingSoftwareFeatures(data);
  }

  const context = {
    questions: questions,
    addQuestions: addQuestionsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler,
    updateCheckboxAnswer: updateCheckboxAnswerHandler,
    updateSkipStatus: updateSkipStatusHandler,
    bookkeepingSoftwareValues: bookkeepingSoftwareValues,
    addBookkeepingSoftwareValues: addBookkeepingSoftwareValuesHandler,
    addBookkeepingSoftwareFeatures: addBookkeepingSoftwareFeaturesHandler,
    softwareFeatures: bookkeepingSoftwareFeatures
  };

  return <BookkeepingAssessmentQuestionContext.Provider value={context}>
    {props.children}
  </BookkeepingAssessmentQuestionContext.Provider>
}

export default BookkeepingAssessmentQuestionContext;
