import React, { useEffect } from 'react';
import TickImg from '../../Assets/Images/Community/thank-you-tick.png'
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import useWindowDimensions from '../../Hooks/useWindowDimensions';

const CommunityTest = () => {

	const { id }                   	= useParams();
	
	useEffect(() => { 
		// document.body.style.backgroundColor = "#007670";
	},[]);

	// const submitForm = () => {
	const submitForm = () => {

		// var formdata = new FormData();
		// formdata.append("oid", "00D5j00000CNDfs");
		// formdata.append("retURL", "https://indicators.acst.com/thank-you");
		// formdata.append("first_name", "Stephen");
		// formdata.append("last_name", "Test33");
		// formdata.append("email", "test33@studio137.co.za");
		// formdata.append("company", "Studio1373");
		// formdata.append("debug", "1");

		// var requestOptions = {
		// 	method: 'POST',
		// 	body: formdata,
		// 	redirect: 'follow'
		// };

		// fetch('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', requestOptions)
		// 	.then(response => response.text())
		// 	.then(result => console.log(result))
		// 	.catch(error => console.log('error', error));

		// webToLead();
		// var fields = [
		// 	{oid: '00D5j00000CNDfs'},
		// 	{first_name: 'Stephen'},
		// 	{last_name: 'Test35'},
		// 	{email: 'test35@studio137.co.za'},
		// 	{company: 'Studio1375'}
		// ]

		var customHiddenIframeName='JLA_API';
		if(!document.getElementById(customHiddenIframeName)){
			var theiFrame=document.createElement("iframe");
			theiFrame.id=customHiddenIframeName;
			theiFrame.name=customHiddenIframeName;
			theiFrame.src='about:blank';
			theiFrame.style.display='none';
			document.body.appendChild(theiFrame);
		}
		// fields['retURL']='https://indicators.acst.com/thank-you';//dummy URL
		var form = document.createElement("form");
		form.method = "POST";
		form.action = "https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8";
		form.setAttribute("target", customHiddenIframeName);

		var theInput = document.createElement("input"); 
		theInput.name = 'oid';
		theInput.value = '00D3t0000011lsD';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = '00N3t00000E3Q1j';
		theInput.value = 'text';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = '00N3t00000E3Q1y';
		theInput.value = 'test';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = '00N3t00000E3Q28';
		theInput.value = 'test';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = '00N3t00000E3Q23';
		theInput.value = 'test';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = 'Ministry_Resource__c';
		theInput.value = '1';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = 'first_name';
		theInput.value = 'Stephen';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = 'last_name';
		theInput.value = '40P';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = 'email';
		theInput.value = 'testing_2@40parables.com';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = 'company';
		theInput.value = 'Live the life church';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = '00N3t00000GVtPJ';
		theInput.value = '200';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = '00N3t00000E3hLY';
		theInput.value = 'IT Director';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = 'Denomination__c';
		theInput.value = 'Evangelical';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		var theInput = document.createElement("input"); 
		theInput.name = 'Message__c';
		theInput.value = 'Testing';
		theInput.setAttribute("type", "hidden");
		form.appendChild(theInput);

		// for (var fieldName in fields) {
		// 	var theInput = document.createElement("input"); 
		// 	theInput.name=fieldName;
		// 	theInput.value =fields[fieldName];
		// 	theInput.setAttribute("type", "hidden");
		// 	form.appendChild(theInput);  
		// }
		document.body.appendChild(form);
		form.submit();

		// <input type=hidden name="oid" value="00D3t0000011lsD">
		// <input type=hidden id="retURL" name="retURL" value="">
		// <input id="00N3t00000E3Q1j" name="00N3t00000E3Q1j" type="hidden" value="PAGE URL OF ASSESSMENT">
		// <input id="00N3t00000E3Q1y" name="00N3t00000E3Q1y" type="hidden" value="SOURCE PARAM FROM URL">
		// <input type="hidden" id="00N3t00000E3Q28" name="00N3t00000E3Q28" value="UTM SOURCE FROM URL">
		// <input type="hidden" id="00N3t00000E3Q23" name="00N3t00000E3Q23" value="UTM MEDIUM FROM URL">
		// <input type="hidden" id="00N3t00000E3Q0N" name="00N3t00000E3Q0N" value="UTM CAMPAIGN FROM URL">
		// <input type="hidden" id="Ministry_Resource__c" name="Ministry_Resource__c" value="1">

		// <input id="first_name" name="first_name" type="text" placeholder="First Name" required>

		// <input id="last_name" name="last_name" type="text" placeholder="Last Name" required>

		// <input  id="email" name="email" type="text" placeholder="Email" required>

		// <input  id="company" name="company" type="text" placeholder="Church Name" required>

		// <input  id="00N3t00000GVtPJ" name="00N3t00000GVtPJ" placeholder="Typical Weekly Attendance (number only)" required>

		// <select id="00N3t00000E3hLY" name="00N3t00000E3hLY" required>
		// <option value="">-Role-</option>
		// <option value="Executive Pastor">Executive Pastor</option>
		// <option value="Senior Pastor">Senior Pastor</option>
		// <option value="Worship Pastor">Worship Pastor</option>
		// <option value="Youth Pastor">Youth Pastor</option>
		// <option value="Director of Children's Ministry">Director of Children's Ministry</option>
		// <option value="Chief Financial Officer">Chief Financial Officer</option>
		// <option value="Church/Business Administrator">Church/Business Administrator</option>
		// <option value="IT Director">IT Director</option>
		// <option value="Communications Director">Communications Director</option>
		// <option value="Administrative Assistant">Administrative Assistant</option>
		// <option value="Financial Administrative Assistant">Financial Administrative Assistant</option>
		// <option value="Elder/Deacon">Elder/Deacon</option>
		// <option value="Congregant">Congregant</option>
		// </select>

		// <input  id="Denomination__c" name="Denomination__c" type="text" placeholder="Denomination" required>

		// <textarea id="Message__c" name="Message__c"><textarea>

		// <button type="submit">Submit</button>

		// const formData = new FormData();

		// formData.append('oid', '00D3t0000011lsD');
		// formData.append('retURL', '');
		// formData.append('00N3t00000E3Q1j', 'https://indicators.acst.com');
		// formData.append('00N3t00000E3Q1y', 'text');
		// formData.append('00N3t00000E3Q28', 'test');
		// formData.append('00N3t00000E3Q23', 'test');
		// formData.append('00N3t00000E3Q0N', 'test');
		// formData.append('Ministry_Resource__c', '1');
		// formData.append('first_name', 'Stephen');
		// formData.append('last_name', 'Diederichs');
		// formData.append('email', 'stephend@40parables.com');
		// formData.append('company', 'Live the Life Church');
		// formData.append('00N3t00000GVtPJ', '200');
		// formData.append('00N3t00000E3hLY', 'IT Director');
		// formData.append('Denomination__c', 'Evangelical');
		// formData.append('Message__c', 'testing');

		// fetch('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', {
		// // fetch('https://apiv2.himalayanhaat.org/v2/products', {
		// 	method: 'POST',
		// 	body: formData,
		// 	redirect: 'follow',
		// 	headers: { 'Content-Type': 'multipart/form-data' },
		// })
		// .then(response => response.text())
		// .then(result => console.log(result))
		// .catch(error => console.log('error', error));
		// var formdata = new FormData();
		// formdata.append("oid", "00D3t0000011lsD");
		// formdata.append("retURL", "http://indicators.acst.com/thank-you");
		// formdata.append("00N3t00000E3Q1j", "https://indicators.acst.com");
		// formdata.append("00N3t00000E3Q1y", "text");
		// formdata.append("00N3t00000E3Q23", "test");
		// formdata.append("00N3t00000E3Q0N", "test");
		// formdata.append("Ministry_Resource__c", "1");
		// formdata.append("first_name", "Stephen");
		// formdata.append("last_name", "Diederichs");
		// formdata.append("email", "stephend@40parables.com");
		// formdata.append("company", "Live the Life Church");
		// formdata.append("00N3t00000GVtPJ", "200");
		// formdata.append("00N3t00000E3hLY", "IT Director");
		// formdata.append("Denomination__c", "Evangelical");
		// formdata.append("Message__c", "testing");
		// formdata.append("00N3t00000E3Q28", "test");

		// var formdata = new FormData();
		// formdata.append("oid", "00D5j00000CNDfs");
		// formdata.append("retURL", "https://indicators.acst.com/thank-you");
		// formdata.append("first_name", "Stephen");
		// formdata.append("last_name", "Test33");
		// formdata.append("email", "test33@studio137.co.za");
		// formdata.append("company", "Studio1373");
		// formdata.append("debug", "1");

		// var requestOptions = {
		// 	method: 'POST',
		// 	body: formdata,
		// 	redirect: 'follow'
		// };

		// fetch('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', requestOptions)
		// 	.then(response => response.text())
		// 	.then(result => console.log(result))
		// 	.catch(error => console.log('error', error));

	}

	return <>
		<div className="container thank-you">
			<div className="details-container">
				<div className="tick"><img src={TickImg} alt="" /></div>
				{/* <div className="tick">&#10003;</div> */}
				<h1>Testing form</h1>
				{/* <p>You'll be notified as your Board Members take the test so you can review aggregate and individual results.</p> */}
				{/* <p>You'll be notified as your team is taking the test so that
				 you can review aggregate results and individual results.</p> */}
				<div className="back-btn" onClick={submitForm}>Submit Form</div>
			</div>
		</div>
		
		{/* <div className="schedule-container">
			Schedule a call with Carey to debrief the results of your test
			and ensure your church is prepared with next steps for future evangelism.
			<div className="cta-btn">
				<a href="https://calendly.com/kurianbk/hear-the-bleat-story" target="_blank">
					<Button>TALK WITH CAREY</Button>
				</a>
			</div>
		</div> */}
	</>;

};

export default CommunityTest;
