// import q1img from '../../Assets/Images/Bookkeeping/q1.png';
import q2img from '../../Assets/Images/Bookkeeping/q2.png';
import q4img from '../../Assets/Images/Bookkeeping/q4.png';
// import q6img from '../../Assets/Images/Bookkeeping/q6.png';
import q7img from '../../Assets/Images/Bookkeeping/q7.png';
import q9img from '../../Assets/Images/Bookkeeping/q9.png';
// import q10img from '../../Assets/Images/Bookkeeping/q10.png';
// import q11img from '../../Assets/Images/Bookkeeping/q11.png';
import q12img from '../../Assets/Images/Bookkeeping/q12.png';
// import q15img from '../../Assets/Images/Bookkeeping/q15.png';
// import q16img from '../../Assets/Images/Bookkeeping/q16.png';
import q17img from '../../Assets/Images/Bookkeeping/q17.png';
// import q18img from '../../Assets/Images/Bookkeeping/q18.png';
import q19img from '../../Assets/Images/Bookkeeping/q19.png';
// import q20img from '../../Assets/Images/Bookkeeping/q20.png';
// import q21img from '../../Assets/Images/Bookkeeping/q21.png';
// import q24img from '../../Assets/Images/Bookkeeping/q24.png';

export const questions_statistics = [
  {
    id: 1,
    title: "", 
    desc: "",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 2,
    title: "",
    desc: <>Only <span><b>4 in 10</b></span> surveyed churches confirmed that they <strong>have a documented manual outlining financial policies.</strong></>,
    image: {
      src: q2img,
      desktop: { height: "45px", width: "auto", padding: "60px 0 45px 0" },
      mobile: { height: "auto", width: "100%", padding: "30px 0 15px 0" },
    }
  },
  {
    id: 3,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 4,
    title: '28%' ,
    desc: <>Only <span><b>28%</b></span> of surveyed churches <strong>confirmed that their cash-related internal controls were rigid enough.</strong></>,
    image: {
      src: q4img,
      desktop: { height: "180px", width: "auto" },
      mobile: { height: "150px", width: "auto" },
    }
  },
  {
    id: 5,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 6,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "60px", width: "auto" },
      mobile: { height: "60px", width: "auto" },
    }
  },
  {
    id: 7,
    title: "77%",
    desc: <><span><b>77%</b></span> of surveyed churches confirmed that they <b>offer health coverage for their pastors and staff</b> in compliance with ACA requirements.</>,
    image: {
      src: q7img,
      desktop: { height: "180px", width: "auto" },
      mobile: { height: "150px", width: "auto" },
    }
  },
  {
    id: 8,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 9,
    title: "91%",
    desc: <><span><b>91%</b></span> of surveyed churches confirmed that they <b>provide written receipts for donations of $250 or more.</b></>,
    image: {
      src: q9img,
      desktop: { height: "180px", width: "auto" },
      mobile: { height: "150px", width: "auto" },
    }
  },
  {
    id: 10,
    title: "",
    desc: "", 
    image: {
      src: null,
      desktop: { height: "60px", width: "auto" },
      mobile: { height: "60px", width: "auto" },
    }
  },
  {
    id: 11,
    title: "", 
    desc: "",
    image: {
      src: null,
      desktop: { height: "120px", width: "auto" },
      mobile: { height: "120px", width: "auto" },
    }
  },
  {
    id: 12,
    title: "",
    desc: <>Only <span><b>33%</b></span> of surveyed churches confirmed that they <b>conduct periodic internal audits of financial processes and controls</b> at an ideal frequency.</>,
    image: {
      src: q12img,
      desktop: { height: "125px", width: "auto" },
      mobile: { height: "85px", width: "auto" },
    }
  },
  {
    id: 13,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 14,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 15,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "35px", width: "auto" },
      mobile: { height: "35px", width: "auto" },
    }
  },
  {
    id: 16,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "115px", width: "auto" },
      mobile: { height: "115px", width: "auto" },
    }
  },
  {
    id: 17,
    title: "21%",
    desc: <>Only <span><b>21%</b></span> of surveyed churches <b>confirmed installation of antivirus</b> software protection on their church computers.</>,
    image: {
      src: q17img,
      desktop: { height: "180px", width: "auto" },
      mobile: { height: "150px", width: "auto" },
    }
  },
  {
    id: 18,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "45px", width: "auto" },
      mobile: { height: "45px", width: "auto" },
    }
  },
  {
    id: 19,
    title: "44%",
    desc: <>Only <span><b>44%</b></span> of surveyed churches <b>confirmed that their computers are secured by a password.</b></>,
    image: {
      src: q19img,
      desktop: { height: "180px", width: "auto" },
      mobile: { height: "150px", width: "auto" },
    }
  },
  {
    id: 20,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "45px", width: "auto" },
      mobile: { height: "45px", width: "auto" },
    }
  },
  {
    id: 21,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "60px", width: "auto" },
      mobile: { height: "60px", width: "auto" },
    }
  },
  {
    id: 22,
    title: "",
    desc: "",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 23,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 24,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 25,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 26,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 27,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
  {
    id: 28,
    title: "",
    desc:"",
    image: {
      src: null,
      desktop: { height: "80px", width: "auto" },
      mobile: { height: "80px", width: "auto" },
    }
  },
];
