import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
import "./HomePage.scss";

import logoLeft from '../../../Assets/Images/LandingPage/logo.png';
import logoFooterLeft from '../../../Assets/Images/Common/footer-logo.png';
import logoLeftMobile from '../../../Assets/Images/LandingPage/logo-left-mobile.png'
import clock from '../../../Assets/Images/LandingPage/clock.svg';
import bgcard from '../../../Assets/Images/LandingPage/bg-card.png';
import community from '../../../Assets/Images/LandingPage/community-engagement.png';
import congregational from '../../../Assets/Images/LandingPage/congregational-engagement.png';
import financial from '../../../Assets/Images/LandingPage/financial-discipline.png';
import information from '../../../Assets/Images/LandingPage/information-security.png';
import trends from '../../../Assets/Images/LandingPage/giving-trends.png';
import financialhealth from '../../../Assets/Images/LandingPage/financial-health.png';
import arrowRed from '../../../Assets/Images/LandingPage/arrow.png';


const HomePage = () => {

  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();

  const data = [
    {
      title: 'Community Engagement Indicator',
      para: "How effective are your church's bookkeeping practices?",
      desc: 'See how your outreach strategy compares with similarly-sized churches.',
      bg_img: community,
      path: "/assessment-community"
    },
    {
      title: 'Congregational Engagement Indicator',
      para: "How effective are your church's bookkeeping practices?",
      desc: 'Assess how well your engagement strategy is serving your congregants and growing your church.',
      bg_img: congregational,
      path: "/assessment-congregation"
    },
    {
      title: 'Financial Discipline Indicator',
      para: "How effective are your church's bookkeeping practices?",
      desc: 'Evaluate how well your bookkeeping practices align with best practices and processes.',
      bg_img: financial,
      path: "/assessment-bookkeeping"
    },
    {
      title: 'Information Security Indicator',
      para: "How effective are your church's bookkeeping practices?",
      desc: 'Compare your security framework with that of your peers and gain insights to protect it against cybercrime.',
      bg_img: information,
      path: "/assessment-infosec"
    },
    // {
    //   title: 'Giving Trends Indicator',
    //   para: "How effective are your church's bookkeeping practices?",
    //   desc: 'Find out how your giving practices can better serve your church and its impact.',
    //   bg_img: trends,
    // path: "/trends"
    // },
    // {
    //   title: 'Financial Health Indicator',
    //   para: "How effective are your church's bookkeeping practices?",
    //   desc: 'Assess and uncover insights to strengthen your financial health.',
    //   bg_img: financialhealth,
    // path: "/financialhealth"
    // },
  ]

  return (
    <div className="home-page-container">

      <div className="header-container-wrapper">
        <div className="header-container">
          {windowWidth > 830 ?
            <div className="logo-left"><img src={logoLeft} alt="Logo" /></div>
            : <div className="logo-left"><img src={logoLeft} alt="Logo" /></div>
          }
        </div>
        <div>
          <h1>Missional Indicators</h1>
          <p>Evaluate your church or organization in key areas and maximize its missional impact.</p>
        </div>
      </div>

      <div className="content-container">
        <div className="sub-container">
          {data.map((item, index) => (
            <div className="card" key={index}>
              <div className="bg-image" style={{ backgroundImage: `url(${item.bg_img})`, backgroundSize: 'cover', height: '339px', borderRadius: '10px 10px 0px 0px' }}>
                <div className="contents">
                  {/* <Button className="cta-btn">GET STARTED</Button> */}
                  <div className='time-estimate'>
                    <div className='img-container'><img className='clock-icon' src={clock} alt="estimated time" /></div><span style={{ margin: "auto" }}>3 min</span>
                  </div>

                </div>
              </div>
              <div className="sub-content-container">
                <p className="title">{item.title}</p>
                <p className="description">{item.desc}</p>
                {/* <div className="try-now"> */}
                <a className="try-now" href={item.path} rel="noreferrer" target="_blank">
                  Try Now
                  <img src={arrowRed} alt="" />
                </a>
                {/* </div> */}
              </div>

            </div>
          ))}

        </div>

      </div>

      <div className="footer-container">
        <div className="contents">
          {windowWidth > 830 ?
            <div className="logo-left"><img src={logoFooterLeft} alt="Logo" /></div>
            : <div className="logo-left"><img src={logoFooterLeft} alt="Logo" /></div>
          }
          <p>© Copyright 2024 ACS Technologies | <a className="privacy-policy" href="https://help.acst.com/en/trust-center/policies/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a></p>
          <div className="logo-right"></div>
        </div>
      </div>

    </div>
  )
}

export default HomePage;

