import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import { Box, Stepper, Button, CircularProgress, LinearProgress } from '@mui/material';

import InfosecQuestion from '../../../Components/Infosec/Questions/InfosecQuestions.jsx';
import InfosecProgressBar from '../../../Components/Infosec/ProgressBar/InfosecProgressBar.jsx';
import InfosecProgressBarSingle from '../../../Components/Infosec/ProgressBarSingle/InfosecProgressBarSingle.jsx';

// import prevArrow from '../../Assets/Images/prev-arrow.svg';
import prevArrow from '../../../Assets/Images/Infosec/prevArrow.png';
import nextArrow from '../../../Assets/Images/Infosec/next-arrow.svg';
import nextArrowWhite from '../../../Assets/Images/Infosec/next-arrow-white.svg';
import useWindowDimensions from '../../../Hooks/useWindowDimensions.js';
// import AssessmentProgressContext from '../Store/AssessmentProgressContext';
import InfosecAssessmentQuestionContext from '../../../Store/Infosec/InfosecAssessmentQuestionContext';
import InfosecQuestionsProgressContext from '../../../Store/Infosec/InfosecQuestionsProgressContext';
import InfosecQuestionInfo from '../../../Components/Infosec/QuestionInfo/InfosecQuestionInfo.jsx';
import { questions_statistics } from '../temp.js';
import bulbImg from '../../../Assets/Images/Infosec/bulb.png'
import InfosecAssessmentAdditionalV3 from '../AssessmentAdditionalV3/InfosecAssessmentAdditionalV3.jsx';


const AssessmentV3 = () => {

  // const assessProgressCtx = useContext(AssessmentProgressContext);
  // console.log("assessProgressCtx", assessProgressCtx);
  const assessCtx = useContext(InfosecAssessmentQuestionContext);
  const questionsCtx = useContext(InfosecQuestionsProgressContext);
  const [selectedAnswer, setSelectedAnswer] = useState('')

  const { windowWidth } = useWindowDimensions();
  let navigate = useNavigate();
  const { id, pageId } = useParams();

  console.log("pageId", pageId);

  useEffect(()=>{
    assessCtx.view?setShowStatistics(true):setShowStatistics(false)
    assessCtx.updateSetStatView(false)
  },[])

  useEffect(() => {
    // document.body.style.backgroundColor = "#E5E5E5";

    setIsLoading(true);
    getQuestions();
    localStorage.removeItem("backFromDetailsPage");
    // setQs(questions);
    // if(id != null) {      
    //   getAssessment();
    // } else {
    //   getQuestions();
    // }

    // getOldQuestions();

    // assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
    //   if(sec.title === "Response") return { ...sec, status: "active" };
    //   else return { ...sec, status: "unfinished" };
    // }));

  }, [pageId]);

  // useEffect(() => {
  //   getQuestions();
  // }, [pageId]);

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [nextValue, setNextValue] = useState(0);
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [currentHash, setCurrentHash] = useState(0);
  const [hasAdditional, setHasAdditional] = useState(false);
  const [qs, setQs] = useState([]);
  const [apiData, setApiData] = useState();
  const [oldQuestions, setOldQuestions] = useState();
  const [showNextBtn, setShowNextBtn] = useState(false);
  const [prevBtnClicked, setPrevBtnClicked] = useState(false);
  const [showStatistics, setShowStatistics] = useState(false);
  const [isSubmittingAnswer, setIsSubmittingAnswer] = useState(false);
  const [answerSubmissionError, setAnswerSubmissionError] = useState(false);
  const [onLastStatisticsPage, setOnLastStatisticsPage] = useState(false);
  const [goBackToPrevQ, setGoBackToPrevQ] = useState(false);

  const questionType = questions?.[activeStep]?.answerType;
  const questionsWithoutStats = [22, 23];

  // console.log("searchParams.get('member')", searchParams.get("member"));

  useEffect(() => {
    // to hide Next button when user has just answered the question (500ms delay) and 
    // to show it again when user clicks on Prev button

    let timeoutId = null;
    let conditionToShowNextBtn = (
      (questions?.[activeStep]?.answerType !== "slider" &&
        questions?.[activeStep]?.answerType !== "multiple_choice" &&
        questions?.[activeStep]?.answerType !== "month_year") ||
      (questions?.[activeStep]?.answer >= 0));

    if (conditionToShowNextBtn && !prevBtnClicked) {
      timeoutId = setTimeout(() => {
        setShowNextBtn(true);
      }, 500);
    }

    if (conditionToShowNextBtn && prevBtnClicked) {
      setPrevBtnClicked(false);
      setShowNextBtn(true);
    }

    if (!conditionToShowNextBtn) {
      setShowNextBtn(false);
    }

    if (showStatistics) {
      setShowNextBtn(true);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [prevBtnClicked, activeStep, questions, showStatistics]);

  useEffect(() => {
    console.log("Do we run??");
    let isDisplay = true;
    // Get all standalone logic (ie parent_id = 0)
    let displayLogic = questions[activeStep]?.logic.filter(obj => obj.parent_id == 0 && obj.logic_type == "display");
    console.log("displayLogic", displayLogic);

    if (displayLogic && displayLogic.length > 0) {
      
      for (let l = 0; l < displayLogic.length; l++) {
        
        let currentLogic = [];
        currentLogic.push(displayLogic[l]);
        let childLogic = questions[activeStep]?.logic.filter(obj => obj.parent_id == displayLogic[l].id);
        currentLogic.push(...childLogic);
        console.log("currentLogic", currentLogic);
        
        if (currentLogic.length > 1) {
          console.log("getOperatorLogic", getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer, questions[activeStep]?.logic[l]?.operator, questions[activeStep]?.logic[l]?.value));
          let subDisplay = 0;
          for (let c = 0; c < currentLogic.length; c++) {
            if (getOperatorLogic(getQuestionBasedOnVariable(questions, currentLogic[c].variable).answer, currentLogic[c].operator, currentLogic[c].value)) {
              subDisplay++;
            }
          }
          if (subDisplay == currentLogic.length) {
            isDisplay = false;
          }
        } else {
          console.log("THIS IS RUNNING");
          console.log(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer);
          if (getOperatorLogic(getQuestionBasedOnVariable(questions, questions[activeStep]?.logic[l]?.variable).answer, questions[activeStep]?.logic[l]?.operator, parseInt(questions[activeStep]?.logic[l]?.value))) {
            isDisplay = false;
          }
        }
      }

    }

    if (!isDisplay) {
      console.log("DOES THIS WORK??");
      assessCtx.updateSkipStatus(questions?.[activeStep]?.id, true);
      setQuestions(questions?.map((q) => {
        if (q.id === questions?.[activeStep]?.id) q.skip = true;
        return q;
      }))
    } else {
      assessCtx.updateSkipStatus(questions?.[activeStep]?.id, false);
      setQuestions(questions?.map((q) => {
        if (q.id === questions?.[activeStep]?.id) q.skip = false;
        return q;
      }))
    }
    
    if (!isDisplay && !goBackToPrevQ) {
      console.log("NEXT QUESTION PLEASE");
      if (assessCtx.questions?.[activeStep]?.skip) {
        setActiveStep(activeStep + 1);
      }
    } else if (!isDisplay && goBackToPrevQ) {
      const idx = activeStep || 0;
      for (let i = idx; i >= 0; i--) {
        if (!assessCtx.questions?.[i]?.skip) {
          setActiveStep(i);
          break;
        }
      }
      setGoBackToPrevQ(false);
    }
  }, [activeStep, assessCtx, goBackToPrevQ]);

  useEffect(() => {
    if (localStorage.getItem("backFromAddQPage") === "true" && questions?.length) {
      // console.log("activeStep", activeStep); console.log("questions", questions);
      setActiveStep(questions?.length - 1);
      localStorage.removeItem("backFromAddQPage");
    }
  }, [questions, pageId]);

  useEffect(() => {
    if (questions?.[activeStep]?.orderNo === questions?.length) {
      setOnLastStatisticsPage(true);
    } else setOnLastStatisticsPage(false);
  }, [questions, activeStep]);



  function getAssessment() {
    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/review', {
    // 	method: 'GET',
    // 	mode: 'cors',
    // 	headers: { 'Content-Type': 'application/json' }
    // }).then(response => {
    // 	return response.json();
    // }).then(data => {
    // 	if(data.status == "success") {
    // 		setSections(data.sections);
    // 		setQuestions(data.questions);
    //     setQuestionCount(data.questions?.length);
    //     if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);
    //     setIsLoading(false);
    //     setActiveStep(data.questions?.length - 1);
    //     setCurrentHash(id);
    // 	} else {
    // 		// setOpenSnackbar(true);
    // 	}
    // });
  }

  function getQuestions() {

    // if(assessCtx.questions?.length > 0) {
    //   console.log("assessCtx.questions", assessCtx.questions);
    //   setQuestions(assessCtx.questions);
    //   setQuestionCount(assessCtx.questions?.length);
    //   setIsLoading(false);

    //   // go to the last additional question if coming back from Details page 
    //   // typeof localStorage.getItem("backFromDetailsPage") is string so "true"
    //   if(localStorage.getItem("backFromDetailsPage") === "true") {
    //     setActiveStep(assessCtx.questions?.length - 1);
    //   } else {
    //     // go to the first unanswered question 
    //     for (let i = 0; i < assessCtx.questions?.length; i++) {
    //       if(!assessCtx.questions?.[i]?.answer && !assessCtx.questions?.[i]?.answerTxt) {
    //         setActiveStep(i);
    //         break;
    //       } else setActiveStep(assessCtx.questions?.length - 1);
    //     }
    //   }
    // }
    // else {

    let clientId = process.env.REACT_APP_INFOSEC_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        console.log(data);
        let questions = data.data?.questions;
        if (retrieveStoredAnswers()) {
          let answeredQuestions = [];
          let storedQuestions = retrieveStoredAnswers();
          questions?.forEach(q => {
            let question = storedQuestions.find(obj => obj.id == q.id);
            if (question) {
              q.answerTxt = question.answerTxt;
              q.answer = question.answer;
              q.answers = question.answers;
            }
          })
        }
        // setSections(data.sections);
        // setQuestions(data.questions);
        // setQuestionCount(data.questions?.length);
        // assessCtx.addQuestions(questions);
        // if((data.hasAdditional === "yes") || (data.hasAdditional === true)) setHasAdditional(true);

        // go to first unanswered question 
        let goingForward = localStorage.getItem("goingForward") === "true" ? true : false;

        const jumpToUnansweredQuestion = () => {
          let sortedQuestions = data?.data.questions;
          sortedQuestions = sortedQuestions?.sort((a, b) => a.orderNo - b.orderNo);
          for (let i = 0; i < sortedQuestions?.length; i++) {
            if (sortedQuestions?.[i]?.answer == undefined && assessCtx.questions?.[i]?.answer !== 0) {
              setActiveStep(i);
              break;
            } else setActiveStep(sortedQuestions?.length - 1);
          }
        }

        if (goingForward) {
          setActiveStep(0);
          localStorage.removeItem("goingForward");
        }
        else jumpToUnansweredQuestion();

        setSections(data?.data.sections);
        setQuestions(data?.data.questions);
        assessCtx.addQuestions(questions);
        setQuestionCount(data?.data.questions?.length);
        console.log("QUESTIONS", data?.data.questions);
        console.log("QUESTIONS", data?.data);
        setApiData(data.data);
        setIsLoading(false);
      } else {
        // setOpenSnackbar(true);
        console.error("Error while fetching questions...", data);
        setIsLoading(false);
      }
    }).catch((err) => {
      console.error("Error while fetching questions...", err);
      setIsLoading(false);
    });
    // }
  }

  // useEffect(() => {
  //   setQuestions(apiData);
  // }, [apiData])

  console.log("apiData", apiData);

  const currentSectionName = sections?.find(section => section.id === questions?.[activeStep]?.sectionId)?.section;

  console.log("hasAdditional", hasAdditional);

  function completeAssessment() {

    setIsLoading(true);
    let clientId = process.env.REACT_APP_INFOSEC_CLIENT_ID;

    let isReview = false;
    if (id != null) {
      isReview = true;
    }

    const jsonBody = { pageId: 814, questions: questions, isReview: isReview }

    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        const addQuestionsPageId = 818;
        localStorage.removeItem("assessmentAnswers");
        // navigate(`/infosec/assessment-additional/${id}/${addQuestionsPageId}?${searchParams}`, { replace: true });
        markAssessmentAsCompleted();
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to submit answers...", err);
      setIsLoading(false);
    });


    // console.log("questions", questions);
    // fetch(process.env.REACT_APP_API_URI + '/v3/questions/' + questionsCtx.currentPage, {
    //   method: 'POST', mode: 'cors',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ clientId, questions, isReview, id })
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   if (data.status == "success") {
    //     if (hasAdditional) {
    //       if (data.member) {
    //         if (searchParams.get("member") === data.memberId) {
    //           navigate("/assessment-additional/" + data.id + "?" + searchParams, { replace: true });
    //         } else navigate("/assessment-additional/" + data.id + "?member=" + data.memberId, { replace: true });
    //       } else navigate("/assessment-additional/" + data.id, { replace: true });
    //     } else {
    //       if (searchParams.get("user") === data.memberId) {
    //         navigate("/details/" + data.id + "?member=" + data.memberId, { replace: true });
    //       } else {
    //         // navigate("/pre-additional-info/" + data.id, { replace: true });
    //         navigate("/details/" + data.id + "?" + searchParams, { replace: true });
    //       }
    //     }
    //   } else {
    //     // setOpenSnackbar(true);
    //   }
    // });

  }

  function markAssessmentAsCompleted() {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/' + id + '/completed', {
      method: 'PUT', mode: 'cors', headers: { 'Content-Type': 'application/json' }, 
      body: JSON.stringify({ hash: id }),
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        setIsLoading(false);
        // const assessmentName = localStorage.getItem("assessmentName");
        // navigate(`/${assessmentName}/outro/${id}/?${searchParams}`, { replace: true });
        const addQuestionsPageId = 818;
        navigate(`/infosec/assessment-additional/${id}/${addQuestionsPageId}?${searchParams}`, { replace: true });
      } else {
        setIsLoading(false);
        console.error("Failed to mark assessment as completed...", data);
      }
    }).catch((err) => {
      console.error("Failed to mark assessment as completed...", err);
      setIsLoading(false);
    })
  }

  console.log("searchParams", [...searchParams]);

  const retrieveStoredAnswers = () => {
    if (typeof window !== 'undefined') {
      // Get the current localstorage
      let savedAnswers = JSON.parse(localStorage.getItem('assessmentAnswers'));
      if (savedAnswers && savedAnswers.length != 0) {
        return savedAnswers;
      }
    }
    return;
  }

  const prevQuestion = () => {
    setGoBackToPrevQ(true);
    if (showStatistics) {
      // setPrevBtnClicked(false);
    } else setPrevBtnClicked(true);
    // if(activeStep < 1) {
    //   // if(searchParams.get("member")) {
    //   if([...searchParams]?.length) {
    //     // navigate("/start?member=" + searchParams.get("member"), { replace: true });
    //     navigate("/start?" + searchParams, { replace: true });
    //   } else navigate("/start", { replace: true });
    // } else {
    //   setActiveStep(activeStep - 1);
    // }

    if (activeStep > 0) {
      if (showStatistics) {
        setShowStatistics(false);
        // setActiveStep(activeStep - 1);
      } else if (questionsWithoutStats.includes(questions?.[activeStep-1]?.orderNo)) {
        setShowStatistics(false);
        setActiveStep(activeStep - 1);
      } else {
        setShowStatistics(true);
        setActiveStep(activeStep - 1);
      }
    } else if (activeStep == 0 && showStatistics) {
      setShowStatistics(false);
    } else {
      navigateBackwardFunc();
    }
  };

  const nextQuestion = () => {

    assessCtx.updateAnswer(questions?.[activeStep].id, questions?.[activeStep].answer);
    assessCtx.updateTextAnswer(questions?.[activeStep].id, questions?.[activeStep].answerTxt);

    if (questionType === "checkbox") {
      assessCtx.updateCheckboxAnswer(questions?.[activeStep].id, questions?.[activeStep].answers);
      submitAnswer(0); // does not matter what we pass as answer arg as answers array is used for checkbox 
      // and answer is being set from questions state variable which is sum (length) of all checked options 
    }

    // else if (hasAdditional) {
    //   // if(searchParams.get("member") != null) {
    //     if (searchParams?.get("member")) {
    //       navigate("/assessment-additional/" + currentHash + "?" + searchParams, { replace: true });
    //     } else {
    //       navigate("/pre-additional-info/" + currentHash + "?" + searchParams, { replace: true });
    //     }
    // } else {
    //   // if(searchParams.get("member") != null) {
    //   if(searchParams.get("member") && currentHash) {
    //     navigate("/details/" + currentHash + "?" + searchParams, { replace: true });
    //   } else {
    //     console.log("\nSUBMITTING ANSWERS\n");
    //     setTimeout(() => { completeAssessment(); }, 500);
    //   }
    // }


    if (activeStep <= (questions?.length - 1) && !(onLastStatisticsPage && showStatistics)) {
      if (questions?.[activeStep]?.answer > 0) {
        // setActiveStep((prev) => prev + 1);
        if (showStatistics && !onLastStatisticsPage) {
          // TODO: this is temporary skip logic fix but update it later
          if (questions?.[activeStep+1]?.skip) {
            setActiveStep((prev) => prev + 2);
            setShowStatistics(false);
          } else {
            setActiveStep((prev) => prev + 1);
            setShowStatistics(false);
          }
        } else if (questionsWithoutStats.includes(questions?.[activeStep+1]?.orderNo)) {
          setActiveStep((prev) => prev + 1);
          setShowStatistics(false);
        } else {
          setShowStatistics(true);
        }
      }
    } else {
      // navigateForwardFunc();
      // if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
      if (questions[activeStep]?.answer > 0) {
        // navigate(`/assessment-additional/${id}/${addQPageId}?${searchParams}`, { replace: true });
        // navigate(`/details/${id}?${searchParams}`, { replace: true });
        setTimeout(() => { completeAssessment(); }, 500);
      }
    }

  };
  

  const submitAnswer = (answer) => {

    let jsonBody = {
      // "id": questions?.[activeStep]?.id,
      "pageId": pageId,
      "answer": answer,
      "answerTxt": null,
      "answerChecked": false,
    }

    if (questionType === "checkbox") {
      jsonBody = {
        // "id": questions?.[activeStep]?.id,
        "pageId": pageId,
        // "answer": answer,
        "answer": questions?.[activeStep]?.answer,
        "answers": questions?.[activeStep]?.answers,
        "answerTxt": null,
        "answerChecked": true,
      }
    }
    console.log("jsonBody", jsonBody);

    setIsSubmittingAnswer(true);
    setAnswerSubmissionError(false);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/question/' + questions?.[activeStep]?.id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      // this should not depend on API response as it needs to happen in the background
      if (activeStep <= (questions?.length - 1)) {
        // setActiveStep(activeStep + 1);
        if (questionsWithoutStats.includes(questions?.[activeStep]?.orderNo)) {
          setShowStatistics(false);
          setActiveStep((prev) => prev + 1);
        } else setShowStatistics(true);
      } else {
        // navigate(navigatePath, { replace: true });
        // navigateForwardFunc();
        completeAssessment();
      }

      if (data.status === "success") {
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        // save the answer option so you see the selected option when going back & forth between questions 
        setIsSubmittingAnswer(false);
        setAnswerSubmissionError(false);

        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
        console.error("Failed to send answer details...", data);
        setIsSubmittingAnswer(false);
        setAnswerSubmissionError(true);
      }
    }).catch((err) => {
      console.error("Failed to send answer details...", err);
      setIsSubmittingAnswer(false);
      setAnswerSubmissionError(true);
    });
  }


  const navigatePath = (pgId) => "/assessment/" + id + "/" + pgId + "/?" + searchParams;
  const navigateForwardFunc = () => {
    if (pageId === "28") {
      navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
    } else if (pageId === "30") {
      navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
    } else if (pageId === "32") {
      navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
    } else if (pageId === "34") {
      navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
    } else if (pageId === "36") {
      navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
    } else if (pageId === "804") {
      navigate("/assessment-additional/" + id + "/818?" + searchParams, { replace: true });
    }
  }
  const navigateBackwardFunc = () => {
    let qOrderNum = questions?.[activeStep]?.orderNo;
    console.log("qOrderNum", qOrderNum);
    console.log("pageId", pageId, typeof pageId);

    if (pageId === "28") navigate("/first-form/" + id + "?" + searchParams, { replace: true });
    else if (pageId === "30") {
      if (qOrderNum == 2) navigate("/charts/" + id + "/29?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(28), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "32") {
      if (qOrderNum == 5) navigate("/charts/" + id + "/31?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(30), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "34") {
      if (qOrderNum == 8) navigate("/charts/" + id + "/33?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(32), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "36") {
      if (qOrderNum == 11) navigate("/charts/" + id + "/35?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(34), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    }
    else if (pageId === "38") {
      if (qOrderNum == 14) navigate("/charts/" + id + "/37?" + searchParams, { replace: true });
      else {
        navigate(navigatePath(36), { replace: true });
        setActiveStep(questions?.length - 1);
      }
    } else {
      if (localStorage.getItem("external-user") === "true") {
        navigate("/infosec/about-infosec/?" + searchParams, { replace: true });
      } else {
        // navigate("/infosec/before-you-start/" + id + "?" + searchParams, { replace: true });
        navigate("/infosec/explainer-infosec/" + id + "?" + searchParams, { replace: true });
      }
    }
  }

  const moveToNextQuestion = (answer) => {
    questions[activeStep].answer = answer;
    assessCtx.updateAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answer);
    // assessCtx.updateTextAnswer(questions?.[activeStep]?.id, questions?.[activeStep]?.answerTxt);
    // if(activeStep < questions?.length - 1) {
    //   setTimeout(() => {
    //     setActiveStep(activeStep + 1);
    //   }, 500);
    // } else {
    //   // questions?.[activeStep].answer = answer;
    //   console.log("\n\nSUBMITTING ANSWERS\n\n");
    //   setTimeout(() => { completeAssessment(); }, 500);
    // }

    // setTimeout(() => {
    submitAnswer(answer);
    // }, 250);
    console.log("answer", answer);

  }

  const nextDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return { display: "flex" };
    }
    if (questions?.[activeStep]?.answer > 0) {
      return { display: "flex" };
    };
    return { display: "flex" };
  }

  const checkNextBtnDisabled = () => {
    if (questions?.[activeStep]?.answerType === "textarea" && questions?.[activeStep]?.answerTxt?.length > 0) {
      return false;
    }
    if (questions?.[activeStep]?.answerType === "checkbox") {
      const anyOptionChecked = questions?.[activeStep]?.answers?.some(option => option.checked);
      if (anyOptionChecked) return false;
      else return true;
    }
    if (questions?.[activeStep]?.answer > 0) {
      return false;
    };
    if (showStatistics) return false;
    return true;
  }

  // const nextDisabled = (answer) => {
  //   if(questions?.[activeStep]?.mandatory === "no") {
  //     return {display: "block"};
  //   } else {
  //     // if(questions?.[activeStep]?.answerType == "slider" && questions?.[activeStep]?.answer != 0) {
  //     if(questions?.[activeStep]?.answerType == "slider" && !questions?.[activeStep]?.answer > 0) {
  //       return { display: "block" };
  //     } else if(questions?.[activeStep]?.answerType == "radio" && questions?.[activeStep]?.answer != 0) {
  //       return {display: "block"};
  //     } else if(questions?.[activeStep]?.answerType == "textarea" && questions?.[activeStep]?.answerTxt != "") {
  //       return {display: "block"};
  //     }
  //   } 
  //   return {display: "none"};
  // }

  const updateTextAnswer = (id, textVal) => {
    setQuestions(questions?.map((q) => {
      if (q.id === id) q.answerTxt = textVal;
      return q;
    }))
  }


  const updateCheckboxQuestion = (id, checkboxAnswers) => {
    let value = 0;
    const notaOption = checkboxAnswers?.find(item => item?.desktopText?.toLowerCase()?.trim() === "none of the above");
    if (notaOption.checked) {
      value = 0;
    } else {
      const lengthOfCheckedOptions = checkboxAnswers?.filter(option => option.checked)?.length;
      value = lengthOfCheckedOptions;
    }
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        q.answers = checkboxAnswers;
        q.answer = value;
      }
      return q;
    }));
  }

  const getQuestionsPerSection = () => {
    let arr = questions?.map(q => q.sectionId);
    let count = {};
    arr?.forEach(element => {
      count[element] = (count[element] || 0) + 1;
    });
    return Object.values(count);
  }

  function getOperatorLogic(operand1, operator, operand2) {
    console.log(operand1, operator, operand2)
    if (operator == '<') {
      return operand1 < operand2;
    } else if (operator == '>') {
      return operand1 > operand2;
    } else if (operator == '||') {
      return operand1 || operand2;
    } else if (operator == '!=') {
      return operand1 != operand2;
    } else if (operator == '==') {
      return operand1 == operand2;
    } else if (operator == '<=') {
      return operand1 <= operand2;
    } else if (operator == '>=') {
      return operand1 >= operand2;
    }
  }

  const getQuestionBasedOnVariable = (array, variable) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].variable == variable) {
        return array[i]
      }
    }
  }

  const questionsPerSection = getQuestionsPerSection();
  // const questionsPerSection = [4, 5, 4, 3];
  console.log("questionsPerSection", questionsPerSection);
  console.log("\n\nquestions?.[activeStep]", activeStep, (questions?.length - 1), "\n\n");
  console.log("assessCtx.questions", assessCtx.questions);
  console.log("questions", questions);


  const getStepContent = (step) => {
    return (
      <InfosecQuestion question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} updateTextAnswer={updateTextAnswer} updateCheckboxQuestion={updateCheckboxQuestion} setSelectedAnswer={setSelectedAnswer} />
    );
  };





  const getStatistics = () => {

    const currentStat = questions_statistics?.find(stat => stat.id == questions?.[activeStep]?.orderNo);

    const skipQuestionNumber = [22, 23];

    function skipStatistic() {
      setShowStatistics(false);
    }

    if (skipQuestionNumber.includes(currentStat.id)) {
      skipStatistic();
    }

    const getDescription = () => {
      return Object.keys(currentStat.desc).filter((key) => {
        return selectedAnswer.toLowerCase() === key.toLowerCase();
      }).map((key) => {
        return currentStat.desc[key];
      });
    };

    let imgStyles = currentStat.image?.desktop;

    if (windowWidth <= 650) {
      imgStyles = currentStat.image?.mobile;
    }


    return (
      <div className="statistics">

        {
          currentStat.position !== 'top' ?
            <>
              <div className="chart">
                {currentStat.image.src && <img src={currentStat.image.src} alt="" style={imgStyles} />}
              </div>

              {currentStat.title ?
                <p className="chart-title">{currentStat.title}</p>
                :
                <div className="progress-indicator">
                  <p className="step-no">{questions?.[activeStep]?.orderNo}</p>
                  /<p className="total-question-count">{questionCount}</p>
                </div>
              }</> :
            <>
              {currentStat.title ?
                <p className="chart-title" style={{marginBottom:'-10px'}}>{currentStat.title}</p>
                :
                <div className="progress-indicator">
                  <p className="step-no">{questions?.[activeStep]?.orderNo}</p>
                  /<p className="total-question-count">{questionCount}</p>
                </div>
              }
              <div className="chart" style={{marginBottom:'40px'}}>
                {currentStat.image.src && <img src={currentStat.image.src} alt="" style={imgStyles} />}
              </div>


            </>
        }

        <div className="statistic">
          <div className="circle">
            <img src={bulbImg} alt="" />
          </div>
          {/* <p className="statistic-desc">{getDescription()}</p> */}
          <p className="statistic-desc" >{currentStat.desc}</p>
        </div>
      </div>
    )
  }

  const showQuestionsSubtext = (questionNum) => {
    if (questionNum == 8) {
      return (
        <p className='subtext'>
          *Including but not limited to: computers, printers, routers, switches, access points, firewalls, network-attached storage devices, modems, VoIP phones, IP cameras, scanners, IoT devices, unified communications equipment, networked security appliances, and environmental monitoring devices.
        </p>
      );
    }
    return null;
  }

  if (isLoading) {
    return <div className="container assessment loading"><CircularProgress style={{ color: "#F26722" }} size={60} /></div>
  }

  return (
    <>
      <div className="container assessment">
        {windowWidth < 830 ? <>
          <InfosecProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={questionCount} />
          <p className="current-section">{currentSectionName}</p>
        </> : <div className="section-progress-top">
          <InfosecProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
        </div>}
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}></Stepper>
        </Box>
        <div>
          {(!showStatistics) ? <div className="steps-container">
            <div>
              <div className="steps">

                {(windowWidth > 830 && questionType !== "checkbox") && <div className="spacer"></div>}

                {/* {windowWidth > 830 ?
                <>
                  <ProgressBar currentProgress={activeStep} questionsPerSection={questionsPerSection} sections={sections} count={questionCount} />
                  <ProgressBar currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} questionsPerSection={questionsPerSection} sections={sections} count={16} />
                </>
                : <>
                  <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} />
                  <ProgressBarSingle currentProgress={(parseInt(questions?.[activeStep]?.orderNo) - 1)} totalNumOfQuestions={16} />
                  <p className="current-section">{currentSectionName}</p>
                </>
              } */}
                {/* <ProgressBarSingle currentProgress={activeStep} totalNumOfQuestions={questionCount} sections={sections} currentSectionName={currentSectionName} /> */}
                {/* <div className="spacer"></div> */}
                {/* <div className="spacer"></div> */}
                {/* <div className="spacer mobile-spacer"></div> */}
                {/* <div className="spacer mobile-spacer"></div> */}

                {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong>/16</div> */}
                <div className="progress-indicator">
                  <p className="step-no">{questions?.[activeStep]?.orderNo}</p>
                  /
                  <p className="total-question-count">{questionCount}</p>
                </div>

                {getStepContent(activeStep)}
                {/* <QuestionInfo /> */}

              </div>
            </div>
            <div className="subtext-container">
              {showQuestionsSubtext(questions?.[activeStep]?.orderNo)}
            </div>

          </div> : <>
            {isSubmittingAnswer ? <div className="loading-container"><CircularProgress style={{ color: '#F26722' }} size={60} /></div> : getStatistics()}
          </>}


          <div className="step-buttons" style={{ justifyContent: "space-between" }}>
            <Button onClick={prevQuestion} className="prev-btn">
              <img src={prevArrow} alt="previous button" />
              {/* {windowWidth <= 550 ? (
                <><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;</>
              ) : (
                <><img src={prevArrow} alt="previous button" />&nbsp;&nbsp;</>
              )} */}
            </Button>



            {showNextBtn && <Button className="next-btn" onClick={nextQuestion} disabled={checkNextBtnDisabled()}
            // style={nextDisabled()}
            >
              Continue
              {/* {windowWidth <= 550 ? (
                <span style={{ fontSize: "18px", }}>NEXT</span>
              ) : (
                <><span style={{ fontSize: "18px", }}>NEXT</span><img style={{ paddingLeft: "8px" }} src={nextArrowWhite} alt="next button" /></>
              )} */}
            </Button>}
          </div>

        </div>
      </div>
    </>
  );
}

export default AssessmentV3;

