import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../../../Assets/Images/Community/prev-arrow.svg";
import nextArrow from "../../../Assets/Images/Community/next-arrow.svg";
import testimonialImage from "../../../Assets/Images/Community/testimonial.jpg";
import scheduledImage from "../../../Assets/Images/Community/schedule-image.png";
import quotationImage from "../../../Assets/Images/Community/quotation.svg";
import acstAssessmentsAdImg from "../../../Assets/Images/Common/acst-assessments-collection.png";
import acstBannerLogoImg from "../../../Assets/Images/Common/acst-banner-logo.png";
import acstBannerLogoMobileImg from "../../../Assets/Images/Common/acst-banner-logo-mobile.png";
// import downArrow from '../../../Assets/Images/LandingPage/down-arrow.png';
import downArrowWhite from '../../../Assets/Images/Community/down-arrow-white.png';
import teamDashboardImg from '../../../Assets/Images/LandingPage/community-sample-dash-team-desktop.png';
import teamDashboardMobileImg from '../../../Assets/Images/LandingPage/community-sample-dash-team-mobile.png';
import individualDashboardImg from '../../../Assets/Images/LandingPage/community-sample-dash-indi-desktop.png';
import individualDashboardMobileImg from '../../../Assets/Images/LandingPage/community-sample-dash-indi-mobile.png';

import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { sectionsText } from "../../../globalFunctions";
// import "../App.scss";
import CommunityInviteInfo from "../../../Components/Community/CommunityInviteInfo";
import Testimonial from "../../../Components/Community/CommunityTestimonial";
import DonutChart from "../../../Components/Community/CommunityDonutChart";
import DonutChartAccordion from "../../../Components/Community/CommunityDonutChartAccordion";

import { Accordion, AccordionSummary, AccordionDetails, Box, Switch, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Remove } from "@mui/icons-material";

const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const CommunityResults = (props) => {
  let navigate                    = useNavigate();
  const { id }                    = useParams();
  
  const { windowWidth }           = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember]   = useState(false);
  const [apiData, setApiData]     = useState();
  const [name, setName]           = useState("");
  const [score, setScore]         = useState(0);
  const [sections, setSections]   = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0]);

  const [chartsData, setChartsData] = useState();

  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [largeScreen, setLargeScreen] = useState(true);
  const [individualImageLoaded, setIndividualImageLoaded] = useState(false);

	
  useEffect(() => {
    document.body.style.backgroundColor = "#435B63";
    fetchResults();
  }, []);

  useEffect(() => {
    if (windowWidth > 830) {
      setLargeScreen(true);
    }
    else {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  useEffect(() => {
    // basic check for API data reliability 
    if(apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        { title: "The community around us is aware of our mission.", 
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1} },
        { title: "How many engagement opportunities did you host in the past year open to the larger community?", 
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2} },
        { title: "Throughout last year, how many community members participated in these engagement opportunities?", 
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3} },
        { title: "Over time, our church's engagement with our community has been...", 
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4} },
        { title: "Our community frequently utilizes our church's building facilities and programs.", 
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5} },
      ]);
    }
  }, [apiData]);

  console.log("chartsData", chartsData);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };
    
    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);

  }, [subScore1, subScore2, subScore3, subScore4, subScore5]);

  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          
          setApiData(data);
          setName(data.name);
          setScore(data.score);
          setSections(data.sections);
          setSubScore1(data.subScore1);
          setSubScore2(data.subScore2);
          setSubScore3(data.subScore3);
          setSubScore4(data.subScore4);
          setSubScore5(data.subScore5);
          setIsMember(data.isMember);
          // console.log(data);

          setIsLoading(false);

        } else {
          // setOpenSnackbar(true);
        }
      });
  };

  // const showTitleText = () => {
  //   if (score > 0 && score <= 50) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
  //   } else if (score > 50 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
  //   } else if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
  //   }
  // };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);
  
  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);

  const showTitleText = () => {
    if (score >= 0 && score <= 50) {
      return <h1>{name}, there's scope to improve your community engagement.</h1>
    } else if (score > 50 && score <= 80) {
      return <h1>{name}, you're ready for greater community engagement.</h1>
    } else if (score > 80) {
      return <h1>{name}, you're doing great!</h1>
    }
  };

  const showScoreText = () => {
    const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);
    
    if(allScoresSame) {
      return <div className="result-text">
        <p style={{ margin: "10px auto 30px" }}>We're glad to see you're maintaining a balanced focus across all fronts of your community engagement strategy.</p>
        <p style={{ margin: "10px auto 5px" }}>Here are some quick tips to elevate engagement through all areas and maximize your Kingdom impact:</p>
        <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
      </div>
    } else return <div className="result-text">
        <p style={{ margin: "10px auto 30px" }}>Keep up the good work on the <span style={{fontWeight: "700"}}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to <span style={{fontWeight: "700"}}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
        <p style={{ margin: "10px auto 5px" }}>Here are some quick tips:</p>
        <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
      </div>
    // if (score > 0 && score <= 50) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // } else if (score > 50 && score <= 80) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // } else if (score > 80) {
    //   return <div className="result-text">
    //     <p></p>
    //   </div>
    // }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showInvite = () => {
    if (isMember) return null;
    else if (props?.showInvite === false) return null;
    else return ( <CommunityInviteInfo /> )
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return (
        <Testimonial />
      );
    } else {
      return null;
    }
  };

  const getLeftMargin = () => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: Math.round(score) + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            <img src={scheduledImage} />
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
          <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Invite your team members</h1>
            <p>Get your team to take this test and see if they think your church is guest ready.</p>
            <div className="cta-btn">
              <Link to={"/community/invite-team/" + id} target="_blank">
                <Button>Invite Your Team</Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }
  };

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }

  const toggleIndividualChange = (e) => {
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const handleIndividualImageLoad = () => {
    setIndividualImageLoaded(true);
  };

  const showDashboard = () => {
    if (isMember) return null;
    return (
      <div className="sample-dashboard">
        <div className="scroll-to-see">
          <h2>Scroll to see what a sample dashboard looks like</h2>
          <img src={downArrowWhite} alt="scroll down" />
        </div>
        <div className="dashboard-heading">
          <div className="line"></div>
          <h1>SAMPLE DASHBOARD</h1>
        </div>
        {largeScreen ?   
          <div className="individual-group">
          <button onClick={() => setCurrentView(0)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
          <button onClick={() => setCurrentView(1)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
          </div> :
          <div className="toggle-individual-group">
            <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
            <div className="switch-container">
              <Switch
                checked={!toggleIndividual}
                onChange={toggleIndividualChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
          </div>
        }
        <div className="results-dashboard-container">
        {(currentView === 0 || !individualImageLoaded) && (<img src={largeScreen ? teamDashboardImg : teamDashboardMobileImg} alt="Team Dashboard" />)}
          
          {/* Replace Image with Individual Dashboard */}
          {currentView === 1 && (<img src={largeScreen ? individualDashboardImg : individualDashboardMobileImg} alt="Individual Dashboard" style={{ display: individualImageLoaded ? 'block' : 'none' }} onLoad={handleIndividualImageLoad} />)}
        </div>
      </div>
    )
  }

  

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#ED2028' }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">

          {showTitleText()}

          <div className="progress-bar">
            <div className="si_ass_result">
              <div className="si_ass_progress">
                <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                  {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                </div>
                <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
              </div>
              <div className="pervalue">
                <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              </div>
              
              
              <div className="si_pro_header">
                <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
              </div>
              
              {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
              {/* </div> */}
            
              <div className="pervalue-text">
                <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 400 }}>Not<br/>Engaging</p>
                {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>Effectively<br/>Engaging</p>
                {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400 }}>Elevating<br/>Engagement</p>
              </div>

            </div>
          </div>
          {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

          {showScoreText()}
          {/* <h3 className="pre-score-header">Here's how you scored on your 4 Ps:</h3> */}
          <div className="score-section-container">

            {sectionsAndSubScores?.map(sec => {
              return (
              <div key={sec.section} className="score-section">
                <p className="score-txt" style={{ 
                  // marginLeft: `calc(${parseInt(sec.score)}% - 3ch)` 
                }}>{Math.round(sec.score)}%</p>
                <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} />
                </div>
                <p className="score-name">{sec.section}</p>        
              </div>)
            })}

          </div>

          <div className="charts-accordions-container">
            {chartsData?.map((chart, idx) => {
              return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                <AccordionSummary expandIcon={<CustomExpandIcon />}>
                  <p>{chart?.title}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} />
                </AccordionDetails>
              </Accordion>);
              })
            }
          </div>
        </div>
      </div>

      {showTeamMember()}
      
      {/* {showLegacyInvite()} */}
      {showDashboard()}

      {showInvite()}

      {showTestimonial()}

      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

      <div className="banner-ad-container">
        <div className="banner-ad">
          <div className="text">
            {
              windowWidth <= 830 ? <img src={acstBannerLogoMobileImg} alt="ACST Logo" className="top-logo-mobile" />
                :
                <img src={acstBannerLogoImg} alt="ACST Logo" className="top-logo" />
            }
            <h3>Explore our suite of free missional indicators today</h3>
            <p>Take our free 3-minute assessments to benchmark yourself against the industry's best practices & become <b>#strongertogether</b></p>
            <a href="/" target="_blank" rel="noreferrer">EXPLORE NOW</a>
          </div>
          <div className="image">
            <img src={acstAssessmentsAdImg} alt="ACST Assessments Collection" />
          </div>
        </div>
      </div>

    </>

  );
};
export default CommunityResults;
