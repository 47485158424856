import React, { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, LinearProgress } from "@mui/material";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { sectionsText } from "../../globalFunctions";
import upArrow from "../../Assets/Images/Infosec/up-arrow.png";
import prevImg from "../../Assets/Images/Infosec/prev-img.png";
import nextImg from "../../Assets/Images/Infosec/next-img.png";
// import tickImg from "../../Assets/Images/Infosec/success-tick-green.svg";
import tickImg from "../../Assets/Images/Infosec/success-tick-green.png";
import InfosecMiscContext from "../../Store/Infosec/InfosecMiscContext";

const normalizeSpaces = (str) => str.replace(/\s+/g, ' ').trim();

const InfosecClickableCircularProgressBars = (props) => {

  const { windowWidth } = useWindowDimensions();
  const infosecMiscCtx = useContext(InfosecMiscContext);
  const [sectionsAndSubScores, setSectionsAndSubScores] = useState(props?.sectionsAndSubScores);
  const [activeSection, setActiveSection] = useState(infosecMiscCtx?.selectedSection || props.sortedSectionsAndSubScores[0]?.section);
  const [progressBarSize, setProgressBarSize] = useState(136);

  
  // use this prop to show/hide TextBox component with text from globalFunctions sectionTexts
  // let showTextBox = true;
  let showTextBox = false;
  if (props.showTextBox === true) showTextBox = true;
  
  
  useEffect(() => {
    if (infosecMiscCtx?.selectedSection) {
      setActiveSection(infosecMiscCtx?.selectedSection);
    } else setActiveSection(props.sortedSectionsAndSubScores[0]?.section);
  }, [infosecMiscCtx?.selectedSection, props.sortedSectionsAndSubScores]);

  useEffect(() => {
    setSectionsAndSubScores(props?.sectionsAndSubScores?.map((obj, idx) => {
      if (obj.section === props.sortedSectionsAndSubScores[0]?.section) {
        return { ...obj, clicked: true };
      }
      return { ...obj, clicked: false };
    }));
  }, [props?.sectionsAndSubScores, props.sortedSectionsAndSubScores]);

  useEffect(() => {
    if (windowWidth <= 830) setProgressBarSize(136);
    else if (windowWidth > 830 && windowWidth < 1100) setProgressBarSize(110);
    else setProgressBarSize(136);
  }, [windowWidth]);

  useEffect(() => {
    setSectionsAndSubScores((prev) => {
      return prev.map((obj) => {
        if (obj.section === infosecMiscCtx?.selectedSection || obj.section === activeSection) {
          return { ...obj, clicked: true };
        }
        return { ...obj, clicked: false };
      });
    });
  }, [activeSection, infosecMiscCtx?.selectedSection]);

  const handleSectionClick = (e, section) => {
    e.preventDefault();
    setActiveSection(section);
    infosecMiscCtx.setSelectedSection(section);
    setSectionsAndSubScores((prev) => {
      return prev.map((obj) => {
        // if (obj.section === section) {
        if (obj.section === infosecMiscCtx?.selectedSection || obj.section === section) {
          return { ...obj, clicked: true };
        }
        return { ...obj, clicked: false };
      });
    });

  };

  const decideColor = (section) => {
    const lowScoreThreshold = 80;
    const lowestScore = props.sortedSectionsAndSubScores[0]?.score;
    const lowestScoreSection = props.sortedSectionsAndSubScores[0]?.section;
    const highestScore = props.sortedSectionsAndSubScores[props.sortedSectionsAndSubScores.length - 1]?.section;
    const lastTwoElements = props.sortedSectionsAndSubScores.slice(-2);
    const isHighestScoreUnique = lastTwoElements?.[0]?.score !== lastTwoElements?.[1]?.score;
    let customColor = "#3D198A";
    // if ((section === lowestScoreSection) && (lowestScore < lowScoreThreshold)) customColor = "#100F2E";
    if ((section === lowestScoreSection) && (lowestScore < lowScoreThreshold)) customColor = "#E25B5B"; // red for lowest score
    else if ((section === highestScore) && isHighestScoreUnique && (highestScore >= lowScoreThreshold)) customColor = "#3D198A";
    return customColor;
  }
  
  // const lowestScoreBottomText = (lowestScoreSectionName) => {
  //   const sectionNameText = normalizeSpaces(lowestScoreSectionName?.toLowerCase().trim());
  //   switch (sectionNameText) {
  //     case "asset management":
  //       return <p>Compromising asset management makes critical data and resources vulnerable to constant risk of exploitation. Let us email you to help you solidify your security shield.</p>;
  //     case "access control":
  //       return <p>Inadequate access control can lead to unauthorized access, jeopardizing your entire security infrastructure. Let us email you to help you solidify your security shield.</p>;
  //     case "policy compliance":
  //       return <p>A breakdown in policy compliance can open the door to reputation damage, legal penalties, and severe security breaches. Let us email you to help you solidify your security shield.</p>;
  //     case "network security":
  //       return <p>Weak network security leaves your organization exposed to devastating data breaches and cyber attacks. Let us email you to help you solidify your security shield.</p>;
  //     case "surveillance measures":
  //       return <p>Lack of robust surveillance measures can result in undetected threats, putting your entire system at risk. Let us email you to help you solidify your security shield.</p>;
  //     default:
  //       return <p>Weak network security leaves your organization exposed to devastating data breaches and cyber attacks. Let us email you to help you solidify your security shield.</p>;
  //   }
  // }

  


  return (
    <>
    <div className="score-section-container">
      {sectionsAndSubScores?.map((sec, idx) => {

        const customColor = decideColor(sec.section);
        
        return (
          <div key={sec.section} className="score-section" onClick={(e) => handleSectionClick(e, sec.section)}>
            {/* <p className="score-txt">{Math.round(sec.score)}%</p> */}
            <div className="score-slider">
              {/* <LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} /> */}
              <IndividualCircularProgressBar size={progressBarSize} customcolor={customColor} className="custom-bar" variant="determinate" value={Math.round(sec?.score)} thickness={3} />
            </div>
            
            <div className="score-name-container" style={{ position: "relative" }}>
              <p className="score-name" style={{
                // borderBottom: (sec.clicked && windowWidth > 830 && showTextBox) ? "4px solid #796BE1" : "4px solid transparent", 
                // color: (sec.clicked && showTextBox) ? "#796BE1" : "#343333", 
                color: (props.sortedSectionsAndSubScores[0]?.section === sec.section ? customColor : "#100F2E"), 
                // fontWeight: (showTextBox ? sec.clicked ? "700" : "700" : "700") 
                fontWeight: (props.sortedSectionsAndSubScores[0]?.section === sec.section ? "700" : "400") 
                }}>{sec.section}</p>
              {/* {(sec.clicked && windowWidth > 830 && showTextBox) && <img src={upArrow} alt="up arrow to show selected item" style={{ position: "absolute", bottom: "1px", left: "50%", width: "18px", height: "15px", transform: "translate(-50%, 0%)" }} />} */}
            </div>

          </div>)
      })}
    </div>

    {/* {showEmailSection()} */}
    
    {showTextBox ? <TextBox activeSection={activeSection} setActiveSection={setActiveSection} sectionsAndSubScores={sectionsAndSubScores} firstName={props.firstName} fullName={props.fullName} email={props.email} /> : <div style={{ height: "30px" }}></div>}

    </>
  );

}



export default InfosecClickableCircularProgressBars;


const TextBox = (props) => {

  const { windowWidth } = useWindowDimensions();
  const infosecMiscCtx = useContext(InfosecMiscContext);
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false);

  const handleSendEmail = async () => {
    const email = props.email;
    const fullName = props.fullName;
    const firstName = props.firstName;

    const emailDetails = {
      details: {
        to: email,
        fullName: fullName,
        firstName: firstName,
      },
    };

    try {
      const response = await fetch( process.env.REACT_APP_API_URI +"/v4/assessment/71/email/63/sendCustomEmail", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailDetails),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Email sent successfully:', data);
      setEmailSentSuccessfully(true);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  
  const activeSection = normalizeSpaces(props.activeSection?.toString()?.toLowerCase());
  // console.log("activeSection", activeSection);
  const activeSectionText = sectionsText[infosecMiscCtx?.selectedSection] || sectionsText[activeSection];
  
  // timeout for "Email Sent Successfully" message
  // useEffect(() => {
  //   let timer;
  //   if (emailSentSuccessfully) {
  //     timer = setTimeout(() => {
  //       setEmailSentSuccessfully(false);
  //     }, 5000);
  //   }

  //   return () => clearTimeout(timer);
  // }, [emailSentSuccessfully]);

  function calcLeftForPointerImg() {
    switch (activeSection) {
      case "policy compliance":     return "11%";
      case "asset management":      return "30%";
      case "access control":        return "50%";
      case "network security":      return "69.5%";
      case "surveillance measures": return "89%";
      default:                      return "50%";
    }
  }


  const sectionsAndSubScores = props.sectionsAndSubScores;

  const switchToPrevSection = () => {
    const sectionIndex = sectionsAndSubScores?.findIndex((obj) => obj.section?.toLowerCase() === activeSection?.toLowerCase());
    if (sectionIndex <= 0) {
      props.setActiveSection(sectionsAndSubScores?.[sectionsAndSubScores?.length - 1]?.section);
    } else {
      props.setActiveSection(sectionsAndSubScores?.[sectionIndex - 1]?.section);
    }
  }

  const switchToNextSection = () => {
    const sectionIndex = sectionsAndSubScores?.findIndex((obj) => obj.section?.toLowerCase() === activeSection?.toLowerCase());
    if (sectionIndex >= sectionsAndSubScores?.length - 1) {
      props.setActiveSection(sectionsAndSubScores?.[0]?.section);
    } else {
      props.setActiveSection(sectionsAndSubScores?.[sectionIndex + 1]?.section);
    }
  }

  function showEmailSection() {
    if (emailSentSuccessfully) {
      return (
        <div className="email-success-container">
          <div className="tick">
            <img src={tickImg} alt="success icon" />
          </div>
          <h2 className="email-success-text">Thank You!</h2>
          <p>We’ll reach out to you via email and help you strengthen your security.</p>
        </div>
      )
    } else {
      return (
        <div className="email-section">
          {windowWidth > 830 && <img src={upArrow} alt="up arrow to show selected item" style={{ position: "absolute", top: "-36px", left: calcLeftForPointerImg(), width: "auto", height: "40px", transform: "translate(-50%, 0%)" }} />}
          {/* {lowestScoreBottomText(props?.sortedSectionsAndSubScores[0]?.section)} */}
          {activeSectionText?.map((item, idx) => {
            return <p className="text" key={idx}>{item}</p>
          })}
          <Button className="email-btn" onClick={handleSendEmail}>Yes, Email Me</Button>
        </div>
      )
    }
  }
  
  return (
    <>
      {/* {windowWidth <= 830 && (
        <>
          <h3 style={{ textAlign: "center", maxWidth: windowWidth <= 550 ? "75%" : "100%", margin: "30px auto", fontWeight: 600 }}>Click on the arrows to unlock specific recommendations.</h3>
          <div className="sections-switcher">
            <Button onClick={switchToPrevSection} className="switch-btn switch-prev"><img src={prevImg} alt="go to previous section" /></Button>
            <h2>{activeSection}</h2>
            <Button onClick={switchToNextSection} className="switch-btn switch-next"><img src={nextImg} alt="go to next section" /></Button>
          </div>
        </>)} */}

    <div className="section-text-container">
      {/* {<p className="header">{activeSectionText.recommendation.header}</p>} */}
      
      {/* {activeSectionText?.map((item, idx) => {
        return <p className="text" key={idx}>{item}</p>
      })} */}

      {showEmailSection()}

      {/* {<p className="header">{activeSectionText.service.header}</p>} */}
      
      {/* {activeSectionText.service.text?.map((item, idx) => {
        return <p className="text" key={idx}>{item}</p>
      })} */}

    </div>
    </>
  );

}

const IndividualCircularProgressBar = (props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress variant="determinate"
        sx={{ color: "#E9E9E9 !important", position: 'absolute', }}
        size={40} thickness={4} {...props} value={100} />
      <CircularProgress variant="indeterminate" {...props}
        value={-props.value} // to show anticlockwise progress 
        sx={{
          '&': { color: `${props.customcolor} !important`, zIndex: 1, },
          '&.MuiCircularProgress-root': { strokeLinecap: 'round', },
        }} />
      <Box sx={{
        top: 0, left: 0, bottom: 0, right: 0, position: 'absolute',
        display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}>
        <p variant="caption" component="div" color="text.primary" style={{ fontSize: "24px", fontWeight: "700", lineHeight: "30px", margin: "0 auto" }}>
          {`${Math.round(props.value)}%`}
        </p>
      </Box>
    </Box>
  );
}


