import React, { useState, useEffect, useContext } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../../../Assets/Images/Infosec/prev-arrow.svg";
import nextArrow from "../../../Assets/Images/Infosec/next-arrow.svg";
import downArrowImg from "../../../Assets/Images/Infosec/down-arrow.png";
import infosecGrayLogoImg from "../../../Assets/Images/Infosec/infosec-gray-logo.png";
import infosecPhishingImg from "../../../Assets/Images/Infosec/infosec-phishing.png";
import infosecRadiologyImg from "../../../Assets/Images/Infosec/infosec-radiology.png";
import infosecRadarImg from "../../../Assets/Images/Infosec/infosec-radar.png";
import infosecEmailImg from "../../../Assets/Images/Infosec/infosec-email.png";
import tickImg from "../../../Assets/Images/Infosec/success-tick-green.png";

import testimonialImage from "../../../Assets/Images/Infosec/testimonial.png";
import scheduledImage from "../../../Assets/Images/Infosec/schedule-image.png";
import quotationImage from "../../../Assets/Images/Infosec/quotation.svg";
import TeamDashboard from '../../../Assets/Images/Infosec/sample-dash-team-desk.png';
import TeamDashboardMobile from '../../../Assets/Images/Infosec/sample-dash-team-mobi.png';
import IndividualDashboard from '../../../Assets/Images/Infosec/sample-dash-indi-desk.png';
import IndividualDashboardMobile from '../../../Assets/Images/Infosec/sample-dash-indi-mobi.png';
import acstAssessmentsAdImg from "../../../Assets/Images/Common/acst-assessments-collection.png";
import acstBannerLogoImg from "../../../Assets/Images/Common/acst-banner-logo.png";
import acstBannerLogoMobileImg from "../../../Assets/Images/Common/acst-banner-logo-mobile.png";

import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { sectionsText } from "../../../globalFunctions.js";
import InfosecInviteInfo from "../../../Components/Infosec/InviteInfo/InfosecInviteInfo.jsx";
import InfosecTestimonial from "../../../Components/Infosec/Testimonial/InfosecTestimonial.jsx";
import InfosecClickableCircularProgressBars from "../../../Components/Infosec/InfosecClickableCircularProgressBars";
// import DonutChart from "../Components/DonutChart";
// import DonutChartAccordion from "../Components/DonutChartAccordion";

import { Accordion, AccordionSummary, AccordionDetails, Box, Switch, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Remove } from "@mui/icons-material";
import InfosecCallInfo from "../../../Components/Infosec/CallInfo/InfosecCallInfo.jsx";
import { questions_statistics } from "../temp.js";
import InfosecMiscContext from "../../../Store/Infosec/InfosecMiscContext.jsx";
import InfosecInvite from "../Invite/InfosecInvite.jsx";
import InfosecInviteThankYou from "../InviteThankYou/InfosecInviteThankYou.jsx";
console.log(questions_statistics);


const Results = (props) => {

  let navigate = useNavigate();
  const { id } = useParams();
  const miscCtx = useContext(InfosecMiscContext);
  const { windowWidth } = useWindowDimensions();
  
  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [score, setScore] = useState(0);
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0]);
  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [largeScreen, setLargeScreen] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [newSections, setNewSections] = useState([]);

  const [chartsData, setChartsData] = useState();
  const [emailSentSuccessfully, setEmailSentSuccessfully] = useState(false);

  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // document.body.style.backgroundColor = "#292829";
    localStorage.removeItem("SELECTED_CATEGORY");
    localStorage.removeItem("external-user");
    getQuestions();
    fetchResults();
  }, []);

  useEffect(() => {
    // basic check for API data reliability 
    if (apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "The community around us is aware of our mission.",
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1 }
        },
        {
          title: "How many engagement opportunities did you host in the past year open to the larger community?",
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2 }
        },
        {
          title: "Throughout last year, how many community members participated in these engagement opportunities?",
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3 }
        },
        {
          title: "Over time, our church's engagement with our community has been...",
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4 }
        },
        {
          title: "Our community frequently utilizes our church's building facilities and programs.",
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5 }
        },
      ]);
    }
  }, [apiData]);

  useEffect(() => {
    if (windowWidth > 830) {
      setLargeScreen(true);
    }
    else {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  console.log("chartsData", chartsData);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };

    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);

  }, [subScore1, subScore2, subScore3, subScore4, subScore5]);

  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      // fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === "success") {
        console.log(data);

        setApiData(data);
        setName(data.name);
        setFullName(data.fullName);
        setEmail(data.email);
        setScore(data.variables?.find(item => item.variable == 'totalpercent')?.score);
        setSections(data.sections);
        setSubScore1(data.variables?.find(item => item.variable == 'section1percent')?.score);
        setSubScore2(data.variables?.find(item => item.variable == 'section2percent')?.score);
        setSubScore3(data.variables?.find(item => item.variable == 'section3percent')?.score);
        setSubScore4(data.variables?.find(item => item.variable == 'section4percent')?.score);
        setSubScore5(data.variables?.find(item => item.variable == 'section5percent')?.score);
        setIsMember(data.isMember);
        // console.log(data);

        setIsLoading(false);

      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to fetch results...", err);
    });
  };

  function getQuestions() {
    const assessmentPageId = 814;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + assessmentPageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        console.log(data);
        // setQuestions(data.data.questions);
        setQuestions(data.data.questions?.sort((a, b) => a.orderNo - b.orderNo));
        setNewSections(data.data.sections);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Error while fetching questions...", err));
    // }
  }

  // const showTitleText = () => {
  //   if (score > 0 && score <= 50) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
  //   } else if (score > 50 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
  //   } else if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
  //   }
  // };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
      let currentIndex = sortedSectionsAndSubScores?.length - 2;
      while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
          highestScoreObject = sortedSectionsAndSubScores[currentIndex];
          currentIndex--;
      }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);
  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }
  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const showTitleText = () => {
    if (score >= 0 && score <= 50) {
      return <h1><span className="user-name">{name}</span>, here are your assessment results.</h1>
    } else if (score > 50 && score <= 80) {
      return <h1><span className="user-name">{name}</span>, here are your assessment results.</h1>
    } else if (score > 80) {
      return <h1><span className="user-name">{name}</span>, here are your assessment results.</h1>
    }
  };

  const showScoreText = () => {
    // const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    // const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);

    // if (allScoresSame) {
    //   return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>We're glad to see you're maintaining a balanced focus across all fronts of your community engagement strategy.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some quick tips to elevate engagement through all areas and maximize your Kingdom impact:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // } else return <div className="result-text">
    //   <p style={{ margin: "10px auto 30px" }}>Keep up the good work on the <span style={{ fontWeight: "700" }}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to <span style={{ fontWeight: "700" }}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
    //   <p style={{ margin: "10px auto 5px" }}>Here are some quick tips:</p>
    //   <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    // </div>
    
    if (score >= 0 && score <= 50) {
      return <div className="result-text">
        <h3 className="pre-score-header">Your Information Security Score is <b>{Math.round(score)}%</b>.</h3>
        <p>It seems like your information is at high risk.<br/><i><b>Higher Ground</b></i> can help you better secure your information.</p>
      </div>
    } else if (score > 50 && score <= 80) {
      return <div className="result-text">
        <h3 className="pre-score-header">Your Information Security Score is <b>{Math.round(score)}%</b>.</h3>
        <p>It seems like you are dealing with some blindspots.<br/><i><b>Higher Ground</b></i> can help you better secure your information.</p>
      </div>
    } else if (score > 80) {
      return <div className="result-text">
        <h3 className="pre-score-header">Your Information Security Score is <b>{Math.round(score)}%</b>.</h3>
        <p>It seems like your framework is quite solid.<br/><i><b>Higher Ground</b></i> can help you maintain a strong security shield.</p>
      </div>
    }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showDashboard = () => {
    return (
      <>
        <div className="dashboard-heading">
          <div className="line"></div>
          <h1>SAMPLE DASHBOARD</h1>
        </div>
        {/* <h1>SAMPLE DASHBOARD</h1> */}
        <div className="individual-group">
          <button onClick={() => setCurrentView(0)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
          <button onClick={() => setCurrentView(1)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
        </div>
        <div className="toggle-individual-group">
          <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
          <Switch
            checked={!toggleIndividual}
            onChange={toggleIndividualChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
        </div>
        <div className="results-dashboard-container">
          <img src={currentView === 0 ? (largeScreen ? TeamDashboard : TeamDashboardMobile) : (largeScreen ? IndividualDashboard : IndividualDashboardMobile)} alt="" />
        </div>
      </>
    )
  }


  const showInvite = () => {
    // if (isMember) return null;
    //   else if (props?.showInvite === false) return null;
    //   else return (<InfosecInviteInfo />);
    if (!miscCtx.showInviteSection && !miscCtx.isInviteSuccessful) {
      if (isMember) return null;
      else if (props?.showInvite === false) return null;
      else return (<InfosecInviteInfo />);
    }

    if (miscCtx.showInviteSection && !miscCtx.isInviteSuccessful) {
      return (<InfosecInvite onResultsPage={true} />);
    }

    if (miscCtx.isInviteSuccessful) {
      return (<InfosecInviteThankYou />);
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <InfosecTestimonial />;
    } else {
      // return null;
      return <InfosecTestimonial />;
    }
  };


  const showCallInfo = () => {
    if (!isMember) {
      return <InfosecCallInfo />;
    }
  }

  const getLeftMargin = () => {
    if (windowWidth <= 830) {
      if (score > 96 && score < 100) {
        return { left: 96.5 + "%" };
      } else if (score >= 100) {
        return { left: 97.5 + "%" };
      } else if (score > 0 && score < 3) {
        return { left: 2.5 + "%" };
      } else if (score <= 0) {
        return { left: 1.5 + "%" };
      } else {
        return { left: Math.round(score) + "%" };
      }
    } else {
      return { left: Math.round(score) + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            <img src={scheduledImage} />
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
          <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Here are your next steps:</h1>
            <div className="steps">
              <ol>
                <li>Invite your leadership team to take this test.</li>
                <li>Unlock your team's individual responses and team scores.*</li>
              </ol>
            </div>
            {/* <p>Get your team to take this test and see if they think your church is guest ready.</p> */}
            <div className="cta-btn">
              <Link to={"/infosec/invite-team/" + id} target="_blank">
                <Button>Invite My Team</Button>
              </Link>
            </div>
            <p>*Scroll down to see what your dashboard may look like.</p>
          </div>
        </div>
      );
    }
  };

  const getMCQAnswers = (question) => {

    let tempAnswerArray = []
    let finalArray = []

    for (let i = 0; i < apiData?.answers?.length; i++) {
      if (apiData?.answers[i].questionId == question.id && apiData?.answers[i].answer == 1) {
        tempAnswerArray.push(apiData?.answers[i])
      }
    }
    for (let i = 0; i < question.answers?.length; i++) {
      for (let j = 0; j < tempAnswerArray?.length; j++) {
        if (tempAnswerArray[j]?.optionId == question.answers[i].id) {
          finalArray.push(question.answers[i])
        }
      }
    }

    return <ul>
      {
        finalArray?.map((answer) => (
          <li style={{color: "#F26722", fontWeight: 700, fontSize: '18px', fontFamily: '"Lato", sans-serif'}} key={answer.id} >
            {answer.desktopText}
          </li>
        ))
      }
    </ul>

  }

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: "#F26722" }} size={60} />
      </div>
    );
  }
  const handleSendEmail = async () => {
    // const fullName = fullName;
    const firstName = name;

    const emailDetails = {
      details: {
        to: email,
        fullName: fullName,
        firstName: firstName,
      },
    };
    console.log(emailDetails)
    try {
      const response = await fetch( process.env.REACT_APP_API_URI +"/v4/assessment/71/email/64/sendCustomEmail", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailDetails),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Email sent successfully:', data);
      setEmailSentSuccessfully(true);
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  function showEmailSection() {
    if (emailSentSuccessfully) {
      return (
        <div className="section">
          <div className="tick">
            <img src={tickImg} className="success-tick" alt="success icon" />
          </div>
          <h2 className="email-success-text">Thank You!</h2>
          <p>We’ll reach out to you via email and help you strengthen your security.</p>
        </div>
      )
    }else{
      return(<Button className="email-btn" onClick={handleSendEmail}>Yes, Email Me</Button>)
    }
  }

  //logs
  console.log(newSections)
  console.log(questions)
  console.log(apiData)

  return (
    <>
      <div className="container results">
        <div className="details-container">
          <div className="first-section">
            {showTitleText()}
            <div className="progress-bar">
              <div className="si_ass_result">
                <div className="si_ass_progress">
                  {/* <div className="transparent" style={{width: `${100-(Math.round(score))}%`}}></div> */}
                  <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                      {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                  </div>
                  <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
                  <div className="score-arrow" style={{ left: Math.round(score) + '%' }}></div>
                </div>
                <div className="pervalue">
                  <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                  <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                  {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                  {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                </div>

                <div className="si_pro_header">
                  <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                  {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
                </div>

                {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                {/* </div> */}

                <div className="pervalue-text">
                  <p className="not-aligned" style={{ width: "50%",  fontWeight: score <= 50 ? 700 : 400, color: score <= 50 ? "#CC2C24" : "#575757" }}>Red Alert!</p>
                  {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                  <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 400, color: score > 50 && score <= 80 ? "#FBBC27" : "#575757" }}>Take Caution</p>
                  {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                  <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 400, color: score > 80 ? "#429653" : "#575757" }}>Stay Sharp</p>
                </div>

              </div>
            </div>
            {/* <div className="spacer"></div>
            <div className="spacer"></div> */}

            {/* <h3 className="pre-score-header"><span className="user-name">{name}</span>, your overall score is <b>{Math.round(score)}%</b>.<br/>Below are your scores per category.</h3> */}
            {/* <h3 className="pre-score-header"><b>Your information security score is {Math.round(score)}%</b></h3> */}
            {showScoreText()}
            <div className="scroll-info">
              <p>Scroll down to learn more about your results</p>
              <img src={downArrowImg} alt="downward arrow scroll down" />
            </div>
          </div>
          {/* {showScoreSubtext()} */}
          {/* <div className="score-section-container">

            {sectionsAndSubScores?.map(sec => {
              return (
                <div key={sec.section} className="score-section">
                  <p className="score-name">{sec.section}</p>
                  <p className="score-txt" style={{
                      // marginLeft: `calc(${parseInt(sec.score)}% - 3ch)` 
                  }}>{Math.round(sec.score)}%</p>
                  <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} />
                  </div>
                </div>)
              })}
          </div> */}

          <div className="second-section">
            {windowWidth > 550 ?
              <h2 className="circular-progress-bar-header">Below are your scores across 5 critical security metrics.<br/>Click each circle to see how you can be better prepared.</h2>
              :
              <h2 className="circular-progress-bar-header">Below are your scores across 5 critical security metrics.<br/>Click each circle to see how you can be better prepared.</h2>
            }

            <InfosecClickableCircularProgressBars sectionsAndSubScores={sectionsAndSubScores} sortedSectionsAndSubScores={sortedSectionsAndSubScores} showTextBox={true} firstName={name} fullName={fullName} email={email} />
          </div>

          {windowWidth > 550 ?
            <div className="logo-image">
              <img src={infosecGrayLogoImg} alt="logo" />
            </div>
            : null
          }

          <div className="third-section">
            {windowWidth > 550 ? 
              <h2 className="scores-header">How did you fare in comparison?</h2>
              : null
            }
            {windowWidth > 550 ?
              <p className="scores-subheader">Here’s a refresher of your responses to the questions along with industry benchmarks.</p>
              : null
            }
            <div className="charts-accordions-container">
              {newSections?.map((section, idx) => {
                return (<Accordion key={section.id} defaultExpanded={idx === 0 ? true : false}>
                  <AccordionSummary expandIcon={<CustomExpandIcon />}>
                    <p>{section.section}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <DonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} /> */}
                    {
                      questions?.map((question, index) => {
                        if (question.sectionId === section.id) {
                          // pre MCQ Question is "Has your org ever been a victim of breach?"
                          // MCQ Question asks for what multiple measures were taken after breach
                          // so only show selected MCQ options if answer to preMCQQuestion is 6 (Yes)
                          const preMCQQuestionId = 1353;
                          // const preMCQQuestion = questions.find(q => q.id == preMCQQuestionId);
                          const preMCQAnswer = apiData?.answers?.find(ans => ans.questionId == preMCQQuestionId)?.answer || "-";
                          console.log("preMCQAnswer should be 6 to show selected MCQ options:", preMCQAnswer);

                          const answer = apiData?.answers?.find(ans => ans.questionId === question.id)?.answer || "-";
                          let answerText = "-";

                          switch (answer) {
                            case 1: answerText = "Yes"; break;
                            case 2: answerText = "Yes"; break;
                            case 3: answerText = "Yes"; break;
                            case 4: answerText = "Yes"; break;
                            case 6: answerText = "No"; break;
                            case 7: answerText = "Unsure"; break;
                            default: answerText = "-"; break;
                          }
                          if (question.orderNo == 8) {
                            switch (answer) {
                              case 1: answerText = "0-10%"; break;
                              case 2: answerText = "11-25%"; break;
                              case 3: answerText = "26-50%"; break;
                              case 4: answerText = "51-75%"; break;
                              case 5: answerText = "76-100%"; break;
                              default: answerText = "-"; break;
                            }
                          }
                          if (question.orderNo == 22) {
                            switch (answer) {
                              case 6: answerText = "Yes"; break;
                              case 4: answerText = "No"; break;
                              case 7: answerText = "Unsure"; break;
                              default: answerText = "test"; break;
                            }
                          }
                          
                          const currentStat = questions_statistics?.[index];
                          let imgStyles = currentStat.image?.desktop;
                          if (windowWidth <= 650) {
                            imgStyles = currentStat.image?.mobile;
                          }

                          return (
                            <div className="question-info" key={question.id}>
                              <div className="question-title">{index + 1}. {question.question}</div>
                              <div className="answer-details">
                                {
                                  question.orderNo == 23 && preMCQAnswer == 6
                                    ? <div>
                                      <div className="your-answer">Your Answer:</div>
                                      {getMCQAnswers(question)}
                                    </div>
                                    : <div className="your-answer">Your Answer: <span>{answerText}</span></div>
                                }
                                <div className="chart">
                                  {currentStat.image.src && <img src={currentStat.image.src} alt="" style={imgStyles} />}
                                </div>
                                {(questions_statistics[index]).title && <div className="answer-brief-detail">{(questions_statistics[index]).title}</div>}
                              </div>
                              {questions[index + 1] && questions[index + 1].sectionId === section.id && <div className="border"></div>}
                            </div>
                          );
                        }
                      })
                    }
                  </AccordionDetails>
                </Accordion>);
                })
              }
            </div>
          </div>
          {
            windowWidth > 550 ?
            <div className="logo-image">
            <img src={infosecGrayLogoImg} alt="logo" />
          </div>
          : null
          }

        </div>
      </div>

      {/* TODO: uncomment this later once it is approved by 40p */}
      <div className="email-section-container">
        <div className="left">
          <div className="img-text">
            <img src={infosecRadarImg} alt="" />
            <p>Endpoint Detection & Response (EDR)</p>
          </div>
          <div className="img-text">
            <img src={infosecRadiologyImg} alt="" />
            <p>Vulnerability Scans</p>
          </div>
          <div className="img-text">
            <img src={infosecPhishingImg} alt="" />
            <p>Phishing as a Service (PhaaS)</p>
          </div>
        </div>
        <div className="right">
          <div className="email-img">
            <img src={infosecEmailImg} className="laptop-img" alt="laptop showing video" />
            <h2>Get our free 3-part video series to start combating cyber attacks today.</h2>
            {showEmailSection()}
          </div>
        </div>
      </div>

      {/* {showTeamMember()} */}

      {/* {showLegacyInvite()} */}

      {/* {!isMember && showDashboard()} */}

      {/* {showInvite()} */}

      {showTestimonial()}

      <div className="note">
        <p>Note: This is a partial information security assessment; it only covers industry best practices to evaluate your organization's information security posture.</p>
      </div>


      {/* {showCallInfo()} */}

      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

      {/* ACST Higher Ground Information Security Indicator Banner Ad */}
      <div className="banner-ad-container">
        <div className="banner-ad">
          <div className="text">
            {
              windowWidth <= 830 ? <img src={acstBannerLogoMobileImg} alt="ACST Logo" className="top-logo-mobile" />
                :
                <img src={acstBannerLogoImg} alt="ACST Logo" className="top-logo" />
            }
            <h3>Explore our suite of free missional indicators today</h3>
            <p>Take our free 3-minute assessments to benchmark yourself against the industry's best practices & become <b>#strongertogether</b></p>
            <a href="/" target="_blank" rel="noreferrer">EXPLORE NOW</a>
          </div>
          <div className="image">
            <img src={acstAssessmentsAdImg} alt="ACST Assessments Collection" />
          </div>
        </div>
      </div>

    </>

  );
};

export default Results;

const CustomExpandIcon = () => {
  return (
      <Box
          sx={{
              ".Mui-expanded & > .collapsIconWrapper": {
                  display: "none"
              },
              ".expandIconWrapper": {
                  display: "none"
              },
              ".Mui-expanded & > .expandIconWrapper": {
                  display: "block"
              }
          }}
      >
          <div className="expandIconWrapper"><Remove /></div>
          <div className="collapsIconWrapper"><Add /></div>
      </Box>
  );
};


