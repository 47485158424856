import { Suspense, lazy, useEffect } from "react";
import { useLocation } from "react-router-dom";
import OtherPagesStyles from "./OtherPagesStyles";

const CongregationStyles = lazy(() => import("./CongregationStyles"));
const BookkeepingStyles = lazy(() => import("./BookkeepingStyles"));
const InfosecStyles = lazy(() => import("./InfosecStyles"));
const CommunityStyles = lazy(() => import("./CommunityStyles"));

export const StylesSelector = ({ children }) => {

  console.log(window.location.pathname)

  const location = useLocation();
  console.log("location", location);

  // const getStyleBasedOnPath = () => {
  //   if (window.location.pathname.split("/").includes("infosec")) {
  //     return <InfosecStyles />
  //   } else if (window.location.pathname.split("/").includes("bookkeeping")) {
  //     return <BookkeepingStyles />
  //   } else if (window.location.pathname.split("/").includes("congregation")) {
  //     return <CongregationStyles />
  //   } else if (window.location.pathname.split("/").includes("community")) {
  //     return <CommunityStyles />
  //   } else {
  //     return <OtherPagesStyles />
  //   }
  // }

  const getStyleBasedOnPath = () => {
    const path = location.pathname.split("/");
    if (path.includes("infosec")) {
      return <InfosecStyles />
    } else if (path.includes("bookkeeping")) {
      return <BookkeepingStyles />
    } else if (path.includes("congregation")) {
      return <CongregationStyles />
    } else if (path.includes("community")) {
      return <CommunityStyles />
    } else {
      return <OtherPagesStyles />
    }
  }

  return (
    <>
      <Suspense fallback={getStyleBasedOnPath()} >
        {getStyleBasedOnPath()}
      </Suspense>
      {children}
    </>
  );
};
