import { useState, useEffect, Fragment } from 'react';
// import bleatLogoMobile from '../../Assets/Images/logo-bleat-mobile.svg';
import bleatLogoWhite from '../../../../Assets/Images/Bookkeeping/bleat-logo-white.png';
import bleatLogoWhiteText from '../../../../Assets/Images/Infosec/bleat-logo-white-text.svg';
import bleatLogoWideWhite from '../../../../Assets/Images/Infosec/footer-mob.png';
import bleatLogoBlack from '../../../../Assets/Images/Bookkeeping/bleat-logo-black.png';
import bleatMobileBlack from '../../../../Assets/Images/Bookkeeping/bleat-logo-bottom-white.svg';
import bleatMobileWhite from '../../../../Assets/Images/Bookkeeping/bleat-logo-bottom-white.svg';
import useWindowDimensions from '../../../../Hooks/useWindowDimensions';
import bleatLogo from '../../../../Assets/Images/Bookkeeping/bleat-logo.svg';
import footerBottomLogo from '../../../../Assets/Images/Bookkeeping/footer-logo-desktop.png';
import footerBottomLogoMobile from '../../../../Assets/Images/Bookkeeping/footer-logo-mobile.png';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';


const BookkeepingFooter = props => {

  const { windowWidth } = useWindowDimensions();
  const currentLocation = useLocation();
  const currentPath = currentLocation.pathname.split("/")[1];
  const [logo, setLogo] = useState(bleatMobileWhite);
  const [isBgDark, setIsBgDark] = useState(false);

  // console.log("currentLocation", currentLocation);
  // console.log(currentLocation.pathname.split("/")[1]);

  useEffect(() => {

    // paths with dark background
    // const pathsWithDarkBg = ["results", "invite-team", "invite-team-thanks", "dashboard"];
    const pathsWithDarkBg = ["results", "dashboard"];

    if (pathsWithDarkBg.includes(currentPath)) {
      // setLogo(bleatMobileWhite);
      setIsBgDark(true);
    } else {
      // setLogo(bleatMobileBlack);
      setIsBgDark(false);
    }

  }, [currentLocation, currentPath]);

  useEffect(() => {
    const pathsWithFooter = ["infosec"];
    const footer = document.querySelector('footer');
    if(pathsWithFooter.includes(currentPath)){
      if (footer) {
        footer.style.display = 'block';
      }
    }


  }, [currentLocation]);

  useEffect(() => {
    if (isBgDark) {
      if (windowWidth > 830) setLogo(bleatLogoWhite);
      else setLogo(bleatLogoWhite);
    } else {
      if (windowWidth > 830) setLogo(bleatLogoBlack);
      else setLogo(bleatLogoBlack);
    }


    // ? If the current path is either "results" or "dashboard" and the current path also contains "bookkeeping", then set the logo to the white version of the bleat logo.
    if ((window.location.pathname.includes('results') || window.location.pathname.includes('dashboard')) && (window.location.pathname.includes('bookkeeping') || window.location.pathname.includes('community'))) {
      setLogo(bleatLogoWhite);
    }


  }, [isBgDark, windowWidth]);

  const infosecCustomFooterPaths = [];

  // get bleat logos based on paths of assessments
  const toggleWhiteBlackTextForBleatLogo = () => {

    if (window.location.pathname.includes('/congregation')) {

      return windowWidth <= 830
        ? <div className="bleat-logo-mobile">
          <img src={bleatLogoWhiteText} alt="bleat logo" height="73px" width="auto" />
        </div>
        : null

    } else if (window.location.pathname.includes('/infosec')) {
      return windowWidth <= 830
        ? <div className="bleat-logo-mobile">
          <img src={bleatLogoWideWhite} alt="bleat logo" height="73px" width="auto" />
        </div>
        : <div className="bleat-logo" style={{ margin: 'auto' }}>
          <img src={bleatLogoWhiteText} alt="bleat logo" height="73px" width="auto" />
        </div>

    } else if (window.location.pathname.includes('/bookkeeping')) {

      return windowWidth <= 830
        ? <div className="bleat-logo-mobile">
          <img src={bleatLogoWhiteText} alt="bleat logo" height="73px" width="auto" />
        </div>
        : <div className="bleat-logo" style={{ margin: 'auto' }}>
          <img src={bleatLogoWhiteText} alt="bleat logo" height="73px" width="auto" />
        </div>

    } else if (window.location.pathname.includes('/community')) {

      return windowWidth <= 830
        ? <div className="bleat-logo-mobile">
          <img src={logo} alt="bleat logo" height="73px" width="auto" />
        </div>
        : <div className="bleat-logo" style={{ margin: 'auto' }}>
          <img src={logo} alt="bleat logo" height="73px" width="auto" />
        </div>

    }

  }


  return <Fragment>

    {}

    <footer style={{ minHeight: '70px' }} className='footer-assessment'
      // className={infosecCustomFooterPaths.includes(currentLocation.pathname) ? "infosec-custom-footer" : ""}
    >

      {/* <div className={'bleat-logo'}>
          {windowWidth > 830 ? <Link to="/start" className="logo-right" style={{}}><img src={logo} width="auto" height={67.5} alt="Bleat" /></Link> : <Link to="/" className="logo-right"></Link>}
          </div> */}

      {toggleWhiteBlackTextForBleatLogo()}

    </footer>
  </Fragment>
}

export default BookkeepingFooter;
