import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
// import testimonialImage from '../../../Assets/Images/Infosec/testimonial.jpg';
import testimonialImage from '../../../Assets/Images/Infosec/testimonial.jpg';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../../../Assets/Images/Infosec/leftQuote.png';
import bottomQ from '../../../Assets/Images/Infosec/rightQuote.png';
import quotation from "../../../Assets/Images/Infosec/quote-single.png";

const InfosecTestimonial = () => {

  const { id } = useParams();

  return (
    <div className="testimonial-container">
      <div className="testimonial">
        <div className="testi">
          <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>It’s very hard to be up to date and up to speed on every section of the network, whether it’s the servers, the switches, or the firewall. You can know a lot about all those areas, but with a managed service provider (like <i><b>Higher Ground</b></i>), you have people who are extremely knowledgeable about every area of the network instead of just one.</p>
          <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{ transform: 'rotate(180deg)' }} alt="quote icon" /></div></div>

        </div>
        <div>
          <div className="avatar">
            <img src={testimonialImage} alt="pastor" />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
          <div className="designation">
          Curtis Ponzio,  
          <p className="designation-inner">IT Consultant, <strong>Southeast Texas</strong></p>
          </div>
        </div>
        {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}
      </div>
    </div>
  );
}

export default InfosecTestimonial;
