import { createContext, useEffect, useState } from 'react';

const CongregationAssessmentProgressContext = createContext();

export function CongregationAssessmentProgressContextProvider(props) {

  const [mainSections, setMainSections] = useState([
    { title: "Response", status: "finished" },
    { title: "Relevance", status: "active" },
    { title: "Rating", status: "unfinished" }
  ]);
  const [mainSection, setMainSection] = useState("Response");
  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    if (isMember) setMainSections(mainSections?.filter(sec => sec.title !== "Relevance"));
  }, [isMember]);

  function updateMainSectionHandler(section) {
    setMainSection(section);
  }

  const context = {
    currentMainSection: mainSection,
    mainSections: mainSections,
    updateCurrentMainSection: updateMainSectionHandler,
    updateMainSectionsStatus: setMainSections,
  };

  return <CongregationAssessmentProgressContext.Provider value={context}>
    {props.children}
  </CongregationAssessmentProgressContext.Provider>
}

export default CongregationAssessmentProgressContext;

