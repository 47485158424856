import { useState, useEffect, useContext } from 'react';

import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { Checkbox, FormGroup } from '@mui/material';
import CongregationAdditionalQuestionContext from '../../../Store/Congregation/CongregationAdditionalQuestionContext';

const Question = (props) => {
  
  console.log("Question props", props);
  
  const addCtx                        = useContext(CongregationAdditionalQuestionContext);
  const { windowWidth }               = useWindowDimensions();
  const [showSlider, setShowSlider]   = useState(false);
  const [progress, setProgress]       = useState(0);
  const [sliderWidth, setSliderWidth] = useState(90);
  const [maxWidth, setMaxWidth]       = useState(props.question?.answers?.length);
  const [runTimeoutFunc, setRunTimeoutFunc] = useState(false);

  const [showOtherOptionTextBox, setShowOtherOptionTextBox] = useState(false);

  const [textAns, setTextAns]         = useState(props.question?.answerTxt);
  const [checkboxAnswers, setCheckboxAnswers] = useState([]);
  
  // empty text area on question change
  useEffect(() => {
    if(!props.question?.answerTxt || props.question?.answerTxt == "") {
      setTextAns("");
    } else {
      setTextAns(props.question?.answerTxt);
    }
    
  }, [props.question]);

  useEffect(() => {
    if (props.question?.answerType === "checkbox" || props.question?.type === "checkbox") {
      setCheckboxAnswers(props.question?.answers);
      // if (props.question?.answers[props.question?.answers?.length - 1]?.checked) setShowOtherOptionTextBox(true);
    } else setCheckboxAnswers([]);
  }, [props.question?.answerType, props.question?.answers, props.question]);

  useEffect(() => {
    if(runTimeoutFunc) { 
      const timer = setTimeout(() => {
        if(props.question?.answer == 0 || !props.question.answer) {
          setShowSlider(false);
        }
      }, 500);
      return () => {
        setRunTimeoutFunc(false);
        clearTimeout(timer);
      }
    }

  }, [props.question?.answer, runTimeoutFunc]);

  // console.log("showSlider", showSlider);


  useEffect(() => {
    if((props.question?.answer == 0 || !props.question?.answer) && !progress) {
      setShowSlider(false);
    } 
    else if(props.question?.answer > 0 || progress > 0) {
      setShowSlider(true);
    }
  }, [props.question?.answer, progress])

  useEffect(() => {
    if (props.question?.answer == 0 || !props.question?.answer) setProgress(0);
    // setProgress(props.question.answer);
    else if (props.question?.answer > 0) {
      const findAnsOption = props.question?.answers?.find((ans) => ans?.value === props?.question?.answer);
      setProgress(findAnsOption?.orderNo || findAnsOption?.order_no);
    }
  }, [props.question]);

  useEffect(() => {
    switch (props.question?.answers?.length) {
      case 2: setSliderWidth(36); break;
      case 3: setSliderWidth(56); break;
      case 4: setSliderWidth(76); break;
      case 5: setSliderWidth(65); break;
      case 6: setSliderWidth(90); break;
      default: break;
    }
    
    setMaxWidth(props.question?.answers?.length);


  }, [props, props.question?.question, props.question?.answer])

  // console.log("props.question.answer", props.question.answer);
  // console.log("progress", progress); console.log("showSlider", showSlider);


  const getLeft = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 20;
      case 6: return index * 16;
      default: return index * 20;
    }
  }
  
  const getLeftRadio = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 6;
      case 6: return index * 4;
      default: return index * 20;
    }
  }

  const getRadioWidth = (answers) => {

    switch (answers) {
      // case 3: return {width: "70%", marginLeft: "7.5%"};
      case 3: return { width: windowWidth > 1000 ? "50%" : windowWidth > 900 ? "50%" : windowWidth < 651 && windowWidth > 550 ? "90%" : windowWidth < 551 ? "95%" : "70%" };
      // case 4: return { width: "75%", margin: "0 auto" };
      
      case 4: return { width: windowWidth < 551 ? "95%" : windowWidth < 651 ? "90%" : windowWidth < 831 ? "80%" : "45ch", margin: "0 auto" };
      case 5: return { width: "90%", marginLeft: "2.5%" };
      // case 6: return { width: "100%", marginLeft: "0%" };
      case 6: return { width: "80%", margin:'auto' };
      default: return { width: "50%" };
    }
    // if(answers === 3) { return {width: "70%", marginLeft: "7.5%"}; }
    // return {width: "50%"};
  }

  const radioOptionWidth = (answers) => {
    switch (answers) {
      case 3: return "90%";
      // case 4: return "90%";
      case 4: return "100%";
      case 5: return "20%";
      case 6: return "16%";
      default: return "20%";
    }
  }

  // console.log("progress", progress);
  const updateStep = (ansVal, orderNo) => {
    console.log("\n\nansVal", ansVal);
    console.log("orderNo", orderNo, "\n\n");
    setShowSlider(true);
    // setTimeout(() => {setProgress(progressArg)}, 0);
    // props.questionAnswered(progressArg);
    setTimeout(() => {setProgress(orderNo)}, 0);
    // setTimeout(() => {setProgress(ansVal)}, 0);
    props.questionAnswered(ansVal);
    setRunTimeoutFunc(true);
  }

  const updateRadioStep = (ansVal, orderNo) => {

    setTimeout(() => {setProgress(orderNo)}, 0);
    props.questionAnswered(ansVal);
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  const updateMcqStep = (ansVal, orderNo , answerText) => {

    setTimeout(() => {setProgress(orderNo)}, 0);
    props.questionAnswered(ansVal);
    props.setSelectedAnswer(answerText)
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  console.log("checkboxAnswers", checkboxAnswers);

  const getLabelStyle = () => {

    // console.log("props.question", props.question);
    if(window.innerWidth > 650) {

      if(props.question?.answers?.length === 5) {
        // return { width: "100%", marginLeft: "-50%" };
        return { width: "12ch", marginLeft: "-6ch" };
      } else if(props.question?.answers?.length === 4) {
        return { width: "95%" };
      } else if(props.question?.answers?.length === 3 && props.question?.answerType === "radio") {
        // return { width: "200%", marginLeft: "-100%" };
        // return { width: "25ch" };
      } else if(props.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else {
        return { width: "auto", marginLeft: "0" };
      }
      // return { width: "14ch", marginLeft: "-7ch" };

    } 
    else {

      if(props.question?.answers?.length === 6) {
        return { width: "9ch", marginLeft: "-4.5ch", wordBreak: "break-all" };
      }
      // if(props.question?.answers?.length === 5) {
      //   return { width: "120%", marginLeft: "-60%" };
      // } else if(props.question?.answers?.length === 4) {
      //   return { width: "150%", marginLeft: "-75%" };
      // } else if(props.question?.answers?.length === 3) {
      //   return { width: "300%", marginLeft: "-150%" };
      // } else if(props.question?.answers?.length === 2) {
      //   return { width: "500%", marginLeft: "-250%" };
      // } else {
      //   return { width: "150%", marginLeft: "-75%" };
      // }
      // else return { width: "12ch", marginLeft: "-6ch" };
      else if (props?.question?.answerType === "slider") {
        // return { width: "9ch", marginLeft: "-4.5ch" };
        return { width: "9ch", marginLeft: "-4.5ch" };
      } else return { width: "auto" };
    }
    
  }

  const checkOpacity = (index) => {
    if(index === 0 && showSlider) return "0";
    else return "1";
  }

  const checkSelected = (optionOrderNo) => {
  // const checkSelected = (data, value) => {
    // console.log("checkSelected data:", data, "value:", value);
    // if(progress === value) {
    if(progress && (Number(progress) === Number(optionOrderNo))) {
      // console.log("desktopText", data.desktopText);
      return "label active";
    }
    else return "label";
  }
  const backgroundColorSet = (optionOrderNo) => {
  // const checkSelected = (data, value) => {
    // console.log("checkSelected data:", data, "value:", value);
    // if(progress === value) {
    if(progress && (Number(progress) === Number(optionOrderNo))) {
      // console.log("desktopText", data.desktopText);
      return "#F26722";
    }
    else return "#fff";
  }

  const updateSliderTextHighlight = (progress) => {
    console.log("progress", progress);
    console.log("desktopText", props?.question?.answers?.find(ans => ans.value === progress)?.desktopText);
    let activeSlider = null;
    // let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    switch (sliderText?.trim()) {
      case "Strongly Disgree":
        activeSlider = 1;
        break;
      case "Strongly<br/>Disgree":
        activeSlider = 1;
        break;
      case "Disagree":
        activeSlider = 2;
        break;
      case "Neutral":
        activeSlider = progress;
        break;
      case "Agree":
        activeSlider = 4;
        break;
      case "Strongly Agree":
        activeSlider = 5;
        break;
      case "Strongly<br/>Agree":
        activeSlider = 5;
        break;
      default: 
        activeSlider = progress;
    }
    return activeSlider;
  }  

  const updateQuestion = (event, step) => {
    // props.question.answerTxt = event.target.value;
    setTextAns(event.target.value);
    // setState({answerTxt: event.target.value});
    props.textAnswer(event.target.value);
    // return true;
  }

  // console.log("addCtx.questions", addCtx.questions);

  const handleMultiSelectionCheckboxes = (data, index, arr) => {
    
    // console.log("data", data); console.log("index", index); console.log("arr", arr);
    const optionId = data.id;
    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {
      if (item.id === optionId) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    setCheckboxAnswers(updatedCheckboxAnswers);
    // props.updateCheckboxQuestion(data.question_id, updatedCheckboxAnswers);
    props.updateMultiSelectionCheckboxQuestion(data.question_id, updatedCheckboxAnswers);
    
    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }
  }
  
  const handleSingleSelectionCheckboxes = (data, index, arr) => {
    
    // console.log("data", data); console.log("index", index); console.log("arr", arr);

    const optionId = data.id;
    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {
      if (item.id === optionId) {
        return { ...item, checked: !item.checked };
      } else {
        return { ...item, checked: false };
      }
    });

    // console.log("updatedCheckboxAnswers", updatedCheckboxAnswers);

    const answer = updatedCheckboxAnswers?.find(ans => ans.checked)?.value;

    setCheckboxAnswers(updatedCheckboxAnswers);
    props.updateSingleSelectionCheckboxQuestion(data.question_id, answer, updatedCheckboxAnswers);
    
    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }
  }

  const handleCheckboxes = (clickedOption, index, arr) => {
    
    // console.log("clickedOption", clickedOption); console.log("index", index); console.log("arr", arr);
    const questionId = props.question.id;
    const clickedOptionId = clickedOption?.id;
    const clickedOptionText = clickedOption?.desktopText?.toLowerCase()?.trim();
    // find the "None of the above" (nota) option nota = none of the above
    const notaOption = checkboxAnswers?.find(item => item?.desktopText?.toLowerCase()?.trim() === "none of the above");
    // check if the current option is "None of the above"/nota
    const clickedNoneOfTheAbove = clickedOptionText?.includes("none of the above");

    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {

      if (clickedNoneOfTheAbove) {
        // if the clicked option is nota, toggle it
        if (item?.id === notaOption?.id) {
          return { ...item, checked: !item.checked };
        } else if (item?.checked) {
          // if nota is checked, uncheck all other options
          return { ...item, checked: false };
        } else return item;
      } else if (notaOption?.checked && item.id !== clickedOptionId) {
        // if nota is checked and the current option is not nota, uncheck it
        return { ...item, checked: false };
      } else if (item.id === clickedOptionId) {
        // toggle the checked state of the current option
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    setCheckboxAnswers(updatedCheckboxAnswers);
    props.updateCheckboxQuestion(questionId, updatedCheckboxAnswers);
    
    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }

  }


  // for questions maxwidth to prevent widow words
  function getQuestionsMaxWidth() {
    const questionId = props.question?.id;
    let maxWidth = "65ch";
    switch (questionId) {
      case 1246: maxWidth = "55ch"; break;
      case 1429: windowWidth < 800 ?  maxWidth = "27ch" : maxWidth = "65ch"; break;
      case 1431: windowWidth < 800 ?  maxWidth = "27ch" : maxWidth = "65ch"; break;
      case 1432: windowWidth < 800 ?  maxWidth = "25ch" : maxWidth = "65ch"; break;
      case 1433: windowWidth < 800 ?  maxWidth = "27ch" : maxWidth = "65ch"; break;
      case 1453: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "65ch"; break;
      case 1456: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "65ch"; break;
      case 1458: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "65ch"; break;
      case 1459: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "65ch"; break;
      case 1461: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "65ch"; break;
      case 1463: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "44ch"; break;
      case 1465: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "44ch"; break;
      case 1467: windowWidth < 800 ?  maxWidth = "29ch" : maxWidth = "65ch"; break;
      case 1469: windowWidth < 800 ?  maxWidth = "24ch" : maxWidth = "44ch"; break;
      default: maxWidth = "65ch"; break;
    }
    return maxWidth;
  }



  return (
    <div>

      <div className="question" style={{ minHeight: props?.question?.answerType === "checkbox" ? "80px" : "140px", maxWidth: getQuestionsMaxWidth() }}>
        <p>{props.question?.question}</p>
      </div>

      {
        (() => {
          let answerType = props.question?.answerType || props.question?.type;

          switch (answerType) {

            case "checkbox":
              return <>
                <div className="question-checkbox-container">
                  <FormGroup>
                    {/* {props.question?.answers?.map((data, index, arr) => ( */}
                    {checkboxAnswers?.map((data, index, arr) => (
                      <FormControlLabel key={`labels-${data?.id}`} className="checkbox-label"
                        onChange={() => handleCheckboxes(data, index, arr)} control={
                          <Checkbox checked={data.checked || false} />} label={data.desktopText || data.desktop_text}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            color: data.checked ? "#F26722" : "#343333", // Change colors based on checked state
                          },
                        }}
                      />
                    ))}
                  </FormGroup>
                  {
                    showOtherOptionTextBox
                      ? <TextField multiline rows={windowWidth < 651 ? 4 : 2} hiddenLabel placeholder="We’re hopeful your feedback will help us serve you better next time." value={textAns} onChange={(e) => { updateQuestion(e, 1) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" style={{ height: "auto" }} />
                      : null
                  }
                </div>
              </>

            case "textarea":
              return <>
                <div className="textarea-field">
                  {/* <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder="Please type your response here" value={props.question?.answerTxt} onChange={(e) => {updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}
                  <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder="We’re hopeful your feedback will help us serve you better next time." value={textAns} onChange={(e) => { updateQuestion(e, 1) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                </div>
              </>

            case "radio": 
              return <>
                <div className="radio-container">
                  <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                    {
                      props.question?.answers?.map((data, index) => (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={checkSelected(data.orderNo || data.order_no)} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', /* width: radioOptionWidth(props?.question?.answers?.length),*/ display: "flex", cursor: "pointer" }} onClick={() => {
                          updateRadioStep(data.value, data.orderNo || data.order_no);
                        }}>
                          {/* <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                          {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                          <Radio checked={data.value === progress} value={progress} sx={{ '&, &.Mui-checked': { color: '#343333', }, }} />
                          <p className="desktop" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                          <p className="mobile" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                        </div>
                      ))
                    }
                    {/* { 
                          props.question?.answers.map((data, index) => (
                            <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                            // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                          ))
                        } */}

                  </div>
                </div>
              </>

            case "multiple_choice":
              return <>
                <div className="multiple-choice-container">
                  <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                    {
                      props.question?.answers?.map((data, index) => (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={`button-${index} ${checkSelected(data.orderNo || data.order_no)}`} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', /* width: radioOptionWidth(props?.question?.answers?.length),*/ display: "flex", cursor: "pointer", justifyContent: 'center', alignItems: 'center', width: '128px', height: '41px', borderRadius: '5px' }} onClick={() => {
                          updateMcqStep(data.value, data.orderNo || data.order_no, data.desktopText);
                        }}>
                          {/* <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                          {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                          <p className="desktop" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                          <p className="mobile" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                        </div>
                      ))
                    }
                    {/* { 
                          props.question?.answers.map((data, index) => (
                            <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                            // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                          ))
                        } */}

                  </div>
                </div>
              </>

            default:
              return <>{
                windowWidth <= 830 ? <>
                  <div className="multiple-choice-container">
                    <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                      {
                        props.question?.answers?.map((data, index) => {
                          let optionText = data.desktopText || data.desktop_text;
                          if (optionText?.includes("<br/>")) {
                            optionText = optionText.replaceAll("<br/>", " ");
                          }
                          return (
                          // were using checkSelected(data.value) here before this change to orderNo
                          <div className={`button-${index} ${checkSelected(data.orderNo || data.order_no)}`} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', /* width: radioOptionWidth(props?.question?.answers?.length),*/ display: "flex", cursor: "pointer", justifyContent: 'center', alignItems: 'center', width: '128px', height: '41px', borderRadius: '5px' }} onClick={() => {
                            updateMcqStep(data.value, data.orderNo || data.order_no, data.desktopText);
                          }}>
                            {/* <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                            {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                            <p className="desktop" style={getLabelStyle()}>{optionText}</p>
                            <p className="mobile" style={getLabelStyle()}>{optionText}</p>
                          </div>
                        )})
                      }
                      {/* { 
                          props.question?.answers.map((data, index) => (
                            <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                            // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                          ))
                        } */}

                    </div>
                  </div>
                </> : <>
                  <div className="slider-container" style={{ width: sliderWidth + '%' }}>
                    <div className="slider">
                      {showSlider ? <Slider
                        key={props.question?.id}
                        valueLabelDisplay="off"
                        step={1}
                        min={1}
                        max={maxWidth}
                        value={progress}
                        // value={updateSliderTextHighlight(progress)}
                        className="custom-slider"
                      /> : <div className="slider-none-adjust"></div>}
                      <div className="slider-points">
                        {
                          props.question?.answers?.map((data, index) => (
                            <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value, data.orderNo || data.order_no)}></a>
                          ))
                        }
                      </div>
                      <LinearProgress className="custom-bar" variant="determinate" value={0} />

                    </div>
                    <div className="slider-labels">
                      {
                        props.question?.answers?.map((data, index) => {
                          return (
                            // were using checkSelected(data.value) here before this change to orderNo
                            <div className={checkSelected(data.orderNo || data.order_no)} key={`labels-${index}`} style={{ left: getLeft(index) + '%' }} onClick={() => updateStep(data.value, data.orderNo || data.order_no)}>
                              {/* <p className="desktop" style={getLabelStyle()}>{data.desktopText}</p> */}
                              <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.desktopText || data.desktop_text }} />
                              {/* <p className="mobile" style={getLabelStyle()}>{data.mobileText}</p> */}
                              <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.mobileText || data.mobile_text }} />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </>
              }</>
          }
        }).call(this)
      }

      {/* <div className="slider-container" style={{ width: state.sliderWidth + '%' }}>
        <div className="slider">
          { state.showSlider ? <Slider
            key={props.question.id}
            valueLabelDisplay="off"
            step={1}
            min={1}
            max={state.maxWidth}
            value={state.progress}
            className="custom-slider"
          /> : <div className="slider-none-adjust"></div> }
          <div className="slider-points">
          {/* { !state.showSlider ? 
            <a className="point" style={{ left: '0%'}} onClick={() => updateStep(1)}></a> : null } */}
      {/* { 
              props.question?.answers.map((data, index) => (
                <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
              ))
            }
          </div>
          <LinearProgress className="custom-bar" variant="determinate" value={0} />
          
        </div>
        <div className="slider-labels">
          { 
            props.question?.answers.map((data, index) => (
              <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}>
                <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktopText }}></p>
                <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobileText }}></p>
              </div>
            ))
          }
        </div>
      </div> */}

    </div>
  );
}
  
export default Question;

