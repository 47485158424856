import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Switch } from "@mui/material";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import logoRight from '../../../Assets/Images/LandingPage/logo.png';
import logoFooterLeft from '../../../Assets/Images/Common/footer-logo.png';
import logoMob from '../../../Assets/Images/LandingPage/ACSTLogoSmall.png';
import clock from '../../../Assets/Images/LandingPage/clock.svg';
import downArrow from '../../../Assets/Images/LandingPage/down-arrow.png';
import TeamDashboard from '../../../Assets/Images/LandingPage/sample-dashboard-desktop.png';
import TeamDashboardMob from '../../../Assets/Images/LandingPage/mob-community-sample-dashboard-desktop.png';
import testimonialImage from '../../../Assets/Images/LandingPage/testimonial-matt.jpg';
import topQ from '../../../Assets/Images/LandingPage/quotetop.svg';
import bottomQ from '../../../Assets/Images/LandingPage/quote-bottom.svg';
import analyzeIcon from '../../../Assets/Images/LandingPage/assess.png';
import alignmentIcon from '../../../Assets/Images/LandingPage/measure-light.png';
import directionIcon from '../../../Assets/Images/LandingPage/build.png';
import invoice from '../../../Assets/Images/LandingPage/invoice.png';
import ArrowLeft from '../../../Assets/Images/LandingPage/prev.svg';
import ArrowRight from '../../../Assets/Images/LandingPage/next.svg';

import slide1 from '../../../Assets/Images/LandingPage/SliderImagesBookKeeping/slide1.png';
import slide2 from '../../../Assets/Images/LandingPage/SliderImagesBookKeeping/slide2.png';
import slide3 from '../../../Assets/Images/LandingPage/SliderImagesBookKeeping/slide3.png';
import slide4 from '../../../Assets/Images/LandingPage/SliderImagesBookKeeping/slide4.png';


import "./LandingPageBookkeeping.scss";

const LandingPageBookkeeping = () => {


  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();

  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [largeScreen, setLargeScreen] = useState(true);


  useEffect(() => {
    if (windowWidth > 830) {
      setLargeScreen(true);
    }
    else {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }

  const toggleIndividualChange = (e) => {
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const createAssessmentBookkeeping = () => {

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_BOOKKEEPING_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        navigate("/bookkeeping/assessment/" + data.id + "/835?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
        console.error("Failed to create new assessment...", data);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }


  const showDashboard = () => {
    return (
      <div className="sample-dashboard">
        <div className="scroll-to-see">
          <h2>Scroll to see what a sample dashboard looks like</h2>
          <img src={downArrow} alt="scroll down" />
        </div>
        <div className="dashboard-heading">
          <div className="line"></div>
          <h1>SAMPLE DASHBOARD</h1>
        </div>
        <div className="individual-group">
          <button onClick={() => setCurrentView(0)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
          <button onClick={() => setCurrentView(1)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
        </div>
        <div className="toggle-individual-group">
          <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
          <Switch
            checked={!toggleIndividual}
            onChange={toggleIndividualChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
        </div>
        <div className="results-dashboard-container">
          <img src={currentView === 0 ? (largeScreen ? TeamDashboard : TeamDashboardMob) : (largeScreen ? '' : '')} alt="" />
        </div>
      </div>
    )
  }

  const showTesttimonial = () => {
    return (
      <div className="testimonial-container">
        <div className="testimonial">
          <div className="testi">
            {/* <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>This assessment helped me audit our bookkeeping practices and understand exactly where we need to improve. I enjoyed being able to see how other similar churches are doing too.</p>

            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{ transform: 'rotate(180deg)' }} alt="quote icon" /></div></div> */}
            <p>
              <span className="quotation-mark-left"><img src={topQ} alt="quote icon" /></span>
              This assessment helped me audit our bookkeeping practices and understand exactly where we need to improve. I enjoyed being able to see how other similar churches are doing <span className="no-wrap">too. <span className="quotation-mark-right"><img src={bottomQ} alt="quote icon" /></span></span>
            </p>
          </div>
          <div>
            <div className="avatar">
              <img src={testimonialImage} alt="pastor" />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div className="designation">
              Matt Wallace<p className="designation-inner">Lead Pastor, <strong>Salt & Light Church</strong></p>
            </div>
          </div>

        </div>
      </div>
    )
  }

  const showStartContainer = () => {
    return (
      <div className="container-start">
        <div className="image">
          <img className="thumbnail" src={invoice} alt="" />
        </div>
        <div className="intro">
          <h1>How effectively does your church steward its financial discipline?</h1>

          <p>Take this 3-minute test to find out where you can improve and where you can better align.</p>
          <div className="completion">
            <Button className="cta" onClick={createAssessmentBookkeeping}>GET STARTED</Button>
            {/* <a className="assess-link" href="/bookkeeping" target="_blank" rel="noreferrer">GET STARTED</a> */}
          </div>

        </div>
      </div>
    )
  }

  return (
    <div className="landing-page-container bookkeeping">

      <div className="content-container">

        <div className="top-section">
          <div className="header-container-wrapper">
            <div className="header-container">
              <div className="logo-left"><img src={largeScreen ? logoRight : logoRight} alt="Logo" /></div>
            </div>
            <div className="contents">
              <h1>Do your church's bookkeeping practices demonstrate solid financial discipline?</h1>
              {/* <p>See how you compare* with similarly-sized American churches</p> */}

              <ol className="intro-points-list">

                <li>Take this assessment</li>
                <li>View your results instantly</li>
                <li>Improve your church's finance management</li>
              </ol>

              <div className="completion">
                <Button className="cta" onClick={createAssessmentBookkeeping}>GET STARTED</Button>
                {/* <a className="assess-link" href="/bookkeeping" target="_blank" rel="noreferrer">GET STARTED</a> */}
                <div className='time-estimate'>
                  <div className='img-container'><img className='clock-icon' src={clock} alt="estimated time" /></div><span style={{ margin: "auto" }}>3 min</span>
                </div>
              </div>

            </div>
            <div className='para-container'>
              <p className='para'>Note: The individual most knowledgeable about your church’s financial practices must take this assessment. If you are not in such a position, please share this assessment with your accounting expert.</p>
            </div>

          </div>

        </div>


        <div className="middle-section">

          <h3>Taste & See Our Assessment</h3>

          <div className="custom-slider">

            {Slider()}

            <Button className="cta" onClick={createAssessmentBookkeeping}>GET STARTED</Button>
            {/* <a className="assess-link" href="/bookkeeping" target="_blank" rel="noreferrer">GET STARTED</a> */}

          </div>

          {showTesttimonial()}

          <div className="unlock-growth">

            <h3>3 KEYS to Unlock Growth</h3>

            <div className="unlock-reasons">
              <div className="reason">
                <img src={analyzeIcon} alt="insights icon" /><br />
                <div className="height30"></div>
                <span>Assess</span><br />
                See how your practices align with the best practices
              </div>

              <div className="reason">
                <img src={alignmentIcon} alt="alignment icon" /><br />
                <div className="height30"></div>
                <span>Measure</span><br />
                Identify where you're doing well and where you need to improve
              </div>

              <div className="reason">
                <img src={directionIcon} alt="direction icon" /><br />
                <div className="height30"></div>
                <span>Build</span><br />
                Maximize your impact with data-driven decisions
              </div>

            </div>

            {/* <div className="cta-btn">
              <Button>GET STARTED</Button>
            </div> */}

          </div>

          <div className="testimonial-container" style={{ background: 'unset' }}>
            <div className="testimonial">
              <div className="testi">
                {/* <div className="quotation-mark" style={{ top: "66px", left: '-6%' }}><img src={topQ} alt="quote icon" /></div>
                <p style={{ fontSize: "32px", color: "#FFFFFF", maxWidth: '46ch' }}>This assessment helped me audit our bookkeeping practices and understand exactly where we need to improve. I enjoyed being able to see how other similar churches are doing too.</p>

                <div className="quotation-mark bottom" style={{ top: "-37px", left: '7%' }} ><div className="quotation-block"><img src={bottomQ} style={{ transform: 'rotate(180deg)' }} alt="quote icon" /></div></div> */}
                <p style={{ color: "#ffffff" }}>
                  <span className="quotation-mark-left"><img src={topQ} alt="quote icon" /></span>
                  This assessment helped me audit our bookkeeping practices and understand exactly where we need to improve. I enjoyed being able to see how other similar churches are doing <span className="no-wrap">too. <span className="quotation-mark-right"><img src={bottomQ} alt="quote icon" /></span></span>
                </p>
              </div>
              <div>
                <div className="avatar">
                  <img src={testimonialImage} alt="pastor" />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div className="designation">
                  Matt Wallace<p className="designation-inner" style={{ color: "#ffffff" }}>Lead Pastor, <strong>Salt & Light Church</strong></p>
                </div>
              </div>

            </div>
          </div>

          {showStartContainer()}

        </div>

      </div>

      <div className="footer-container">
        <div className="contents">
          <div className="logo-left"><img src={largeScreen ? logoFooterLeft : logoFooterLeft} alt="Logo" /></div>
          <p>© Copyright 2024 ACS Technologies | <a className="privacy-policy" href="https://help.acst.com/en/trust-center/policies/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a></p>
          <div className="logo-left"></div>
        </div>
      </div>

    </div>
  )
}


export default LandingPageBookkeeping;





const slides = [
  { image: slide1, },
  { image: slide2, },
  { image: slide3, },
  { image: slide4, },

];

const Slider = () => {

  const [active, setActive] = useState(0);
  const [autoplay, setAutoplay] = useState(1);
  const max = slides.length;

  const intervalBetweenSlides = () => autoplay && setActive(active === max - 1 ? 0 : active + 1)

  useEffect(() => {
    const interval = setInterval(() => intervalBetweenSlides(), 4000);
    return () => clearInterval(interval);
  });


  const nextOne = () => active < max - 1 && setActive(active + 1)

  const prevOne = () => active > 0 && setActive(active - 1)

  const isActive = value => active === value && 'active'

  const setSliderStyles = () => {
    const transition = active * - 100;

    return {
      width: (slides.length * 100) + 'vw',
      transform: 'translateX(' + transition + 'vw)'
    }
  }

  const renderSlides = () => slides.map((item, index) => (
    <div
      className='each-slide'
      key={index}
    >
      <img src={item.image} alt="" />
    </div>
  ));

  const renderDots = () => slides.map((silde, index) => ( // check index
    <li
      className={isActive(index) + ' dots'}
      key={index}>
      <button onClick={() => setActive(index)}>
        <div className="slider-dots"></div>
      </button>
    </li>
  ));


  const renderArrows = () => (
    <React.Fragment>
      <button
        type='button'
        className='arrows prev'
        onClick={() => prevOne()} >
        <img src={ArrowLeft} />

      </button>
      <button
        type='button'
        className='arrows next'
        onClick={() => nextOne()} >
        <img src={ArrowRight} />
      </button>
    </React.Fragment>
  )

  return (
    <section className='slider'>
      <div
        className='wrapper'
        style={setSliderStyles()}>
        {renderSlides()}
      </div>
      {renderArrows()}
      <ul className='dots-container'>
        {renderDots()}
      </ul>

    </section>
  );
};
