import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Switch } from "@mui/material";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import logoFooterLeft from '../../../Assets/Images/Common/footer-logo.png';
import logoRight from '../../../Assets/Images/LandingPage/logo.png';
import logoMob from '../../../Assets/Images/LandingPage/ACSTLogoSmall.png'
import clock from '../../../Assets/Images/LandingPage/clock-white.svg';
import downArrow from '../../../Assets/Images/LandingPage/down-arrow.png';
import teamDashboardImg from '../../../Assets/Images/LandingPage/community-sample-dash-team-desktop.png';
import teamDashboardMobileImg from '../../../Assets/Images/LandingPage/community-sample-dash-team-mobile.png';
import individualDashboardImg from '../../../Assets/Images/LandingPage/community-sample-dash-indi-desktop.png';
import individualDashboardMobileImg from '../../../Assets/Images/LandingPage/community-sample-dash-indi-mobile.png';
import testimonialImage from '../../../Assets/Images/LandingPage/testimonial-image.png';
import topQ from '../../../Assets/Images/LandingPage/quotetop.svg';
import bottomQ from '../../../Assets/Images/LandingPage/quote-bottom.svg';
import analyzeIcon from '../../../Assets/Images/LandingPage/insight.png';
import alignmentIcon from '../../../Assets/Images/LandingPage/measure.png';
import directionIcon from '../../../Assets/Images/LandingPage/construction.png';
import Group from '../../../Assets/Images/LandingPage/group.png';

import ArrowLeft from '../../../Assets/Images/LandingPage/left-arrow.svg';
import ArrowRight from '../../../Assets/Images/LandingPage/right-arrow.svg';

import slide1 from '../../../Assets/Images/LandingPage/SliderImageCommunity/slide1.png';
import slide2 from '../../../Assets/Images/LandingPage/SliderImageCommunity/slide2.png';
import slide3 from '../../../Assets/Images/LandingPage/SliderImageCommunity/slide3.png';
import slide4 from '../../../Assets/Images/LandingPage/SliderImageCommunity/slide4.png';

import "./LandingPageCommunity.scss";



const LandingPageCommunity = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { windowWidth } = useWindowDimensions();

  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [largeScreen, setLargeScreen] = useState(true);


  useEffect(() => {
    if (windowWidth > 830) {
      setLargeScreen(true);
    }
    else {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }

  const toggleIndividualChange = (e) => {
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const createAssessmentCommunity = () => {

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if(searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_COMMUNITY_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        navigate("/community/intro/" + data.id + "?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }



  const showDashboard = () => {
    return (
      <div className="sample-dashboard">
        <div className="scroll-to-see">
          <h2>Scroll to see what a sample dashboard looks like</h2>
          <img src={downArrow} alt="scroll down" />
        </div>
        <div className="dashboard-heading">
          <div className="line"></div>
          <h1>SAMPLE DASHBOARD</h1>
        </div>
        {largeScreen ?   
          <div className="individual-group">
          <button onClick={() => setCurrentView(0)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
          <button onClick={() => setCurrentView(1)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
          </div> :
          <div className="toggle-individual-group">
            <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
            <div className="switch-container">
              <Switch
                checked={!toggleIndividual}
                onChange={toggleIndividualChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
          </div>
        }
        <div className="results-dashboard-container">
          <img src={currentView === 0 ? (largeScreen ? teamDashboardImg : teamDashboardMobileImg) : (largeScreen ? '' : '')} alt="" />
          
          {/* Replace Image with Individual Dashboard */}
          <img src={currentView === 1 ? (largeScreen ? individualDashboardImg : individualDashboardMobileImg) : (largeScreen ? '' : '')} alt="" /> 
        </div>
      </div>
    )
  }

  const showTesttimonial = () => {
    return (
      <div className="testimonial-container">
        <div className="testimonial">
          <div className="testi">
            {/* <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
            <p>This assessment helped our church think through internal and external engagement strategies, refine them, and make intentional moves to improve them. It helped us find blind spots, identify barriers, and start the conversations needed to improve our church.</p>

            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{ transform: 'rotate(180deg)' }} alt="quote icon" /></div></div> */}
            <p>
              <span className="quotation-mark-left"><img src={topQ} alt="quote icon" /></span>
              This assessment helped our church think through internal and external engagement strategies, refine them, and make intentional moves to improve them. It helped us find blind spots, identify barriers, and start the conversations needed to improve our <span className="no-wrap">church. <span className="quotation-mark-right"><img src={bottomQ} alt="quote icon" /></span></span>
            </p>

          </div>
          <div>
            <div className="avatar">
              <img src={testimonialImage} alt="pastor" />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
            <div className="designation">
              Chris Martinez<p className="designation-inner">Lead Pastor, <strong>Lakeside Church</strong></p>
            </div>
          </div>

        </div>
      </div>
    )
  }

  const showStartContainer = () => {
    return (
      <div className="container-start">
        <div className="image">
          <img  className="thumbnail" src={Group} alt="" />
        </div>
        <div className="intro">
          <h1>How effectively does your church fuel its community outreach?</h1>
          <p>Take this 3-minute test to find out where you can improve and where you can better align.</p>
          <div className="completion">
            <Button className="cta" onClick={createAssessmentCommunity}>GET STARTED</Button>
            {/* <a className="assess-link" href="/community" target="_blank" rel="noreferrer">GET STARTED</a> */}
          </div>

        </div>
      </div>
    )
  }

  return (
    <div className="landing-page-container community">

      <div className="content-container">

        <div className="top-section">
          <div className="header-container-wrapper">
            <div className="header-container">
              <div className="logo-left">
                <img src={ largeScreen ? logoRight :logoRight } alt="Logo" /></div>
            </div>
            <div className="contents">
              <h1>Is your outreach strategy engaging your community effectively?</h1>
              <p>See how you compare* with similarly-sized churches</p>

              <ol className="intro-points-list">

                <li>Take this 3-minute benchmark assessment</li>
                <li>Invite your team to take it too after you do</li>
                <li>View everyone's results on one dashboard</li>
                <li>Align on a strategy to elevate engagement</li>

              </ol>

              <div className="completion">
                <Button className="cta" onClick={createAssessmentCommunity}>GET STARTED</Button>
                {/* <a className="assess-link" href="/community" target="_blank" rel="noreferrer">GET STARTED</a> */}
                <div className='time-estimate'>
                  <div className='img-container'><img className='clock-icon' src={clock} alt="estimated time" /></div><span style={{ margin: "auto" }}>3 min</span>
                </div>
              </div>

            </div>
            <div className='para-container'>
              <p className='para'>*We can help you compare your church's data to that of about 10,000 churches in the US through our church engagement research.</p>
            </div>

          </div>

        </div>


        <div className="middle-section">

          <h3>Taste & See Our Assessment<br/> & Team-Based Dashboard </h3>

          <div className="custom-slider">

            {Slider()}

            <Button className="cta" onClick={createAssessmentCommunity}>GET STARTED</Button>
            {/* <a className="assess-link" href="/community" target="_blank" rel="noreferrer">GET STARTED</a> */}

          </div>

          {showTesttimonial()}

          <div className="unlock-growth">

            <h3>3 KEYS to Unlock Growth</h3>

            <div className="unlock-reasons">
              <div className="reason">
                <img src={analyzeIcon} alt="insights icon" /><br />
                <div className="height30"></div>
                <span>Assess</span><br />
                See where your team is aligned and where you need to align
              </div>

              <div className="reason">
                <img src={alignmentIcon} alt="alignment icon" /><br />
                <div className="height30"></div>
                <span>Measure</span><br />
                Identify where you’re doing well and where you need to improve
              </div>

              <div className="reason">
                <img src={directionIcon} alt="direction icon" /><br />
                <div className="height30"></div>
                <span>Build</span><br />
                Maximize your impact with data-driven decisions
              </div>

            </div>

            {/* <div className="cta-btn">
              <Button>GET STARTED</Button>
            </div> */}

          </div>

          {showDashboard()}

          <div className="testimonial-container testimonial-second" style={{ background: 'unset' }}>
            <div className="testimonial">
              <div className="testi">
                {/* <div className="quotation-mark"><img src={topQ} alt="quote icon" /></div>
                <p style={{ color: "#4A4A4A", maxWidth: '46ch' }}>This assessment helped our church think through internal and external engagement strategies, refine them, and make intentional moves to improve them. It helped us find blind spots, identify barriers, and start the conversations needed to improve our church.</p>

                <div className="quotation-mark bottom"  ><div className="quotation-block"><img src={bottomQ} style={{ transform: 'rotate(180deg)' }} alt="quote icon" /></div></div> */}
                <p>
                  <span className="quotation-mark-left"><img src={topQ} alt="quote icon" /></span>
                  This assessment helped our church think through internal and external engagement strategies, refine them, and make intentional moves to improve them. It helped us find blind spots, identify barriers, and start the conversations needed to improve our <span className="no-wrap">church. <span className="quotation-mark-right"><img src={bottomQ} alt="quote icon" /></span></span>
                </p>
              </div>
              <div>
                <div className="avatar">
                  <img src={testimonialImage} alt="pastor" />
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <div className="designation">
                  Chris Martinez<p className="designation-inner">Lead Pastor, <strong>Lakeside Church</strong></p>
                </div>
              </div>

            </div>
          </div>


          {showStartContainer()}

        </div>

      </div>

      <div className="footer-container">
        <div className="contents">
          <div className="logo-left"><img src={largeScreen ? logoFooterLeft : logoFooterLeft} alt="Logo" /></div>
          <p>© Copyright 2024 ACS Technologies | <a className="privacy-policy" href="https://help.acst.com/en/trust-center/policies/privacy-policy" rel="noreferrer" target="_blank">Privacy Policy</a></p>
          <div className="logo-left"></div>
        </div>
      </div>

    </div>
  )
}


export default LandingPageCommunity;





const slides = [
  { image: slide1, },
  { image: slide2, },
  { image: slide3, },
  { image: slide4, },

];

const Slider = () => {

  const [active, setActive] =useState(0);
  const [autoplay, setAutoplay] = useState(1);
  const max = slides.length;

  const intervalBetweenSlides = () => autoplay && setActive(active === max - 1 ? 0 : active + 1)

  useEffect(() => {
      const interval = setInterval( () => intervalBetweenSlides(), 4000);
      return () => clearInterval(interval);
  });


  const nextOne = () => active < max - 1 && setActive(active + 1)

  const prevOne = () => active > 0 && setActive(active - 1)

  const isActive = value => active === value && 'active'

  const setSliderStyles = () => {
      const transition = active * - 100;
      
      return {
        width: ( slides.length * 100 ) + 'vw',
        transform: 'translateX(' + transition + 'vw)'
      }
  }

  const renderSlides = () => slides.map((item, index) => (
      <div 
          className='each-slide' 
          key={ index } 
          >
            <img src={item.image} alt="" />
      </div> 
  ));

  const renderDots = () => slides.map((silde, index) => ( // check index
      <li 
          className={ isActive(index) + ' dots' }   
          key={ index }>
              <button onClick={ () => setActive(index) }>
                  <div className="slider-dots"></div>
              </button>
      </li> 
  ));


  const renderArrows = () => (
      <React.Fragment>
          <button 
              type='button'
              className='arrows prev' 
              onClick={ () => prevOne() } >
              <img src={ArrowLeft} />
            
          </button>
          <button 
              type='button'
              className='arrows next' 
              onClick={ () => nextOne() } > 
              <img src={ArrowRight} />
          </button>
      </React.Fragment>
  )

  return (
      <section className='slider'>
          <div 
              className='wrapper' 
              style={ setSliderStyles() }>
              { renderSlides() }
          </div>
          { renderArrows() }
          <ul className='dots-container'>
              { renderDots() }
          </ul>
         
      </section>
  );
};
