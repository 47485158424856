import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

// import VideoThumbnail from '../../../Assets/Images/Infosec/video-thumbnail.png';
import VideoThumbnail from '../../../Assets/Images/Infosec/thumbnail.png';
// import VideoThumbnailMobile from '../../Assets/Images/Infosec/video-thumbnail-mobile.png';
// import PlayButton from '../../Assets/Images/Infosec/play-button.png';
import PlayButton from '../../../Assets/Images/Infosec/play-btn.png';
import PlayButtonHover from '../../../Assets/Images/Infosec/play-btn-hover.png';
import startBgImg from '../../../Assets/Images/Infosec/infosec-start-bg.png';
// import clockImg from '../../../Assets/Images/Infosec/clock.png';
import clockImg from '../../../Assets/Images/Infosec/clock-white.png';
import { Close } from "@mui/icons-material";
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { Button, CircularProgress } from '@mui/material';

import systemsIcon from '../../../Assets/Images/Bookkeeping/systems.png';
import processIcon from '../../../Assets/Images/Bookkeeping/process.png';
import complianceIcon from '../../../Assets/Images/Bookkeeping/compliance.png';
import stewardshipIcon from '../../../Assets/Images/Bookkeeping/stewardship.png';
import technologyIcon from '../../../Assets/Images/Bookkeeping/technology.png';

// BookkeepingStartV2 => /about-bookkeeping
// BookkeepingStartV3 => /explainer-bookkeeping/:id
// BookkeepingStartV2 is for users coming from external links
// check BookkeepingStartV3 for normal users who start assessment from infosec homepage/landing page
const BookkeepingStartV2 = props => {
  
  const { windowWidth } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [playingVideo, setPlayingVideo] = useState(false);
  const [startPlaying, setStartPlaying] = useState(false);

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const display = searchParams.get("display");
  const navigate = useNavigate();


  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  useEffect(() => {
    // document.body.style.background = `url(${startBgImg})`;
    localStorage.setItem("external-user", "true");
  }, []);

  // console.log(searchParams.get("display"));

  const assessmentPageLink = () => {
    // navigate("/infosec/assessment/" + id + "/814?" + searchParams, { replace: true });
  }

  const introPageLink = () => {
    // createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const createAssessment = () => {

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_BOOKKEEPING_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        navigate("/bookkeeping/assessment/" + data.id + "/835?" + searchParams, { replace: true });
        // navigate("/bookkeeping/explainer-bookkeeping/" + data.id );
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
        console.error("Failed to create new assessment...", data);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }

  return (
    <div className="start-explainer-container">
      <div className="start-explainer">
      <h1 className="heading">Evaluate your church's financial discipline across these <span className='text-orange'>critical financial metrics</span>.</h1>
        <ul>
          <li>
            <img src={processIcon} alt="" className="explainer-icon" />
            <div className="text-content">
              <h3>PROCESS</h3>
              <p>Implementation of policies and procedures</p>
            </div>
          </li>
          <li>
            <img src={complianceIcon} alt="" className="explainer-icon" />
            <div className="text-content">
              <h3>Compliance</h3>
              <p>Adherence to laws, regulations, and standards</p>
            </div>
          </li>
          <li>
            <img src={stewardshipIcon} alt="" className="explainer-icon" />
            <div className="text-content">
              <h3>Stewardship</h3>
              <p>Accountable and transparent oversight of resources</p>
            </div>
          </li>
          <li>
            <img src={technologyIcon} alt="" className="explainer-icon" />
            <div className="text-content">
              <h3>TECHNOLOGY</h3>
              <p>Effectiveness of digital toolkit and solutions</p>
            </div>
          </li>
          <li>
            <img src={systemsIcon} alt="" className="explainer-icon" />
            <div className="text-content">
              <h3>SYSTEMS<span style={{ color: "#ED2028" }}>*</span></h3>
              <p>Management framework and controls</p>
            </div>
          </li>
        </ul>
        <div className="completion" style={{ justifyContent: "center" }}>
          {/* <Button className="cta" onClick={createAssessment}>Continue</Button> */}
          <Button className="cta" onClick={createAssessment}>
            {isLoading ? <CircularProgress style={{ color: '#FFFFFF' }} size={23} />
              : "Get Started"}
          </Button>
        </div>
        <div className="footnote">
          <p>*Questions in this category are qualitative and will not significantly impact your scorecard.</p>
        </div>
      </div>
    </div>
  );
}

export default BookkeepingStartV2;
