import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../../Assets/Images/Community/testimonial.jpg';
// import topQ from '../Assets/Images/topQ.svg';
import topQ from '../../Assets/Images/Community/leftQuote.png';
import bottomQ from '../../Assets/Images/Community/rightQuote.png';


const CommunityTestimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">
        <div className="testi">
            <div className="quotation-mark"><img src={topQ}/></div>
            <p>This assessment helped our church think through internal and external engagement strategies, refine them, and make intentional moves to improve them. It helped us find blind spots, identify barriers, and start the conversations needed to improve our church.</p>
            {/* <p></p> */}
            
            <div className="quotation-mark bottom"><div className="quotation-block"><img src={bottomQ} style={{transform:'rotate(180deg)'}} /></div></div>
            
          </div>
        <div>
          <div className="avatar">
            <img src={testimonialImage} alt="pastor" width={125} height={125} />
          </div>
        </div>
        <div style={{display:'flex', justifyContent:'center' , flexDirection:'column', alignItems:'center'}}>          
          <div className="designation">
          Chris Martinez<p className="designation-inner">Lead Pastor, Lakeside Church</p> 
          </div>
        </div>
          {/* <div className="cta-btn">
          <Link to={"/community/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
    </>
  );
}

export default CommunityTestimonial;
