import { useLocation } from "react-router-dom";
import InfosecHeader from "../../Infosec/Layout/Header/InfosecHeader";
import BookkeepingHeader from "../../Bookkeeping/Layout/Header/BookkeepingHeader";
import CongregationHeader from "../../Congregation/Layout/Header/CongregationHeader";
import CommunityHeader from "../../Community/Layout/CommunityHeader";



const AssessmentHeader = props => {

  const location = useLocation();

  const getContentBasedOnPath = () => {

    if (location.pathname.split("/").includes("infosec")) {

      return <InfosecHeader />

    } else if (location.pathname.split("/").includes("infosec-church")) {

      return <InfosecHeader />

    }else if (location.pathname.split("/").includes("bookkeeping")) {

      return <BookkeepingHeader />

    } else if (location.pathname.split("/").includes("congregation")) {

      return <CongregationHeader />

    } else if (location.pathname.split("/").includes("community")) {

      return <CommunityHeader />

    }

  }

  return (
    <>
      {getContentBasedOnPath()}
    </>
  )
}

export default AssessmentHeader;
