import { createContext, useState } from 'react';

const InfosecAdditionalQuestionContext = createContext({
  questions: {},
  addQuestions: (addQuestions) => { },
  updateAnswer: (updateAnswer) => { },
  updateTextAnswer: (updateTextAnswer) => { },
});

export function InfosecAdditionalQuestionContextProvider(props) {

  const [questions, setQuestions] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [form1Details, setForm1Details] = useState({ zipCode: "", avgWeeklyAttd: "" });
  
  // using this with react-select Creatable so that newly created values can also be stored in context object
  const [roleValues, setRoleValues] = useState([
    {value:"Executive Pastor",label:"Executive Pastor"},
    {value:"Senior Pastor",label:"Senior Pastor"},
    {value:"Worship Pastor",label:"Worship Pastor"},
    {value:"Youth Pastor",label:"Youth Pastor"},
    {value:"Church/Business Administrator",label:"Church/Business Administrator"},
    {value:"IT Director",label:"IT Director"},
    {value:"Chief Financial Officer",label:"Chief Financial Officer"},
    {value:"Administrative Assistant",label:"Administrative Assistant"},
    {value:"Financial Administrative Assistant",label:"Financial Administrative Assistant"},
    {value:"Elder/Deacon",label:"Elder/Deacon"},
    ]);

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function addFormDetailsHandler(data) {
    setFormDetails(data);
  }

  function addForm1DetailsHandler(data) {
    setForm1Details(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answer = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answerTxt = answer;
    let localQuestions = [];
    console.log("We are here");
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  const context = {
    questions: questions,
    formDetails: formDetails,
    form1Details: form1Details,
    addQuestions: addQuestionsHandler,
    addFormDetails: addFormDetailsHandler,
    addForm1Details: addForm1DetailsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler,
    roleValues: roleValues,
    setRoleValues: setRoleValues
  };

  return <InfosecAdditionalQuestionContext.Provider value={context}>
    {props.children}
  </InfosecAdditionalQuestionContext.Provider>
}

export default InfosecAdditionalQuestionContext;
