import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useParams, useNavigate } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

import prevArrow from "../../../Assets/Images/Bookkeeping/prev-arrow.svg";
import nextArrow from "../../../Assets/Images/Bookkeeping/next-arrow.svg";
import downArrowImg from "../../../Assets/Images/Bookkeeping/down-arrow.png";
// import acstGrayLogoImg from "../../../Assets/Images/Bookkeeping/acst-logo-gray.png";
import acstGrayLogoImg from "../../../Assets/Images/Bookkeeping/acst-gray-logo.png";


import SouthIcon from '@mui/icons-material/South'

import testimonialImage from "../../../Assets/Images/Bookkeeping/testimonial.png";
import scheduledImage from "../../../Assets/Images/Bookkeeping/schedule-image.png";
import quotationImage from "../../../Assets/Images/Bookkeeping/quotation.svg";
import TeamDashboard from '../../../Assets/Images/Bookkeeping/sample-dash-team-desk.png';
import TeamDashboardMobile from '../../../Assets/Images/Bookkeeping/sample-dash-team-mobi.png';
import IndividualDashboard from '../../../Assets/Images/Bookkeeping/sample-dash-indi-desk.png';
import IndividualDashboardMobile from '../../../Assets/Images/Bookkeeping/sample-dash-indi-mobi.png';
import acstAssessmentsAdImg from "../../../Assets/Images/Common/acst-assessments-collection.png";
import acstBannerLogoImg from "../../../Assets/Images/Common/acst-banner-logo.png";
import acstBannerLogoMobileImg from "../../../Assets/Images/Common/acst-banner-logo-mobile.png";
import infosecPhishingImg from "../../../Assets/Images/Infosec/infosec-phishing.png";
import infosecRadiologyImg from "../../../Assets/Images/Infosec/infosec-radiology.png";
import infosecRadarImg from "../../../Assets/Images/Infosec/infosec-radar.png";
import infosecEmailImg from "../../../Assets/Images/Infosec/infosec-email.png";


import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { sectionsText } from "../../../globalFunctions";
import BookkeepingInviteInfo from "../../../Components/Bookkeeping/InviteInfo/BookkeepingInviteInfo.jsx";
import BookkeepingTestimonial from "../../../Components/Bookkeeping/Testimonial/BookkeepingTestimonial.jsx";
import BookkeepingClickableCircularProgressBars from "../../../Components/Bookkeeping/BookkeepingClickableCircularProgressBars.jsx";
// import DonutChart from "../Components/DonutChart";
// import DonutChartAccordion from "../Components/DonutChartAccordion";

import { Accordion, AccordionSummary, AccordionDetails, Box, Switch, } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Add, Remove } from "@mui/icons-material";
import BookkeepingCallInfo from "../../../Components/Bookkeeping/CallInfo/BookkeepingCallInfo.jsx";
import { questions_statistics } from "../temp.js";
import BookkeepingInvite from "../Invite/BookkeepingInvite.jsx";
import BookkeepingInviteThankYou from "../InviteThankYou/BookkeepingInviteThankYou.jsx";
console.log(questions_statistics);
const CustomExpandIcon = () => {
  return (
    <Box
      sx={{
        ".Mui-expanded & > .collapsIconWrapper": {
          display: "none"
        },
        ".expandIconWrapper": {
          display: "none"
        },
        ".Mui-expanded & > .expandIconWrapper": {
          display: "block"
        }
      }}
    >
      <div className="expandIconWrapper"><Remove /></div>
      <div className="collapsIconWrapper"><Add /></div>
    </Box>
  );
};

const BookkeepingResults = (props) => {

  let navigate = useNavigate();
  const { id } = useParams();
  const { windowWidth } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(true);
  const [isMember, setIsMember] = useState(false);
  const [apiData, setApiData] = useState();
  const [name, setName] = useState("");
  const [score, setScore] = useState(0);
  const [sections, setSections] = useState([]);
  const [subScore1, setSubScore1] = useState(0);
  const [subScore2, setSubScore2] = useState(0);
  const [subScore3, setSubScore3] = useState(0);
  const [subScore4, setSubScore4] = useState(0);
  const [subScore5, setSubScore5] = useState(0);
  const [subScores, setSubScores] = useState([0, 0, 0, 0, 0]);
  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);
  const [largeScreen, setLargeScreen] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [newSections, setNewSections] = useState([]);
  const [showInvitePageOnResultsPage, setShowInvitePageOnResultsPage] = useState(false);
  const [showInviteSuccessOnResultsPage, setShowInviteSuccessOnResultsPage] = useState(false);

  const [chartsData, setChartsData] = useState();


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    
    document.body.style.backgroundImage = "none";
    document.body.style.background = "#435B63";
    document.body.style.backgroundColor = "#435B63";
    getQuestions();
    fetchResults();
  }, []);

  useEffect(() => {
    // basic check for API data reliability 
    if (apiData?.score > 0 && apiData?.status === "success") {

      const score1 = apiData?.chart1Values?.find(val => val.name === apiData?.selected1Answer)?.y;
      const score2 = apiData?.chart2Values?.find(val => val.name === apiData?.selected2Answer)?.y;
      const score3 = apiData?.chart3Values?.find(val => val.name === apiData?.selected3Answer)?.y;
      const score4 = apiData?.chart4Values?.find(val => val.name === apiData?.selected4Answer)?.y;
      const score5 = apiData?.chart5Values?.find(val => val.name === apiData?.selected5Answer)?.y;

      setChartsData([
        {
          title: "The community around us is aware of our mission.",
          order: 1, values: apiData?.chart1Values, answer: { name: apiData?.selected1Answer, y: score1 }
        },
        {
          title: "How many engagement opportunities did you host in the past year open to the larger community?",
          order: 2, values: apiData?.chart2Values, answer: { name: apiData?.selected2Answer, y: score2 }
        },
        {
          title: "Throughout last year, how many community members participated in these engagement opportunities?",
          order: 3, values: apiData?.chart3Values, answer: { name: apiData?.selected3Answer, y: score3 }
        },
        {
          title: "Over time, our church's engagement with our community has been...",
          order: 4, values: apiData?.chart4Values, answer: { name: apiData?.selected4Answer, y: score4 }
        },
        {
          title: "Our community frequently utilizes our church's building facilities and programs.",
          order: 5, values: apiData?.chart5Values, answer: { name: apiData?.selected5Answer, y: score5 }
        },
      ]);
    }
  }, [apiData]);

  useEffect(() => {
    if (windowWidth > 830) {
      setLargeScreen(true);
    }
    else {
      setLargeScreen(false);
    }
  }, [windowWidth]);

  console.log("chartsData", chartsData);

  // to animate subScores on results page 
  useEffect(() => {
    // this settimeout is causing donutcharts selected pie enlargement to disappear so commented it out 
    // const timer = setTimeout(() => {
    //   setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);
    // }, 250);
    // return () => {
    //   clearTimeout(timer);
    // };

    setSubScores([subScore1, subScore2, subScore3, subScore4, subScore5]);

  }, [subScore1, subScore2, subScore3, subScore4, subScore5]);

  const fetchResults = () => {
    // fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + id, {
    fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      // fetch(process.env.REACT_APP_API_URI + "/v3/user/assessment/" + id + "/results", {
      method: "GET",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === "success") {
        console.log(data);

        setApiData(data);
        setName(data.name);
        setScore(Math.abs(data.variables?.find(variable => variable.variable == 'totalscore')?.score - 100));
        setSections(data.sections);
        setSubScore1(Math.abs(data.variables?.find(variable => variable.variable == 'processtotal')?.score - 100));
        setSubScore2(Math.abs(data.variables?.find(variable => variable.variable == 'compliancetotal')?.score - 100));
        setSubScore3(Math.abs(data.variables?.find(variable => variable.variable == 'stewardtotal')?.score - 100));
        setSubScore4(Math.abs(data.variables?.find(variable => variable.variable == 'techtotal')?.score - 100));
        setSubScore5(data.subScore5);
        setIsMember(data.isMember);
        // console.log(data);

        setIsLoading(false);

      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to fetch results...", err);
    });
  };

  function getQuestions() {
    const questionsPageId = 835;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + questionsPageId, {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        console.log(data);
        let sortedQuestions = data.data?.questions?.sort((a, b) => a.orderNo - b.orderNo);
        sortedQuestions?.length > 0 && setQuestions(sortedQuestions);
        setNewSections(data.data.sections);
      } else {
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Error while fetching questions...", err));
    // }
  }

  // const showTitleText = () => {
  //   if (score > 0 && score <= 50) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Sub-optimal</h1>
  //   } else if (score > 50 && score <= 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Adequate</h1>
  //   } else if (score > 80) {
  //     return <h1><span className="user-name">{name}</span>, your ChMS is Perfect</h1>
  //   }
  // };

  const sectionsAndSubScores = sections?.map((sec, idx) => ({ section: sec.section, score: subScores[idx] }));
  // remove the last Feedback section from Results and Dashboard
  // sectionsAndSubScores.pop();
  console.log("sectionsAndSubScores", sectionsAndSubScores);

  const sortedSectionsAndSubScores = [...sectionsAndSubScores]?.sort((a, b) => a.score - b.score);
  console.log("sortedSectionsAndSubScores", sortedSectionsAndSubScores);

  // get the object with the highest score
  let highestScoreObject = sortedSectionsAndSubScores?.[(sortedSectionsAndSubScores?.length) - 1];

  // if there are duplicate highest scores, find the first occurrence
  if (highestScoreObject?.score === sortedSectionsAndSubScores[(sortedSectionsAndSubScores?.length) - 2]?.score) {
    let currentIndex = sortedSectionsAndSubScores?.length - 2;
    while (currentIndex >= 0 && sortedSectionsAndSubScores?.[currentIndex]?.score === highestScoreObject.score) {
      highestScoreObject = sortedSectionsAndSubScores[currentIndex];
      currentIndex--;
    }
  }

  // console.log("sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]", sortedSectionsAndSubScores[sortedSectionsAndSubScores?.length - 1]);
  // console.log("highestScoreObject", highestScoreObject);
  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }
  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  const showTitleText = () => {
    if (score >= 0 && score <= 50) {
      return <h1>{name}, it seems like there's room to improve your church's financial discipline.</h1>
    } else if (score > 50 && score <= 80) {
      return <h1>{name}, it seems like your church is ready for greater financial discipline.</h1>
    } else if (score > 80) {
      return <h1>{name}, it seems like your church demonstrates strong financial discipline.</h1>
    }
  };

  const showScoreText = () => {
    const section = sectionsText?.[sortedSectionsAndSubScores[0]?.section?.toLowerCase()];
    const allScoresSame = sortedSectionsAndSubScores?.every(obj => obj.score === sortedSectionsAndSubScores?.[0]?.score);

    // if (allScoresSame) {
    //   return <div className="result-text">
    //     <p style={{ margin: "10px auto 30px" }}>We're glad to see you're maintaining a balanced focus across all fronts of your community engagement strategy.</p>
    //     <p style={{ margin: "10px auto 5px" }}>Here are some quick tips to elevate engagement through all areas and maximize your Kingdom impact:</p>
    //     <div style={{ margin: "5px auto 10px 10px" }}>{sectionsText?.sameScores?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    //   </div>
    // } else return <div className="result-text">
    //   <p style={{ margin: "10px auto 30px" }}>Keep up the good work on the <span style={{ fontWeight: "700" }}>{highestScoreObject?.section?.toLowerCase()}</span> front and pay special attention to <span style={{ fontWeight: "700" }}>{sortedSectionsAndSubScores[0]?.section?.toLowerCase()}</span>.</p>
    //   <p style={{ margin: "10px auto 5px" }}>Here are some quick tips:</p>
    //   <div style={{ margin: "5px auto 10px 10px" }}>{section?.map((text, idx) => <p style={{ margin: "2px auto" }}>{idx + 1}.&nbsp;&nbsp;{text}</p>)}</div>
    // </div>
    if (score > 0 && score <= 50) {
      return <div className="result-text">
        <p className="pre-score-header"><span>{name}</span>, your overall score is <b>{Math.round(score)}%</b>.</p>
        <p>Your financial discipline needs immediate attention.</p>
      </div>
    } else if (score > 50 && score <= 80) {
      return <div className="result-text">
        <p className="pre-score-header"><span>{name}</span>, your overall score is <b>{Math.round(score)}%</b>.</p>
        <p>You are ready for stronger financial discipline.</p>
      </div>
    } else if (score > 80) {
      return <div className="result-text">
        <p className="pre-score-header"><span>{name}</span>, your overall score is <b>{Math.round(score)}%</b>.</p>
        <p>You seem to have strong financial discipline.</p>
      </div>
    }
  };

  const showProgressBarText = (scoreValue) => {
    if (scoreValue >= 95) {
      return "Enjoying health";
    } else if (scoreValue >= 80 && scoreValue < 95) {
      return "Improving health";
    } else if (scoreValue >= 70 && scoreValue < 80) {
      return "Pursuing health";
    } else if (scoreValue < 70) {
      return "Battling unhealthiness";
    }
  }

  const showDashboard = () => {
    return (
      <>
        <div className="dashboard-heading">
          <div className="line"></div>
          <h1>SAMPLE DASHBOARD</h1>
        </div>
        {/* <h1>SAMPLE DASHBOARD</h1> */}
        <div className="individual-group">
          <button onClick={() => setCurrentView(0)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
          <button onClick={() => setCurrentView(1)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
        </div>
        <div className="toggle-individual-group">
          <div className={currentView == 0 ? "active left" : "left"}>TEAM</div>
          <Switch
            checked={!toggleIndividual}
            onChange={toggleIndividualChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          <div className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</div>
        </div>
        <div className="results-dashboard-container">
          <img src={currentView === 0 ? (largeScreen ? TeamDashboard : TeamDashboardMobile) : (largeScreen ? IndividualDashboard : IndividualDashboardMobile)} alt="" />
        </div>
      </>
    )
  }

  const showInvite = () => {
    // if (isMember) return null;
    // else if (props?.showInvite === false) return null;
    // else return (<InviteInfo />)
    if (showInvitePageOnResultsPage && !showInviteSuccessOnResultsPage) {
      return <BookkeepingInvite
        setShowInviteSuccessOnResultsPage={setShowInviteSuccessOnResultsPage}
        setShowInvitePageOnResultsPage={setShowInvitePageOnResultsPage}
      />
    } else if (showInviteSuccessOnResultsPage && !showInvitePageOnResultsPage) {
      return <BookkeepingInviteThankYou
        setShowInviteSuccessOnResultsPage={setShowInviteSuccessOnResultsPage}
        setShowInvitePageOnResultsPage={setShowInvitePageOnResultsPage}
      />
    } else {
      return <BookkeepingInviteInfo
        setShowInvitePageOnResultsPage={setShowInvitePageOnResultsPage}
        setShowInviteSuccessOnResultsPage={setShowInviteSuccessOnResultsPage}
      />
    }
  };

  const showTeamMember = () => {
    if (isMember) {
      return (
        <div className="team-container">
          <h3>Thank you for taking this assessment!</h3>
          Please get in touch with your leader for the next step.
          <br />
          <br />
        </div>
      );
    } else {
      return null;
    }
  };

  const showTestimonial = () => {
    if (!isMember) {
      return <BookkeepingTestimonial />;
    } else {
      // return null;
      return <BookkeepingTestimonial />;
    }
  };

  const showCallInfo = () => {
    if (!isMember) {
      return <BookkeepingCallInfo />;
    }
  }

  const getLeftMargin = () => {
    if (windowWidth <= 830) {
      if (score > 96 && score < 100) {
        return { left: 96.5 + "%" };
      } else if (score >= 100) {
        return { left: 97.5 + "%" };
      } else if (score > 0 && score < 3) {
        return { left: 2.5 + "%" };
      } else if (score <= 0) {
        return { left: 1.5 + "%" };
      } else {
        return { left: Math.round(score) + "%" };
      }
    } else {
      return { left: Math.round(score) + "%" };
    }
  };

  const getBarTextLeft = (score) => {
    if (window.innerWidth < 650 && score > 80) {
      return { left: "-170px" }
    }
    return { left: "-100px" }
  }

  function getQuestionNum(queOrderNo) {
    let qNum = queOrderNo;
    switch (qNum) {
      case 24: qNum = 23; break;
      case 25: qNum = 23; break;
      case 26: qNum = 24; break;
      case 27: qNum = 25; break;
      default: break;
    }
    return qNum;
  }

  const showSchedule = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="schedule-container">
          <div className="avatar">
            <img src={scheduledImage} />
          </div>
          <h3>Schedule a call with Kim</h3>
          <p>Ready to optimize your board for fundraising or craft and execute an effective annual giving or capital campaign focused on Biblical generosity?</p>
          <p>Talk with Kim to learn how your Christian school's fundraising can become truly transformative.</p>
          <div className="cta-btn">
            <a href="https://meetings.hubspot.com/kim-jennings" target="_blank">
              <Button>TALK TO KIM</Button>
            </a>
          </div>
        </div>
      );
    }
  };

  const showLegacyInvite = () => {
    if (isMember) {
      return null;
    } else {
      return (
        <div className="invite-legacy-container">
          <div className="invite-legacy">
            <h1>Here are your next steps:</h1>
            <div className="steps">
              <ol>
                <li>Invite your leadership team to take this test.</li>
                <li>Unlock your team's individual responses and team scores.*</li>
              </ol>
            </div>
            {/* <p>Get your team to take this test and see if they think your church is guest ready.</p> */}
            <div className="cta-btn">
              <Link to={"/invite-team/" + id} target="_blank">
                <Button>Invite My Team</Button>
              </Link>
            </div>
            <p>*Scroll down to see what your dashboard may look like.</p>
          </div>
        </div>
      );
    }
  };

  const newSectionsAndSubScores = sectionsAndSubScores?.filter((item) => item?.section?.toLowerCase() !== "systems");
  const newSortedSectionsAndSubScores = sortedSectionsAndSubScores?.filter((item) => item?.section?.toLowerCase() !== "systems");

  if (isLoading) {
    return (
      <div className="container results loading">
        <CircularProgress style={{ color: '#FFFFFF' }} size={60} />
      </div>
    );
  }

  return (
    <>
      <div className="container results">
        <div className="details-container">
          <div className="first-section">

            {showTitleText()}
            <div className="progress-bar">
              <div className="si_ass_result">
                <div className="si_ass_progress">
                  <div className="transparent" style={{ width: `${100 - (Math.round(score))}%` }}></div>
                  {/* <div className="progress-line" style={{ left: Math.round(score) + '%' }}>
                    <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div>
                </div> */}
                  <span className="msi_score" style={getLeftMargin()}>{Math.round(score)}%</span>
                  <div className="score-arrow" style={{ left: Math.round(score) + '%' }}></div>
                </div>
                <div className="pervalue">
                  <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                  <h4 className="nimp" style={{ minWidth: '50%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '30%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '20%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                  {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                  {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                </div>

                <div className="si_pro_header">
                  <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                  <h4 className="mexpe" style={{ minWidth: '50%' }}></h4>
                  <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                  {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                  {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
                </div>

                {/* <div className="pervalue-text"> */}
                {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                {/* </div> */}

                <div className="pervalue-text">
                  <p className="not-aligned" style={{ width: "50%",color: "#ff0000", fontWeight: score <= 50 ? 700 : 400 }}>Code Red</p>
                  {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                  <p className="some-aligned" style={{ width: "30%",color: "#ff9900", fontWeight: score > 50 && score <= 80 ? 700 : 400 }}>High Alert</p>
                  {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                  <p className="high-aligned" style={{ width: "20%",color: "#6aa84f", fontWeight: score > 80 ? 700 : 400 }}>Stay Vigilant</p>
                </div>

              </div>
            </div>
            {/* <div className="spacer"></div>
          <div className="spacer"></div> */}

            {showScoreText()}
            <div className="scroll-down">
              <p> Scroll down to learn more about your scores</p>
              <img src={downArrowImg} alt="downward arrow scroll down" />
            </div>
          </div>

          {/* <div className="score-section-container">

            {sectionsAndSubScores?.map(sec => {
              return (
                <div key={sec.section} className="score-section">
                  <p className="score-name">{sec.section}</p>
                  <p className="score-txt" style={{
                    // marginLeft: `calc(${parseInt(sec.score)}% - 3ch)` 
                    }}>{Math.round(sec.score)}%</p>
                    <div className="score-slider"><LinearProgress className="custom-bar" variant="determinate" value={Math.round(sec?.score)} />
                    </div>
                    </div>)
                    })}
                    </div> */}

          <div className="second-section">
            <h2 className="circular-progress-bar-header">Below are your scores across critical financial metrics.<br/>Click each circle to see where your practices need strengthening.</h2>
            <BookkeepingClickableCircularProgressBars sectionsAndSubScores={newSectionsAndSubScores} sortedSectionsAndSubScores={newSortedSectionsAndSubScores} showTextBox={true} />
            {/* <BookkeepingClickableCircularProgressBars sectionsAndSubScores={sectionsAndSubScores} sortedSectionsAndSubScores={sortedSectionsAndSubScores} showTextBox={true} /> */}
            <p className="footnote">*Questions in the 'Systems' category are qualitative in nature and do not significantly impact your scorecard.</p>
          </div>

          <div className="logo-image">
            <img src={acstGrayLogoImg} alt="logo" />
          </div>

          <div className="third-section">
            {/* <h2 className="scores-header">How did you fare in comparison?</h2>
            <p className="scores-subheader">Here's a quick recap of your responses and how they compare to similar-sized churches.</p> */}
            <h2 className="scores-header">How did you fare?</h2>
            <p className="scores-subheader">Here’s a quick recap of your responses and that of similarly-sized churches.</p>

            <div className="charts-accordions-container">
              {/* {console.log("newSections: ",newSections)} */}
              {newSections?.map((section, idx) => {
                return (<Accordion defaultExpanded={idx === 0 ? true : false}>
                  <AccordionSummary expandIcon={<CustomExpandIcon />}>
                    <p>{section.section}</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* <BookkeepingDonutChartAccordion answer={chart.answer} data={chart.values} order={chart.order} dashboard={false} /> */}
                    {console.log("questions: ",questions)}
                    {
                      questions?.map((question, index) => {
                        if (question.sectionId === section.id) {
                          const answer = apiData?.answers?.find(ans => ans.questionId === question.id)?.answer || "-";
                          const answersTxt =  apiData?.answers?.find(ans => ans.questionId === question.id)?.answerTxt || "-"
                          // Q22: which bookkeeping software are you using?                          
                          const q22Ans = apiData?.answers?.find(ans => ans.questionId == 1476); // answer can be 1 or 5
                          let answerText = question.answers?.find((ans) => ans.value === answer)?.desktopText;
                          if (question.id == 1476 && q22Ans?.answer == 5 && q22Ans?.answerTxt?.trim()?.length > 0) {
                            answerText = q22Ans?.answerTxt?.trim();
                          }

                          if ( question.orderNo === 8 && question.answer === 7 ) {
                            answerText = "Not applicable"
                          }

                          answerText = answerText?.replaceAll("<br/>", " ");

                          const currentStat = questions_statistics?.[index];
                          let imgStyles = currentStat.image?.desktop;
                          if (windowWidth <= 650) {
                            imgStyles = currentStat.image?.mobile;
                          }
                          
                          // to show text on not applicable
                          if(answersTxt == 'not applicable'){
                            answerText = answersTxt 
                          }
                          // console.log("Q:", question.orderNo, " = ", question.question, "\nA:", answer, "&", answerText);

                          // commented this as need to show not applicable one also.
                          // if (!answerText) return null;

                          return (
                            <div className="question-info">
                              <div className="question-title">{getQuestionNum(question.orderNo)}. {question.question}</div>
                              <div className="answer-details">
                                <div className="your-answer">Your Answer: <span>{answerText || "-"}</span></div>
                                <div className="chart">
                                  {currentStat.image.src && <img src={currentStat.image.src} alt="" style={imgStyles} />}
                                  {(questions_statistics[index]).desc && <div className="answer-brief-detail">{(questions_statistics[index]).desc}</div>}
                                </div>
                              </div>
                              {questions[index + 1] && questions[index + 1].sectionId === section.id && <div className="border"></div>}
                            </div>
                          );
                        }
                      })
                    }
                  </AccordionDetails>
                </Accordion>);
              })}
            </div>
          </div>

          <div className="logo-image">
            <img src={acstGrayLogoImg} alt="logo" />
          </div>

        </div>
      </div>

      {/* TODO: Uncomment Email section when it is required */}
      {/* <div className="email-section-container">
        <div className="left">
          <div className="img-text">
            <img src={infosecRadarImg} alt="" />
            <p>Endpoint Detection & Response (EDR)</p>
          </div>
          <div className="img-text">
            <img src={infosecRadiologyImg} alt="" />
            <p>Vulnerability Scans</p>
          </div>
          <div className="img-text">
            <img src={infosecPhishingImg} alt="" />
            <p>Phishing as a Service (PhaaS)</p>
          </div>
        </div>
        <div className="right">
          <div className="email-img">
            <img src={infosecEmailImg} className="laptop-img" alt="laptop showing video" />
            <h2>Get our free 3-part video series to start combating cyber attacks today</h2>
            <Button className="email-btn" onClick={() => { }}>Yes, Email Me</Button>
          </div>
        </div>
      </div> */}

      {/* {showTeamMember()} */}

      {/* {showLegacyInvite()} */}

      {/* {!isMember && showDashboard()} */}

      {/* {showInvite()} */}

      {showTestimonial()}

      {/* {showCallInfo()} */}

      {/* <Testimonial /> */}


      {/* <div className="testimonial-container">

        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Doing this assessment as a team gave us better insights into our level of alignment with our school board. It also helped us put a plan in place to make our meetings more productive, so that we continue to align on the board's objectives together.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} />
          </div>

          <div className="designation">
            Sam Davis<br />
            <span>Lead Pastor, Springs of Life Church</span>
          </div>

        </div>

      </div> */}

      {/* {showSchedule()} */}

      {/* {showLegacyInvite()} */}

      {/* </div> */}

      <div className="banner-ad-container">
        <div className="banner-ad">
          <div className="text">
            {
              windowWidth <= 830 ? <img src={acstBannerLogoMobileImg} alt="ACST Logo" className="top-logo-mobile" />
                :
                <img src={acstBannerLogoImg} alt="ACST Logo" className="top-logo" />
            }
            <h3>Explore our suite of free missional indicators today</h3>
            <p>Take our free 3-minute assessments to benchmark yourself against the industry's best practices & become <b>#strongertogether</b></p>
            <a href="/" target="_blank" rel="noreferrer">EXPLORE NOW</a>
          </div>
          <div className="image">
            <img src={acstAssessmentsAdImg} alt="ACST Assessments Collection" />
          </div>
        </div>
      </div>

    </>

  );
};

export default BookkeepingResults;

