import { useContext, useEffect } from "react";
import useWindowDimensions from "../../Hooks/useWindowDimensions";
import { getChartsValues } from "../../utils/chartsCalculations";
import CongregationChartsContext from "../../Store/Congregation/CongregationChartsContext";
// import { questions_statistics } from "../../CongregationTemp";

export const CongregationShowChartIcons = (props) => {

  const { windowWidth } = useWindowDimensions();

  const chartsCtx = useContext(CongregationChartsContext);

  const questionNum = props?.questionNum;
  const currentStat = chartsCtx.questions_statistics?.find(stat => stat.id == questionNum);
  const icons = chartsCtx.questions_statistics?.find(stat => stat.id == questionNum)?.icons;


  const chartsData = getChartsValues(questionNum);

  useEffect(() => {
    getChartsValues(questionNum);
  }, [questionNum]);


  return (
    <div className="chart-icons">
      {icons?.map((icon, index) => {
        let iconStyles = {};
        let iconContainerStyles = currentStat.iconContainerStyles;
        if (windowWidth <= 830) {
          iconStyles = icon.mobile;
        } else {
          iconStyles = icon.desktop;
        }
        // console.log("icon", icon);
        return (
          <div className="chart-icon" key={index} style={iconContainerStyles}>
            {currentStat.position === "right" ?
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={icon.src} alt={icon.alt} style={iconStyles} />
                <p className="chart-icon-value right-side-text" style={icon.value2Styles}><span>X</span> {icon.value2}</p>
                <p className="chart-icon-text">{icon.desc}</p>
              </div>
              :
              <>
                <img src={icon.src} alt={icon.alt} style={iconStyles} />
                <p className="chart-icon-value">{icon.value}</p>
                <p className="chart-icon-text">{icon.desc}</p>
              </>
            }
          </div>
        );
      })}
    </div>
  );

}

