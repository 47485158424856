import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

// import "../App.scss";

const Invite = () => {
  const { windowWidth } = useWindowDimensions();

  let navigate = useNavigate();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // const [list, setList]                     = useState([]);
  const [invitees, setInvitees] = useState([]);
  const [firstRowName, setFirstRowName] = useState("");
  const [firstRowEmail, setFirstRowEmail] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);


  useEffect(() => {
    let invitee = [{
      id: 1,
      name: "",
      email: "",
      showFullName: false,
      showEmail: false
    }]
    setInvitees(invitee);
    // document.body.style.backgroundColor = "#007670";
  }, []);

  const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

  const submitToApi = (member) => {

    const payload = {
      members: member
    }

    fetch(process.env.REACT_APP_API_URI + '/v1/assessment/share/' + id, {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        navigate("/community/invite-team-thanks/" + id + "?" + searchParams, { replace: true });
      } else {
        setIsSaving(false);
        // setOpenSnackbar(true);
      }
    });

  }

  const disableInviteBtn = () => {
    setIsDisabled(false);
    invitees.forEach(invitee => {
      if (!invitee?.name?.length || !invitee?.email?.length) {
        setIsDisabled(true);
      }
    });
  }

  const handleSubmit = () => {
    setIsSaving(true);

    let formHasError = false;
    invitees.forEach(invitee => {
      if (invitee.name == "") {
        invitee.showFullName = true;
        formHasError = true;
      }
      if (!emailRegex.test(invitee.email)) {
        invitee.showEmail = true;
        formHasError = true;
      }
    });

    if (formHasError) {
      setTimeout(() => { setIsSaving(false); }, 500);
    } else {
      submitToApi(invitees);
    }

  }

  const deletePerson = (p) => {
    setInvitees(invitees.filter((person) => {
      return person.id !== p
    }));
  };

  const addPerson = () => {

    let id = 2;
    if (invitees.length > 0) {
      id = invitees[invitees.length - 1].id + 1;
    }

    let person = {
      id: id,
      name: "",
      email: ""
    }
    setInvitees([...invitees, person]);

  };

  const updateName = (e, id) => {
    let currentMember = invitees.find(function (obj) { return obj.id == id; });
    currentMember.name = e.target.value;
    disableInviteBtn();

  }

  const updateEmail = (e, id) => {
    let currentMember = invitees.find(function (obj) { return obj.id == id; });
    currentMember.email = e.target.value;
    disableInviteBtn();

  }

  const showButton = () => {
    if (isSaving) {
      return <div className="button-progress"><CircularProgress style={{ color: '#FFFFFF' }} size={23} /></div>
    }
    return "Send Invite";
  }

  const removeError = (id, field, e) => {

    // change active input field title color 
    if (e.target.closest(".form-detail")) {
      document.body.querySelectorAll(".input-title").forEach(elm => elm.style.color = "#27282A")
      e.target.closest(".form-detail").querySelector(".input-title").style.color = "#151515";
    }

    let newinvitees = [];
    invitees.forEach(invitee => {
      if (field == "fullName" && invitee.id == id) {
        invitee.showFullName = false;
      }
      if (field == "email" && invitee.id == id) {
        invitee.showEmail = false;
      }
      newinvitees.push(invitee);
    });
    setInvitees(newinvitees);
  }


  return (
    <>

      <div className="container invite">
        <div className="details-container">
          <h1>Invite your team to take this assessment</h1>
          <p>Invite your team and see everyone’s responses on one dashboard.</p>
          {/* <p>Their results will populate your dashboard and as the Head of School, you'll see how the entire leadership team of the Board + Head of School aligns individually and collectively.</p> */}

          {
            invitees.map((data, index) => (
              <div className="form _form" key={data.id}>
                <div className="invite-number">{index + 1}.</div>
                <div className="form-detail">
                  <p className="input-title">Full name</p>
                  <TextField hiddenLabel placeholder="E.g.: Simon Peter" onFocus={(e) => { removeError(data.id, "fullName", e) }} onChange={(e) => { updateName(e, data.id) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" />
                  {
                    data.showFullName ?
                      <div className="error-text">Please enter a name.</div>
                      : null
                  }
                </div>
                <div className="form-detail">
                  <p className="input-title">Email</p>
                  <TextField hiddenLabel placeholder="E.g.: speter@petra.church" onFocus={(e) => { removeError(data.id, "email", e) }} onChange={(e) => { updateEmail(e, data.id) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="invite-field" />
                  {
                    data.showEmail ?
                      <div className="error-text">Please enter a valid email address.</div>
                      : null
                  }
                </div>
                {data.id != 1 ?
                  <div className="delete-btn">
                    <div onClick={() => deletePerson(data.id)}>
                      <DeleteIcon className="delete-icon" />
                    </div>
                  </div>
                  : null
                }
              </div>
            ))
          }

          <div className="invite-another-person">
            <div className="invite-another">

              <div className="form_icons--icon">
                <Button onClick={addPerson} className="add-btn">
                  {/* <AddIcon className="invite-another-icon" />ADD&nbsp; */}
                  + Add&nbsp;

                </Button>
              </div>
            </div>
          </div>

          {/* <Button className="cta-btn" disabled={isSaving} onClick={() => handleSubmit()}>{showButton()}</Button> */}

          <div className="buttons">
            {windowWidth <= 830 && <Button onClick={addPerson} className="add-btn" disabled={isDisabled}>
              + Add&nbsp;
            </Button>}
            <Button className="cta-btn" disabled={isSaving} onClick={() => handleSubmit()}>{showButton()}</Button>
          </div>

        </div>
      </div>
    </>
  );

};
export default Invite;