import React, { useEffect, useState } from "react";
import TickImg from "../../../Assets/Images/Bookkeeping/success-tick.svg";
import GraphImg from '../../../Assets/Images/Bookkeeping/graph_Icon.png'
import { Link, useParams, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

const BookkeepingInviteThankYou = (props) => {
  
  const { id } = useParams();
  const location = useLocation();

  const [onResultsPage, setOnResultsPage] = useState(false);

  useEffect(() => {
    // document.body.style.backgroundColor = "#292829";
  }, []);

  useEffect(() => {
    if (location.pathname?.includes("results")) {
      setOnResultsPage(true);
    } else {
      setOnResultsPage(false);
    }
  }, [location, location.pathname]);
  

  return (
    <>
      <div className="container thank-you barlow-bold">
        <div className="invite-success-details-container">
          <div className="tick">
            <img src={TickImg} alt="" />
          </div>
          {/* <div className="tick">&#10003;</div> */}
          <h1>You have successfully sent invites!</h1>
          {/* <p>
            You'll be notified each time one of your team members takes the test
            so that you can intentionally simplify generosity in your church.
          </p> */}
          {/* <p>You'll be notified as your Board Members take the test so you can review aggregate and individual results.</p> */}
          {/* <p>You'll be notified as your team is taking the test so that
				 you can review aggregate results and individual results.</p> */}
          {onResultsPage ? 
            <div className="back-btn">
              <p className="clickable-text back-btn" onClick={() => {
                props.setShowInvitePageOnResultsPage(false);
                props.setShowInviteSuccessOnResultsPage(false);
              }}>INVITE MORE TEAM MEMBERS</p>
            </div>
            :
            <div className="back-btn">
            <Link to={"/bookkeeping/results/" + id}>INVITE MORE TEAM MEMBERS</Link>
          </div>}
        </div>
      </div>

      {/* <div className="schedule-container">
		<img src={GraphImg} alt="" />
        <h4>
          Schedule a call to have our team walk you through your report and see
          how you can accelerate generosity in your church.
        </h4>
        <button className="cta-btn">Schedule a call</button>
      </div> */}
    </>
  );
};

export default BookkeepingInviteThankYou;
