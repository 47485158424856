import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../../../Assets/Images/Community/video-thumbnail.png';
// import VideoThumbnailMobile from '../Assets/Images/video-thumbnail-mobile.png';
// import PlayButton from '../Assets/Images/play-button.png';
import PlayButton from '../../../Assets/Images/Community/play-btn.png';
import Clock from '../../../Assets/Images/Community/clock.png';
import { Close} from "@mui/icons-material";
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
// import '../App.scss';
import { Button } from '@mui/material';

const CommunityStart = props => {

  const { windowWidth }                   = useWindowDimensions();

  const [isLoading, setIsLoading]         = useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  const [playingVideo, setPlayingVideo]   = useState(false);
  const [startPlaying, setStartPlaying]   = useState(false);
  
  const vidRef      = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);
  
  const display = searchParams.get("display");
  const navigate = useNavigate();

  

  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  const createAssessment = () => {

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if(searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_COMMUNITY_CLIENT_ID;
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        navigate("/community/intro/" + data.id + "?" + searchParams, { replace: true });
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to create new assessment...", err));
  }


  const howItWorksLink = () => {
    if(searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentLink = () => {
    return "/assessment?" + searchParams;
  }

  const preAssessmentQuestionsLink = () => {
    navigate("/pre-additional-questions?" + searchParams);
  }
  
  const introPageLink = () => {
    createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const playVideo = (e) => {
    
    if(windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden;"
      videoElm.style.cssText = "width: 100%; margin: 0 auto; background-color: #E5E5E5; border-radius: 0;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if(!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();
    
    if(windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 46%; padding: 0px 3%; overflow: hidden;";
      videoElm.style.cssText = "width: 48%; margin: 0 auto; background-color: #435B63; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);

    if(windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 46%; padding: 0px 3%; overflow: hidden;";
      videoElm.style.cssText = "width: 48%; margin: 0 auto; background-color: #435B63; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";

      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if(playingVideo) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} />;
    } else {
      if(startPlaying) {
        return null
      } else {
        return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if(playingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
    }
  }


  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/Guest-Ready-Intro-Video.mp4";

  return (
    <div className="container start">
      {/* <div className="video" ref={videoElmRef}>
        <video ref={vidRef} onEnded={handleVideoPlaybackEnd}>
          <source src={vidsrcURL} type="video/mp4" />
        </video>
        {showThumbnail()}
        {showPlayButton()}
        
        {playingVideo && <Close className="close-video" style={{ position: "absolute", height: "20px", width: "20px", color: "#fefefebb", opacity: 0.75, zIndex: 99, padding: "15px", top: "5px", cursor: "pointer", 
        right: windowWidth > 1100 ? "70px" : "5px" }} onClick={(e) => pauseVideo(e)} />}
        
      </div> */}
      <div className="intro" ref={introElmRef}>
        <h1>Is your outreach strategy engaging <br></br> your community effectively?</h1>
        <p className='intro-para'>See how you compare* with similarly-sized churches</p>
        {/* <div className="completion">See how you compare* with similarly-sized churches</div> */}
        <ol className="intro-points-list">
          {searchParams.get("leader") ? <>
            <li>Take this 3-minute benchmark assessment</li>
            <li>View your results instantly</li>
            <li>Align with your team to elevate engagement</li>
          </> : <>
            <li>Take this 3-minute benchmark assessment</li>
            <li>Invite your team to take it too after you do</li>
            <li>View everyone's results on one dashboard</li>
            <li>Align on a strategy to elevate engagement</li>
          </>}
        </ol>

        <div className="completion">
          {/* <div className='btn-container'> */}
          {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
          <Button className="cta" onClick={createAssessment}>Get Started</Button>
          {/* </div> */}
          <div className='time-estimate'>
            <div className='img-container'><img className='clock-icon' src={Clock} alt="estimated time" /></div><span style={{fontWeight:'normal'}}>{searchParams.get("leader") ? "3 min" : "3 min"}</span>
          </div>
        </div>

  
        <div className='para-container'>
          <p className='para'>*We can help you compare your church's data to that of about 10,000 churches
          in the US through our church engagement research.</p>
        </div>
      </div>

    </div>
  );
}

export default CommunityStart;
