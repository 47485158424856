import { useState, useEffect, createContext } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";

const InfosecMiscContext = createContext();

export function InfosecMiscContextProvider(props) {

  const location = useLocation();

  const [showInviteSection, setShowInviteSection] = useState(false);
  const [isInviteSuccessful, setIsInviteSuccessful] = useState(false);
  const [showInviteSuccessSection, setShowInviteSuccessSection] = useState(false);
  const [selectedSection, setSelectedSection] = useState("");


  const context = {
    showInviteSection: showInviteSection,
    setShowInviteSection: setShowInviteSection,
    isInviteSuccessful: isInviteSuccessful,
    setIsInviteSuccessful: setIsInviteSuccessful,
    showInviteSuccessSection: showInviteSuccessSection,
    setShowInviteSuccessSection: setShowInviteSuccessSection,
    selectedSection: selectedSection,
    setSelectedSection: setSelectedSection
  };

  return <InfosecMiscContext.Provider value={context}>
    {props.children}
  </InfosecMiscContext.Provider>
}

export default InfosecMiscContext;

