import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { StylesSelector } from './Components/Layout/StylesSelector';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

const root = document.getElementById('root');
const rootContainer = createRoot(root);
// rootContainer.render(<StylesSelector> <App /> </StylesSelector>);
rootContainer.render(
  // Note: added StylesSelector inside Router (which was outside earlier) after updating getStyleBasedOnPath in StylesSelector to use useLocation instead of window.location. Since useLocation can only be used inside Router.
  <Router>
    <StylesSelector>
      <App />
    </StylesSelector>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

