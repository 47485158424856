import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";

// import VideoThumbnail from '../../../Assets/Images/Infosec/video-thumbnail.png';
import VideoThumbnail from '../../../Assets/Images/Infosec/thumbnail.png';
// import VideoThumbnailMobile from '../../Assets/Images/Infosec/video-thumbnail-mobile.png';
// import PlayButton from '../../Assets/Images/Infosec/play-button.png';
import PlayButton from '../../../Assets/Images/Infosec/play-btn.png';
import PlayButtonHover from '../../../Assets/Images/Infosec/play-btn-hover.png';
import startBgImg from '../../../Assets/Images/Infosec/infosec-start-bg.png';
// import clockImg from '../../../Assets/Images/Infosec/clock.png';
import clockImg from '../../../Assets/Images/Infosec/clock-white.png';
import { Close } from "@mui/icons-material";
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { Button, CircularProgress } from '@mui/material';

import ExplainerPolicy from '../../../Assets/Images/Infosec/explainer-policy.svg'
import ExplainerAsset from '../../../Assets/Images/Infosec/explainer-asset.svg'
import ExplainerAccess from '../../../Assets/Images/Infosec/explainer-access.svg'
import ExplainerNetwork from '../../../Assets/Images/Infosec/explainer-network.svg'
import ExplainerSurveillance from '../../../Assets/Images/Infosec/explainer-surveillance.svg'

// InfosecStartV2 => /about-infosec
// InfosecStartV3 => /explainer-infosec/:id
// InfosecStartV2 is for users coming from external links
// check InfosecStartV3 for normal users who start assessment from infosec homepage/landing page
const InfosecStartV2 = props => {
  
  const { windowWidth } = useWindowDimensions();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [playingVideo, setPlayingVideo] = useState(false);
  const [startPlaying, setStartPlaying] = useState(false);

  const vidRef = useRef(null);
  const introElmRef = useRef(null);
  const videoElmRef = useRef(null);

  const display = searchParams.get("display");
  const navigate = useNavigate();



  // useEffect(() => {
  //   if(display === "preview") {
  //     console.log("changed background img for embedded preview display...");
  //     document.body.style.background = `#242424 url(${introBgImg}) repeat`;
  //   } else {
  //     document.body.style.background = `#414042`;
  //   }
  // }, [display]);

  useEffect(() => {
    document.body.style.background = `url(${startBgImg})`;
    localStorage.setItem("external-user", "true");
  }, []);

  const submitCategoryToApi = async (assessmentHash) => {

    const pageId = 845;
    let apiCall = `/v3/user/assessment/${assessmentHash}/form/${pageId}`;
    const orgType = "Church"; // this page is only for Churches so orgType will always be "Church"

    let jsonBody = JSON.stringify({
      details: { "organizationType": orgType?.trim() },
      noAutomation: true,
      // formVersion: 2
    });
    console.log(jsonBody);

    const response = await fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    })

    if (!response.ok) {
      throw new Error('Failed to execute second API POST request');
    }
    
    // if (response.status === "success") {
    //   navigate("/infosec/assessment/" + assessmentHash + "/814?" + searchParams, { replace: true });
    // }

    return response.json();

    
    // .then(response => {
    //   return response.json();
    // }).then(data => {
    //   if (data.status === "success") {
    //     navigate("/infosec/assessment/" + assessmentHash + "/814?" + searchParams, { replace: true });
    //   } else {
    //     console.error("Failed to submit selected category...", data);
    //   }
    // }).catch((err) => {
    //   console.error("Failed to submit selected category...", err);
    // });

  }

  const createAssessment = async () => {

    let leaderId = 0;
    let userId = 0;
    let isMember = false;
    if (searchParams?.get("leader")) {
      leaderId = searchParams?.get("leader");
      userId = searchParams?.get("user");
      isMember = true;
    }

    const clientId = process.env.REACT_APP_INFOSEC_CLIENT_ID;
    const response = await fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment', {
      method: 'POST', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ clientId, isMember, leaderId, userId })
    })
    
    if (!response.ok) {
      throw new Error("Failed to execute createAssessment API POST request");
    }

    return response.json();
    
    // .then(response => {
    //   return response.json();
    // }).then(data => {
    //   if (data.status === "success") {
    //     navigate("/infosec/before-you-start/" + data.id + "/?" + searchParams, { replace: true });
    //     console.log("data", data);
    //   } else {
    //     console.log("Failed to create new assessment...", data);
    //   }
    // }).catch((err) => console.error("Failed to create new assessment...", err));
  }

  const handleStartAssessment = async () => {

    // we need to make 2 API calls for this one
    // first to create new assessment 
    // second to update organization type using assessment ID from first API call
    
    setIsLoading(true);
    setError(null);

    try {
      // first API call to create assessment and get assessment ID
      const firstResponse = await createAssessment();
      const assessmentId = firstResponse.id;

      // second API call with assessment ID from the first response
      const secondResponse = await submitCategoryToApi(assessmentId);

      console.log("Second API response:", secondResponse);
      // navigate to another page after successful second API call
      navigate("/infosec/assessment/" + assessmentId + "/814?" + searchParams, { replace: true });
    } catch (error) {
      setError(error.message);
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }


  const howItWorksLink = () => {
    if (searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }
  // console.log(searchParams.get("display"));

  const assessmentPageLink = () => {
    // navigate("/infosec/assessment/" + id + "/814?" + searchParams, { replace: true });
  }

  const preAssessmentQuestionsLink = () => {
    navigate("/pre-additional-questions?" + searchParams);
  }

  const introPageLink = () => {
    createAssessment();
    // navigate("/intro/" + id + "?" + searchParams);
  }

  const playVideo = (e) => {

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 0%; padding: 0; overflow: hidden;"
      videoElm.style.cssText = "width: 100%; margin: 0 auto; background-color: #0E435C; border-radius: 0;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    vidRef.current.play();
    setPlayingVideo(true);
    if (!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    // vidRef.current.pause();
    // setPlayingVideo(false);
    vidRef.current.pause();

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 50%; padding: 20px 5%; overflow: hidden;";
      videoElm.style.cssText = "width: 50%; margin: 0 auto; background-color: #0E435C; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";
      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    setPlayingVideo(false);
  }

  const handleVideoPlaybackEnd = () => {
    setPlayingVideo(false);

    if (windowWidth > 830) {
      const introElm = introElmRef.current;
      const videoElm = videoElmRef.current;

      introElm.style.cssText = "width: 50%; padding: 20px 5%; overflow: hidden;";
      videoElm.style.cssText = "width: 50%; margin: 0 auto; background-color: #0E435C; border-top-left-radius: 10px; border-bottom-left-radius: 10px;";

      // if(display === "preview") {
      //   videoElm.style.background = `#242424 url(${introBgImg}) repeat`;
      // } else videoElm.style.backgroundColor = "#435B63";
    }
    showThumbnail();
    showPlayButton();
  }

  const showThumbnail = () => {
    if (playingVideo) {
      return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} />;
    } else {
      if (startPlaying) {
        return null
      } else {
        return <img alt="video thumbnail" className="thumbnail" src={VideoThumbnail} />
      }
    }
  }

  const showPlayButton = () => {
    if (playingVideo) {
      return null
    } else {
      return <img alt="play button" className="playbtn" src={PlayButton} onMouseOver={e => (e.currentTarget.src = PlayButtonHover)} onMouseOut={e => (e.currentTarget.src = PlayButton)} onClick={(e) => playVideo(e)} />
    }
  }


  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/Guest-Ready-Intro-Video.mp4";

  return (
    <div className="start-explainer-container">
      <div className="start-explainer">
      <h1 className="heading">Evaluate the maturity of your information security through <span className='text-orange'>26 questions</span> spanning <span className='text-orange'>5 security metrics</span>.</h1>
        <ul>
          <li>
            <img src={ExplainerPolicy} alt="" />
            <div className="text-content">
              <h3>POLICY COMPLIANCE</h3>
              <p>Security policies and guidelines</p>
            </div>
          </li>
          <li>
            <img src={ExplainerAsset} alt="" />
            <div className="text-content">
              <h3>ASSET MANAGEMENT</h3>
              <p>Asset care throughout the lifecycle</p>
            </div>
          </li>
          <li>
            <img src={ExplainerAccess} alt="" />
            <div className="text-content">
              <h3>ACCESS CONTROL</h3>
              <p>Systems and resources access regulation</p>
            </div>
          </li>
          <li>
            <img src={ExplainerNetwork} alt="" />
            <div className="text-content">
              <h3>NETWORK SECURITY</h3>
              <p>Network infrastructure protection and management</p>
            </div>
          </li>
          <li>
            <img src={ExplainerSurveillance} alt="" />
            <div className="text-content">
              <h3>SURVEILLANCE MEASURES</h3>
              <p>Surveillance protocol implementation and monitoring</p>
            </div>
          </li>
        </ul>
        <div className="completion" style={{ justifyContent: "center" }}>
          {/* <Button className="cta" onClick={createAssessment}>Continue</Button> */}
          <Button className="cta" onClick={handleStartAssessment}>
            {isLoading ? <CircularProgress style={{ color: '#FFFFFF' }} size={23} />
              : "Continue"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InfosecStartV2;
