import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route, IndexRoute, useParams, useLocation } from "react-router-dom";
import TagManager from 'react-gtm-module';
import CircularProgress from '@mui/material/CircularProgress';

import AssessmentHeader from './Components/Layout/Header/AssessmentHeader.jsx';

// Congregation Imports 
import CongregationHeader from './Components/Congregation/Layout/Header/CongregationHeader.jsx';
import CongregationFooter from './Components/Congregation/Layout/Footer/CongregationFooter.jsx';
// import CongregationLeftLogo from './Components/Congregation/Layout/CongregationLeftLogo.jsx';
import HeadTagHelmet from './Components/HeadTagHelmet.js';
import CongregationDonutChart from './Components/Congregation/DonutChart/CongregationDonutChart.jsx';
import CongregationStart from './Pages/Congregation/Start/CongregationStart.jsx';
// import Assessment from './Pages/Congregation/Assessment/Assessment.jsx';
import CongregationAssessmentV3 from './Pages/Congregation/AssessmentV3/CongregationAssessmentV3.jsx';
// import AssessmentAdditional from './Pages/Congregation/AssessmentAdditional/AssessmentAdditional.jsx';
import CongregationAssessmentAdditionalV3 from './Pages/Congregation/AssessmentAdditionalV3/CongregationAssessmentAdditionalV3.jsx';
import CongregationDetails from './Pages/Congregation/Details/CongregationDetails.jsx';
import CongregationResults from './Pages/Congregation/Results/CongregationResults.jsx';
import CongregationDashboard from './Pages/Congregation/Dashboard/CongregationDashboard.jsx';
import CongregationInvite from './Pages/Congregation/Invite/CongregationInvite.jsx';
import CongregationInviteThankYou from './Pages/Congregation/InviteThankYou/CongregationInviteThankYou.jsx';
import CongregationPreAdditionalInfo from './Pages/Congregation/PreAdditionalInfo/CongregationPreAdditionalInfo.jsx';
import CongregationPreAdditionalQuestions from './Pages/Congregation/PreAdditionalQuestions/CongregationPreAdditionalQuestions.jsx';
import CongregationPreAssessment from './Pages/Congregation/PreAssessment/CongregationPreAssessment.jsx';
import CongregationIntro from './Pages/Congregation/Intro/CongregationIntro.jsx';
import CongregationFirstForm from './Pages/Congregation/FirstForm/CongregationFirstForm.jsx';
import CongregationCharts from './Pages/Congregation/Chart/CongregationChart.jsx';
// import { AssessmentProgressContextProvider } from './Store/AssessmentProgressContext';
// import AssessmentProgressSections from './Components/Congregation/AssessmentProgressSections';
import { CongregationPreAdditionalQuestionContextProvider } from './Store/Congregation/CongregationPreAdditionalQuestionContext';
import { CongregationAdditionalQuestionContextProvider } from './Store/Congregation/CongregationAdditionalQuestionContext';
import { CongregationAssessmentQuestionContextProvider } from './Store/Congregation/CongregationAssessmentQuestionContext';
import { CongregationQuestionsProgressContextProvider } from './Store/Congregation/CongregationQuestionsProgressContext';
import { CongregationChartsContextProvider } from './Store/Congregation/CongregationChartsContext.jsx';

// Infosec Higher Ground Imports 
import InfosecHeader from './Components/Infosec/Layout/Header/InfosecHeader.jsx';
import InfosecFooter from './Components/Infosec/Layout/Footer/InfosecFooter.jsx';
// import InfosecLeftLogo from './Components/Infosec/Layout/InfosecLeftLogo.jsx';
import InfosecDonutChart from './Components/Infosec/DonutChart/InfosecDonutChart.jsx';
import InfosecStart from './Pages/Infosec/Start/InfosecStart.jsx';
import InfosecStartV2 from './Pages/Infosec/Start/InfosecStartV2.jsx';
import InfosecStartV3 from './Pages/Infosec/Start/InfosecStartV3.jsx';
import InfosecAssessmentV3 from './Pages/Infosec/AssessmentV3/InfosecAssessmentV3.jsx';
import InfosecDetails from './Pages/Infosec/Details/InfosecDetails.jsx';
import InfosecResults from './Pages/Infosec/Results/InfosecResults.jsx';
import InfosecDashboard from './Pages/Infosec/Dashboard/InfosecDashboard.jsx';
import InfosecInvite from './Pages/Infosec/Invite/InfosecInvite.jsx';
import InfosecInviteThankYou from './Pages/Infosec/InviteThankYou/InfosecInviteThankYou.jsx';
import InfosecPreAdditionalInfo from './Pages/Infosec/PreAdditionalInfo/InfosecPreAdditionalInfo.jsx';
import InfosecPreAdditionalQuestions from './Pages/Infosec/PreAdditionalQuestions/InfosecPreAdditionalQuestions.jsx';
import InfosecPreAssessment from './Pages/Infosec/PreAssessment/InfosecPreAssessment';
import InfosecIntro from './Pages/Infosec/Intro/InfosecIntro.jsx';
import InfosecFirstForm from './Pages/Infosec/FirstForm/InfosecFirstForm.jsx';
import InfosecCharts from './Pages/Infosec/Chart/InfosecChart.jsx';
import InfosecAssessmentAdditionalV3 from './Pages/Infosec/AssessmentAdditionalV3/InfosecAssessmentAdditionalV3.jsx';
import InfosecChurchStart from './Pages/Infosec/Start/InfosecChurchStart.jsx'
// import { AssessmentProgressContextProvider } from './Store/AssessmentProgressContext';
// import AssessmentProgressSections from './Components/AssessmentProgressSections';
import { InfosecMiscContextProvider } from './Store/Infosec/InfosecMiscContext.jsx';
import { InfosecPreAdditionalQuestionContextProvider } from './Store/Infosec/InfosecPreAdditionalQuestionContext';
import { InfosecAdditionalQuestionContextProvider } from './Store/Infosec/InfosecAdditionalQuestionContext';
import { InfosecAssessmentQuestionContextProvider } from './Store/Infosec/InfosecAssessmentQuestionContext';
import { InfosecQuestionsProgressContextProvider } from './Store/Infosec/InfosecQuestionsProgressContext';


// Bookkeeping Imports 

import BookkeepingHeader from './Components/Bookkeeping/Layout/Header/BookkeepingHeader.jsx';
import BookkeepingFooter from './Components/Bookkeeping/Layout/Footer/BookkeepingFooter.jsx';
import BookkeepingLeftLogo from './Components/Bookkeeping/Layout/BookkeepingLeftLogo.jsx';
import BookkeepingDonutChart from './Components/Bookkeeping/DonutChart/BookkeepingDonutChart.jsx';
import BookkeepingStart from './Pages/Bookkeeping/Start/BookkeepingStart.jsx';
import BookkeepingAssessmentV3 from './Pages/Bookkeeping/AssessmentV3/BookkeepingAssessmentV3.jsx';
import BookkeepingAssessmentAdditionalV3 from './Pages/Bookkeeping/AssessmentAdditionalV3/BookkeepingAssessmentAdditionalV3.jsx';
import BookkeepingDetails from './Pages/Bookkeeping/Details/BookkeepingDetails.jsx';
import BookkeepingResults from './Pages/Bookkeeping/Results/BookkeepingResults.jsx';
import BookkeepingDashboard from './Pages/Bookkeeping/Dashboard/BookkeepingDashboard.jsx';
import BookkeepingInvite from './Pages/Bookkeeping/Invite/BookkeepingInvite.jsx';
import BookkeepingInviteThankYou from './Pages/Bookkeeping/InviteThankYou/BookkeepingInviteThankYou.jsx';
import BookkeepingPreAdditionalInfo from './Pages/Bookkeeping/PreAdditionalInfo/BookkeepingPreAdditionalInfo.jsx';
import BookkeepingPreAdditionalQuestions from './Pages/Bookkeeping/PreAdditionalQuestions/BookkeepingPreAdditionalQuestions.jsx';
import BookkeepingPreAssessment from './Pages/Bookkeeping/PreAssessment/BookkeepingPreAssessment.jsx';
import { BookkeepingMiscContextProvider } from './Store/Bookkeeping/BookkeepingMiscContext.jsx';
import { BookkeepingPreAdditionalQuestionContextProvider } from './Store/Bookkeeping/BookkeepingPreAdditionalQuestionContext';
import { BookkeepingAdditionalQuestionContextProvider } from './Store/Bookkeeping/BookkeepingAdditionalQuestionContext';
import { BookkeepingAssessmentQuestionContextProvider } from './Store/Bookkeeping/BookkeepingAssessmentQuestionContext';
import { BookkeepingQuestionsProgressContextProvider } from './Store/Bookkeeping/BookkeepingQuestionsProgressContext';
import BookkeepingIntro from './Pages/Bookkeeping/Intro/BookkeepingIntro.jsx';
import BookkeepingFirstForm from './Pages/Bookkeeping/FirstForm/BookkeepingFirstForm.jsx';
import BookkeepingCharts from './Pages/Bookkeeping/Chart/BookkeepingChart.jsx';
import BookkeepingStartV2 from './Pages/Bookkeeping/Start/BookkeepingStartV2.jsx';
import BookkeepingStartV3 from './Pages/Bookkeeping/Start/BookkeepingStartV3.jsx';
import Footer from './Components/Bookkeeping/Layout/Footer/BookkeepingFooter.jsx';


// Community Engagement Indicator Imports 

import CommunityLeftLogo from './Components/Community/Layout/CommunityLeftLogo.jsx';
import CommunityDonutChart from './Components/Community/CommunityDonutChart.jsx';
import CommunityStart from './Pages/Community/Start/CommunityStart.jsx';
import CommunityAssessmentV3 from './Pages/Community/AssessmentV3/CommunityAssessmentV3.jsx';
import CommunityAssessmentAdditionalV3 from './Pages/Community/AssessmentAdditionalV3/CommunityAssessmentAdditionalV3.jsx';
import CommunityDetails from './Pages/Community/Details/CommunityDetails.jsx';
import CommunityResults from './Pages/Community/Results/CommunityResults.jsx';
import CommunityDashboard from './Pages/Community/Dashboard/CommunityDashboard.jsx';
import CommunityInvite from './Pages/Community/Invite/CommunityInvite.jsx';
import CommunityInviteThankYou from './Pages/Community/InviteThankYou/CommunityInviteThankYou.jsx';
import CommunityPreAdditionalInfo from './Pages/Community/PreAdditionalInfo/CommunityPreAdditionalInfo.jsx';
import CommunityPreAdditionalQuestions from './Pages/Community/PreAdditionalQuestions/CommunityPreAdditionalQuestions.jsx';
import CommunityPreAssessment from './Pages/Community/PreAssessment/CommunityPreAssessment.jsx';
import { CommunityPreAdditionalQuestionContextProvider } from './Store/Community/CommunityPreAdditionalQuestionContext';
import { CommunityAdditionalQuestionContextProvider } from './Store/Community/CommunityAdditionalQuestionContext';
import { CommunityAssessmentQuestionContextProvider } from './Store/Community/CommunityAssessmentQuestionContext';
import { CommunityQuestionsProgressContextProvider } from './Store/Community/CommunityQuestionsProgressContext';
import CommunityIntro from './Pages/Community/Intro/CommunityIntro.jsx';
import CommunityFirstForm from './Pages/Community/FirstForm/CommunityFirstForm.jsx';
import CommunityCharts from './Pages/Community/Chart/CommunityChart.jsx';
import CommunityTest from './Pages/Community/CommunityTest.jsx';

import HomePage from './Pages/OtherPages/HomePage/HomePage.jsx';
import LandingPageCommunity from './Pages/OtherPages/LandingPages/LandingPageCommunity.jsx';
import LandingPageCongregation from './Pages/OtherPages/LandingPages/LandingPageCongregation.jsx';
import LandingPageBookkeeping from './Pages/OtherPages/LandingPages/LandingPageBookkeeping.jsx';
import LandingPageInfosec from './Pages/OtherPages/LandingPages/LandingPageInfosec.jsx';



// ReactGA.initialize('');
// ReactGA.pageview(window.location.pathname + window.location.search);

// TODO: this might need to be 4 different GTM IDs based on pathname/assessment
// const tagManagerArgs = {
//   gtmId: 'GTM-5WM8JV7K'
// }
// TagManager.initialize(tagManagerArgs);

function App() {

  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [resultPage, setResultsPage] = useState(false);

  const location = useLocation();

  useEffect(() => {
    let gtmId = "";
    if (location.pathname.includes("bookkeeping")) {
      gtmId = "GTM-P5Q48LR7";
    } else if (location.pathname.includes("community")) {
      gtmId = "GTM-KL23W77";
    } else if (location.pathname.includes("congregation")) {
      gtmId = "GTM-NHKL6G6S";
    } else if (location.pathname.includes("infosec")) {
      gtmId = "GTM-NP8JVW7J";
    } else {
      gtmId = "GTM-5WM8JV7K";
    }
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, [location, location.pathname]);

  useEffect(() => {
    const url = window.location.pathname;
    console.log("TRUE RESULTS URL", url);
    if (url.split("/").includes("results")) {
      console.log("TRUE RESULTS");
      setResultsPage(true);
    } else {
      console.log("FALSE RESULTS");

      setResultsPage(false);
    }
  }, [])

  useEffect(() => {
    getClient();
  }, []);


  const getClient = () => {
    setIsLoading(false);
  }

  if (isLoading) {
    return <div className="container details loading"><CircularProgress style={{ color: "#ED2028" }} size={60} /></div>
  }

  console.log('location.pathname',location.pathname)

  return (
    <div className={`App ${( location.pathname.includes('bookkeeping') ) && "bookkeeping-app"}`}>
      {
        ( location.pathname.includes('bookkeeping') ) && <div className="bk-filter"></div>
      }
      {/* <AssessmentProgressContextProvider> */}
      {/* <BrowserRouter> Router is now in index.js */} 
        <CongregationChartsContextProvider>
        <CongregationAssessmentQuestionContextProvider>
        <CongregationAdditionalQuestionContextProvider>
        <CongregationPreAdditionalQuestionContextProvider>
        <CongregationQuestionsProgressContextProvider>
        <InfosecMiscContextProvider>
        <InfosecPreAdditionalQuestionContextProvider>
        <InfosecAdditionalQuestionContextProvider>
        <InfosecAssessmentQuestionContextProvider>
        <InfosecQuestionsProgressContextProvider>
        <CommunityAssessmentQuestionContextProvider>
        <CommunityAdditionalQuestionContextProvider>
        <CommunityPreAdditionalQuestionContextProvider>
        <CommunityQuestionsProgressContextProvider>
        <BookkeepingMiscContextProvider>
        <BookkeepingAssessmentQuestionContextProvider>
        <BookkeepingAdditionalQuestionContextProvider>
        <BookkeepingPreAdditionalQuestionContextProvider>
        <BookkeepingQuestionsProgressContextProvider>
          <HeadTagHelmet />
          <AssessmentHeader style={resultPage ? { backgroundColor: "#ffffff" } : { backgroundColor: "#ffffff" }} />
          <main className="main">
            {/* <LeftLogo/> */}
            {/* <CommunityLeftLogo /> */}
            {/* <BookkeepingLeftLogo /> */}
            {/* <AssessmentProgressSections /> */}
            <Routes>
              {/* Home Page */}
              {/* <Route path="/home-page" exact index element={<HomePage setName={setName} />} /> */}
              <Route path="/" exact index element={<HomePage setName={setName} />} />
              <Route path="/:id" exact index element={<HomePage setName={setName} />} />
              
              {/* Landing Pages for all ACST assessments */}
              <Route path="/assessment-community" exact index element={<LandingPageCommunity />} />
              <Route path="/assessment-congregation" exact index element={<LandingPageCongregation />} />
              <Route path="/assessment-bookkeeping" exact index element={<LandingPageBookkeeping />} />
              <Route path="/assessment-infosec" exact index element={<LandingPageInfosec />} />

              {/* Congregation Routes */}
              <Route path="/congregation" exact index element={<CongregationStart setName={setName} />} />
              <Route path="/congregation/start" exact element={<CongregationStart setName={setName} />}></Route>
              <Route path="/congregation/before-you-start/:id" exact element={<CongregationPreAssessment />}></Route>
              <Route path="/congregation/before-you-start" exact element={<CongregationPreAssessment />}></Route>
              {/* <Route path="/congregation/assessment" exact element={<Assessment />}></Route> */}
              <Route path="/congregation/assessment/:id/:pageId" exact element={<CongregationAssessmentV3 />}></Route>
              {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
              {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
              <Route path="/congregation/pre-additional-info/:id" exact element={<CongregationPreAdditionalInfo />}></Route>
              <Route path="/congregation/pre-additional-questions/" exact element={<CongregationPreAdditionalQuestions />}></Route>
              {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
              {/* <Route path="/congregation/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route> */}
              {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
              <Route path="/congregation/assessment-additional/:id/:pageId" exact element={<CongregationAssessmentAdditionalV3 />}></Route>
              <Route path="/congregation/details/:id" exact element={<CongregationDetails />}></Route>
              <Route path="/congregation/results/:id" exact element={<CongregationResults />}></Route>
              <Route path="/congregation/invite-team/:id" exact element={<CongregationInvite />}></Route>
              <Route path="/congregation/invite-team-thanks/:id" exact element={<CongregationInviteThankYou />}></Route>
              <Route path="/congregation/dashboard/:id" exact element={<CongregationDashboard />}></Route>
              <Route path="/congregation/chart/:id/:pageId" exact element={<CongregationDonutChart />}></Route>
              {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
              <Route path="/congregation/intro/:id" exact element={<CongregationIntro />}></Route>
              <Route path="/congregation/intro" exact element={<CongregationIntro />}></Route>
              <Route path="/congregation/first-form/:id" exact element={<CongregationFirstForm />}></Route>
              <Route path="/congregation/first-form" exact element={<CongregationFirstForm />}></Route>
              <Route path="/congregation/charts/:id/:pageId" exact element={<CongregationCharts />}></Route>

              {/* Infosec Higher Ground Routes */}

              <Route path="/infosec" exact index element={<InfosecStart setName={setName} />} />
              <Route path="/infosec-church" exact index element={<InfosecChurchStart setName={setName} />} />
              <Route path="/infosec/start" exact element={<InfosecStart setName={setName} />}></Route>
              {/* users coming from external links will go to /infosec/about-infosec */}
              <Route path="/infosec/about-infosec" exact element={<InfosecStartV2 />}></Route>
              {/* normal users starting from home/landing page will go to /infosec/explainer-infosec/:id */}
              <Route path="/infosec/explainer-infosec/:id" exact element={<InfosecStartV3 />}></Route>
              <Route path="/infosec/before-you-start/:id" exact element={<InfosecPreAssessment />}></Route>
              <Route path="/infosec/before-you-start" exact element={<InfosecPreAssessment />}></Route>
              <Route path="/infosec/assessment/:id/:pageId" exact element={<InfosecAssessmentV3 />}></Route>
              {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
              {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
              <Route path="/infosec/pre-additional-info/:id" exact element={<InfosecPreAdditionalInfo />}></Route>
              <Route path="/infosec/pre-additional-questions/" exact element={<InfosecPreAdditionalQuestions />}></Route>
              {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
              {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
              <Route path="/infosec/assessment-additional/:id/:pageId" exact element={<InfosecAssessmentAdditionalV3 />}></Route>
              <Route path="/infosec/details/:id" exact element={<InfosecDetails />}></Route>
              <Route path="/infosec/results/:id" exact element={<InfosecResults />}></Route>
              <Route path="/infosec/invite-team/:id" exact element={<InfosecInvite />}></Route>
              <Route path="/infosec/invite-team-thanks/:id" exact element={<InfosecInviteThankYou />}></Route>
              {/* <Route path="/infosec/dashboard/:id" exact element={<InfosecDashboard />}></Route> */}
              <Route path="/infosec/chart/:id/:pageId" exact element={<InfosecDonutChart />}></Route>
              {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
              <Route path="/infosec/intro/:id" exact element={<InfosecIntro />}></Route>
              <Route path="/infosec/intro" exact element={<InfosecIntro />}></Route>
              <Route path="/infosec/first-form/:id" exact element={<InfosecFirstForm />}></Route>
              <Route path="/infosec/first-form" exact element={<InfosecFirstForm />}></Route>
              <Route path="/infosec/infosec/charts/:id/:pageId" exact element={<InfosecCharts />}></Route>

              {/* Bookkeeping Routes */}

              <Route path="/bookkeeping" exact index element={<BookkeepingStart />} />
              <Route path="/bookkeeping/start" exact element={<BookkeepingStart />}></Route>
              {/* users coming from external links will go to /infosec/about-infosec */}
              <Route path="/bookkeeping/about-bookkeeping" exact element={<BookkeepingStartV2 />}></Route>
              {/* normal users starting from home/landing page will go to /infosec/explainer-infosec/:id */}
              <Route path="/bookkeeping/explainer-bookkeeping/:id" exact element={<BookkeepingStartV3 />}></Route>
              <Route path="/bookkeeping/before-you-start/:id" exact element={<BookkeepingPreAssessment />}></Route>
              <Route path="/bookkeeping/before-you-start" exact element={<BookkeepingPreAssessment />}></Route>
              <Route path="/bookkeeping/assessment" exact element={<BookkeepingAssessmentV3 />}></Route>
              <Route path="/bookkeeping/assessment/:id/:pageId" exact element={<BookkeepingAssessmentV3 />}></Route>
              {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
              {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
              <Route path="/bookkeeping/pre-additional-info/:id" exact element={<BookkeepingPreAdditionalInfo />}></Route>
              <Route path="/bookkeeping/pre-additional-questions/" exact element={<BookkeepingPreAdditionalQuestions />}></Route>
              {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
              <Route path="/bookkeeping/assessment-additional/:id" exact element={<BookkeepingAssessmentAdditionalV3 />}></Route>
              {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
              <Route path="/bookkeeping/assessment-additional/:id/:pageId" exact element={<BookkeepingAssessmentAdditionalV3 />}></Route>
              <Route path="/bookkeeping/details/:id" exact element={<BookkeepingDetails />}></Route>
              <Route path="/bookkeeping/results/:id" exact element={<BookkeepingResults />}></Route>
              <Route path="/bookkeeping/invite-team/:id" exact element={<BookkeepingInvite />}></Route>
              <Route path="/bookkeeping/invite-team-thanks/:id" exact element={<BookkeepingInviteThankYou />}></Route>
              {/* <Route path="/bookkeeping/dashboard/:id" exact element={<BookkeepingDashboard />}></Route> */}
              <Route path="/chart/:id/:pageId" exact element={<BookkeepingDonutChart />}></Route>
              {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
              <Route path="/bookkeeping/intro/:id" exact element={<BookkeepingIntro />}></Route>
              <Route path="/bookkeeping/intro" exact element={<BookkeepingIntro />}></Route>
              <Route path="/bookkeeping/first-form/:id" exact element={<BookkeepingFirstForm />}></Route>
              <Route path="/bookkeeping/first-form" exact element={<BookkeepingFirstForm />}></Route>
              <Route path="/bookkeeping/charts/:id/:pageId" exact element={<BookkeepingCharts />}></Route>

              {/* Community Engagement Indicator Routes */}

              <Route path="/community/" exact index element={<CommunityStart setName={setName} />} />
              <Route path="/community/start" exact element={<CommunityStart setName={setName} />}></Route>
              {/* <Route path="/before-you-start" exact element={<PreAssessment />}></Route> */}
              {/* <Route path="/assessment" exact element={<Assessment />}></Route> */}
              <Route path="/community/assessment/:id/:pageId" exact element={<CommunityAssessmentV3 />}></Route>
              {/* <Route path="/assessment/:id/review" exact element={<Assessment />}></Route> */}
              {/* <Route path="/assessment/:id/:pageId/review" exact element={<AssessmentV3 />}></Route> */}
              <Route path="/community/pre-additional-info/:id" exact element={<CommunityPreAdditionalInfo />}></Route>
              <Route path="/community/pre-additional-questions/" exact element={<CommunityPreAdditionalQuestions />}></Route>
              {/* <Route path="/pre-additional-questions/:id" exact element={<PreAdditionalQuestions />}></Route> */}
              {/* <Route path="/assessment-additional/:id" exact element={<AssessmentAdditional />}></Route> */}
              {/* <Route path="/assessment-additional/:id/:pageId" exact element={<AssessmentAdditional />}></Route> */}
              <Route path="/community/assessment-additional/:id/:pageId" exact element={<CommunityAssessmentAdditionalV3 />}></Route>
              <Route path="/community/details/:id" exact element={<CommunityDetails />}></Route>
              <Route path="/community/results/:id" exact element={<CommunityResults />}></Route>
              <Route path="/community/invite-team/:id" exact element={<CommunityInvite />}></Route>
              <Route path="/community/invite-team-thanks/:id" exact element={<CommunityInviteThankYou />}></Route>
              <Route path="/community/dashboard/:id" exact element={<CommunityDashboard/>}></Route>
              <Route path="/community/chart/:id/:pageId" exact element={<CommunityDonutChart/>}></Route>
              {/* <Route path="/chart" exact element={<DonutChart/>}></Route> */}
              <Route path="/community/intro/:id" exact element={<CommunityIntro/>}></Route>
              <Route path="/community/intro" exact element={<CommunityIntro/>}></Route>
              <Route path="/community/first-form/:id" exact element={<CommunityFirstForm/>}></Route>
              <Route path="/community/first-form" exact element={<CommunityFirstForm/>}></Route>
              <Route path="/community/charts/:id/:pageId" exact element={<CommunityCharts/>}></Route>
              <Route path="/community/test" exact element={<CommunityTest />}></Route>

            </Routes>
          </main>
          <Footer />

        </BookkeepingQuestionsProgressContextProvider>
        </BookkeepingPreAdditionalQuestionContextProvider>
        </BookkeepingAdditionalQuestionContextProvider>
        </BookkeepingAssessmentQuestionContextProvider>
        </BookkeepingMiscContextProvider>
        </CommunityQuestionsProgressContextProvider>
        </CommunityPreAdditionalQuestionContextProvider>
        </CommunityAdditionalQuestionContextProvider>
        </CommunityAssessmentQuestionContextProvider>
        </InfosecQuestionsProgressContextProvider>
        </InfosecAssessmentQuestionContextProvider>
        </InfosecAdditionalQuestionContextProvider>
        </InfosecPreAdditionalQuestionContextProvider>
        </InfosecMiscContextProvider>
        </CongregationQuestionsProgressContextProvider>
        </CongregationPreAdditionalQuestionContextProvider>
        </CongregationAdditionalQuestionContextProvider>
        </CongregationAssessmentQuestionContextProvider>
        </CongregationChartsContextProvider>
      {/* </BrowserRouter> Router is now in index.js */}
      {/* </AssessmentProgressContextProvider> */}
    </div>
  );
}

export default App;
