import { createContext, useEffect, useState } from 'react';
import q4icon1 from '../../Assets/Images/Congregation/q4-icon1.svg';
import q4icon2 from '../../Assets/Images/Congregation/q4-icon2.svg';
import q4icon3 from '../../Assets/Images/Congregation/q4-icon3.svg';
import q9icon1 from '../../Assets/Images/Congregation/q9-icon1.svg';
import q13icon1 from '../../Assets/Images/Congregation/q13-icon1.svg';
import q15icon1 from '../../Assets/Images/Congregation/q15-icon1.svg';
import q15icon2 from '../../Assets/Images/Congregation/q15-icon2.svg';
import { getChartsValues } from '../../utils/chartsCalculations';

const CongregationChartsContext = createContext();

export function CongregationChartsContextProvider(props) {

  const [avgWeeklyAttd, setAvgWeeklyAttd]  = useState(0);
  const chartsData = getChartsValues(0, avgWeeklyAttd);

  const questions_statistics = [
    {
      id: 1,
      title: "",
      desc: <>A similarly sized American church typically has congregants attend church <span>1-12 times annually</span>.</>,
      iconContainerStyles: { },
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "80px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 2,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "45px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "40px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 3,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 4,
      title: null,
      desc: chartsData?.chart2Text,
      iconContainerStyles: { flex: 1 },
      image: { 
        src: null, 
        desktop: { height: "115px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "115px", width: "auto", maxWidth: "100%" }
      },
      icons: [
        { src: q4icon1, alt: "donors icon", desc: "Donors", value: chartsData?.avgDonorCount, 
          mobile: { height: "70px", width: "auto" }, 
          desktop: { height: "110px", width: "auto" }
        },
        { src: q4icon2, alt: "calendar icon", desc: "times a Year", value: chartsData?.donationTimes2, 
          mobile: { height: "70px", width: "auto" }, 
          desktop: { height: "110px", width: "auto" }
        },
        { src: q4icon3, alt: "amount icon", desc: "per Donor", value: chartsData?.donationPerDonor, 
          mobile: { height: "70px", width: "auto" }, 
          desktop: { height: "110px", width: "auto" }
        },
      ]
    },
    {
      id: 5,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 6,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "60px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "55px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 7,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "35px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "35px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 8,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 9,
      title: "",
      desc: chartsData?.chart3Text,
      iconContainerStyles: { flex: "none" },
      position: "right",
      image: { 
        src: null, 
        desktop: { height: "115px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "115px", width: "auto", maxWidth: "100%" }
      },
      icons: [
        { src: q9icon1, alt: "kids icon", desc: "", value: "", value2: chartsData?.avgNumOfKids, value2Styles: { position: "relative", bottom: "-25px", margin: "0 auto" }, 
          desktop: { height: "115px", width: "auto" }, 
          mobile: { height: "auto", maxHeight: "115px", width: "auto", maxWidth: "100%" }
        },
      ]
    },
    {
      id: 10,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "60px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "60px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 11,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "120px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "120px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 12,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "35px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "35px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 13,
      title: "",
      desc: chartsData?.chart4Text,
      position: "right",
      iconContainerStyles: { flex: "none" },
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      },
      icons: [
        { src: q13icon1, alt: "volunteers icon", desc: "", value: "", value2: chartsData?.avgVolunteerCount, value2Styles: { position: "relative", bottom: "-25px", margin: "0 auto" }, 
          desktop: { height: "115px", width: "auto" }, 
          mobile: { height: "auto", maxHeight: "115px", width: "auto", maxWidth: "100%" }
        },
      ]
    },
    {
      id: 14,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 15,
      title: "",
      desc: chartsData?.chart5Text,
      iconContainerStyles: { flex: 1 },
      image: { 
        src: null, 
        desktop: { height: "35px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "35px", width: "auto", maxWidth: "100%" }
      },
      icons: [
        { src: q15icon1, alt: "donors icon", desc: "Missional Donors", value: chartsData?.avgMissionalDonorCount, 
          mobile: { height: "70px", width: "auto" }, 
          desktop: { height: "110px", width: "auto" }
        },
        { src: q15icon2, alt: "amount icon", desc: "per year per donor", value: chartsData?.avgMissionalDonationPerDonor, 
          mobile: { height: "70px", width: "auto" }, 
          desktop: { height: "110px", width: "auto" }
        },
      ]
    },
    {
      id: 16,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "115px", width: "auto" }, 
        mobile: { height: "auto", maxHeight: "105px", width: "auto", maxWidth: "100%" }
      }
    },
    {
      id: 17,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 18,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "45px", width: "auto" }, 
        mobile: { height: "45px", width: "auto" }
      },
      position: "top"
    },
    {
      id: 19,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 20,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "45px", width: "auto" }, 
        mobile: { height: "30px", width: "auto" }
      }
    },
    {
      id: 21,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "60px", width: "auto" }, 
        mobile: { height: "60px", width: "auto" }
      }
    },
    {
      id: 22,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 23,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 24,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    },
    {
      id: 25,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "85px", width: "auto" }
      }
    },
    {
      id: 26,
      title: "",
      desc: "",
      image: { 
        src: null, 
        desktop: { height: "80px", width: "auto" }, 
        mobile: { height: "80px", width: "auto" }
      }
    }
  ];
  
  


  function updateAvgWeeklyAttd(attd) {
    setAvgWeeklyAttd(attd);
  }

  const context = {
    avgWeeklyAttd: avgWeeklyAttd,
    updateAvgWeeklyAttd: updateAvgWeeklyAttd,
    questions_statistics: questions_statistics
  };

  return <CongregationChartsContext.Provider value={context}>
    {props.children}
  </CongregationChartsContext.Provider>
}

export default CongregationChartsContext;
