import { createContext, useState } from 'react';


const BookkeepingAdditionalQuestionContext = createContext({
  questions: {},
  addQuestions: (addQuestions) => { },
  updateAnswer: (updateAnswer) => { },
  updateTextAnswer: (updateTextAnswer) => { },
  addBookkeepingSoftwareValues: (addBookkeepingSoftwareValues) => { },
  addBookkeepingSoftwareFeatures: (features) => { },
  softwareFeatures: '',
  bookkeepingSoftwareValues: [
    { label: "Acts", value: "Acts" },
    { label: "Aplos", value: "Aplos" },
    { label: "ChurchTrac", value: "ChurchTrac" },
    { label: "FreshBooks", value: "FreshBooks" },
    { label: "IconCMO", value: "IconCMO" },
    { label: "NetSuite", value: "NetSuite" },
    { label: "PowerChurch", value: "PowerChurch" },
    { label: "QuickBooks", value: "QuickBooks" },
    { label: "Quicken", value: "Quicken" },
    { label: "Realm", value: "Realm" },
    { label: "ShelbyNext Financials", value: "ShelbyNext Financials" },
    { label: "Wave", value: "Wave" },
    { label: "Xero", value: "Xero" },
    { label: "Zoho Books", value: "Zoho Books" },
  ],
});

export function BookkeepingAdditionalQuestionContextProvider(props) {

  const [questions, setQuestions] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [form1Details, setForm1Details] = useState({ zipCode: "", avgWeeklyAttd: "" });
  const [bookkeepingSoftwareFeatures, setBookkeepingSoftwareFeatures] = useState('')
  const [bookkeepingSoftwareValues, setBookkeepingSoftwareValues] = useState([
    { label: "Acts", value: "Acts" },
    { label: "Aplos", value: "Aplos" },
    { label: "ChurchTrac", value: "ChurchTrac" },
    { label: "FreshBooks", value: "FreshBooks" },
    { label: "IconCMO", value: "IconCMO" },
    { label: "NetSuite", value: "NetSuite" },
    { label: "PowerChurch", value: "PowerChurch" },
    { label: "QuickBooks", value: "QuickBooks" },
    { label: "Quicken", value: "Quicken" },
    { label: "Realm", value: "Realm" },
    { label: "ShelbyNext Financials", value: "ShelbyNext Financials" },
    { label: "Wave", value: "Wave" },
    { label: "Xero", value: "Xero" },
    { label: "Zoho Books", value: "Zoho Books" },
  ]);
  
  console.log(bookkeepingSoftwareFeatures);

  function addBookkeepingSoftwareValuesHandler(data) {
    setBookkeepingSoftwareValues(data);
    console.log('test context')
  }

  function addBookkeepingSoftwareFeaturesHandler(data) {
    setBookkeepingSoftwareFeatures(data);
    console.log('test context')
  }

  function addQuestionsHandler(data) {
    setQuestions(data);
  }

  function addFormDetailsHandler(data) {
    setFormDetails(data);
  }

  function addForm1DetailsHandler(data) {
    setForm1Details(data);
  }

  function updateAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answer = answer;
    let localQuestions = [];
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  function updateTextAnswerHandler(questionId, answer) {
    let question = questions.find(function (obj) { return obj.id == questionId; });
    question.answerTxt = answer;
    let localQuestions = [];
    console.log("We are here");
    questions.forEach(q => localQuestions.push(q));
    localStorage.setItem('additionalAnswers', JSON.stringify(localQuestions));
  }

  const context = {
    questions: questions,
    formDetails: formDetails,
    form1Details: form1Details,
    addQuestions: addQuestionsHandler,
    addFormDetails: addFormDetailsHandler,
    addForm1Details: addForm1DetailsHandler,
    updateAnswer: updateAnswerHandler,
    updateTextAnswer: updateTextAnswerHandler,
    bookkeepingSoftwareValues: bookkeepingSoftwareValues,
    addBookkeepingSoftwareValues: addBookkeepingSoftwareValuesHandler,
    addBookkeepingSoftwareFeatures: addBookkeepingSoftwareFeaturesHandler,
    softwareFeatures: bookkeepingSoftwareFeatures
  };

  return <BookkeepingAdditionalQuestionContext.Provider value={context}>
    {props.children}
  </BookkeepingAdditionalQuestionContext.Provider>
}

export default BookkeepingAdditionalQuestionContext;


