import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import CongregationAdditionalQuestionContext from '../../../Store/Congregation/CongregationAdditionalQuestionContext';

// import QuestionTemp from '../Components/Congregation/QuestionsTemp';
import CongregationQuestion from '../../../Components/Congregation/Questions/CongregationQuestions.jsx';

import prevArrow from '../../../Assets/Images/Congregation/prevArrow.png';
import nextArrow from '../../../Assets/Images/Congregation/next-arrow.svg';
import nextArrowWhite from '../../../Assets/Images/Congregation/next-arrow-white.svg';
import CongregationAssessmentProgressContext from '../../../Store/Congregation/CongregationAssessmentProgressContext';
import useWindowDimensions from "../../../Hooks/useWindowDimensions.js";


const CongregationAssessmentAdditionalV3 = () => {

  const { windowWidth } = useWindowDimensions();
  let navigate = useNavigate();
  const { id, pageId } = useParams();
  const assessProgressCtx = useContext(CongregationAssessmentProgressContext);
  const addCtx = useContext(CongregationAdditionalQuestionContext);

  console.log("\n\nAdditionalQuestionContext", addCtx, "\n\n");

  useEffect(() => {
    // document.body.style.backgroundColor = "#EEEEEE";

    getAdditional();
    localStorage.setItem("backFromAddQPage", true);
  }, []);


  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [prevValue, setPrevValue] = useState(0);
  const [textInput, setTextInput] = useState("");

  // const [nextDisabled, setNextDisabled] = useState(true);

  let backFromDetailsPage = localStorage.getItem("backFromDetailsPage") === "true" ? true : false;
  console.log("backFromDetailsPage", backFromDetailsPage);
  useEffect(() => {
    if (backFromDetailsPage) {
      setActiveStep(questions?.length - 1);
    }
    else setActiveStep(0)
  }, [backFromDetailsPage, questions?.length])

  useEffect(() => {
    if (questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(questions?.[activeStep]?.answerTxt);
    }
  }, [questions, activeStep]);

  useEffect(() => {
    if (addCtx.questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(addCtx.questions?.[activeStep]?.answerTxt);
    }
  }, [activeStep, addCtx]);

  function getAdditional() {

    if (addCtx.questions.length > 0) {
      setQuestions(addCtx.questions);
      setQuestionCount(addCtx.questions.length);
      setIsLoading(false);
    } else {

      let clientId = process.env.REACT_APP_CONGREGATION_CLIENT_ID;

      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
        // fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          console.log("data", data);
          // setQuestions(data.questions);
          setQuestions(data?.questions);
          setQuestionCount(data?.questions.length);
          addCtx.addQuestions(data?.questions);
          // localStorage.removeItem("backFromDetailsPage");
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error("Failed to fetch additional questions...", err));

    }

  }

  function completeAssessmentAdditional() {

    setIsLoading(true);

    const jsonBody = { questions, pageId: 818, };

    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/additional', {
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        setIsLoading(false);
        if(data.member == true) {
          navigate("/details/" + id + '?member=' + data.memberId + '&' + searchParams, { replace: true });
        //   navigate("/post-additional-info/" + id + '?' + searchParams, { replace: true });
        } else {
          navigate("/details/" + id + "?" + searchParams, { replace: true });
        // navigate("/post-additional-info/" + id + '?' + searchParams, { replace: true });
        }
      } else {
        setIsLoading(false);
  			// setOpenSnackbar(true);
      }
    }).catch((err) => {
      console.error("Failed to submit answers...", err);
      setIsLoading(false);
    });
  }

  const submitAnswer = () => {
    // const answerType = questions?.[activeStep]?.answerType || questions?.[activeStep]?.type;
    const jsonBody = {
      questions: [
        {
          "id": questions?.[activeStep]?.id,
          "answer" : 0,
          "answerTxt": textInput
        }
      ],
    }
    // console.log(jsonBody);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        setTextInput("");
        if (activeStep < (questions?.length - 1)) {
          setActiveStep(activeStep + 1);
          // setPrevValue(questions?.[activeStep + 1].answer);
        } else {
          navigate("/details/" + id + "?" + searchParams, { replace: true });
        }
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to send answer details...", err));
  }

  const prevQuestion = () => {
    if (activeStep === 0) {
      // if(searchParams.get("leader") || searchParams.get("member")) {
      //   navigate("/pre-additional-info2/" + id + '?' + searchParams, { replace: true });
      // } else 
      // navigate("/assessment/" + id + "/review" + '?' + searchParams, { replace: true });
      navigate("/assessment/" + id + "/849?" + searchParams, { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answerTxt);
    }
  };

  // const nextQuestion = () => {
  //   if(activeStep < questions.length) {
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answerTxt);
  //     // setTimeout(() => {
  //       setActiveStep((prev) => prev + 1);
  //       setPrevValue(questions[activeStep + 1].answer);
  //       addCtx.updateAnswer(questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //       setTextInput("");
  //     // }, 50);
  //   } else {
  //     setTimeout(() => { completeAssessmentAdditional(); }, 500);
  //   }
  // };
  const nextQuestion = () => {
    
    addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);

    const isMandatory = questions[activeStep]?.mandatory === "yes" || questions[activeStep]?.mandatory === true || questions[activeStep]?.mandatory === "true";
    const questionAnswered = questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length;

    if (activeStep < questions?.length - 1) {
      if (questions?.[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
        setActiveStep((prev) => prev + 1);
      }
    } else {
      if (isMandatory) {
        if (questionAnswered) {
          setTimeout(() => { completeAssessmentAdditional(); }, 500);
        }
      } else {
        setTimeout(() => { completeAssessmentAdditional(); }, 500);
      }
    }
  };

  console.log("questions", questions); console.log("addCtx.questions", addCtx.questions);
  console.log("questions[activeStep]", questions[activeStep]);

  const moveToNextQuestion = (answer) => {
    // console.log("answer", answer);
    setQuestions((prev) => prev.map((q, i) => i === activeStep ? { ...q, answer } : q));
    addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    
    if (activeStep < questions?.length - 1) {
      // if (questions?.[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
      if (answer > 0 || answer?.trim()?.length) {
        setTimeout(() => setActiveStep((prev) => prev + 1), 500);
      }
    } else {
      if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
        // if (questions[activeStep]?.answer > 0) {
        setTimeout(() => { completeAssessmentAdditional(); }, 500);
      }
    }

  }

  const updateTextQuestion = (answer) => {
    console.log("answer", answer);
    setTextInput(answer);
    addCtx.updateTextAnswer(questions[activeStep].id, answer);
  }

  const hasAnswers = () => {
    questions.forEach(question => {
      if (questions?.[activeStep].answerTxt != "") {
        return 1;
      }
    })
    return 0;
  }

  const nextDisabled = (answer) => {
    if (questions[activeStep]?.mandatory === "no") {
      return { display: "block" };
    } else {
      if (questions[activeStep]?.answerType === "slider" && questions[activeStep]?.answer !== 0) {
        return { display: "block" };
      } else if (questions[activeStep]?.answerType === "textarea" && questions[activeStep]?.answerTxt !== "") {
        return { display: "block" };
      }
    }
    return { display: "none" };
  }

  // const updateQuestion = (event, step) => {
  //   setTextField(event.target.value);
  //   questions[step].answer = event.target.value;
  //   // return true;
  // }

  const nextQuestionClass = () => {
    if (activeStep === 0) {
      return "next-btn";
    } else if (activeStep === questions?.length - 1) {
      return "all-set-btn next-btn";
    } else return "next-btn";
  }

  const nextArrowFunc = () => {
    if (activeStep === 0) {
      return nextArrow;
    }
    return nextArrowWhite;
  }

  const updateMultiSelectionCheckboxQuestion = (id, checkboxAnswers) => {
    // console.log("checkboxAnswers", checkboxAnswers, "id:", id);
    setQuestions(questions?.map((q) => {
      if (q.id === id) {
        q.answers = checkboxAnswers;
      }
      return q;
    }));
  }
  
  const updateSingleSelectionCheckboxQuestion = (id, answer, checkboxAnswers) => {
    // console.log("answer", answer, "id:", id);
    setQuestions((prev) => prev?.map((q) => {
      if (q.id === id) {
        q.answer = answer;
        q.answers = checkboxAnswers;
      }
      return q;
    }));

    if (activeStep < questions?.length - 1) {
      // if (questions?.[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
      if (answer > 0 || answer?.trim()?.length) {
        setTimeout(() => setActiveStep((prev) => prev + 1), 500);
      }
    } else {
      if (questions[activeStep]?.answer > 0 || questions[activeStep]?.answerTxt?.trim()?.length) {
        // if (questions[activeStep]?.answer > 0) {
        setTimeout(() => { completeAssessmentAdditional(); }, 500);
      }
    }

  }

  const getStepContent = (step) => {
    return (
      <CongregationQuestion question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} textAnswer={updateTextQuestion} updateMultiSelectionCheckboxQuestion={updateMultiSelectionCheckboxQuestion} updateSingleSelectionCheckboxQuestion={updateSingleSelectionCheckboxQuestion} />
    );
  };

  // const getStepContent = (step) => {
  //   if(step === 0) {
  //     return (
  //       <QuestionTemp question={questions[step].question} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
  //     );
  //   } else {
  //     return <div>
  //       <div className="question textarea">
  //         <p dangerouslySetInnerHTML={{__html: questions[step].question}}></p>
  //       </div>
  //       <div className="textarea-field">
  //         <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textField}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
  //       </div>
  //     </div>
  //   }

  // };

  const showNextButton = () => {
    if (activeStep === (questions?.length - 1)) {
      return <>
        ALL DONE!
      </>;
    } else if (activeStep === 1) {
      return <>
        {/* Next&nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        {/* NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" /> */}
        NEXT
      </>;
    } else {
      return <>
        {/* &nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        {/* NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" /> */}
        NEXT
      </>;
    }
  }

  const showAdditionalQHeader = () => {
    switch (activeStep) {
      case 0: return "IMPACT";
      case 1: return "INFLUENCE";
      case 2: return "IMPROVEMENT";
      default: return "IMPACT";
    }
  }

  console.log("\n\ntextInput", textInput);

  if (isLoading) {
    return <div className="container assessment-additional loading"><CircularProgress style={{ color: '#F26722' }} size={60} /></div>
  }

  return (
    <div className="container assessment-additional">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">
              <div className="spacer" style={{ height: windowWidth > 650 ? "30px" : "20px" }}></div>

              <h1>
                {/* {showAdditionalQHeader()} */}
                Additional question
                </h1>

              {/* <div className="spacer"></div> */}
              {/* <div className="progress-indicator"><strong>{activeStep + 1}</strong> /{questions.length}</div> */}

              {getStepContent(activeStep)}

              {/* <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;
                </Button>
                <Button className={nextQuestionClass()} onClick={nextQuestion} style={nextDisabled()}>
                  {showNextButton()}
                </Button>
              </div> */}

            </div>
          </div>
        </div>
      </div>

      <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevQuestion()}>
          <img src={prevArrow} className='prev-arrow' alt="Previous Page Arrow" />
        </Button>
        <Button className={"cta-next" + nextQuestionClass()} 
        disabled={textInput?.length > 0 || addCtx.questions?.[activeStep]?.answerTxt?.trim()?.length > 0 || questions?.[activeStep]?.answer > 0 || questions?.[activeStep]?.mandatory === "no" ? false : true}
        // disabled={textInput?.length || hasAnswers() > 0 ? false : true}
          // style={nextDisabled()} 
          onClick={() => nextQuestion()}
        >
          {/* NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" /> */}
          {showNextButton()}
        </Button>
      </div>

    </div>
  );
}

export default CongregationAssessmentAdditionalV3;
