import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from "react-router-dom";

// import Logo from '../../Assets/Images/Congregation/logo.png';
import logoLeft from '../../../../Assets/Images/Congregation/logo-left.png';
import logoRight from '../../../../Assets/Images/Congregation/logo-right.png';
import logoLeftMobile from '../../../../Assets/Images/Congregation/logo-left-mobile.png';
// import logoRightMobile from '../../../../Assets/Images/Congregation/logo-right-mobile.png';
// import bleatLogo from '../../../Assets/Images/Congregation/logo-bleat-desktop.png';
import bleatLogo from '../../../../Assets/Images/Congregation/bleat-logo.svg';
// import bleatLogoMobile from '../../../../Assets/Images/Congregation/bleat-logo-mobile.png';
// import logo from '../../../../Assets/Images/Congregation/logo.png';
import logoMobile from '../../../../Assets/Images/Congregation/logo-mobile.png';

import useWindowDimensions from '../../../../Hooks/useWindowDimensions';

// import '../../../../CongregationApp.scss'


const CongregationHeader = props => {

  const { windowWidth } = useWindowDimensions();
  const [resultPage, setResultsPage] = useState(false);
  const location  = useLocation();
  
  useEffect(() => {
    if (location.pathname.split("/").includes("results") || location.pathname.split("/").includes("invite-team") || location.pathname.split("/").includes("dashboard") ) {
      setResultsPage(true);
    }
  },[])
  return (
    <>
      <header style={{ backgroundColor: resultPage ? "#ffffff" : "#ffffff" }} className='congregation-header'>
        <div>
        <div className="header-wrapper">
        
        {windowWidth > 830 ? <div className="logo-left"><img src={logoLeft} alt="Logo" /></div> : <div className="logo-left"><img src={logoLeft} alt="Logo" /></div>}

          
          {/* <div className="assessment-title desktop" id="assessment-title">
            <p className="logo-text">Church Management Software Assessment</p>
            <p className="logo-subtext">ASSESSMENT</p>
          </div> */}

            {/* <div className="left-logo" style={{ }}><Link to ="/start"><img src={logoLeft} alt="church logo" width={170} height={64} style={{padding: "20px 30px 30px 30px"}}/></Link></div> */}

            <div className="assessment-title desktop">
              <p className="logo-text">Congregational Engagement Indicator</p>
              {/* <p className="logo-subtext">ASSESSMENT</p> */}
            </div>

          {windowWidth > 830 ? <div className="logo-right" style={{ }}><img src={logoRight} alt="Bleat" /></div> : <div className="logo-right"></div>}

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}

        </div>
        </div>

      </header>
    </>
  )
}

export default CongregationHeader;
