import { useState, useEffect, createContext } from 'react';
// import { useLocation, useNavigate, useParams } from "react-router-dom";

const BookkeepingMiscContext = createContext();

export function BookkeepingMiscContextProvider(props) {

    const [selectedSection, setSelectedSection] = useState("");


  const context = {
    selectedSection: selectedSection,
    setSelectedSection: setSelectedSection
  };

  return <BookkeepingMiscContext.Provider value={context}>
    {props.children}
  </BookkeepingMiscContext.Provider>
}

export default BookkeepingMiscContext;

