import { useState, useEffect, useContext } from 'react';

import Slider from '@mui/material/Slider';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import { Button, Checkbox, FormGroup, InputAdornment, IconButton } from '@mui/material';
import ReactSelectCreatable from 'react-select/creatable';
import BookkeepingAssessmentQuestionContext from '../../../Store/Bookkeeping/BookkeepingAssessmentQuestionContext';
import Select from 'react-select';
import textIcon from '../../../Assets/Images/Bookkeeping/text-icon.png';
import { Clear } from '@mui/icons-material';


const BookkeepingQuestions = (props) => {

  console.log("Question props", props);

  const assessCtx = useContext(BookkeepingAssessmentQuestionContext);
  const { windowWidth } = useWindowDimensions();
  const [showSlider, setShowSlider] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(90);
  const [maxWidth, setMaxWidth] = useState(props.question?.answers?.length);
  const [runTimeoutFunc, setRunTimeoutFunc] = useState(false);

  const [showOtherOptionTextBox, setShowOtherOptionTextBox] = useState(false);
  const [showOtherOptionTextInput, setShowOtherOptionTextInput] = useState(false);

  const [textAns, setTextAns] = useState(props.question?.answerTxt);
  const [checkboxAnswers, setCheckboxAnswers] = useState([]);
  const [noneBtnClicked, setNoneBtnClicked] = useState(false);
  const [otherBtnClicked, setOtherBtnClicked] = useState(false);
  const [selectedBookkeepingSoftwareValue, setSelectedBookkeepingSoftwareValue] = useState({ label: "Select/start typing", value: "Select/start typing" });
  const [bookkeepingTextAns, setBookkeepingTextAns] = useState(props.question?.answerTxt);
  const [selectTypeAnswerId, setSelectTypeAnswerId] = useState(null);
  const [selectedSelectOptionValue, setSelectedSelectOptionValue] = useState({ label: "Select/start typing", value: "Select/start typing", orderNo: '' });
  const [selectOptions, setSelectOptions] = useState([]);

  const yesNoQuestionTypeQsArray = [25];
  const isYesNoType = yesNoQuestionTypeQsArray?.includes(props?.question?.orderNo)

  // console.log(selectedBookkeepingSoftwareValue)

  // to map into select options
  useEffect(() => {
    const transformedOptions = props?.question?.answers?.map(answer => ({
      id: answer.id,
      label: answer.desktopText,
      value: answer.desktopText,
      ansValue: answer.value,
      orderNo: answer?.orderNo || answer?.order_no
    }));

    setSelectOptions(transformedOptions);

    if ((props.question?.answerType === "select" || props.question?.answerType === "search_select" || props.question?.type === "select") && props?.question?.optionId) {
      const selectedOption = transformedOptions?.find(item => item?.id === props?.question?.optionId);
      // console.log("selectedOption", selectedOption);
      setSelectedSelectOptionValue(selectedOption);
    } else {
      setSelectedSelectOptionValue({ label: "Select/start typing", value: "Select/start typing", orderNo: '' });
    }
  }, [props?.question, props]);



  // empty text area on question change
  useEffect(() => {
    if (!props.question?.answerTxt || props.question?.answerTxt == "") {
      setTextAns("");
    } else {
      setTextAns(props.question?.answerTxt);
    }

  }, [props.question]);

  useEffect(() => {
    if (props.question?.answerTxt?.length > 0 && !props.question?.optionId) {
      setShowOtherOptionTextBox(true)
    } else {
      setShowOtherOptionTextBox(false)
      // setSelectedBookkeepingSoftwareValue(props?.question?.isOtherValue);
    }
  }, [props.question]);


  useEffect(() => {
    if (props.question?.answerType === "checkbox" || props.question?.type === "checkbox") {
      setCheckboxAnswers(props.question?.answers);
      // if (props.question?.answers[props.question?.answers?.length - 1]?.checked) setShowOtherOptionTextBox(true);
    } else setCheckboxAnswers([]);
  }, [props.question?.answerType, props.question?.answers, props.question]);

  useEffect(() => {
    if (runTimeoutFunc) {
      const timer = setTimeout(() => {
        if (props.question?.answer == 0 || !props.question.answer) {
          setShowSlider(false);
        }
      }, 500);
      return () => {
        setRunTimeoutFunc(false);
        clearTimeout(timer);
      }
    }

  }, [props.question?.answer, runTimeoutFunc]);

  // console.log("showSlider", showSlider);

  useEffect(() => {
    if ((props.question?.answer == 0 || !props.question?.answer) && !progress) {
      setShowSlider(false);
    }
    else if (props.question?.answer > 0 || progress > 0) {
      setShowSlider(true);
    }
  }, [props.question?.answer, progress])

  useEffect(() => {
    if (props.question?.answer == 0 || !props.question?.answer) setProgress(0);
    // setProgress(props.question.answer);
    else if (props.question?.answer > 0) {
      const findAnsOption = props.question?.answers?.find((ans) => ans?.value === props?.question?.answer);
      setProgress(findAnsOption?.orderNo || findAnsOption?.order_no);
    }
  }, [props.question, props?.question?.id, props?.question?.orderNo]);

  useEffect(() => {
    switch (props.question?.answers?.length) {
      case 2: setSliderWidth(36); break;
      case 3: setSliderWidth(56); break;
      case 4: setSliderWidth(76); break;
      case 5: setSliderWidth(85); break;
      case 6: setSliderWidth(90); break;
      default: break;
    }

    setMaxWidth(props.question?.answers?.length);


  }, [props, props.question?.question, props.question?.answer]);

  // this is a custom update only for ACST Bookkeeping assessment
  // selection between None and Other for bookkeeping software dropdown
  useEffect(() => {
    const ansTxt = props.question?.answerTxt;

    if (ansTxt === "None" || ansTxt === "No") {

      setShowOtherOptionTextBox(false);
      setShowOtherOptionTextInput(false);
      setSelectTypeAnswerId(props.question?.answers[1]?.id)

    } else if ((ansTxt === "Yes") && ansTxt?.toString()?.trim()?.length) {

      setShowOtherOptionTextInput(true);
      setShowOtherOptionTextBox(false);
      setSelectTypeAnswerId(props.question?.answers[0]?.id)

    }
    //  else if ((ansTxt === "Other") && ansTxt?.toString()?.trim()?.length) {

    //   setShowOtherOptionTextInput(false);
    //   setShowOtherOptionTextBox(true);
    //   setSelectTypeAnswerId(props.question?.answers[1]?.id)

    // }

    // if ((ansTxt !== "None" && ansTxt !== "No") && (ansTxt?.toString()?.trim()?.length) && assessCtx?.bookkeepingSoftwareValues?.find(val => val.value === ansTxt)) {

    //   setShowOtherOptionTextBox(true);
    //   setSelectTypeAnswerId(props.question?.answers[1]?.id)
    //   let selected = assessCtx?.bookkeepingSoftwareValues?.find(val => val.value === ansTxt);
    //   setSelectedBookkeepingSoftwareValue(selected);
    //   selected?.value && setBookkeepingTextAns(selected?.value);

    // } else if ((ansTxt !== "None" && ansTxt !== "No") && (ansTxt?.toString()?.trim()?.length) && assessCtx.softwareFeatures === ansTxt) {

    //   setShowOtherOptionTextInput(true);
    //   setSelectTypeAnswerId(props.question?.answers[1]?.id)
    //   setBookkeepingTextAns(assessCtx.softwareFeatures);

    // } else if ((ansTxt !== "None" && ansTxt !== "No")) {

    //   setSelectTypeAnswerId(null);
    //   setShowOtherOptionTextBox(false);
    //   setShowOtherOptionTextInput(false)
    //   setBookkeepingTextAns('');
    //   props.textAnswer("", props?.question?.id);
    //   props.question.answerTxt = '';

    // }

    // console.log(ansTxt)

  }, [props.question]);


  console.log("props.question.answer", props.question);
  // console.log("progress", progress); console.log("showSlider", showSlider);


  const getLeft = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 20;
      case 6: return index * 16;
      default: return index * 20;
    }
  }

  const getLeftRadio = (index) => {
    switch (props.question?.answers?.length) {
      case 2: return index * 80;
      case 3: return index * 40;
      case 4: return index * 27;
      case 5: return index * 6;
      case 6: return index * 4;
      default: return index * 20;
    }
  }

  const getRadioWidth = (answers) => {

    switch (answers) {
      // case 3: return {width: "70%", marginLeft: "7.5%"};
      case 3: return { width: windowWidth > 1000 ? "50%" : windowWidth > 900 ? "50%" : windowWidth < 651 && windowWidth > 550 ? "90%" : windowWidth < 551 ? "95%" : "70%" };
      // case 4: return { width: "75%", margin: "0 auto" };

      case 4: return { width: windowWidth < 551 ? "95%" : windowWidth < 651 ? "90%" : windowWidth < 831 ? "80%" : "45ch", margin: "0 auto" };
      case 5: return { width: "90%", marginLeft: "2.5%" };
      // case 6: return { width: "100%", marginLeft: "0%" };
      case 6: return { width: "80%", margin: 'auto' };
      default: return { width: "50%" };
    }
    // if(answers === 3) { return {width: "70%", marginLeft: "7.5%"}; }
    // return {width: "50%"};
  }

  const radioOptionWidth = (answers) => {
    switch (answers) {
      case 3: return "90%";
      // case 4: return "90%";
      case 4: return "100%";
      case 5: return "20%";
      case 6: return "16%";
      default: return "20%";
    }
  }

  // console.log("progress", progress);

  // console.log("Q.No.", props?.question?.orderNo, "progress", progress);

  const updateStep = (ansVal, orderNo) => {
    // console.log("\n\nansVal", ansVal);
    // console.log("orderNo", orderNo, "\n\n");
    setShowSlider(true);
    // setTimeout(() => {setProgress(progressArg)}, 0);
    // props.questionAnswered(progressArg);
    setTimeout(() => { setProgress(orderNo) }, 0);
    // setTimeout(() => {setProgress(ansVal)}, 0);
    props.questionAnswered(ansVal);
    setRunTimeoutFunc(true);
  }

  const updateRadioStep = (ansVal, orderNo) => {

    setTimeout(() => { setProgress(orderNo) }, 0);
    props.questionAnswered(ansVal);
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  const updateMcqStep = (ansVal, orderNo, id) => {

    setTimeout(() => { setProgress(orderNo) }, 0);
    props.questionAnswered(ansVal);
    props.updateTextAnswerForMCQ(id, '')
    setTimeout(() => {
      // setProgress(0);
    }, 500);

  }

  // console.log("checkboxAnswers", checkboxAnswers);

  const getLabelStyle = () => {

    let answerType = props.question?.answerType || props.question?.type;

    // console.log("props.question", props.question);
    if (window.innerWidth > 650) {

      if (props.question?.answers?.length === 5) {
        // return { width: "100%", marginLeft: "-50%" };
        // return { width: "14ch", marginLeft: "-7ch" };
        if (answerType === "select") {
          return { textAlign: "left" };
        } else if (answerType === "radio" && props.question?.answers?.length > 3) {
          return { textAlign: "left" };
        } else {
          return { textAlign: "center" };
        }
      } else if (props.question?.answers?.length === 4) {
        return { width: "95%" };
      } else if (props.question?.answers?.length === 3 && props.question?.answerType === "radio") {
        // return { width: "200%", marginLeft: "-100%" };
        // return { width: "25ch" };
      } else if (props.question?.answers?.length === 2) {
        return { width: "400%", marginLeft: "-200%" };
      } else if (answerType === "radio" && props.question?.answers?.length > 3) {
        return { textAlign: "left" };
      } else {
        return { width: "auto", marginLeft: "0" };
      }
      // return { width: "14ch", marginLeft: "-7ch" };

    }
    else {

      if (props.question?.answers?.length === 6) {
        return { width: "9ch", marginLeft: "-4.5ch", wordBreak: "break-all" };
      }
      // if(props.question?.answers?.length === 5) {
      //   return { width: "120%", marginLeft: "-60%" };
      // } else if(props.question?.answers?.length === 4) {
      //   return { width: "150%", marginLeft: "-75%" };
      // } else if(props.question?.answers?.length === 3) {
      //   return { width: "300%", marginLeft: "-150%" };
      // } else if(props.question?.answers?.length === 2) {
      //   return { width: "500%", marginLeft: "-250%" };
      // } else {
      //   return { width: "150%", marginLeft: "-75%" };
      // }
      // else return { width: "12ch", marginLeft: "-6ch" };
      else if (props?.question?.answerType === "slider" || props?.question?.type === "slider") {
        // return { width: "9ch", marginLeft: "-4.5ch" };
        return { width: "10ch", marginLeft: "-5ch" };
      } else return { width: "auto" };
    }

  }

  const checkOpacity = (index) => {
    if (index === 0 && showSlider) return "0";
    else return "1";
  }

  const checkSelected = (optionOrderNo) => {

    if(props.question?.answerTxt == "not applicable"){
      return "label"
    }

    // const checkSelected = (data, value) => {
    // console.log("checkSelected data:", data, "value:", value);
    // if(progress === value) {
    if (progress && (Number(progress) === Number(optionOrderNo))) {
      // console.log("desktopText", data.desktopText);
      console.log(progress)
      return "label active";
    }
    else return "label";
  }
  const backgroundColorSet = (optionOrderNo) => {
    // const checkSelected = (data, value) => {
    // console.log("checkSelected data:", data, "value:", value);
    // if(progress === value) {
    if (progress && (Number(progress) === Number(optionOrderNo))) {
      // console.log("desktopText", data.desktopText);
      return "#F26722";
    }
    else return "#fff";
  }

  const updateSliderTextHighlight = (progress) => {
    // console.log("progress", progress);
    // console.log("desktopText", props?.question?.answers?.find(ans => ans.value === progress)?.desktopText);
    let activeSlider = null;
    // let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    let sliderText = props?.question?.answers?.find(ans => ans.value === progress)?.desktopText;
    switch (sliderText?.trim()) {
      case "Strongly Disgree":
        activeSlider = 1; break;
      case "Strongly<br/>Disgree":
        activeSlider = 1; break;
      case "Disagree":
        activeSlider = 2; break;
      case "Neutral":
        activeSlider = progress; break;
      case "Agree":
        activeSlider = 4; break;
      case "Strongly Agree":
        activeSlider = 5; break;
      case "Strongly<br/>Agree":
        activeSlider = 5; break;
      default:
        activeSlider = progress;
    }
    return activeSlider;
  }

  const updateQuestion = (event, step) => {
    // props.question.answerTxt = event.target.value;
    setTextAns(event.target.value);
    // setState({answerTxt: event.target.value});
    props.textAnswer(event.target.value, props?.question?.id);
    props.question.answerTxt = event.target.value;
    // return true;
    assessCtx.addBookkeepingSoftwareFeatures(event.target.value)
  }

  const updateQuestionForQuestionTypeSelect = (item, event, step, textVal) => {

    const text = textVal?.toString()?.trim()?.length > 0 ? textVal : event.target.value;

    if (item != null) {
      if (text === "Other") {
        setShowOtherOptionTextInput(false);
        setShowOtherOptionTextBox(true);
        setSelectTypeAnswerId(item.id);
        setBookkeepingTextAns('');
        props.textAnswer("", props?.question?.id);
        props.question.answerTxt = '';

      } else if (text === "Yes") {
        setShowOtherOptionTextBox(false);
        setShowOtherOptionTextInput(true);
        setSelectTypeAnswerId(item.id);
        setBookkeepingTextAns('');
        props.textAnswer("", props?.question?.id);
        props.question.answerTxt = '';
        setTextAns("")
      } else {
        setSelectedBookkeepingSoftwareValue({ label: "Select/start typing", value: "Select/start typing" })
        setShowOtherOptionTextBox(false);
        setShowOtherOptionTextInput(false);
        setSelectTypeAnswerId(item.id)
        setBookkeepingTextAns(text);
        props.textAnswer(text, props?.question?.id);
        props.question.answerTxt = text;
        setTimeout(() => { setProgress(item.value) }, 0);
        props.questionAnswered(text);
      }
    }

    // console.log(text)

  }

  const updateQuestionForQuestionTypeSelectDropdown = (item, event, step, textVal) => {

    const text = textVal?.toString()?.trim()?.length > 0 ? textVal : event.target.value;

    setBookkeepingTextAns(text);
    props.textAnswer(text, props?.question?.id);
    // props.question.answerTxt = text;

    // console.log(text)

  }

  // const updateSelectAnswer = (event) => {
  //   if (event.label === 'Other') {
  //     setShowOtherOptionTextBox(true)
  //   } else {
  //     setTimeout(() => { setProgress(event.orderNo) }, 0);
  //     props.questionAnswered(event.orderNo);
  //     setSelectedSelectOptionValue(event)
  //   }

  // }

  const updateSelectAnswer = (option) => {
    // console.log("updateSelectAnswer option", option);
    setTimeout(() => { setProgress(option.orderNo) }, 0);
    // props.questionAnswered(event.value);
    if (option?.label?.toLowerCase()?.trim() === "other") {
      setShowOtherOptionTextBox(true);
    } else {
      props.questionAnswered(option);
    }
    props.updateTextAnswer(props?.question?.id, option?.label);
    setSelectedSelectOptionValue(option);
  }

  function updateTextAns(e) {
    setTextAns(e.target.value);
    // third argument is for otherTextInput (not one of the dropdown options)
    props.updateTextAnswer(props?.question?.id, e.target.value, true);
  }

  function handleClearTextAnswer(e) {
    setTextAns("");
    setShowOtherOptionTextBox(false);
  }
  // console.log(props.question)

  // console.log("assessCtx.questions", assessCtx.questions);

  const handleMultiSelectionCheckboxes = (data, index, arr) => {

    // console.log("data", data); console.log("index", index); console.log("arr", arr);
    const optionId = data.id;
    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {
      if (item.id === optionId) {
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    setCheckboxAnswers(updatedCheckboxAnswers);
    // props.updateCheckboxQuestion(data.question_id, updatedCheckboxAnswers);
    props.updateMultiSelectionCheckboxQuestion(data.question_id, updatedCheckboxAnswers);

    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }
  }

  const handleSingleSelectionCheckboxes = (data, index, arr) => {

    // console.log("data", data); console.log("index", index); console.log("arr", arr);

    const optionId = data.id;
    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {
      if (item.id === optionId) {
        return { ...item, checked: !item.checked };
      } else {
        return { ...item, checked: false };
      }
    });

    // console.log("updatedCheckboxAnswers", updatedCheckboxAnswers);

    const answer = updatedCheckboxAnswers?.find(ans => ans.checked)?.value;

    setCheckboxAnswers(updatedCheckboxAnswers);
    props.updateSingleSelectionCheckboxQuestion(data.question_id, answer, updatedCheckboxAnswers);

    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }
  }

  const handleNone = () => {
    setTimeout(() => { setProgress(props?.question?.orderNo) }, 0);
    // second arg is for answeredAsNone
    props.questionAnswered(0, true);
  }

  const handleCheckboxes = (clickedOption, index, arr) => {

    // console.log("clickedOption", clickedOption); console.log("index", index); console.log("arr", arr);
    const questionId = props?.question?.id;
    const clickedOptionId = clickedOption?.id;
    const clickedOptionText = clickedOption?.desktopText?.toLowerCase()?.trim();
    // find the "None of the above" (nota) option nota = none of the above
    const notaOption = checkboxAnswers?.find(item => item?.desktopText?.toLowerCase()?.trim() === "none of the above");
    // check if the current option is "None of the above"/nota
    const clickedNoneOfTheAbove = clickedOptionText?.includes("none of the above");

    const updatedCheckboxAnswers = checkboxAnswers?.map((item) => {

      if (clickedNoneOfTheAbove) {
        // if the clicked option is nota, toggle it
        if (item?.id === notaOption?.id) {
          return { ...item, checked: !item.checked };
        } else if (item?.checked) {
          // if nota is checked, uncheck all other options
          return { ...item, checked: false };
        } else return item;
      } else if (notaOption?.checked && item.id !== clickedOptionId) {
        // if nota is checked and the current option is not nota, uncheck it
        return { ...item, checked: false };
      } else if (item.id === clickedOptionId) {
        // toggle the checked state of the current option
        return { ...item, checked: !item.checked };
      }
      return item;
    });

    setCheckboxAnswers(updatedCheckboxAnswers);
    props.updateCheckboxQuestion(questionId, updatedCheckboxAnswers);

    // provide input textbox for last option 
    // if (index === (arr?.length - 1)) {
    //   if (showOtherOptionTextBox) {
    //     setShowOtherOptionTextBox(false);
    //   } else {
    //     setShowOtherOptionTextBox(true);
    //   }
    // }

  }

  const handleQuestionTypeSelect = () => {

  }

  const handleNotApplicableQuestionSubmission = (e) => {
    const questionId = props?.question?.id;
    props.answerAsNotApplicable(questionId, e.target.checked);
  }

  const handleCreateNewDropdownOption = (inputValue) => {
    // console.log("inputValue", inputValue);
    let options = assessCtx.bookkeepingSoftwareValues
    const newOption = { label: inputValue?.toString()?.trim(), value: inputValue?.toString()?.trim() };
    options.push(newOption)
    assessCtx.addBookkeepingSoftwareValues(options);
    setSelectedBookkeepingSoftwareValue(newOption);
    setBookkeepingTextAns(newOption.value);
    // props.textAnswer(newOption.value, props?.question?.id);
    props.updateTextAnswer(props.question?.id, newOption.value);
  };

  // for maxwidth
  function getQuestionsMaxWidth() {
    const questionId = props.question?.id;
    let maxWidth = "65ch";
    switch (questionId) {
      case 1334: maxWidth = "50ch"; break;
      case 1335: maxWidth = "50ch"; break;
      case 1342: maxWidth = "50ch"; break;
      case 1346: maxWidth = "55ch"; break;
      case 1478: maxWidth = "50ch"; break;
      case 1483: maxWidth = "50ch"; break;
      default: maxWidth = "65ch"; break;
    }
    return maxWidth;
  }

  console.log(assessCtx)

  return (
    <div>

      <div className="question" style={{ minHeight: props?.question?.answerType === "radio" ? "140px" : props?.question?.answerType === 'select' ? '80px' : "140px", maxWidth: getQuestionsMaxWidth() }}>
        <p>{props.question?.question}</p>
      </div>

      {
        (() => {
          let answerType = props.question?.answerType || props.question?.type;

          switch (answerType) {

            case "checkbox":
              return <>
                <div className="question-checkbox-container">
                  <FormGroup>
                    {/* {props.question?.answers?.map((data, index, arr) => ( */}
                    {checkboxAnswers?.map((data, index, arr) => (
                      <FormControlLabel key={`labels-${data?.id}`} className="checkbox-label"
                        onChange={() => handleCheckboxes(data, index, arr)} control={
                          <Checkbox checked={data.checked || false} />} label={data.desktopText || data.desktop_text}
                        sx={{
                          '& .MuiSvgIcon-root': {
                            color: data.checked ? "#F26722" : "#343333", // Change colors based on checked state
                          },
                        }}
                      />
                    ))}
                  </FormGroup>
                  {
                    showOtherOptionTextBox
                      ? <TextField multiline rows={windowWidth < 651 ? 4 : 2} hiddenLabel placeholder="Please type your response here" value={textAns} onChange={(e) => { updateQuestion(e, 1) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" style={{ height: "auto" }} />
                      : null
                  }
                </div>
              </>

            case "textarea":
              return <>
                <div className="textarea-field">
                  {/* <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder="Please type your response here" value={props.question?.answerTxt} onChange={(e) => {updateQuestion(e, 1)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" /> */}
                  <TextField hiddenLabel multiline rows={windowWidth > 650 ? 4 : 6} placeholder="Please type your response here" value={textAns} onChange={(e) => { updateQuestion(e, 1) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                </div>
              </>

            case "radio":
              return <>
                <div className="radio-container">
                  <div className="slider-labels" >
                    {/* <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}> */}
                    {
                      props.question?.answers?.map((data, index) => (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={checkSelected(data.orderNo || data.order_no)} key={`labels-${index}`} style={{/* left: getLeftRadio(index) + '%',  width: radioOptionWidth(props?.question?.answers?.length),*/ display: "flex", cursor: "pointer" }} onClick={() => {
                          updateRadioStep(data.value, data.orderNo || data.order_no);
                        }}>
                          {/* <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                          {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                          <Radio checked={data.orderNo === progress} value={progress} sx={{ '&, &.Mui-checked': { color: '#ED2028', }, }} />
                          <p className="desktop" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                          <p className="mobile" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                        </div>
                      ))
                    }
                    {/* { 
                          props.question?.answers.map((data, index) => (
                            <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                            // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                          ))
                        } */}

                  </div>
                </div>
              </>

            case "multiple_choice":
              return <>
                <div className="multiple-choice-container">
                  <div className="slider-labels" style={getRadioWidth(props.question?.answers?.length)}>
                    {
                      props.question?.answers?.map((data, index) => (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={`button-${index} ${checkSelected(data.orderNo || data.order_no)}`} key={`labels-${index}`} style={{ left: getLeftRadio(index) + '%', /* width: radioOptionWidth(props?.question?.answers?.length),*/ display: "flex", cursor: "pointer", justifyContent: 'center', alignItems: 'center', width: '128px', height: '41px', borderRadius: '5px' }} onClick={() => {
                          updateMcqStep(data.value, data.orderNo || data.order_no, props.question?.id);
                        }}>
                          {/* <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}> */}
                          {/* <FormControlLabel value={data.value} control={<Radio />} label="" /> */}
                          <p className="desktop" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                          <p className="mobile" style={getLabelStyle()}>{data.desktopText || data.desktop_text}</p>
                        </div>
                      ))
                    }
                    {/* { 
                          props.question?.answers.map((data, index) => (
                            <FormControlLabel value={data.value} control={<Radio />} label={data.value} onClick={() => updateStep(data.value)} />
                            // <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }}></a>
                          ))
                        } */}

                  </div>

                  {props.question.na === "yes" &&
                    <FormGroup row className="not-applicable-container">
                      <FormControlLabel
                        control={<Checkbox checked={props.question?.skip || false || props.question?.answerTxt == "not applicable"} onChange={handleNotApplicableQuestionSubmission}
                          style={{ color: '#343333' }}
                        />}
                        label="Not Applicable" />
                    </FormGroup>
                  }

                </div>
              </>

            case "select":
              
              return <>
                {
                  isYesNoType ?
                    <div className="select-container yes-no-container">

                      <div className="select-btn-container">
                        {
                          props.question.answers.map((item, index) => <Button key={item.id} variant="contained" className={`select-btn none-btn ${selectTypeAnswerId == item.id ? 'selected' : ''}`} onClick={(e) => { updateQuestionForQuestionTypeSelect(item, e, 1, item.desktop_text || item.desktopText); }}>{item.desktop_text || item.desktopText}</Button>)
                        }
                      </div>

                      {
                        selectTypeAnswerId && showOtherOptionTextInput && isYesNoType
                          ? <>
                            <div className="text-input-field">
                              <TextField hiddenLabel placeholder="Please mention feature(s)" value={textAns} onChange={(e) => { updateQuestion(e, 1) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                            </div>
                          </>
                          : null
                      }
                    </div> :
                    <div className="select-container">

                      {!showOtherOptionTextBox ? <Select classNamePrefix="react-select" className="form-details-text react-select simple-select"
                        options={selectOptions}
                        onChange={(e) => updateSelectAnswer(e)}
                        value={selectedSelectOptionValue}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#EC1C24',
                            // primary50: '#ED2028',
                            primary: '#343333',
                            // neutral0: '#F4F4F4',
                            // neutral10: '#F4F4F4',
                            // neutral50: '#868686', // placeholder text color
                            // neutral90: '#343333',
                          },
                        })}
                        placeholder={"Select/Start Typing"} />
                        : <div className='other-container'>
                          <p className="other-option-text">Other</p>

                          <TextField hiddenLabel multiline rows={1} placeholder="Start Typing" value={textAns} onChange={(e) => { updateTextAns(e) }} fullWidth variant="outlined" size="normal"
                            InputProps={{
                              disableUnderline: true,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img src={textIcon} height={20} alt="text icon" />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={handleClearTextAnswer}>
                                    <Clear />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            className="form-details-text question-type-select" />

                        </div>

                      }
                      {/* <div className="select-btn-container">
                  {
                    props.question.answers.map((item, index) => <Button key={item.id} variant="contained" className={`select-btn none-btn ${selectTypeAnswerId == item.id ? 'selected' : ''}`} onClick={(e) => { updateQuestionForQuestionTypeSelect(item, e, 1, item.desktop_text || item.desktopText); }}>{item.desktop_text || item.desktopText}</Button>)
                  }
                </div> */}

                      <div className='select-btn-container'>
                        <Button variant="contained"
                          className={`select-btn none-btn ${noneBtnClicked ? 'selected' : ''}`}
                          onClick={(e) => {
                            setNoneBtnClicked(true);
                            setShowOtherOptionTextBox(false);
                            handleNone()
                          }}>None</Button>
                      </div>

                      {
                        //  selectTypeAnswerId&& showOtherOptionTextBox
                        //   ? <>
                        //     <p className="other-option-text">Please tell us the name of your bookkeeping software</p>
                        //     {/* <TextField hiddenLabel multiline rows={1} placeholder="E.g. Wave" value={bookkeepingTextAns} onChange={(e) => {updateQuestionForQuestionTypeSelect(e, 1)}} fullWidth variant="outlined" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text question-type-select" /> */}
                        //     <ReactSelectCreatable classNamePrefix="react-select"
                        //       className="form-details-text react-select question-type-select"
                        //       value={selectedBookkeepingSoftwareValue}
                        //       // onChange={(e) => {updateQuestionForQuestionTypeSelect(e, 1)}} 
                        //       onChange={(newValue, e) => {
                        //         setSelectedBookkeepingSoftwareValue(newValue);
                        //         updateQuestionForQuestionTypeSelectDropdown(null, e, 1, newValue?.value);
                        //       }}
                        //       options={assessCtx.bookkeepingSoftwareValues}
                        //       onCreateOption={handleCreateNewDropdownOption}
                        //       theme={(theme) => ({
                        //         ...theme,
                        //         colors: {
                        //           ...theme.colors,
                        //           primary25: '#FFFFFF',
                        //           primary50: '#ED202877',
                        //           primary: '#3B3A3A',
                        //           neutral0: '#F4F4F4',
                        //           neutral50: '#868686', // placeholder text color
                        //         },
                        //       })}
                        //       placeholder={"Start typing or select"}
                        //     />
                        //   </>
                        //   : null
                      }

                      {
                        selectTypeAnswerId && showOtherOptionTextInput && isYesNoType
                          ? <>
                            <div className="text-input-field">
                              <TextField hiddenLabel placeholder="Please mention feature(s)" value={textAns} onChange={(e) => { updateQuestion(e, 1) }} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
                            </div>
                          </>
                          : null
                      }
                    </div>
                }
              </>

            default:
              return <div className="slider-container" style={{ width: sliderWidth + '%' }}>
                <div className="slider">
                  {showSlider ? <Slider
                    key={props.question?.id}
                    valueLabelDisplay="off"
                    step={1}
                    min={1}
                    max={maxWidth}
                    value={progress}
                    // value={updateSliderTextHighlight(progress)}
                    className="custom-slider"
                  /> : <div className="slider-none-adjust"></div>}
                  <div className="slider-points">
                    {
                      props.question?.answers?.map((data, index) => (
                        <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value, data.orderNo || data.order_no)}></a>
                      ))
                    }
                  </div>
                  <LinearProgress className="custom-bar" variant="determinate" value={0} />

                </div>
                <div className="slider-labels">
                  {
                    props.question?.answers?.map((data, index) => {
                      return (
                        // were using checkSelected(data.value) here before this change to orderNo
                        <div className={checkSelected(data.orderNo || data.order_no)} key={`labels-${index}`} style={{ left: getLeft(index) + '%' }} onClick={() => updateStep(data.value, data.orderNo || data.order_no)}>
                          {/* <p className="desktop" style={getLabelStyle()}>{data.desktopText}</p> */}
                          <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.desktopText || data.desktop_text }} />
                          {/* <p className="mobile" style={getLabelStyle()}>{data.mobileText}</p> */}
                          <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{ __html: data.mobileText || data.mobile_text }} />
                        </div>
                      );
                    })}
                </div>
              </div>
          }
        }).call(this)
      }

      {/* <div className="slider-container" style={{ width: state.sliderWidth + '%' }}>
        <div className="slider">
          { state.showSlider ? <Slider
            key={props.question.id}
            valueLabelDisplay="off"
            step={1}
            min={1}
            max={state.maxWidth}
            value={state.progress}
            className="custom-slider"
          /> : <div className="slider-none-adjust"></div> }
          <div className="slider-points">
          {/* { !state.showSlider ? 
            <a className="point" style={{ left: '0%'}} onClick={() => updateStep(1)}></a> : null } */}
      {/* { 
              props.question?.answers.map((data, index) => (
                <a className="point" key={`points-${index}`} style={{ left: getLeft(index) + '%', opacity: checkOpacity(index) }} onClick={() => updateStep(data.value)}></a>
              ))
            }
          </div>
          <LinearProgress className="custom-bar" variant="determinate" value={0} />
          
        </div>
        <div className="slider-labels">
          { 
            props.question?.answers.map((data, index) => (
              <div className={checkSelected(data, data.value)} key={`labels-${index}`} style={{ left: getLeft(index) + '%'}} onClick={() => updateStep(data.value)}>
                <p className="desktop" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.desktopText }}></p>
                <p className="mobile" style={getLabelStyle()} dangerouslySetInnerHTML={{__html: data.mobileText }}></p>
              </div>
            ))
          }
        </div>
      </div> */}

    </div>
  );
}

export default BookkeepingQuestions;

