import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


// import QuestionTemp from '../Components/QuestionsTemp';
import CommunityQuestion from '../../../Components/Community/CommunityQuestions';

// import prevArrow from '../Assets/Images/prev-arrow.svg';
import prevArrow from '../../../Assets/Images/Community/prev-arrow.svg';
import nextArrow from '../../../Assets/Images/Community/next-arrow.svg';
import nextArrowWhite from '../../../Assets/Images/Community/next-arrow-white.svg';

import CommunityAdditionalQuestionContext from '../../../Store/Community/CommunityAdditionalQuestionContext';
import CommunityAssessmentProgressContext from '../../../Store/Community/CommunityAssessmentProgressContext';
import useWindowDimensions from "../../../Hooks/useWindowDimensions";
// import '../App.scss';


const AssessmentAdditionalV3 = () => {
  
  const { windowWidth }   = useWindowDimensions();
  let navigate            = useNavigate();
  const { id, pageId }    = useParams();
  const assessProgressCtx = useContext(CommunityAssessmentProgressContext);
  const addCtx 	          = useContext(CommunityAdditionalQuestionContext);
  
  console.log("\n\nAdditionalQuestionContext", addCtx, "\n\n");

  useEffect(() => {
    // document.body.style.backgroundColor = "#EEEEEE";

    getAdditional();
    localStorage.setItem("backFromAddQPage", true);
  }, []);
  

  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [prevValue, setPrevValue] = useState(0);
  const [textInput, setTextInput] = useState("");
  
  // const [nextDisabled, setNextDisabled] = useState(true);

  let backFromDetailsPage = localStorage.getItem("backFromDetailsPage") === "true" ? true : false;
  console.log("backFromDetailsPage", backFromDetailsPage);
  useEffect(() => {
    if (backFromDetailsPage) {
      setActiveStep(questions?.length - 1);
    }
    else setActiveStep(0)
  }, [backFromDetailsPage, questions?.length])

  useEffect(() => {
    if (questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(questions?.[activeStep]?.answerTxt);
    }
  }, [questions, activeStep]);

  useEffect(() => {
    if(addCtx.questions?.[activeStep]?.answerTxt?.length > 0) {
      setTextInput(addCtx.questions?.[activeStep]?.answerTxt);
    }
  }, [activeStep, addCtx]);

  function getAdditional() {

    if(addCtx.questions.length > 0) {
      setQuestions(addCtx.questions);
      setQuestionCount(addCtx.questions.length);
      setIsLoading(false);
    } else {

      let clientId              = process.env.REACT_APP_COMMUNITY_CLIENT_ID;
    
      // fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + clientId + '/additional', {
      fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id + '/pages/' + pageId, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' }
      }).then(response => {
        return response.json();
      }).then(data => {
        if(data.status === "success") {
          console.log("data", data);
          // setQuestions(data.questions);
          setQuestions(data?.data.questions);
          setQuestionCount(data?.data.questions.length);
          addCtx.addQuestions(data?.data.questions);
          // localStorage.removeItem("backFromDetailsPage");
          setIsLoading(false);
        } else {
          // setOpenSnackbar(true);
        }
      }).catch((err) => console.error("Failed to fetch additional questions...", err));

    }

  }

  // function completeAssessmentAdditional() {

  //   setIsLoading(true);

  //   fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/additional', {
	// 		method: 'PUT',
	// 		mode: 'cors',
	// 		headers: { 'Content-Type': 'application/json' },
	// 		body: JSON.stringify({
	// 			questions
	// 		})
	// 	}).then(response => {
	// 		return response.json();
	// 	}).then(data => {
	// 		if(data.status === "success") {
  //       setIsLoading(false);
  //       if(data.member === true) {
  //         navigate("/details/" + id + '?member=' + data.memberId + '&' + searchParams, { replace: true });
  //       //   navigate("/post-additional-info/" + id + '?' + searchParams, { replace: true });
  //       } else {
  //         navigate("/details/" + id + "?" + searchParams, { replace: true });
  //       // navigate("/post-additional-info/" + id + '?' + searchParams, { replace: true });
	// 		  } 
  //     } else {
  //       setIsLoading(false);
	// 			// setOpenSnackbar(true);
	// 		}
	// 	});
	// }

  const submitAnswer = () => {
    const jsonBody = { 
      questions: [
        {
          "id": questions?.[activeStep]?.id,
          "answer": 0,
          "answerTxt": textInput
        }
      ],
    }
    // console.log(jsonBody);
    fetch(process.env.REACT_APP_API_URI + '/v3/user/assessment/' + id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody)
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        // navigate("/assessment/" + id + "/" + pageId + "/?" + searchParams, { replace: true });
        setTextInput("");
        if(activeStep < (questions?.length - 1)) {
          setActiveStep(activeStep + 1);
          // setPrevValue(questions?.[activeStep + 1].answer);
        } else {
          navigate("/community/details/" + id + "?" + searchParams, { replace: true });
        }
        console.log("data", data);
      } else {
        // setIsSaving(false);
        // setOpenSnackbar(true);
      }
    }).catch((err) => console.error("Failed to send answer details...", err));
  }

  const prevQuestion = () => {
    if(activeStep === 0) {
      // if(searchParams.get("leader") || searchParams.get("member")) {
      //   navigate("/pre-additional-info2/" + id + '?' + searchParams, { replace: true });
      // } else 
      // navigate("/assessment/" + id + "/review" + '?' + searchParams, { replace: true });
      navigate("/community/assessment/" + id + "/38?" + searchParams, { replace: true });
    } else {
      setActiveStep(activeStep - 1);
      setPrevValue(questions[activeStep - 1].answerTxt);
    }
  };

  // const nextQuestion = () => {
  //   if(activeStep < questions.length) {
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //     console.log("test", questions[activeStep + 1].id, questions[activeStep + 1].answerTxt);
  //     // setTimeout(() => {
  //       setActiveStep((prev) => prev + 1);
  //       setPrevValue(questions[activeStep + 1].answer);
  //       addCtx.updateAnswer(questions[activeStep + 1].id, questions[activeStep + 1].answer);
  //       setTextInput("");
  //     // }, 50);
  //   } else {
  //     setTimeout(() => { completeAssessmentAdditional(); }, 500);
  //   }
  // };
  const nextQuestion = () => {
    // console.log("activeStep", activeStep); 
    // console.log("questions.length - 1", questions.length - 1);
    if(activeStep < questions.length - 1) {
      addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
      addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
      // setTimeout(() => {
        // setTextInput("");
        // setActiveStep(activeStep + 1);
        // setPrevValue(questions[activeStep + 1].answer);
      // }, 20);
    } else {
      // setTimeout(() => { completeAssessmentAdditional(); }, 500);
    }
    submitAnswer();
  };

  console.log("questions", questions); console.log("addCtx.questions", addCtx.questions);
  console.log("questions[activeStep]", questions[activeStep]);

  const moveToNextQuestion = (answer) => {
    // questions[activeStep].answer = answer;
    console.log("answer", answer);
    addCtx.updateAnswer(questions[activeStep].id, questions[activeStep].answer);
    addCtx.updateTextAnswer(questions[activeStep].id, questions[activeStep].answerTxt);
    // if(activeStep + 1 < questions.length) {
    //   setTimeout(() => {
    //     setActiveStep(activeStep + 1);
    //   }, 500);
    // } else {      
    //   setTimeout(() => { completeAssessmentAdditional(); }, 500);
    // }

    submitAnswer();

  }

  const updateTextQuestion = (answer) => {
    console.log("answer", answer);
    setTextInput(answer);
    // addCtx.updateTextAnswer(questions[activeStep + 1].id, answer);
    addCtx.updateTextAnswer(questions[activeStep].id, answer);
  }

  const hasAnswers = () => {
    questions.forEach(question => {
      if(questions?.[activeStep].answerTxt != "") {
        return 1;
      }
    })
    return 0;
  }

  const nextDisabled = (answer) => {
    if(questions[activeStep]?.mandatory === "no") {
      return {display: "block"};
    } else {
      if(questions[activeStep]?.answerType === "slider" && questions[activeStep]?.answer !== 0) {
        return {display: "block"};
      } else if(questions[activeStep]?.answerType === "textarea" && questions[activeStep]?.answerTxt !== "") {
        return {display: "block"};
      }
    } 
    return {display: "none"};
  }

  // const updateQuestion = (event, step) => {
  //   setTextField(event.target.value);
  //   questions[step].answer = event.target.value;
  //   // return true;
  // }
  
  const nextQuestionClass = () => {
    if(activeStep === 0) {
      return "";
    } else if(activeStep === questions?.length - 1) {
      return "all-set-btn nextbtn";
    } else return "nextbtn";
  }

  const nextArrowFunc = () => {
    if(activeStep === 0) {
      return nextArrow;
    }
    return nextArrowWhite;
  }

  const getStepContent = (step) => {
    return (
      <CommunityQuestion question={questions?.[step]} questionCount={questionCount} questionAnswered={moveToNextQuestion} currentValue={prevValue} textAnswer={updateTextQuestion} />
    );
  };

  // const getStepContent = (step) => {
  //   if(step === 0) {
  //     return (
  //       <QuestionTemp question={questions[step].question} questionAnswered={moveToNextQuestion} currentValue={prevValue}/>
  //     );
  //   } else {
  //     return <div>
  //       <div className="question textarea">
  //         <p dangerouslySetInnerHTML={{__html: questions[step].question}}></p>
  //       </div>
  //       <div className="textarea-field">
  //         <TextField hiddenLabel multiline rows={4} placeholder="Please type your response here" value={textField}  onChange={(e) => {updateQuestion(e, step)}} fullWidth variant="standard" size="normal" InputProps={{ disableUnderline: true }} className="form-details-text" />
  //       </div>
  //     </div>
  //   }
    
  // };

  const showNextButton = () => {
    if(activeStep === (questions?.length - 1)) {
      return <>
        NEXT
      </>;
    } else if(activeStep === 1) {
      return <>
        {/* Next&nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" />
      </>;
    } else {
      return <>
        {/* &nbsp;&nbsp;<img src={nextArrowFunc()} alt="Next Button" /> */}
        {/* NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" /> */}
        NEXT
      </>;
    }
  }

  console.log("\n\ntextInput", textInput);

  if (isLoading) {
		return <div className="container assessment-additional loading"><CircularProgress style={{ color: '#ED2028' }} size={60} /></div>
	}

  return (
    <div className="container assessment-additional">
      <Box sx={{ width: '100%' }}>
        <Stepper activeStep={activeStep}></Stepper>
      </Box>
      <div>
        <div className="steps-container">
          <div>
            <div className="steps">
              <div className="spacer" style={{ height: windowWidth > 650 ? "50px" : "20px" }}></div>

              <h1>{activeStep === 0 ? "IMPACT" :  "IMPROVEMENT"}</h1>

              {/* <div className="spacer"></div> */}
              <div className="progress-indicator"><strong>{activeStep + 1}</strong>/{questions.length}</div>

              {getStepContent(activeStep)}

              {/* <div className="step-buttons">
                <Button onClick={prevQuestion}>
                  <img src={prevArrow} />&nbsp;&nbsp;
                </Button>
                <Button className={nextQuestionClass()} onClick={nextQuestion} style={nextDisabled()}>
                  {showNextButton()}
                </Button>
              </div> */}
              
            </div>
          </div>
        </div>
      </div>
      
      <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevQuestion()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV</Button>
        <Button className={"cta " + nextQuestionClass()} disabled={textInput?.length > 0 || addCtx.questions?.[activeStep]?.answerTxt?.length > 0 ? false : true}
          // style={nextDisabled()} 
        onClick={() => nextQuestion()}
        //  disabled={textInput?.length || hasAnswers() > 0 ? false : true}
         >
          {/* NEXT&nbsp;&nbsp;<img src={nextArrow} alt="Next Page Arrow" /> */}
          {showNextButton()}
        </Button>
      </div>
      
    </div>
  );
}

export default AssessmentAdditionalV3;
