import { createContext, useEffect, useState } from 'react';

const CongregationQuestionsProgressContext = createContext();

export function CongregationQuestionsProgressContextProvider(props) {

  const [currentPage, setCurrentPage] = useState(28);
  const [currentQ, setCurrentQ] = useState(0);


  function updateCurrentPageHandler(page) {
    setCurrentPage(page);
  }

  const context = {
    currentPage: currentPage,
    currentQ: currentQ,
    updateCurrentPage: updateCurrentPageHandler,
  };

  return <CongregationQuestionsProgressContext.Provider value={context}>
    {props.children}
  </CongregationQuestionsProgressContext.Provider>
}

export default CongregationQuestionsProgressContext;
