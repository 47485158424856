import { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import churchImg from '../../../Assets/Images/Infosec/church.png';
import profitImg from '../../../Assets/Images/Infosec/for-profit.png';
import nonProfitImg from '../../../Assets/Images/Infosec/nonprofit.png';
import instituteImg from '../../../Assets/Images/Infosec/institute.png';
import rightTickImg from '../../../Assets/Images/Infosec/right-tick.png'; 


const categories = [
  { id: 1, img: churchImg, text: [""], name: "Church" },
  { id: 2, img: instituteImg, text: [""], name: "Educational Institution" },
  { id: 3, img: nonProfitImg, text: [""], name: "Non-Profit Organization" },
  { id: 4, img: profitImg, text: [""], name: "For-Profit Business" },
];


const InfosecPreAssessment = () => {
  
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(categories[0]); // default church selected

  function goToAssessmentPage() {
    // navigate("/assessment/" + id + "/814?" + searchParams, { replace: true });
    submitCategoryToApi();
  }

  useEffect(() => {
    const data = window.localStorage.getItem("SELECTED_CATEGORY");
    if (data !== null) setSelectedCategory(JSON.parse(data));
  }, []);

  useEffect(() => {
    window.localStorage.setItem("SELECTED_CATEGORY", JSON.stringify(selectedCategory));
  }, [selectedCategory]);


  const handleCategorySelect = (category) => {
    // console.log("category", category);
    setSelectedCategory(category);
  };


  const submitCategoryToApi = () => {

    const pageId = 845;
    let apiCall = `/v3/user/assessment/${id}/form/${pageId}`;
    const orgType = selectedCategory?.name;

    let jsonBody = JSON.stringify({
      details: { "organizationType": orgType?.trim() },
      noAutomation: true,
      // formVersion: 2
    });
    console.log(jsonBody);

    fetch(process.env.REACT_APP_API_URI + apiCall, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: jsonBody
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        // navigate("/infosec/assessment/" + id + "/814?" + searchParams, { replace: true });
        navigate("/infosec/explainer-infosec/" + id + "?" + searchParams, { replace: true });
      } else {
        console.error("Failed to submit selected category...", data);
      }
    }).catch((err) => {
      console.error("Failed to submit selected category...", err);
    });

  }



  return (
    <div className="container pre-assessment">
      <h1>Select your organization type</h1>
      <div className="assessment-categories">
        {categories.map((category) => (
          <div key={category.id}
            className={`category ${(selectedCategory?.id === category.id) ? "selected" : selectedCategory?.name ? "non-selected" : ""}`}
            onClick={() => handleCategorySelect(category)}
          >
            <div className={`right-tick-img-container`} style={selectedCategory?.id === category.id ? {} : { display: 'none' }}>
              <img src={rightTickImg} alt="" />
            </div>
            <div className="img-container">
              <img src={category.img} alt="" />
            </div>
            <p className="text">{category.name}</p>
          </div>
        ))}
      </div>
      <div className="button" style={selectedCategory ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <Button className="cta" onClick={goToAssessmentPage}>Continue</Button>
      </div>
    </div>
  );
}

export default InfosecPreAssessment;

