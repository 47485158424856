import { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Button } from "@mui/material";
import prevArrow from "../../../Assets/Images/Infosec/prev-arrow.svg";
import nextArrow from "../../../Assets/Images/Infosec/next-arrow.svg";
// import AssessmentProgressContext from "../Store/AssessmentProgressContext";

const InfosecPreAdditionalInfo = () => {

  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // const assessProgressCtx               = useContext(AssessmentProgressContext);

  // useEffect(() => {
  //   assessProgressCtx.updateMainSectionsStatus(assessProgressCtx?.mainSections?.map(sec => {
  //     if (sec.title === "Response") return { ...sec, status: "finished" };
  //     if (sec.title === "Relevance") return { ...sec, status: "active" };
  //     else return { ...sec, status: "unfinished" };
  //   }));
  // }, []);

  const nextPage = () => {
    if (!searchParams.get("leader")) {
      navigate("/pre-additional-questions/" + id + "?" + searchParams, { replace: true });
    } else navigate("/details/" + id + "?" + searchParams, { replace: true });
  }

  const prevPage = () => {
    if (!searchParams.get("leader")) {
      navigate("/assessment/" + id + "/review", { replace: true });
    } else navigate("/assessment/" + id + "/review?" + searchParams, { replace: true });
  }

  return (
    <div className="container pre-additional-info">
      <h1>Thanks, you're almost done!</h1>
      <div className="text-container">
        <p>This next section asks 6 contextual questions that will help shape relevant recommendations for you.</p>
        <p>For e.g., knowing what % of your budget is spent on your ChMS helps us confirm if you're overpaying for it. Or, knowing next year's weekend attendance goal helps check if your ChMS will grow with your church.</p>
        <p>Your responses to these 6 questions will remain confidential and will not even be shared with your team (when you invite them at the end to take this same test). The resulting insights will ensure the best return on investment and mission on your ChMS.</p>
      </div>
      {/* <Link to={assessmentLink()} className="cta-link"><Button className="cta">CONTINUE</Button></Link> */}
      <div className="step-buttons">
        <Button className="cta-prev" onClick={() => prevPage()}>
          <img src={prevArrow} alt="Previous Page Arrow" />&nbsp;&nbsp;PREV
        </Button>
        <Button className="cta" onClick={() => nextPage()}>CONTINUE</Button>
      </div>
    </div>
  )
}


export default InfosecPreAdditionalInfo;
