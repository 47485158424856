import React, { useEffect } from "react";
import TickImg from "../../../Assets/Images/Congregation/success-tick.svg";
import GraphImg from '../../../Assets/Images/Congregation/graph_Icon.png'
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

const CongregationInviteThankYou = () => {


  const { id } = useParams();

  useEffect(() => {
    // document.body.style.backgroundColor = "#292829";
  }, []);

  return (
    <>
      <div className="container thank-you">
        <div className="details-container">
          <div className="tick">
            <img src={TickImg} alt="" />
          </div>
          {/* <div className="tick">&#10003;</div> */}
          <h1>You have successfully sent invites!</h1>
          {/* <p>Once the whole team takes this assessment you will be able to access your group dashboard. Check your email to view your group dashboard.</p> */}
          {/* <p>You'll be notified as your Board Members take the test so you can review aggregate and individual results.</p> */}
          {/* <p>You'll be notified as your team is taking the test so that
				 you can review aggregate results and individual results.</p> */}
          <div className="back-btn">
            <Link to={"/congregation/results/" + id}>INVITE MORE TEAM MEMBERS</Link>
          </div>
        </div>
      </div>

      {/* <div className="schedule-container">
		<img src={GraphImg} alt="" />
        <h4>
          Schedule a call to have our team walk you through your report and see
          how you can accelerate generosity in your church.
        </h4>
        <button className="cta-btn">Schedule a call</button>
      </div> */}
    </>
  );
};

export default CongregationInviteThankYou;
