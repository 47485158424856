import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const HeadTagHelmet = () => {
  const location = useLocation();
  const assessment = location.pathname.split("/")[1];
  console.log("assessment", assessment, location.pathname.split("/")[1]);

  const [assessTitle, setAssessTitle] = useState("");
  const [assessDescription, setAssessDescription] = useState("");
  const [favicons, setFavicons] = useState([]);
  const [bugHerdId, setBugHerdId] = useState("");

  useEffect(() => {
    if (assessment.includes("community")) {
      setAssessTitle("Community Engagement Indicator | ACS Technologies");
      setAssessDescription(
        "Take our FREE 3-min assessment with your leadership team and see how well your congregational engagement strategy is serving your community."
      );
      setFavicons([
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon.ico" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
      ]);
      setBugHerdId("https://www.bugherd.com/sidebarv2.js?apikey=toci0jccw3z2cxhpwgxkng");
    } else if (assessment.includes("infosec")) {
      setAssessTitle("Information Security Indicator | Higher Ground by ACS Technologies");
      setAssessDescription(
        "Discover potential risks in your information security with our free 3-minute assessment. Identify vulnerabilities and learn best practices to enhance your data protection."
      );
      setFavicons([
        { rel: "icon", type: "image/png", sizes: "180x180", href: "/apple-touch-icon.png" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-infosec-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-infosec-16x16.png" },
        { rel: "manifest", href: "/site.webmanifest" },
        { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#5bbad5" },
      ]);
      setBugHerdId("https://www.bugherd.com/sidebarv2.js?apikey=uh9h3pgemnfg6haitkseya");
    } else if (assessment.includes("congregation")) {
      setAssessTitle("Congregational Engagement Indicator | ACS Technologies");
      setAssessDescription(
        "Take our FREE 3-min assessment with your leadership team and see how well your congregational engagement strategy is serving your community."
      );
      setFavicons([
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon.ico" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
      ]);
      setBugHerdId("https://www.bugherd.com/sidebarv2.js?apikey=aoku1kzhtsfowgwtle2ocq");
    } else if (assessment.includes("bookkeeping")) {
      setAssessTitle("Financial Discipline Indicator | ACS Technologies");
      setAssessDescription(
        "Take our FREE 3-min assessment to assess your financial practices. Discover potential risks to strengthen your shield against financial fraud and mismanagement."
      );
      setFavicons([
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon.ico" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
      ]);
      setBugHerdId("https://www.bugherd.com/sidebarv2.js?apikey=korjq7whunjv81izhwxjsg");
    } else {
      setAssessTitle("Missional Indicators | ACS Technologies");
      setAssessDescription(
        "Explore our suite of free missional indicators today so that we can get #strongertogether."
      );
      setFavicons([
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon.ico" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
      ]);
      setBugHerdId("https://www.bugherd.com/sidebarv2.js?apikey=lawvwanj6waqrxiffmxc9a");
    }
  }, [assessment]);

  return (
    <Helmet>
      <meta name="description" content={assessDescription} />
      <title>{assessTitle}</title>
      {favicons.map((icon, index) => (
        <link key={index} rel={icon.rel} type={icon.type} sizes={icon.sizes} href={icon.href} />
      ))}
      {/* BugHerd */}
      <script type="text/javascript" src={bugHerdId} async="true"></script>
    </Helmet>
  );
};

export default HeadTagHelmet;
