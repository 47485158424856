import React from 'react';
import { Link } from "react-router-dom";

// import Logo from '../../Assets/Images/logo.png';
import logoLeft from '../../../Assets/Images/Community/logo-left.png';
import logoRight from '../../../Assets/Images/Community/logo-right.png';
import logoLeftMobile from '../../../Assets/Images/Community/left-logo-mobile.png';
// import bleatLogo from '../../Assets/Images/logo-bleat-desktop.png';
// import bleatLogo from '../../Assets/Images/assessment-logo-right.svg';
import bleatLogoMobile from '../../../Assets/Images/Community/bleat-logo-mobile.png';
import useWindowDimensions from '../../../Hooks/useWindowDimensions';
import churchLogo from '../../../Assets/Images/Community/CHF-Logo-Color_white.png';

const CommunityHeader = props => {

  const { windowWidth } = useWindowDimensions();

  return (
    <>
      <header className='commmunity-header'>
        <div>
        <div className="header-wrapper">
        
          {/* windowWidth > 830 ? <Link to="/start" className="logo-left"><img src={logoLeft} alt="Logo" /></Link> : <Link to="/start" className="logo-left"><img src={logoLeftMobile} alt="Logo" /></Link> */}

          
          {/* <div className="assessment-title desktop" id="assessment-title">
            <p className="logo-text">Church Management Software Assessment</p>
            <p className="logo-subtext">ASSESSMENT</p>
          </div> */}

            {windowWidth < 830 ? 
              <div className="logo-left"><img src={logoRight} alt="church logo" /></div>
              :
              <div className="logo-left"><img src={logoRight} alt="Logo" /></div>
            }

            <div className="assessment-title desktop">
              <p className="logo-text">Community Engagement Indicator</p>
              {/* <p className="logo-subtext">INDICATOR</p>   */}
            </div>

            <div className="logo-right"></div>

          {/* {windowWidth > 830 ? <Link to="/community/start" className="logo-right" style={{ }}><img src={bleatLogo} width="auto" height={67.5} alt="Bleat" /></Link> : <Link to="/community/start" className="logo-right"></Link>} */}

        {/* {windowWidth <= 830 ?
          (<div className="bleat-logo-mobile">
            <img src={bleatLogoMobile} alt="bleat logo" height="43px" width="auto" />
          </div>) : (<div className="bleat-logo"><img src={bleatLogo} alt="bleat logo" height="57px" width="50px" /></div>)} */}

        </div>
        </div>

      </header>
    </>
  )
}

export default CommunityHeader;
